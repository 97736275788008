import { SignatureTemplate } from "@shared/types/signatureTemplate";
import React, { useState } from "react";
import { Button } from "src/components/Button";
import { colorsPallet, colorTokens } from "src/theme";
import { toDateFormat } from "src/utils/time";
import styled from "styled-components";
import { SignatureTemplateModal } from "./SignatureTemplateModal";
import { NonPostElement } from "src/pages/pta/PTAPersonalPage";
import { Spinner } from "src/components/icons/Spinner";

type Props = {
  signatureTemplates: SignatureTemplate[] | undefined;
  isLoading: boolean;
};

export const SignatureTemplateHistoryTable = ({
  signatureTemplates,
  isLoading,
}: Props) => {
  const [showSignatureModal, setShowSignatureModal] = useState<boolean>(false);
  const [selectedSignatureTemplateIndex, setSelectedSignatureTemplateIndex] =
    useState<number>(0);

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Spinner />
      </div>
    );
  return (
    <>
      {!signatureTemplates || signatureTemplates.length === 0 ? (
        <NonPostElement
          title="履歴がありません"
          message="規約をアップロードから作成できます"
        />
      ) : (
        <>
          {signatureTemplates.map((signatureTemplate, index) => (
            <Container key={signatureTemplate.id}>
              {index === 0 && <CurrentTag>現在の規約</CurrentTag>}
              <CardWrapper>
                <ContentWrapper>
                  <DateText>
                    {toDateFormat(new Date(signatureTemplate.createdAt))}
                  </DateText>
                  {signatureTemplate.comment &&
                    signatureTemplate.comment !== "" && (
                      <ContentText>
                        変更内容:{signatureTemplate.comment}
                      </ContentText>
                    )}
                  <ConfirmButton
                    onClick={() => {
                      setShowSignatureModal(!showSignatureModal);
                      setSelectedSignatureTemplateIndex(index);
                    }}
                  >
                    内容を確認
                  </ConfirmButton>
                </ContentWrapper>
              </CardWrapper>
            </Container>
          ))}
          {showSignatureModal && (
            <SignatureTemplateModal
              close={() => {
                setShowSignatureModal(false);
              }}
              signatureTemplate={
                signatureTemplates[selectedSignatureTemplateIndex]
              }
            />
          )}
        </>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dcdcdc;
  padding: 16px;
  background-color: white;

  &:last-child {
    border-bottom: none;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateText = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;

const ContentText = styled.p`
  margin-bottom: 8px;
  color: ${colorTokens.textSubdued};
`;

const CardWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const CurrentTag = styled.span`
  background-color: #ffe082;
  color: #333;
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 12px;
  margin-right: 8px;
  width: fit-content;
  margin-bottom: 8px;
`;

const ConfirmButton = styled(Button)`
  margin-top: 8px;
  background-color: transparent;
  padding: 0;
  color: ${colorsPallet.primary};
  width: fit-content;
`;
