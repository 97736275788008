// FIXME: Long lines (>1000 lines) CODE SMELLS
import React, { useMemo, useState } from "react";
import { useCurrentUser } from "../../../hooks/recoil/user";
import * as usersApi from "../../../apiClients/users";
import { CurrentUser } from "../../../apiClients/auth";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { usePolyfitHistory } from "../../../hooks/router";
import { Margin } from "../../../components/Margin";
import { getStaticImageUrl } from "../../../utils/getStaticImageUrl";
import { NoResults } from "../../../features/ListPage/NoResults";
import { Button } from "../../../components/Button";
import { ArrowRightIcon } from "../../../components/icons/ArrowRight";
import { toInputDateFormatYearMonth } from "../../../utils/time";
import { Avatar } from "../../../components/Common/Avatar";
import { MenuModal } from "../../../features/ListPage/MenuModal";
import { NameListPublishedModal } from "../../../features/ListPage/NameListPublishedModal";
import { ListBulkImportModal } from "../../../features/ListPage/ListBulkImportModal";
import { useFileDownload } from "../../../hooks/useFileDownload";
import { TabNavigation } from "../../../components/TabNavigation";
import { useTeacherUsers } from "../../../hooks/query/users/teacherUsers";
import { TeacherUser } from "../../../apiClients/users";
import * as csvAPI from "../../../apiClients/csv";
import { CSVLoadingModal } from "../../../components/Modal";
import { useToast } from "../../../components/Toast";
import { useSearchText } from "../../../hooks/recoil/searchText";
import { getClassName } from "../../../utils/getChildClass";
import { isCsAdminRole, isPtaAdminRole } from "../../../utils/types/role";
import { Spinner } from "../../../components/icons/Spinner";
import { useCurrentCommunityId } from "../../../hooks/router";
import { TextField as TextField2 } from "src/components/form/TextField";
import { ErrorView } from "src/components/ErrorView";
import { SearchIcon } from "src/components/icons/SearchIcon";
import { TextWithHighlight } from "src/components/TextWithHighlight";
import { useGetCurrentOrganization } from "../../../hooks/query/organization";
import { getGradeLabel } from "@shared/utils/getGradeLabel";

// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

/**
 * PTA名簿用ページ（教員）
 */
export function PTAListTeacherTab({
  setTab,
}: {
  setTab: (tab: "parent" | "teacher" | "resident") => void;
}) {
  const currentUser: CurrentUser = useCurrentUser();

  const [searchText, setSearchText] = useSearchText();

  const searchCondition = useMemo(() => {
    return { q: searchText, filter: {} };
  }, [searchText]);

  const {
    isLoading: isTeacherLoading,
    teachers,
    error,
  } = useTeacherUsers(searchCondition, {
    enabled: !!currentUser,
  });

  const isPtaAdmin = isPtaAdminRole(currentUser.role);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenPublishedModal, setIsOpenPublishedModal] = useState(false);
  const [isOpenListBulkImportModal, setIsOpenListBulkImportModal] =
    useState(false);

  // for CSV download
  const [isCSVLoading, setIsCSVLoading] = useState(false);
  const { download } = useFileDownload();
  const toast = useToast();

  const handleClickDownload = async () => {
    try {
      setIsCSVLoading(true);
      const blob = await csvAPI.exportCsv("TEACHER");
      const date = toInputDateFormatYearMonth(new Date());
      const fileName = `【外部流出厳禁】PTA名簿_${date}.csv`;
      download(blob, fileName);
    } catch (e) {
      toast.error("CSVのダウンロードに失敗しました");
    } finally {
      setIsCSVLoading(false);
    }
  };

  return (
    <>
      <NameListPage>
        <NameListHeadingRow>
          <NameListHeading>名簿</NameListHeading>
          {isPtaAdmin && (
            <NameListCSVButtonWrap>
              <CSVOutputButton fill onClick={handleClickDownload}>
                CSV出力
              </CSVOutputButton>
              <AdminMenuButton
                type="button"
                onClick={() => setIsOpenMenu(!isOpenMenu)}
              >
                ...
              </AdminMenuButton>

              <MenuModal
                isOpen={isOpenMenu}
                onClose={() => setIsOpenMenu(false)}
              />
            </NameListCSVButtonWrap>
          )}

          <NameListPublishedModal
            isOpen={isOpenPublishedModal}
            onClose={() => setIsOpenPublishedModal(false)}
          />
        </NameListHeadingRow>
        <TabNavigation
          elements={[
            ...(usersApi.inPTA(currentUser)
              ? [
                  {
                    isActive: false,
                    text: "保護者",
                    onclick: () => {
                      setTab("parent");
                      // reset();
                    },
                  },
                  {
                    isActive: true,
                    text: "教員",
                    onclick: () => {
                      setTab("teacher");
                      // reset();
                    },
                  },
                ]
              : []),
            ...(isCsAdminRole(currentUser.role)
              ? [
                  {
                    isActive: false,
                    text: "地域住民",
                    onclick: () => {
                      setTab("resident");
                      // reset();
                    },
                  },
                ]
              : []),
          ]}
        />

        <div style={{ display: "flex", gap: "8px" }}>
          <div style={{ flex: "auto" }}>
            <TextField2
              placeholder="氏名、所属、役職で検索"
              onChange={(text) => setSearchText(text)}
              value={searchText}
              prepend={<SearchIcon size={20} />}
            />
          </div>
        </div>

        <Margin marginBottom={10} />

        {isTeacherLoading ? (
          <LoadingCard>
            <Spinner />
          </LoadingCard>
        ) : error ? (
          <ErrorView error={error} />
        ) : teachers.length === 0 ? (
          <NoResults />
        ) : (
          <NameListTable>
            <NameListColumnNameRow head={true}>
              <NameListColumn>名前</NameListColumn>
              <NameListColumnWrapper>担任</NameListColumnWrapper>
              <NameListClassColumn isPtaAdmin={isPtaAdmin}>
                学年
              </NameListClassColumn>
              <NameListClassColumn isPtaAdmin={isPtaAdmin}>
                クラス
              </NameListClassColumn>
              {isPtaAdmin && (
                <NameListColumnWrapper>メールアドレス</NameListColumnWrapper>
              )}
              {isPtaAdmin && (
                <NameListColumnWrapper>電話番号</NameListColumnWrapper>
              )}
            </NameListColumnNameRow>
            {/* TODO: テーブル化 */}
            {teachers.map((teacher: TeacherUser, i: number) => {
              return (
                <TeacherListColumn
                  teacher={teacher}
                  isPtaAdmin={isPtaAdmin}
                  key={i}
                />
              );
            })}
          </NameListTable>
        )}
      </NameListPage>

      <ListBulkImportModal
        isOpenListBulkImportModal={isOpenListBulkImportModal}
        setIsOpenListBulkImportModal={setIsOpenListBulkImportModal}
        downloadCsvFile={handleClickDownload}
      />
      {isCSVLoading && <CSVLoadingModal />}
    </>
  );
}

const TeacherListColumn = ({
  teacher,
  isPtaAdmin,
}: {
  teacher: TeacherUser;
  isPtaAdmin: boolean;
}) => {
  const history = usePolyfitHistory();
  const { communityId } = useCurrentCommunityId();
  const { organization } = useGetCurrentOrganization();
  const [searchText] = useSearchText();

  return (
    <NameListColumnNameRow
      onClick={() =>
        history.push({
          to: "PTA_LIST_MEMBER",
          query: { userId: teacher.id },
        })
      }
    >
      <UserPicCell>
        <PreUserPicCell>
          <Avatar src={teacher.picture || defaultUserImg} alt="" size={40} />
        </PreUserPicCell>
        <PreUserNameCell>
          <Name>
            <TextWithHighlight text={teacher.name} keywords={[searchText]} />
          </Name>
          <NameListOrganizationData>
            {/* 本部 */} {/* 会長 */}
            {teacher.baseInfo?.belong?.name && (
              <TextWithHighlight
                text={teacher.baseInfo.belong.name}
                keywords={[searchText]}
              />
            )}
            {teacher.baseInfo?.part?.name && (
              <TextWithHighlight
                text={teacher.baseInfo.part.name}
                keywords={[searchText]}
              />
            )}
          </NameListOrganizationData>
        </PreUserNameCell>
        <NameButtonWrap>
          <Link
            to={{
              pathname: `/pta/list/member`,
              search: `userId=${teacher.id}&communityId=${communityId}`,
            }}
          >
            &gt;
          </Link>
        </NameButtonWrap>
      </UserPicCell>
      <Panel>
        {/* 担任の有無 */}
        {teacher.teacherInfo?.hasHomeroom ? "あり" : "なし"}
      </Panel>
      <Panel>
        {/* 学年 */}
        {getGradeLabel(
          teacher.teacherInfo?.grade,
          organization?.schoolDisplayType,
          true,
          false,
          "short"
        )}
      </Panel>
      <Panel>
        {/* クラス */}
        {getClassName(teacher.teacherInfo?.class)}
      </Panel>
      {isPtaAdmin && (
        <Panel>
          {/* TODO: 権限ないと見れないようにする */}
          <TextWithHighlight text={teacher.email} keywords={[searchText]} />
        </Panel>
      )}
      {isPtaAdmin && (
        <Panel>
          {/* 電話番号 */}
          {teacher.baseInfo?.phoneNumber}
        </Panel>
      )}
      <Panel>
        <ArrowRightIcon size={24} />
      </Panel>
    </NameListColumnNameRow>
  );
};

const NameListPage = styled.div`
  margin: 0 auto;
`;

const NameButtonWrap = styled.div`
  margin: auto;
  margin-right: 0;
  padding-right: 10px;
  display: none;
  @media (max-width: 1279px) {
    display: block;
  }
`;

const NameListCSVButtonWrap = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const AdminMenuButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: #e1e2e5;
  color: #343741;
  border-radius: 6px;
  border: none;
  margin-left: 12px;
  padding-bottom: 10px;
  cursor: pointer;
`;

const NameListHeadingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 16px;
`;

const NameListHeading = styled.div`
  font-weight: bold;
  font-size: 22px;
  color: #1a1c21;
`;

const NameListTable = styled.table`
  background-color: #fff;
  border: none;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  font-weight: normal;
`;

const LoadingCard = styled.div`
  background-color: #fff;
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NameListColumn = styled.th`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-align: left;
  padding: 12px 16px;
`;

const NameListColumnWrapper = styled.th`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-align: left;
  padding: 12px 16px;
  @media (max-width: 1279px) {
    display: none;
  }
`;

const Name = styled.div`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  text-align: left;
`;

const Panel = styled.th`
  color: #343741;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  padding-left: 16px;
  @media (max-width: 1279px) {
    display: none;
  }
`;

const NameListColumnNameRow = styled.tr<{ head?: boolean }>`
  background-color: #fff;
  ${(props) =>
    !props.head &&
    `border-top: 1px solid #e3e6eb; cursor: pointer; &:hover { filter: brightness(95%);}`};
`;

const UserPicCell = styled.th`
  color: #343741;
  font-size: 14px;
  display: flex;
  align-items: center;
  height: 100%;
`;

const NameListOrganizationData = styled.div`
  display: flex;
  gap: 4px;
  color: #69707d;
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  text-align: left;
`;

const PreUserPicCell = styled.div`
  padding: 14px;
`;

const PreUserNameCell = styled.div`
  padding: 14px 0;
`;

const CSVOutputButton = styled(Button)`
  padding: 9.5px 12px;
  width: 112px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`;

const NameListClassColumn = styled.th<{ isPtaAdmin: boolean }>`
  color: #343741;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  text-align: left;
  padding: 12px 16px;
  width: ${(props) => (props.isPtaAdmin ? "80px" : "20%")};
  @media (max-width: 1279px) {
    display: none;
  }
`;
