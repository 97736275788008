import React, { useState } from "react";
import styled from "styled-components";
import { PostType } from "../../hooks/api/post";
import { Badge } from "../../components/Common/Badge";
import { TargetBadge } from "../../components/Common/TargetBadge";
import { PdfBadge } from "./PdfBadge";
import { PostHistory } from "./PostHistory";
import { PostUserInfo } from "./PostUserInfo";
import HtmlViewer from "./HTMLViewer";
import { DeliverySummary } from "./DeliverySummary";
import { useCurrentUser } from "../../hooks/recoil/user";
import { AnswerSurvey } from "./AnswerSurvey/EntryPoint";
import { shouldShowAnswerSurvey } from "./AnswerSurvey/shouldShowAnswerSurvey";
import { AggregateSurvey } from "./AggregateSurvey/EntryPoint";
import { CheckSurvey } from "./CheckSurvey/CheckSurvey";
import { shouldShowAggregateSurvey } from "./AggregateSurvey/shouldShowAggregateSurvey";
import { shouldShowCheckSurvey } from "./CheckSurvey/shouldShowCheckSurvey";
import { showSurveyBadge, SurveyBadge } from "./SurveyBadge";
import { Buttons, PostEditable, PostSettingEditTab } from "./PostHeader";
import { SettingEditInfo } from "../../pages/pta/list/PTAMemberPage";
import { ModalBackGround } from "../../components/ListPageFilterSetting";
import { TrashPostButton } from "./TrashPostButton";
import { isPtaAdminRole } from "../../utils/types/role";
import { TargetGradesType } from "@shared/types/post/api";
import { SurveyQuestions } from "@shared/types/post/survey/survey";
import { getClassName } from "src/utils/getChildClass";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import { useGetCurrentOrganization } from "src/hooks/query/organization";

type props = {
  post: PostType;
  isOpen: boolean;
  type: "default" | "me" | "unapproved" | "undelivered";
  onPostApproved?: (post: PostType) => void;
  getPostBelongLabel: (target: TargetGradesType | string) => string;
  getPostUserGroupLabel: (target: string) => string;
  refetch: () => void;
};
export const PostList = ({
  post,
  isOpen,
  onPostApproved,
  type,
  getPostBelongLabel,
  getPostUserGroupLabel,
  refetch,
}: props) => {
  const { organization } = useGetCurrentOrganization();
  const [isSettingOpen, setIsSettingOpen] = useState<boolean>(false);
  const currentUser = useCurrentUser();
  const [isAlreadyAnswered, setIsAlreadyAnswered] = useState<boolean>(
    post.isAlreadyAnswered || false
  );

  const isAllUserPostTarget = post.targetAllTag;

  const targetGrades = [...post.targetGradeTags].sort((a, b) => a - b);
  const targetGradeAndClasses = post.targetGradeAndClasses;

  const targetBelongs = post.targetBelongIdTags;

  // メールイベントは2024年1月1日以前の連絡に対しては記録されていないので、それらではsummaryを表示しない
  const isPostCreatedAfter20240101 =
    post?.createdAt &&
    new Date(post.createdAt) > new Date("2024-01-01 00:00:00");

  // ポストに紐付いたアンケート情報
  const surveyQuestions: SurveyQuestions | undefined = post.surveyQuestions;
  // 「アンケートに回答」ボタンを表示する条件
  const showAnswerSurvey: boolean = shouldShowAnswerSurvey(
    post,
    surveyQuestions
  );
  // 「アンケート結果を見る」ボタンを表示する条件
  const showAggregateSurvey: boolean = shouldShowAggregateSurvey(
    currentUser,
    post,
    surveyQuestions
  );
  // 「アンケートを確認する」ボタンを表示する条件
  const showCheckSurvey: boolean = shouldShowCheckSurvey(
    currentUser,
    post,
    surveyQuestions
  );

  // 設定ボタンの表示フラグ
  const showSettingButton =
    (isPtaAdminRole(currentUser.role) || post.userId === currentUser.id) &&
    type === "default";

  return (
    <PostWrapper>
      <PostHeader>
        {isAllUserPostTarget && <Badge color="success">全員</Badge>}
        <PostTagUl>
          {post.status === "REMANDED" && (
            <li>
              <Badge color="danger">差し戻し</Badge>
            </li>
          )}

          {post.status === "UNAPPROVED" && (
            <li>
              <Badge color="danger">承認待ち</Badge>
            </li>
          )}

          {post.status === "DRAFT" && (
            <li>
              <Badge color="primary">下書き</Badge>
            </li>
          )}

          {post.status === "APPROVED" &&
            post.scheduledSendAt &&
            new Date(post.scheduledSendAt) > new Date() && (
              <Badge color="danger">投稿予約</Badge>
            )}

          {!isAllUserPostTarget &&
            targetGrades.map((tg, index) => (
              <li key={index}>
                <TargetBadge grade={tg}>
                  {getGradeLabel(
                    tg,
                    organization?.schoolDisplayType,
                    true,
                    true,
                    "full",
                    "年"
                  )}
                </TargetBadge>
              </li>
            ))}
          {!isAllUserPostTarget &&
            targetGradeAndClasses.map((value, index) => {
              return value.classes.map((classItem, classIndex) => (
                <TargetBadge key={index} grade={value.grade}>
                  {getGradeLabel(
                    value.grade,
                    organization?.schoolDisplayType,
                    true,
                    true,
                    "full",
                    "年"
                  )}
                  {getClassName(classItem, true, true)}
                </TargetBadge>
              ));
            })}
          {!isAllUserPostTarget &&
            targetBelongs.map((tg, index) => (
              <TargetBadge key={index}>{getPostBelongLabel(tg)}</TargetBadge>
            ))}
          {!isAllUserPostTarget &&
            post.targetPostUserGroupIdTags.map((tag, index) => (
              <TargetBadge key={index} type="userPostGroup">
                {getPostUserGroupLabel(tag)}
              </TargetBadge>
            ))}
          {showSurveyBadge(surveyQuestions) && <SurveyBadge />}
        </PostTagUl>
        <PostEditable onClick={(e) => e.stopPropagation()}>
          {showSettingButton && (
            <Buttons>
              <SettingButton
                onClick={() => {
                  setIsSettingOpen(!isSettingOpen);
                }}
              >
                ...
              </SettingButton>
            </Buttons>
          )}
          {isSettingOpen && (
            <>
              <ModalBackGround
                onClick={() => {
                  setIsSettingOpen(false);
                }}
              />
              <PostSettingEditTab>
                <SettingEditInfo>
                  <TrashPostButton post={post} refetch={refetch} />
                </SettingEditInfo>
              </PostSettingEditTab>
            </>
          )}
        </PostEditable>
      </PostHeader>
      <PostTitle isOpen={isOpen}>{post.title}</PostTitle>
      <PostText isOpen={isOpen}>
        <HtmlViewer htmlContent={post.text} />
      </PostText>
      {post.pdfFilePath && (
        <PdfBadge filePath={post.pdfFilePath} fileName={post.fileName}>
          {post.fileName}
        </PdfBadge>
      )}
      <PostUserInfo post={post} />
      {isOpen && type !== "default" && (
        <PostHistory
          post={post}
          type={type}
          onPostApproved={onPostApproved}
          refetch={refetch}
        />
      )}
      {isOpen && (
        <PostFooter>
          {showAnswerSurvey && (
            <AnswerSurvey
              postId={post.id}
              surveyQuestions={surveyQuestions}
              isAlreadyAnswered={isAlreadyAnswered}
              setIsAlreadyAnswered={setIsAlreadyAnswered}
            />
          )}
          {showCheckSurvey && <CheckSurvey surveyQuestions={surveyQuestions} />}
          <AlignRight>
            {showAggregateSurvey && (
              <AggregateSurvey
                surveyQuestions={surveyQuestions}
                postId={post.id}
                isAlreadyAnswered={isAlreadyAnswered}
              />
            )}
            {isPostCreatedAfter20240101 &&
              currentUser.isApprover &&
              type === "default" && <DeliverySummary postId={post.id} />}
          </AlignRight>
        </PostFooter>
      )}
    </PostWrapper>
  );
};

const PostWrapper = styled.div`
  padding: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const PostHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const PostTagUl = styled.ul`
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  gap: 5px;
`;

const PostTitle = styled.h2<{ isOpen: boolean }>`
  font-weight: bold;
  font-size: 22px;
  color: #1a1c21;
  overflow: hidden;
  word-wrap: break-word;
  ${(props) => props.isOpen == false && `  white-space: nowrap`};
  text-overflow: ellipsis;

  @media (max-width: 460px) {
    font-size: 18px;
  }
`;

const PostText = styled.p<{ isOpen: boolean }>`
  color: #343741;
  overflow: hidden;
  word-wrap: break-word;
  ${(props) => props.isOpen == false && `  white-space: nowrap`};
  text-overflow: ellipsis;

  @media (max-width: 460px) {
    font-size: 16px;
  }
`;

const PostFooter = styled.div`
  display: flex;
  flex-direction: column;
`;

const AlignRight = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
`;

const SettingButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: #e1e2e5;
  color: #343741;
  border-radius: 8px;
  border: none;
  padding-bottom: 8px;
  cursor: pointer;
  margin-left: 4px;
`;
