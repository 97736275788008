import React, { useMemo } from "react";
import styled from "styled-components";
import { unreachable } from "../utils/unreachable";
import { ApplicationStatus } from "@shared/types/recruitmentApplication";

type RecruitmentTagProps = { status: ApplicationStatus };

const RecruitmentTag = ({ status }: RecruitmentTagProps) => {
  const convertStatusText = useMemo(() => {
    switch (status) {
      case "APPLIED":
        return "選考中";
      case "APPROVED":
        return "参加決定";
      case "REJECTED":
        return "見送り";
      case "DECLINED":
        return "不参加";
      default:
        unreachable(status);
    }
  }, [status]);

  return <Tag value={status}>{convertStatusText}</Tag>;
};

const Tag = styled.p<{
  value: "APPLIED" | "APPROVED" | "REJECTED" | "DECLINED";
}>`
  background-color: ${(props) => {
    switch (props.value) {
      case "APPLIED":
        return "#FF7E62";
      case "APPROVED":
        return "#79AAD9";
      case "REJECTED":
        return "#EE789D";
      case "DECLINED":
        return "#6DCCB1";
      default:
        unreachable(props.value);
    }
  }};
  border-radius: 3px;
  display: flex;
  width: 72px;
  height: 27px;
  padding: 1px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  color: #fff;

  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 150% */
`;

export default RecruitmentTag;
