import { RecruitmentStatus } from "./recruitment";
import { CommunityUserType } from "./userType";

export const ApplicationStatus = {
  APPLIED: "APPLIED",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  DECLINED: "DECLINED",
} as const;

export type ApplicationStatus =
  (typeof ApplicationStatus)[keyof typeof ApplicationStatus];

export const ApplicationStatusWithAll = {
  ...ApplicationStatus,
  ALL: "ALL",
} as const;

export type ApplicationStatusWithAll =
  (typeof ApplicationStatusWithAll)[keyof typeof ApplicationStatusWithAll];

/**
 * 応募系APIクライアント 取得系
 */
export type RecruitmentApplication = {
  id: string;
  accountId: string;
  user: {
    id: string;
    email: string;
    name: string;
    picture: string | null;
  };
  coverLetterChatMessage?: {
    content: string;
  };
  status: ApplicationStatus;
  communityUserType: CommunityUserType | null;
  rejectedAt: Date;
  recruitment: {
    id: string;
    picture: string | null;
    title: string | null;
    status: RecruitmentStatus;
    createdBy?: string;
    schedule: {
      start: Date;
      end: Date;
    }[];
  };
};

export type ApplicationStatusCount = {
  status: ApplicationStatus;
  count: number;
};

export type ApplicationUserTypeCounts = {
  type: CommunityUserType;
  count: number;
};

export type ApplicationCountsForFilter = {
  statusCounts: ApplicationStatusCount[];
  userTypeCounts: ApplicationUserTypeCounts[];
};

export const RecruitmentApplicationListOrder = {
  USER_NAME: "USER_NAME",
  RECRUITMENT_TITLE: "RECRUITMENT_TITLE",
  RECRUITMENT_START_DATE: "RECRUITMENT_START_DATE",
} as const;

export type RecruitmentApplicationListOrder =
  (typeof RecruitmentApplicationListOrder)[keyof typeof RecruitmentApplicationListOrder];
