import React from "react";
import styled from "styled-components";
import * as theme from "../theme";
import { zIndexes } from "../zIndex";
import { logout } from "../utils/auth";
import { Button } from "./Button";
import { useFirebaseUserContext } from "../hooks/context/firebaseUser";

const NaxBox = styled.div`
  position: sticky;
  top: 0;
  z-index: ${zIndexes.fixedElement};
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${theme.colorsPallet.primary};
  padding: 12px 16px;
  height: 48px;
`;
const NavHeader = styled.div`
  flex-grow: 1;
  color: ${theme.colorsPallet.emptyShade};
  font-size: ${theme.typo.heading5};
  font-weight: 700;
  line-height: 24px;
`;

const ButtonWrap = styled.div`
  margin-left: auto;
  background: ${theme.colorsPallet.emptyShade};
  border-radius: 6px;
`;

function Navigation() {
  const { firebaseUser } = useFirebaseUserContext();

  return (
    <NaxBox>
      <NavHeader>polyfit</NavHeader>
      {firebaseUser && (
        <ButtonWrap>
          <Button color="secondary" empty size="small" onClick={logout}>
            {"ログアウト"}
          </Button>
        </ButtonWrap>
      )}
    </NaxBox>
  );
}

export { Navigation };
