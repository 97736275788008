import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { PencilIcon } from "../../../components/icons/PencilIcon";
import {
  EditBaseInfoIcon,
  EditLinkContainer,
  PCBaseInfoWrapper,
  StyledTable,
  StyledTd,
  StyledTh,
  StyledTr,
} from "../../../pages/pta/admin/ProfilePage";
import { Heading2 } from "../../../components/Typo";
import { ResidentInfoEditModal } from "./ResidentInfoEditModal";
import {
  dayOfWeekOptions,
  getDayOfWeekText,
} from "../../../utils/types/dayOfWeek";
import { useResidentInfo } from "../../../hooks/api/residentInfo";
import { useCurrentUser } from "../../../hooks/recoil/user";
import {
  usePolyfitHistory,
  usePolyfitLocationQuery,
} from "../../../hooks/router";
import { convertArrayToPreferredPaid } from "../../../validator/form/residentInfo/residentInfoFormSchema";

export const ResidentInfoContent = () => {
  const { isLoading, currentResidentInfo, refetchResidentInfo } =
    useResidentInfo();
  const currentUser = useCurrentUser();
  const { editModal } = usePolyfitLocationQuery("PTA_PROFILE", {
    userId: currentUser.id,
    editModal: undefined,
  });
  const [isOpen, setIsOpen] = useState(editModal === "show");
  const history = usePolyfitHistory();

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  useEffect(() => {
    if (editModal) {
      history.push(
        {
          to: "PTA_PROFILE",
          query: { userId: currentUser.id },
        },
        { replace: true }
      );
    }
  });

  return (
    <>
      <PCBaseInfoWrapper>
        <StyledFlexContainer>
          {isLoading ? (
            <>読み込み中…</>
          ) : (
            <div>
              <StyledContainerPc>
                <Title>配信・応募条件</Title>

                <StyledTable>
                  <StyledTr>
                    <StyledTh>希望活動曜日</StyledTh>
                    <StyledTd>
                      {!!currentResidentInfo?.preferredDays &&
                      currentResidentInfo?.preferredDays?.length > 0
                        ? currentResidentInfo?.preferredDays
                            ?.sort((a, b) => {
                              const indexA = dayOfWeekOptions.findIndex(
                                (dayObj) => dayObj.value === a
                              );
                              const indexB = dayOfWeekOptions.findIndex(
                                (dayObj) => dayObj.value === b
                              );
                              return indexA - indexB;
                            })
                            .map((day) => getDayOfWeekText(day) ?? "")
                            .join("、")
                        : "該当なし"}
                    </StyledTd>
                  </StyledTr>
                  <StyledTr>
                    <StyledTh>スキル</StyledTh>
                    <StyledTd>
                      {[
                        ...(currentResidentInfo?.teacherLicenses ?? []),
                        ...(currentResidentInfo?.medicalLicenses ?? []),
                        ...(currentResidentInfo?.skills ?? []),
                      ].join("、")}
                      {[
                        ...(currentResidentInfo?.teacherLicenses ?? []),
                        ...(currentResidentInfo?.medicalLicenses ?? []),
                        ...(currentResidentInfo?.skills ?? []),
                      ].length === 0 && "該当なし"}
                    </StyledTd>
                  </StyledTr>
                  <StyledTr>
                    <StyledTh>希望する活動内容（有償・無償）</StyledTh>
                    <StyledTd>
                      {currentResidentInfo?.preferredPaid &&
                        (currentResidentInfo.preferredPaid.includes("PAID") &&
                        currentResidentInfo.preferredPaid.includes("FREE")
                          ? "どちらでも"
                          : currentResidentInfo.preferredPaid.includes("PAID")
                          ? "有償"
                          : currentResidentInfo.preferredPaid.includes("FREE")
                          ? "無償"
                          : "該当なし")}
                    </StyledTd>
                  </StyledTr>
                  <StyledTr>
                    <StyledTh>希望ボランティア</StyledTh>
                    <StyledTd>
                      {currentResidentInfo?.preferredVolunteerType &&
                        (currentResidentInfo.preferredVolunteerType ?? []).join(
                          "、"
                        )}
                      {currentResidentInfo?.preferredPaid &&
                        (currentResidentInfo.preferredVolunteerType ?? [])
                          .length === 0 &&
                        "該当なし"}
                    </StyledTd>
                  </StyledTr>
                </StyledTable>
              </StyledContainerPc>

              <StyledContainerSp>
                <Title>配信・応募条件</Title>
                <StyledTableSp>
                  <StyledTr>
                    <StyledTHSp>希望活動曜日</StyledTHSp>
                    <StyledTdSp>
                      {!!currentResidentInfo?.preferredDays &&
                      currentResidentInfo?.preferredDays?.length > 0
                        ? currentResidentInfo?.preferredDays
                            ?.sort((a, b) => {
                              const indexA = dayOfWeekOptions.findIndex(
                                (dayObj) => dayObj.value === a
                              );
                              const indexB = dayOfWeekOptions.findIndex(
                                (dayObj) => dayObj.value === b
                              );
                              return indexA - indexB;
                            })
                            .map((day) => getDayOfWeekText(day) ?? "")
                            .join("、")
                        : "該当なし"}
                    </StyledTdSp>
                  </StyledTr>
                  <StyledTr>
                    <StyledTHSp>スキル</StyledTHSp>
                    <StyledTdSp>
                      {[
                        ...(currentResidentInfo?.teacherLicenses ?? []),
                        ...(currentResidentInfo?.medicalLicenses ?? []),
                        ...(currentResidentInfo?.skills ?? []),
                      ].join("、")}
                      {[
                        ...(currentResidentInfo?.teacherLicenses ?? []),
                        ...(currentResidentInfo?.medicalLicenses ?? []),
                        ...(currentResidentInfo?.skills ?? []),
                      ].length === 0 && "該当なし"}
                    </StyledTdSp>
                  </StyledTr>
                  <StyledTr>
                    <StyledTHSp>希望する活動内容（有償・無償）</StyledTHSp>
                    <StyledTdSp>
                      {currentResidentInfo?.preferredPaid &&
                      currentResidentInfo.preferredPaid.includes("PAID") &&
                      currentResidentInfo.preferredPaid.includes("FREE")
                        ? "どちらでも"
                        : currentResidentInfo?.preferredPaid &&
                          currentResidentInfo.preferredPaid.includes("PAID")
                        ? "有償"
                        : currentResidentInfo?.preferredPaid &&
                          currentResidentInfo.preferredPaid.includes("FREE")
                        ? "無償"
                        : "該当なし"}
                    </StyledTdSp>
                  </StyledTr>
                  <StyledTr>
                    <StyledTHSp>希望ボランティア</StyledTHSp>
                    <StyledTdSp>
                      {currentResidentInfo?.preferredVolunteerType &&
                        (currentResidentInfo.preferredVolunteerType ?? []).join(
                          "、"
                        )}
                      {(currentResidentInfo?.preferredVolunteerType ?? [])
                        .length === 0 && "該当なし"}
                    </StyledTdSp>
                  </StyledTr>
                </StyledTableSp>
              </StyledContainerSp>
            </div>
          )}
          {!isLoading && (
            <EditLinkContainer onClick={onOpen}>
              <PencilIcon />
              <EditBaseInfoIcon>編集</EditBaseInfoIcon>
            </EditLinkContainer>
          )}

          {isOpen && currentResidentInfo && (
            <ResidentInfoEditModal
              onClose={onClose}
              residentInfo={{
                ...currentResidentInfo,
                preferredPaid: convertArrayToPreferredPaid(
                  currentResidentInfo.preferredPaid
                ),
              }}
              fetch={refetchResidentInfo}
            />
          )}
        </StyledFlexContainer>
      </PCBaseInfoWrapper>
    </>
  );
};

const StyledContainerPc = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
`;
const StyledContainerSp = styled.div`
  @media (min-width: 500px) {
    display: none;
  }
`;

const StyledTableSp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
`;

export const PartnerInfoContainer = styled.div`
  width: 100%;

  @media (max-width: 1025px) {
    width: 100%;
  }
`;

const StyledFlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 480px) {
    display: block;
  }
`;

const StyledTHSp = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

const StyledTdSp = styled.p`
  font-size: 12px;
  word-break: break-all;
`;

export const Title = styled(Heading2)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #343741;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  font-size: 14px;
  color: #0077cc;
  padding: 5px;
`;
