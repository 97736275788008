import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "src/components/Button";
import { LinkLineModal } from "./LinkLineModal";
import { UnlinkLineModal } from "./UnlinkLineModal";
import { useGetLineAccount } from "src/hooks/query/lineAccount";
import { usePolyfitHistory, usePolyfitLocationQuery } from "src/hooks/router";
import { useCurrentUser } from "src/hooks/recoil/user";

export const LineLinkSettingSection = () => {
  const history = usePolyfitHistory();
  const currentUser = useCurrentUser();
  const [linkLineModalOpened, setLinkLineModalOpened] =
    useState<boolean>(false);
  const [linkLineModalMode, setLinkLineModalMode] = useState<
    "register" | "add_friend"
  >("register");

  const [unlinkLineModalOpened, setUnlinkLineModalOpened] =
    useState<boolean>(false);
  const { lineAccount, isLoading } = useGetLineAccount();

  const { requestLineFriendModal } = usePolyfitLocationQuery("PTA_PROFILE", {
    userId: "",
    requestLineFriendModal: undefined,
  });

  useEffect(() => {
    if (requestLineFriendModal === "show") {
      setLinkLineModalMode("add_friend");
      setLinkLineModalOpened(true);
      history.push(
        {
          to: "PTA_PROFILE",
          query: { userId: currentUser.id },
        },
        { replace: true }
      );
    }
  }, [requestLineFriendModal, history]);

  const handleNotificationLinkClick = () => {
    setLinkLineModalMode("add_friend");
    setLinkLineModalOpened(true);
  };

  const handleLinkButtonClick = () => {
    setLinkLineModalMode("register");
    setLinkLineModalOpened(true);
  };

  if (isLoading) return <></>;

  return (
    <>
      <SettingSection>
        <SettingMainItem>
          <SettingItemLabel>
            <SettingItemName>LINE連携</SettingItemName>
          </SettingItemLabel>
          <SettingAction>
            <div>
              {lineAccount ? (
                <>
                  <Button
                    size="small"
                    color="subPrimary"
                    onClick={() => setUnlinkLineModalOpened(true)}
                  >
                    連携中
                  </Button>
                </>
              ) : (
                <Button
                  size="small"
                  color="subPrimary"
                  onClick={handleLinkButtonClick}
                >
                  連携
                </Button>
              )}
            </div>
          </SettingAction>
        </SettingMainItem>
        <SettingItemDetailDescription>
          {lineAccount ? (
            <>
              連携済みLINEアカウント
              {lineAccount.displayName ? `（${lineAccount.displayName}）` : ""}
              と連携されています。
              <br />
              通知が届かない場合は
              <NotificationLink onClick={handleNotificationLinkClick}>
                こちら
              </NotificationLink>
            </>
          ) : (
            "お持ちのLINEアカウントで通知を受け取れるようになります"
          )}
        </SettingItemDetailDescription>
      </SettingSection>
      {linkLineModalOpened && (
        <LinkLineModal
          isOpen={linkLineModalOpened}
          close={() => {
            setLinkLineModalOpened(false);
          }}
          mode={linkLineModalMode}
        />
      )}
      {unlinkLineModalOpened && (
        <UnlinkLineModal
          isOpen={unlinkLineModalOpened}
          close={() => {
            setUnlinkLineModalOpened(false);
          }}
        />
      )}
    </>
  );
};

const SettingMainItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SettingItemLabel = styled.div`
  font-weight: 700;
  font-family: "Inter";
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
`;

const SettingItemName = styled.span`
  font-weight: 700;
  font-family: "Inter";
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
`;

const SettingSection = styled.div``;

const SettingItemDetailDescription = styled.p`
  font-weight: 400;
  font-family: "Inter";
  font-style: normal;
  font-size: 12px;
  line-height: 19px;
  color: #808080;
  margin-top: 4px;
`;

const SettingAction = styled.div`
  flex-shrink: 0;
  display: flex;
  gap: 10px;
`;

const NotificationLink = styled.span`
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;
