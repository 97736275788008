import React, { useCallback, useState } from "react";
import {
  ModalBody,
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "../../../components/Modal";
import styled from "styled-components";
import { Margin } from "../../../components/Margin";
import { ModalLabel } from "../../Post/CreatePostModal";
import { RecruitmentDateStatus } from "src/pages/resident/recruitment/ResidentRecruitmentApproveListPage";

interface Props {
  onSubmit: (comment: string) => void;
  onClose: () => void;
  recruitmentTitle: string;
  recruitmentDateStatus: RecruitmentDateStatus;
}

export default function SelfDeclineApplicationModal({
  onSubmit,
  onClose,
  recruitmentTitle,
  recruitmentDateStatus,
}: Props) {
  const [text, setText] = useState<string>("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const dateStatus = recruitmentDateStatus;

  const onChangeText = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.value.length <= 3000) {
        setText(e.target.value);
        setShowErrorMessage(false);
      } else {
        setShowErrorMessage(true);
      }
    },
    []
  );

  const handleSubmit = useCallback(() => {
    if (showErrorMessage) return;
    onSubmit(text);
  }, [showErrorMessage, text, onSubmit]);

  switch (dateStatus) {
    case "NotStarted":
      return (
        <ModalPortal onClose={onClose}>
          <ModalHeader>参加を不参加</ModalHeader>
          <ModalBody>
            <div>
              「{recruitmentTitle}
              」への参加を不参加にします。一度不参加した募集には再度応募することはできません。
            </div>
            <Margin marginTop={15} />
            <ModalLabel>不参加理由</ModalLabel>
            <Margin marginTop={5} />
            <TextArea
              name=""
              id=""
              cols={30}
              rows={10}
              value={text}
              onChange={onChangeText}
              placeholder="入力してください（3000文字以内）"
            />
            <ModalSubmitButtons
              submitColor="danger"
              submitText="参加を不参加にする"
              onSubmit={handleSubmit}
              disabled={showErrorMessage}
              onCancel={onClose}
            />
          </ModalBody>
        </ModalPortal>
      );
    case "IsToday":
      return (
        <ModalPortal onClose={onClose}>
          <ModalHeader>参加を不参加</ModalHeader>
          <ModalBody>
            <div>
              当日のため不参加に変更できません。管理者に直接ご連絡ください。
            </div>
            <ModalSubmitButtons submitText="OK" onSubmit={onClose} />
          </ModalBody>
        </ModalPortal>
      );
    case "Started":
      return (
        <ModalPortal onClose={onClose}>
          <ModalHeader>参加を不参加</ModalHeader>
          <ModalBody>
            <div>
              この募集は既に開始しているため不参加に変更できません。管理者に直接ご連絡ください。
            </div>
            <ModalSubmitButtons submitText="OK" onSubmit={onClose} />
          </ModalBody>
        </ModalPortal>
      );
  }
}
const TextArea = styled.textarea`
  width: 100%;
  background-color: #fbfcfd;
  border: 1px solid rgba(19, 34, 149, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 8px;
`;
