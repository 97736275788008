import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useApiContext } from "src/apiClients/client";
import { getRecruitmentList } from "src/apiClients/recruitment";
import { QueryOptions } from "src/hooks/query/QueryOptions";
import { RecruitmentListQueryType } from "@shared/validator/features/recruitment.schema";

export const useRecruitmentList = (
  condition: RecruitmentListQueryType,
  queryOptions: QueryOptions = {}
) => {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: ["api", "recruitment", "list", apiContext, condition],
    queryFn: async () => await getRecruitmentList(condition),
    ...queryOptions,
  });

  return {
    recruitments: useMemo(() => query.data?.recruitments || [], [query.data]),
    totalCount: query.data?.totalCount || 0,
    hasNextPage: query.data?.hasNextPage || false,
    ...query,
  };
};
