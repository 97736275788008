import { createRoute } from "..";
import AppDefaultLayout from "../../layouts/AppDefaultLayout";
import ProfilePage from "../../pages/pta/admin/ProfilePage";
import SignatureTemplatesPage from "../../pages/pta/admin/SignatureTemplatesPage";
import PTAListPage from "../../pages/pta/list/PTAListPage";
import PTAMemberPage from "../../pages/pta/list/PTAMemberPage";
import { ResidentOrganizationChatRegistrationHandler } from "../../pages/resident/chatMessage/ResidentOrganizationChatRegistrationHandler";
import EmptyLayout from "../../layouts/EmptyLayout";
import { LineLoginCallbackHandler } from "../../pages/line/LineLoginCallbackHandler";

/**
 *  認証済みのPTAとRESIDENT両方のルート
 */
export const COMMON_ROUTE_DEFS = {
  PTA_LIST: createRoute({
    path: "/pta/list",
    title: "名簿",
    component: PTAListPage,
    layout: AppDefaultLayout,
    route: "PTA_OR_CS_MEMBER",
  }),
  PTA_LIST_MEMBER: createRoute<{ userId: string }>({
    path: "/pta/list/member",
    title: "メンバー",
    component: PTAMemberPage,
    layout: AppDefaultLayout,
    route: "PTA_OR_CS_MEMBER",
  }),
  PTA_PROFILE: createRoute<{
    userId: string;
    editModal?: "show" | "close";
    requestLineFriendModal?: "show" | "close";
  }>({
    path: "/pta/profile",
    title: "プロフィール",
    component: ProfilePage,
    layout: AppDefaultLayout,
    route: "PTA_OR_CS_MEMBER",
  }),
  PTA_ADMIN_SIGNATURE_TEMPLATES: createRoute({
    path: "/pta/admin/signature_templates",
    title: "入会",
    component: SignatureTemplatesPage,
    layout: AppDefaultLayout,
    route: "PTA_OR_CS_MEMBER",
  }),
  RESIDENT_ORGANIZATION_CHAT_REGISTRATION_HANDLER: createRoute<{
    token: string;
  }>({
    path: "/organization_chat_registration_handler",
    title: "",
    component: ResidentOrganizationChatRegistrationHandler,
    layout: EmptyLayout,
    route: "PTA_OR_CS_MEMBER_OR_CS_GUEST",
  }),
  LINE_LOGIN_CALLBACK_HANDLER: createRoute<{
    code: string;
    state: string;
  }>({
    path: "/line/callback",
    title: "",
    component: LineLoginCallbackHandler,
    layout: EmptyLayout,
    route: "PTA_OR_CS_MEMBER",
  }),
};

export const commonRouters = Object.values(COMMON_ROUTE_DEFS);
