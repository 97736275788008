import { BaseRequest } from "./base";
import { ApplicationStatus } from "./recruitmentApplication";
import { ParticipantChatRoom } from "./participantChatRoom";
import { RecruitmentApplication } from "./recruitmentApplication";
import { Role } from "./role";

export const ApprovalStatus = {
  UNAPPROVED: "UNAPPROVED",
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
} as const;

export type ApprovalStatus =
  (typeof ApprovalStatus)[keyof typeof ApprovalStatus];

// chatRoom に一回でも入ったステータス
export const JoinedApprovalStatus: Array<ApprovalStatus> = [
  ApprovalStatus.APPROVED,
  ApprovalStatus.REJECTED,
] as const;

export const ChatRoomOrigin = {
  QRCODE: "QRCODE",
  CSADMIN: "CSADMIN",
  RECRUITMENT_APPLICATION: "RECRUITMENT_APPLICATION",
  BOARD_EDUCATION: "BOARD_EDUCATION",
} as const;
export type ChatRoomOrigin =
  (typeof ChatRoomOrigin)[keyof typeof ChatRoomOrigin];

export type UpdateChatRoomRequest = {
  chatRoomId: string;
};

export type UpdateAdminOnlyChatRoomRequest = {
  adminOnly: boolean;
} & UpdateChatRoomRequest;

export type GetChatRoomRequest = BaseRequest & {
  chatRoomId: string;
};

export const ChatRoomType = {
  RECRUITMENT_APPLICATION: "RECRUITMENT_APPLICATION",
  RECRUITMENT_APPLICATION_BOARD_EDUCATION:
    "RECRUITMENT_APPLICATION_BOARD_EDUCATION",
  INDIVIDUAL_CHAT_WITH_ORGANIZATION: "INDIVIDUAL_CHAT_WITH_ORGANIZATION",
  GROUP_CHAT_WITH_ORGANIZATION: "GROUP_CHAT_WITH_ORGANIZATION",
  INDIVIDUAL_CHAT_WITH_BOARD_EDUCATION: "INDIVIDUAL_CHAT_WITH_BOARD_EDUCATION",
  GROUP_CHAT_WITH_BOARD_EDUCATION: "GROUP_CHAT_WITH_BOARD_EDUCATION",
} as const;
export type ChatRoomType = (typeof ChatRoomType)[keyof typeof ChatRoomType];

export const boardEducationChatRoomTypes: ChatRoomType[] = [
  ChatRoomType.RECRUITMENT_APPLICATION_BOARD_EDUCATION,
  ChatRoomType.INDIVIDUAL_CHAT_WITH_BOARD_EDUCATION,
  ChatRoomType.GROUP_CHAT_WITH_BOARD_EDUCATION,
];

export type FilterApprovalStatus =
  | Extract<ApprovalStatus, "PENDING" | "APPROVED">
  | "";

export type ChatRoomFilterType = {
  type: ChatRoomType | "";
  recruitmentName?: string | null;
  recruitmentSchedule?: string | null;
  applicationStatus: ApplicationStatus | "";
  approvalStatus: FilterApprovalStatus;
  participantChatRoomApprovalStatus: FilterApprovalStatus;
  chatRoomName: string | "";
};

export type CreateChatRoomRequest = {
  accountId: string;
};

export type CreateGroupChatRoomRequest = {
  accountIds: string[];
  name: string;
};
export type UpdateGroupChatRoomRequest = {
  chatRoomId: string;
  accountIds: string[] | undefined;
  name: string;
};

export const approvalStatusOptions = [
  { value: "PENDING", text: "承認待ち" },
  { value: "APPROVED", text: "承認済み" },
] as const;

export const participantApprovalStatusOptions = [
  { value: "PENDING", text: "招待中" },
  { value: "APPROVED", text: "参加中" },
] as const;

export type ChatRoom = {
  id: string;
  name: string;
  communityId?: string;
  adminOnly: boolean;
  iconFilePath?: string;
  type: ChatRoomType;
  approvalStatus: ApprovalStatus;
  recordId: string;
  createdBy: string;
  createdAt: string;
  deletedAt: string;
  lastMessageCreatedBy: string;
  updatedAt: Date;
  origin: ChatRoomOrigin;
  ParticipantChatRooms: ParticipantChatRoom[];
};

type IndividualChat = {
  account: {
    id: string;
    userId: string;
    name: string;
    picture: string;
    role: Role;
  };
  organization: {
    id: string;
  };
};

export type IndividualChatWithOrganization = IndividualChat;

export type IndividualChatWithBoardEducation = IndividualChat & {
  boardEducation?: {
    name: string;
  };
};

export type GroupChatWithOrganization = {
  accounts: {
    id: string;
    userId: string;
    name: string;
    picture: string;
  }[];
  organization: {
    id: string;
  };
};

export type ChatRoomEntity = {
  chatRoom: ChatRoom;
  individualChatWithOrganization?: IndividualChatWithOrganization;
  individualChatWithBoardEducation?: IndividualChatWithBoardEducation;
  recruitmentApplication?: RecruitmentApplication;
  groupChatWithOrganization?: GroupChatWithOrganization;
  groupChatWithBoardEducation?: GroupChatWithOrganization;
  lastReadAt: Date;
  lastReadAts?: Date[];
};

export type ChatRoomListResponse = {
  chatRooms: ChatRoomEntity[];
  totalCount: number;
  hasNextPage: boolean;
};
