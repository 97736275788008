import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { PencilIcon } from "../../../components/icons/PencilIcon";
import {
  EditBaseInfoIcon,
  EditLinkContainer,
  PCBaseInfoWrapper,
  StyledTable,
  StyledTd,
  StyledTh,
  StyledTr,
} from "../../../pages/pta/admin/ProfilePage";
import { Heading2 } from "../../../components/Typo";
import { TeacherInfoEditModal } from "./TeacherInfoEditModal";
import { useTeacherInfo } from "../../../hooks/api/teacherInfo";
import { getClassName } from "../../../utils/getChildClass";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { getGradeLabel } from "@shared/utils/getGradeLabel";

export const TeacherInfoContent = () => {
  const { isLoading, currentTeacherInfo, refetchTeacherInfo } =
    useTeacherInfo();
  const { organization } = useGetCurrentOrganization();
  const [isOpen, setIsOpen] = useState(false);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  return (
    <>
      <PCBaseInfoWrapper>
        <StyledFlexContainer>
          {isLoading ? (
            <>読み込み中…</>
          ) : (
            <div>
              <StyledContainerPc>
                <Title>担任情報</Title>

                <StyledTable>
                  <StyledTr>
                    <StyledTh>担任の有無</StyledTh>
                    <StyledTd>
                      {currentTeacherInfo !== null ? "あり" : "なし"}
                    </StyledTd>
                  </StyledTr>
                  {currentTeacherInfo !== null && (
                    <>
                      <StyledTr>
                        <StyledTh>学年</StyledTh>
                        <StyledTd>
                          {getGradeLabel(
                            currentTeacherInfo?.grade,
                            organization?.schoolDisplayType,
                            true,
                            false,
                            "short"
                          )}
                        </StyledTd>
                      </StyledTr>
                      <StyledTr>
                        <StyledTh>クラス</StyledTh>
                        <StyledTd>
                          {getClassName(currentTeacherInfo?.class)}
                        </StyledTd>
                      </StyledTr>
                    </>
                  )}
                </StyledTable>
              </StyledContainerPc>

              <StyledContainerSp>
                <Title>担任情報</Title>
                <StyledTableSp>
                  <StyledTr>
                    <StyledTHSp>担任の有無</StyledTHSp>
                    <StyledTdSp>
                      {currentTeacherInfo !== null ? "あり" : "なし"}
                    </StyledTdSp>
                  </StyledTr>
                  <StyledTr>
                    <StyledTHSp>学年</StyledTHSp>
                    <StyledTdSp>{currentTeacherInfo?.grade}</StyledTdSp>
                  </StyledTr>
                  <StyledTr>
                    <StyledTHSp>クラス</StyledTHSp>
                    <StyledTdSp>
                      {getClassName(currentTeacherInfo?.class)}
                    </StyledTdSp>
                  </StyledTr>
                </StyledTableSp>
              </StyledContainerSp>
            </div>
          )}
          {!isLoading && (
            <EditLinkContainer onClick={onOpen}>
              <PencilIcon />
              <EditBaseInfoIcon>編集</EditBaseInfoIcon>
            </EditLinkContainer>
          )}

          {isOpen && (
            <TeacherInfoEditModal
              onClose={onClose}
              teacherInfo={currentTeacherInfo}
              fetch={refetchTeacherInfo}
            />
          )}
        </StyledFlexContainer>
      </PCBaseInfoWrapper>
    </>
  );
};

const StyledContainerPc = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
`;
const StyledContainerSp = styled.div`
  @media (min-width: 500px) {
    display: none;
  }
`;

const StyledTableSp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
`;

export const PartnerInfoContainer = styled.div`
  width: 100%;

  @media (max-width: 1025px) {
    width: 100%;
  }
`;

const StyledFlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 480px) {
    display: block;
  }
`;

const StyledTHSp = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

const StyledTdSp = styled.p`
  font-size: 12px;
  word-break: break-all;
`;

export const Title = styled(Heading2)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #343741;
  margin-bottom: 20px;
`;

export const Description = styled.p`
  font-size: 14px;
  color: #0077cc;
  padding: 5px;
`;
