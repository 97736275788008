import { EmptyObject } from "react-hook-form";
import * as client from "./client";
import { DayOfWeekType } from "../utils/types/dayOfWeek";
import { PaidType, VolunteerType } from "../@shared/types/volunteerType";
import {
  MedicalLicenseType,
  SkillType,
  TeachingLicenseType,
} from "../utils/types/license";
import { ChildClass } from "./child";
import { UserType } from "../utils/types/userType";
import { ServiceType } from "../utils/types/serviceType";
import { Role } from "../utils/types/role";
import {
  GetAccountsResponse,
  ResendEmailVerificationMailRequest,
  ResendEmailVerificationMailResponse,
  SignupWithInvitationResponse,
  UpdateCommunityRolesByTokenRequest,
} from "@shared/types/auth";
import { CommunityUserType } from "@shared/types/userType";
import { SignupWithInvitationRequest } from "@shared/types/auth";
import { ResidentInfoSchemaType } from "@shared/validator/models/residentInfo.schema";
import {
  ResidentInfoBodyForBESchemaType,
  ResidentInfoBodyForCSSchemaType,
  ResidentInfoBodySchemaType,
  UpdateAccountCommunityRolesRequestSchemaType,
} from "@shared/validator/features/auth.schema";

export type CurrentUser = {
  id: string;
  email: string;
  name: string;
  internalRole: "INTERNAL_ADMIN" | "Member";
  picture: string;
  role: Role;
  isApprover: boolean;
  firebaseUid: string;
  able: boolean;
  baseInfo?: BaseInfo;
  signUpState: boolean;
  type: UserType;
  account?: Account;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};

export type Account = {
  id: string;
  organizationId: string;
  userId: string;
  roleId: string;
  belongId: string | null;
  partId: string | null;
  able: boolean;
  signUpState: boolean;
  isApprover: boolean;
  type: UserType;
  serviceType: ServiceType;
  schoolGroup: string | null;
  isPreschooler: boolean;
  selfFreeMemo: string;
  adminFreeMemo: string;
  communityRoles: AccountCommunityRole[];
  createdAt: Date;
  updatedAt: Date;
};

export type AccountCommunityRole = {
  accountId: string;
  roleId: string;
  role: {
    id: string;
    name: Role;
  };
  communityId: string;
  isMain: boolean;
  type?: CommunityUserType;
};

export type BaseInfo = {
  userId: string;
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  phoneNumber: string;
  schoolGroup: string;
  isPreschooler: boolean | number;
  selfFreeMemo: string;
  adminFreeMemo?: string;
  gender?: "MALE" | "FEMALE" | "OTHER";
  ageGroup?: number;
  birthday?: Date;
  postalCode?: string;
  prefecture?: string;
  city?: string;
  address1?: string;
  address2?: string;
  latitude?: number;
  longitude?: number;
  belongId?: string;
  belong?: {
    id: string;
    organizationId: string;
    name: string;
    createdAt: Date;
  };
  partId?: string;
  part?: {
    id: string;
    organizationId: string;
    name: string;
    createdAt: Date;
  };
};

export type ResidentBaseInfo = {
  userId: string;
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
  gender: "MALE" | "FEMALE" | "OTHER";
  birthday: Date;
  ageGroup: number;
  postalCode: string;
  prefecture: string;
  city: string;
  address1: string;
  address2: string;
  phoneNumber: string;
};

export type ResidentInfo = {
  preferredDays: DayOfWeekType[];
  teacherLicenses: TeachingLicenseType[];
  medicalLicenses: MedicalLicenseType[];
  skills: SkillType[];
  preferredPaid: PaidType[];
  preferredVolunteerType: VolunteerType[];
  privateEnterprise: string | null;
  university: string | null;
  externalOrganization: string | null;
  pta: string | null;
};

export async function signupWithInvitation({
  token,
  isGuest,
}: SignupWithInvitationRequest): Promise<SignupWithInvitationResponse> {
  const res = await client.post<
    SignupWithInvitationRequest,
    SignupWithInvitationResponse
  >("/auth/signupWithInvitation", {
    token,
    isGuest,
  });
  return res;
}

export async function resendEmailVerificationMail({
  token,
}: ResendEmailVerificationMailRequest): Promise<ResendEmailVerificationMailResponse> {
  const res = await client.post<
    ResendEmailVerificationMailRequest,
    ResendEmailVerificationMailResponse
  >("/auth/resendEmailVerificationMail", {
    token,
  });
  return res;
}

export async function currentUser(): Promise<CurrentUser> {
  const res = await client.get<{}, { user: CurrentUser }>(
    "/auth/currentUser",
    {}
  );
  return res.user;
}

export async function firebaseState(userId: string): Promise<{
  emailVerified: boolean;
  disabled: boolean;
}> {
  const res = await client.get<
    { userId: string },
    { emailVerified: boolean; disabled: boolean }
  >("/auth/firebaseState", { userId });
  return res;
}

export async function getUserById(userId: string): Promise<CurrentUser> {
  const res = await client.get<{ userId: string }, { user: CurrentUser }>(
    "/auth/getUser",
    { userId: userId }
  );
  return res.user;
}

export async function syncEmail(): Promise<{ success: boolean }> {
  const res = await client.post<{}, { success: boolean }>(
    "/auth/syncEmail",
    {}
  );
  return res;
}

export async function changeEmailByInternalAdmin(
  userId: string,
  email: string
): Promise<{ status: string; code?: string; message?: string }> {
  const res = await client.post<
    { userId: string; email: string },
    { status: string; code?: string; message?: string }
  >("/auth/changeEmailByInternalAdmin", {
    userId: userId,
    email,
  });
  return res;
}

export async function isExistsOrganizationUser(
  token: string
): Promise<boolean> {
  const res = await client.get<{ token: string }>(
    "/auth/isExistsOrganizationUser",
    { token: token }
  );

  return res.result;
}

export async function registerUserType(userType: UserType): Promise<{}> {
  const res = await client.post<{ userType: UserType }>(
    "/auth/registerUserType",
    { userType }
  );
  return res;
}

export async function registerInfo(data: Omit<BaseInfo, "userId">) {
  const baseInfo = await client.post<
    {
      data: Omit<BaseInfo, "userId">;
    },
    BaseInfo
  >("/auth/registerInfo", {
    data,
  });
  return baseInfo;
}

export async function registerResidentInfo(
  data: Partial<Omit<ResidentBaseInfo, "userId">>
) {
  const baseInfo = await client.post<
    {
      data: Partial<Omit<ResidentBaseInfo, "userId">>;
    },
    ResidentBaseInfo
  >("/auth/registerResidentInfo", {
    data,
  });
  return baseInfo;
}

export async function registerFaceImgPath(data: { faceImgPath: string }) {
  const baseInfo = await client.post<
    { data: { faceImgPath: string } },
    BaseInfo
  >("/auth/registerFaceImgPath", { data });
  return baseInfo;
}

export async function registerFaceImgPathForBE(data: {
  accountId: string;
  faceImgPath: string;
}) {
  const baseInfo = await client.post<
    { accountId: string; faceImgPath: string },
    BaseInfo
  >("/auth/registerFaceImgPathForBE", data);
  return baseInfo;
}

export async function getBaseInfo(): Promise<BaseInfo> {
  const res = await client.get<{}, BaseInfo>("/auth/getBaseInfo", {});
  return res;
}

export async function getResidentBaseInfo(): Promise<ResidentBaseInfo> {
  const res = await client.get<{}, ResidentBaseInfo>(
    "/auth/getResidentBaseInfo",
    {}
  );
  return res;
}

export async function getBaseInfoById(userId: string): Promise<BaseInfo> {
  const res = await client.get<{ userId: string }, BaseInfo>(
    "/auth/getBaseInfo",
    {
      userId,
    }
  );
  return res;
}

/**
 * 名簿用の退会処理
 */
export async function deleteAccountById(
  targetAccountId: string
): Promise<EmptyObject> {
  const data = { targetAccountId };
  const res = await client.post("/auth/deleteAccount", { data });
  return res;
}

/**
 * PTAの未承認のメンバー用の退会処理
 */
export async function deleteUnApprovedAccount(
  targetAccountId: string
): Promise<EmptyObject> {
  const data = { targetAccountId };
  const res = await client.post("/auth/deleteUnApprovedAccount", { data });
  return res;
}

export async function approveUsers(
  datas: { userId: string; accountId: string | undefined }[]
) {
  const res = await client.post("/auth/approveUsers", { datas });
  return res;
}

export async function completeAdminRegistration() {
  const res = await client.post("/auth/completeAdminRegistration", {});
  return res;
}

export async function completeMemberRegistration() {
  const res = await client.post("/auth/completeMemberRegistration", {});
  return res;
}

export async function completeResidentRegistration() {
  const res = await client.post("/auth/completeResidentRegistration", {});
  return res;
}

export async function updateBelongAndPart(
  userId: string,
  belongId?: string,
  partId?: string
) {
  const data = {
    userId,
    belongId,
    partId,
  };
  const res = await client.post<{ data: typeof data }, BaseInfo>(
    "/auth/updateBelongAndPart",
    { data }
  );
  return res;
}

export async function getTeacherInfo(): Promise<{
  data: { grade: number; class: ChildClass } | undefined | null;
}> {
  const res = await client.get<
    {},
    { data: { grade: number; class: ChildClass } | undefined | null }
  >("/auth/getTeacherInfo", {});
  return res;
}

export async function updateTeacherInfo(
  data: {
    grade: number;
    class: ChildClass;
  } | null
): Promise<{}> {
  const res = await client.post<{ data: typeof data }>(
    "/auth/updateTeacherInfo",
    { data }
  );
  return res;
}

export async function getResidentInfo(): Promise<{
  data: ResidentInfoSchemaType | undefined | null;
}> {
  const res = await client.get<
    {},
    {
      data: ResidentInfoSchemaType | undefined | null;
    }
  >("/auth/getResidentInfo", {});
  return res;
}

export async function updateResidentInfo(
  data: ResidentInfoSchemaType
): Promise<{}> {
  const res = await client.post<ResidentInfoBodySchemaType>(
    "/auth/updateResidentInfo",
    { data }
  );
  return res;
}

export async function updateTeacherInfoByAdmin(
  userId: string,
  data: {
    grade: number;
    class: ChildClass;
  } | null
): Promise<{}> {
  const res = await client.post<{ userId: string; data: typeof data }>(
    "/auth/updateTeacherInfoByAdmin",
    { userId, data }
  );
  return res;
}

export async function updateResidentInfoByCSAdmin(
  userId: string,
  data: ResidentInfoSchemaType
): Promise<{}> {
  const res = await client.post<ResidentInfoBodyForCSSchemaType>(
    "/auth/updateResidentInfoByCSAdmin",
    { userId, data }
  );
  return res;
}

/**
 * TODO: こちらのAPIはaccountIdを用いているが、updateResidentInfoByCSAdminはuserIdを用いている
 * accountIdに統一する
 */
export async function updateResidentInfoByBEAdmin(
  accountId: string,
  data: ResidentInfoSchemaType
): Promise<{}> {
  const res = await client.post<ResidentInfoBodyForBESchemaType>(
    "/auth/updateResidentInfoByBEAdmin",
    { accountId, data }
  );
  return res;
}

/**
 * アカウント一覧を取得する
 */
export const getAccounts = async (): Promise<GetAccountsResponse[]> => {
  const res = await client.get<{}, GetAccountsResponse[]>(
    "/auth/getAccounts",
    {}
  );
  return res;
};

/**
 * 現在のアカウントを設定する
 */
export const setCurrentAccount = async (
  userId: string,
  organizationId: string
) => {
  const res = await client.post<{
    userId: string;
    organizationId: string;
  }>("/auth/setCurrentAccount", { userId, organizationId });

  await client.refreshToken();

  return res;
};

/**
 * 紐づくcommunitiesを更新する
 */
export const updateCommunityRoles = async (
  data: UpdateAccountCommunityRolesRequestSchemaType
) => {
  const res = await client.post<UpdateAccountCommunityRolesRequestSchemaType>(
    "/auth/updateCommunityRoles",
    data
  );
  return res;
};

/**
 * tokenに紐づくcommunityをアカウントのcommunityRolesに追加する
 */
export const updateCommunityRolesByToken = async (
  token: string,
  roleName: string
) => {
  const res = await client.post<UpdateCommunityRolesByTokenRequest>(
    "/auth/updateCommunityRolesByToken",
    { token, roleName }
  );
  return res;
};

/**
 * 現在のRoleを更新する
 */
export const updateRole = async (userId: string, role: string) => {
  const res = await client.post<{
    userId: string;
    role: string;
  }>("/auth/updateRole", { userId, role });
  return res;
};

/**
 * 強制的にAccountを更新する
 * InternalAdminのみ
 */
export const updateAccountByInternalAdmin = async (
  data: {
    userId: string;
    userType: UserType;
    able: boolean;
    isApprover: boolean;
    signUpState: boolean;
    role: string;
  },
  organizationId: string
) => {
  const res = await client.post<{
    data: {
      userId: string;
      userType: UserType;
      able: boolean;
      isApprover: boolean;
      signUpState: boolean;
      role: string;
    };
    organizationId: string;
  }>("/auth/updateAccountByInternalAdmin", {
    data,
    organizationId,
  });
  return res;
};

/**
 * 校区の選択を変更できるかどうかを確認する
 */
export async function canDeleteCommunityRole(
  communityId: string,
  userId: string
) {
  const res = await client.get<
    { communityId: string; userId: string },
    boolean
  >("/auth/canDeleteCommunityRole", {
    userId: userId,
    communityId: communityId,
  });
  return res;
}

export async function checkUserInThisCommunity(userId: string) {
  const res = await client.get<{ userId: string }, boolean>(
    "/auth/checkUserInThisCommunity",
    {
      userId: userId,
    }
  );
  return res;
}
