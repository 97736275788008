import { z } from "zod";
import {
  zRequiredString,
  zHiraganaString,
  zPhoneNumberString,
  zPastDateString,
} from "@shared/validator/rules/string";
import { residentInfoFormSchema } from "src/validator/form/residentInfo/residentInfoFormSchema";
import { communityRolesFormSchema } from "src/validator/form/member/community/community.schema";

/**
 * CSの基本情報のスキーマ
 */
export const CSFormValueSchema = z.object({
  baseInfo: z.object({
    lastName: zRequiredString,
    lastNameKana: zHiraganaString,
    firstName: zRequiredString,
    firstNameKana: zHiraganaString,
    phoneNumber: zPhoneNumberString,
    gender: z.enum(["MALE", "FEMALE", "OTHER"]),
    ageGroup: z.number().optional(),
    birthday: zPastDateString,
    postalCode: z.string().optional().nullable(),
    prefecture: z.string().optional().nullable(),
    city: zRequiredString,
    address1: zRequiredString,
    address2: z.string().optional().nullable(),
  }),
  community: communityRolesFormSchema,
});

/**
 * 名簿/プロフィール
 * 管理人のみアクセス可
 */
export const CSAdminFormValueSchema = z.object({
  baseInfo: z.object({
    userId: z.string(),
    lastName: zRequiredString,
    lastNameKana: zHiraganaString,
    firstName: zRequiredString,
    firstNameKana: zHiraganaString,
    phoneNumber: zPhoneNumberString,
    gender: z.enum(["MALE", "FEMALE", "OTHER"]),
    ageGroup: z.number().optional(),
    birthday: zPastDateString,
    postalCode: z.string().optional().nullable(),
    prefecture: z.string().optional().nullable(),
    city: zRequiredString,
    address1: zRequiredString,
    address2: z.string().optional().nullable(),
  }),
  community: communityRolesFormSchema,
  residentInfo: residentInfoFormSchema,
});
