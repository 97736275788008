import styled from "styled-components";
import * as theme from "../theme";

export type ButtonColor = keyof typeof theme.buttons;
function getBg({
  color,
  fill,
  empty,
  disabled,
}: {
  color: ButtonColor;
  fill: boolean;
  empty: boolean;
  disabled: boolean;
}): string {
  if (disabled) {
    return theme.buttons["disabled"].bg;
  }
  if (empty) {
    return theme.buttons["ghost"].bg;
  }
  if (fill) {
    return theme.buttons[color].fillBg;
  } else {
    return theme.buttons[color].bg;
  }
}
function getColor({
  color,
  fill,
  empty,
  disabled,
}: {
  color: ButtonColor;
  fill: boolean;
  empty: boolean;
  disabled: boolean;
}): string {
  if (disabled) {
    return theme.buttons["disabled"].color;
  }
  if (empty) {
    return theme.buttons[color].color;
  }
  if (fill) {
    return theme.buttons[color].fill;
  } else {
    return theme.buttons[color].color;
  }
}

function getEmptyHoverBg(empty: boolean) {
  if (!empty) return "";
  return `background-color: ${theme.colorsPallet.lightestShade};`;
}

function getCursor(disabled: boolean) {
  if (!disabled) return "pointer";
  else return "not-allowed";
}

type ButtonAttrsProps = {
  type?: "submit" | "button" | "reset";
};

export const Button = styled.button.attrs<ButtonAttrsProps>(({ type }) => ({
  type,
}))<{
  color?: ButtonColor;
  size?: "large" | "small";
  fill?: boolean;
  empty?: boolean;
  disabled?: boolean;
  width?: string;
  height?: string;
}>`
  background-color: ${(props) =>
    getBg({
      color: props.color || "primary",
      fill: !!props.fill,
      empty: !!props.empty,
      disabled: !!props.disabled,
    })};
  color: ${(props) =>
    getColor({
      color: props.color || "primary",
      fill: !!props.fill,
      empty: !!props.empty,
      disabled: !!props.disabled,
    })};
  font-size: ${theme.typo.small};
  line-height: 21px;
  padding: ${(props) => (props.size === "large" ? "7px" : "5px")} 22px;
  border-radius: 6px;
  border: none;
  cursor: ${(props) => getCursor(!!props.disabled)};
  outline: none;
  appearance: none;
  text-align: center;
  white-space: nowrap;
  width: ${(props) => props.width ?? "auto"};
  height: ${(props) => props.height ?? "auto"};

  &:hover {
    ${(props) => getEmptyHoverBg(!!props.empty)}
    opacity: 0.8;
  }

  &:focus {
    ${(props) => getEmptyHoverBg(!!props.empty)}
    opacity: 0.7;
  }
`;

// FIXME: do not use "export const" with styled-components
export const ButtonGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
