import React, { useCallback, useState } from "react";
import { useApproveRecruitment } from "../../../hooks/api/recruitment";
import { Title } from "../../../components/Title";
import styled from "styled-components";
import { usePolyfitHistory } from "../../../hooks/router";
import { declineApplicationByRecruitmentId } from "../../../apiClients/recruitmentApplication";
import SelfDeclineApplicationModal from "../../../features/Recruitment/application/SelfDeclineApplicationModal";
import { NotFoundRecruitment } from "../../../features/Recruitment/recruitment/NotFoundRecruitment";
import { RecruitmentCard } from "../../../features/Recruitment/recruitment/RecruitmentCard";
import { Recruitment } from "@shared/types/recruitment";

export type RecruitmentDateStatus = "IsToday" | "Started" | "NotStarted";
export default function ResidentRecruitmentApproveListPage() {
  const { recruitments, refetch } = useApproveRecruitment();
  const history = usePolyfitHistory();
  const [editRecruitmentId, setRecruitmentId] = useState<string>();
  const [editRecruitmentTitle, setEditRecruitmentTitle] = useState<string>("");
  const [recruitmentDateStatus, setRecruitmentDateStatus] =
    useState<RecruitmentDateStatus>("NotStarted");

  const handleOpenModal = useCallback(
    (id: string, isStarted: RecruitmentDateStatus) => {
      setRecruitmentId(id);
      setRecruitmentDateStatus(isStarted);
    },
    []
  );

  const handleDeclineSubmit = useCallback(
    async (comment: string) => {
      await declineApplicationByRecruitmentId(editRecruitmentId ?? "", comment);
      await refetch();
      setRecruitmentId(undefined);
      setRecruitmentDateStatus("NotStarted");
    },
    [editRecruitmentId]
  );

  const handleCloseModal = useCallback(() => {
    setRecruitmentId(undefined);
    setRecruitmentDateStatus("NotStarted");
  }, []);

  const isRecruitmentStarted = useCallback(
    (recruitment: Recruitment): RecruitmentDateStatus => {
      const firstDay = new Date(
        recruitment.schedule.reduce((a, b) =>
          new Date(a.date) < new Date(b.date) ? a : b
        ).date
      );
      firstDay.setHours(0, 0, 0, 0);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (firstDay.getTime() === today.getTime()) return "IsToday";
      if (firstDay <= today) return "Started";
      return "NotStarted";
    },
    []
  );

  const isRecruitmentEnded = useCallback((recruitment: Recruitment) => {
    const lastDay = new Date(
      recruitment.schedule.reduce((a, b) =>
        new Date(a.end) > new Date(b.end) ? a : b
      ).end
    );
    const today = new Date();

    return lastDay < today;
  }, []);

  const notEndedRecruitments = recruitments?.filter(
    (recruitment) => !isRecruitmentEnded(recruitment)
  );
  const endedRecruitments = recruitments?.filter((recruitment) =>
    isRecruitmentEnded(recruitment)
  );
  return (
    <>
      <NotEndedRecruitments>
        <Title>参加する募集一覧</Title>

        {/* TODO: カード部分を共通化 */}
        {notEndedRecruitments?.length === 0 ? (
          <NotFoundRecruitment
            title="参加する募集はありません"
            description="参加する募集が作成されるとこちらの画面に表示されます"
          />
        ) : (
          <CardContainer>
            {notEndedRecruitments?.map((recruitment, i) => (
              <RecruitmentCard
                key={recruitment.id}
                recruitment={recruitment}
                primaryButtonLabel="詳細を確認する"
                onClickPrimaryButton={() =>
                  history.push({
                    to: "RESIDENT_RECRUITMENT_DETAIL",
                    query: { id: recruitment.id },
                  })
                }
                cancelButtonLabel="不参加にする"
                cancelButtonIsDisabled={
                  isRecruitmentStarted(recruitment) !== "NotStarted"
                }
                onClickCancelButton={() => {
                  setEditRecruitmentTitle(recruitment.title);
                  handleOpenModal(
                    recruitment.id ?? "",
                    isRecruitmentStarted(recruitment)
                  );
                }}
              />
            ))}
          </CardContainer>
        )}
      </NotEndedRecruitments>
      <EndedRecruitments>
        <Title>参加した募集一覧</Title>
        {endedRecruitments?.length === 0 ? (
          <NotFoundRecruitment
            title="参加した募集はありません"
            description="参加した募集が作成されるとこちらの画面に表示されます"
          />
        ) : (
          <CardContainer>
            {endedRecruitments?.map((recruitment, i) => (
              <RecruitmentCard
                key={recruitment.id}
                recruitment={recruitment}
                primaryButtonLabel="詳細を確認する"
                onClickPrimaryButton={() =>
                  history.push({
                    to: "RESIDENT_RECRUITMENT_DETAIL",
                    query: { id: recruitment.id },
                  })
                }
              />
            ))}
          </CardContainer>
        )}
      </EndedRecruitments>
      {editRecruitmentId && (
        <SelfDeclineApplicationModal
          onSubmit={handleDeclineSubmit}
          onClose={handleCloseModal}
          recruitmentTitle={editRecruitmentTitle}
          recruitmentDateStatus={recruitmentDateStatus}
        />
      )}
    </>
  );
}

const CardContainer = styled.div`
  position: relative;
  display: grid;
  grid-gap: 24px;
  margin: 2em auto;
  width: 100%;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const NotEndedRecruitments = styled.section``;
const EndedRecruitments = styled.section`
  margin-top: 16px;
`;
