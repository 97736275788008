import styled from "styled-components";
import React, { useCallback, useState } from "react";
import { BERecruitment } from "@shared/types/boardEducation/recruitment";
import { toDisplaySlashDateFormat } from "src/utils/time";
import { Avatar } from "src/components/Common/Avatar";
import { recruitmentIsOpened } from "src/apiClients/recruitment";
import { Role } from "@shared/types/role";
import { beRoleOptions } from "src/utils/types/role";
import { getStaticImageUrl } from "src/utils/getStaticImageUrl";
import { usePolyfitHistory } from "src/hooks/router";
import { BREAKPOINTS } from "src/components/Responsive";
import { zIndexes } from "src/zIndex";
import CloseRecruitmentModal from "./CloseRecruitmentModal";
import DeleteOpenRecruitmentModal from "./DeleteOpenRecruitmentModal";
import { useToast } from "src/components/Toast";
import {
  closeBERecruitment,
  deleteBEOpenRecruitment,
} from "src/apiClients/boardEducation/recruitment";
import { colorsPallet } from "src/theme";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { AppliedCountModal } from "./AppliedCountModal";
import { ApprovedCountModal } from "./ApprovedCountModal";

// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

export const RecruitmentListColumn = ({
  recruitment,
  refetch,
}: {
  recruitment: BERecruitment;
  refetch: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<BERecruitment[], Error>>;
}) => {
  const history = usePolyfitHistory();
  if (!recruitment.account) return;
  const toast = useToast();

  /**
   * 設定モーダルの表示制御
   */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isCloseRecruitment, setIsCloseRecruitment] = useState<boolean>(false);
  const [isDeleteRecruitment, setIsDeleteRecruitment] =
    useState<boolean>(false);
  const [isAppliedCountOpenModal, setIsAppliedCountOpenModal] =
    useState<boolean>(false);
  const [isApprovedCountOpenModal, setIsApprovedCountOpenModal] =
    useState<boolean>(false);

  const onEditRecruitment = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    history.push({
      to: "BE_ADMIN_RECRUITMENT_EDIT_OPENED",
      query: { id: recruitment.id },
    });
  };

  const onDetailRecruitment = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    history.push({
      to: "BE_ADMIN_RECRUITMENT_DETAIL",
      query: { id: recruitment.id },
    });
  };

  const onOpenModalCloseRecruitment = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setIsCloseRecruitment(true);
  };
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmitCloseRecruitment = useCallback(async () => {
    setDoubleClickBlocked(false);
    try {
      await closeBERecruitment(recruitment.id);
      await refetch();
      toast.success("募集の掲載を終了しました");
      setIsOpen(false);
    } catch (e) {
      toast.error("募集の掲載の終了に失敗しました");
    } finally {
      setIsCloseRecruitment(false);
      setDoubleClickBlocked(false);
    }
  }, []);

  const onOpenModalDeleteRecruitment = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setIsDeleteRecruitment(true);
  };

  const onSubmitDeleteRecruitment = async () => {
    setDoubleClickBlocked(true);
    try {
      await deleteBEOpenRecruitment(recruitment.id);
      await refetch();
      toast.success("募集を削除しました");
      setIsOpen(false);
      history.push({
        to: "BE_ADMIN_RECRUITMENT_LIST",
      });
    } catch (e) {
      toast.error("募集の削除に失敗しました");
    } finally {
      setIsDeleteRecruitment(false);
    }
    setDoubleClickBlocked(false);
  };

  const onClickSetting = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const onClickBackGround = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setIsOpen(false);
    setIsAppliedCountOpenModal(false);
    setIsApprovedCountOpenModal(false);
  };

  // 応募数モーダルクリック時
  const appliedCountOpenModal = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (recruitment.recruitmentAppliedCount === 0) return;
    setIsAppliedCountOpenModal(true);
  };

  // 確定数モーダルクリック時
  const approvedCountOpenModal = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    if (recruitment.recruitmentApprovedCount === 0) return;
    setIsApprovedCountOpenModal(true);
  };

  return (
    <NameListColumnNameRow onClick={onDetailRecruitment}>
      <Panel>
        {/* 日付 */}
        <NoWrapWord>
          {toDisplaySlashDateFormat(recruitment.schedule[0].start)}
        </NoWrapWord>
      </Panel>
      <Panel>
        {/* 応募タイトル */}
        {recruitment.title}
      </Panel>
      <Panel>
        {/* 学校 教育委員会の場合は組織名 */}
        {recruitment.community?.name || recruitment.managedCSOrganizationName}
      </Panel>
      <Panel>
        <UserPicCell>
          <PreUserPicCell>
            <Avatar
              src={recruitment.account.user.picture || defaultUserImg}
              alt={recruitment.account.user.name}
              size={40}
            />
          </PreUserPicCell>
          <PreUserNameCell>
            <Name>{recruitment.account.user.name}</Name>
            <NameListOrganizationData>
              <ResidentRoleTagWrapper
                role={recruitment.account.role.name as Role}
              >
                <NoWrapWord>
                  {
                    beRoleOptions.find(
                      (role) => role.value === recruitment.account?.role.name
                    )?.text
                  }
                </NoWrapWord>
              </ResidentRoleTagWrapper>
            </NameListOrganizationData>
          </PreUserNameCell>
        </UserPicCell>
      </Panel>
      <Panel>
        {/* 募集人数 */}
        <NoWrapWord>
          {recruitment.volunteerCount === -1
            ? "無制限"
            : recruitment.volunteerCount}
        </NoWrapWord>
      </Panel>
      <Panel>
        {/* 応募数 */}
        <CountButton
          onClick={appliedCountOpenModal}
          isDisabled={recruitment.recruitmentAppliedCount === 0}
        >
          <NoWrapWord>{recruitment.recruitmentAppliedCount}</NoWrapWord>
        </CountButton>
        {isAppliedCountOpenModal && (
          <AppliedCountModal
            onClose={() => setIsAppliedCountOpenModal(false)}
            recruitmentId={recruitment.id}
          />
        )}
      </Panel>
      <Panel>
        {/* 確定数 */}
        <CountButton
          onClick={approvedCountOpenModal}
          isDisabled={recruitment.recruitmentApprovedCount === 0}
        >
          <NoWrapWord>{recruitment.recruitmentApprovedCount}</NoWrapWord>
        </CountButton>
        {isApprovedCountOpenModal && (
          <ApprovedCountModal
            onClose={() => setIsApprovedCountOpenModal(false)}
            recruitmentId={recruitment.id}
          />
        )}
      </Panel>
      <Panel>
        {/* ステータス */}
        <IsRecruitmentOpen isOpen={recruitmentIsOpened(recruitment)}>
          <NoWrapWord>
            {recruitmentIsOpened(recruitment) ? "募集中" : "募集終了"}
          </NoWrapWord>
        </IsRecruitmentOpen>
      </Panel>
      <Panel>
        <SettingButtonDiv>
          <div>
            <SettingButton onClick={onClickSetting} isOpen={isOpen}>
              …
            </SettingButton>
          </div>
          {isOpen && (
            <>
              <EditMenuTab>
                {recruitmentIsOpened(recruitment) && (
                  <>
                    <SettingEditInfo onClick={onEditRecruitment}>
                      募集を編集
                    </SettingEditInfo>
                    <SettingEditInfo onClick={onOpenModalCloseRecruitment}>
                      <DangerText>掲載を終了</DangerText>
                      {!!isCloseRecruitment && (
                        <CloseRecruitmentModal
                          title={recruitment.title}
                          onSubmit={onSubmitCloseRecruitment}
                          onClose={() => setIsCloseRecruitment(false)}
                        />
                      )}
                    </SettingEditInfo>
                  </>
                )}

                <SettingEditInfo onClick={onOpenModalDeleteRecruitment}>
                  <DangerText>募集を削除</DangerText>
                  {!!isDeleteRecruitment && (
                    <DeleteOpenRecruitmentModal
                      title={recruitment.title}
                      onSubmit={onSubmitDeleteRecruitment}
                      onClose={() => setIsDeleteRecruitment(false)}
                      disabled={doubleClickBlocked}
                    />
                  )}
                </SettingEditInfo>
              </EditMenuTab>
              <MenuBackGround onClick={onClickBackGround} />
            </>
          )}
        </SettingButtonDiv>
      </Panel>
    </NameListColumnNameRow>
  );
};

const NameListColumnNameRow = styled.tr`
  background-color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #ebebeb;
  }
`;

const Panel = styled.th`
  color: #343741;
  font-size: 14px;
  font-weight: normal;
  text-align: left;
  padding-left: 16px;
  height: 71px;
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    display: "none";
  }
`;

const UserPicCell = styled.div`
  color: #343741;
  font-size: 14px;
  display: flex;
  align-items: center;
  height: 100%;
`;

const PreUserPicCell = styled.div`
  padding: 0 14px 0 0px;
`;

const PreUserNameCell = styled.div`
  padding: 14px 0;
`;

const Name = styled.div`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  text-align: left;
`;

const NameListOrganizationData = styled.div`
  display: flex;
  gap: 4px;
  color: #69707d;
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  text-align: left;
`;

const ResidentRoleTagWrapper = styled.div<{ role: Role }>`
  display: inline-block;
  padding: 2px 8px;
  background-color: ${(props) => {
    switch (props.role) {
      case Role.CSAdmin:
        return "#79aad9";
      case Role.CSOperator:
        return "#EE789D";
      case Role.CSMember:
        return "#6DCCB1";
      case Role.BEAdmin:
        return "#8858FF";
      default:
        break;
    }
  }};
  border-radius: 40px;
  color: #ffffff;
`;

const IsRecruitmentOpen = styled.div<{ isOpen: boolean }>`
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-align: left;
  padding: 5px 16px;
  width: fit-content;
  border-radius: 24px;
  background-color: ${(props) => (props.isOpen ? "#6DCCB1" : "#C4C4C4")};
`;

const NoWrapWord = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 2px 5px;
`;

// 設定ボタン
const SettingButtonDiv = styled.div`
  position: relative;
  width: fit-content;
`;

const SettingButton = styled.button<{ isOpen: boolean }>`
  width: 40px;
  height: 40px;
  background-color: transparent;
  font-size: 16px;
  color: #343741;
  border: none;
  border-radius: 100%;
  padding-bottom: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f3f4f8;
  }
  background-color: ${(props) => (props.isOpen ? "#f3f4f8" : "transparent")};
`;

const SettingEditTab = styled.div`
  position: absolute;
  right: 0;
  background-color: #ffffff;
  border-radius: 5px;
  width: 130px;
  border: 1px solid #e4e6f3;
  padding: 5px 0;
`;

const EditMenuTab = styled(SettingEditTab)`
  z-index: ${zIndexes.notModalMenu};
`;

const SettingEditInfo = styled.div`
  padding: 10px 16px;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #e4e6f3;
  }
`;

const MenuBackGround = styled.div`
  z-index: ${zIndexes.notModalMenuBackground};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

const DangerText = styled.span`
  color: ${colorsPallet.danger};
`;

// 応募・確定数ボタン
const CountButton = styled.button<{ isDisabled: boolean }>`
  background-color: transparent;
  border: none;
  &:hover {
    text-decoration: underline;
  }
  cursor: ${(props) => (props.isDisabled ? "default" : "pointer")};
`;
