import React from "react";
import styled from "styled-components";
import { Margin } from "../../../components/Margin";
import {
  ModalPortal,
  ModalHeader,
  ModalSubmitButtons,
} from "../../../components/Modal";

type Props = {
  title: string;
  onSubmit: () => void;
  onClose: () => void;
};

export const ResendRecruitmentModal = ({ title, onSubmit, onClose }: Props) => {
  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>
        <p>募集を再通知</p>
      </ModalHeader>
      <ModalBody>
        <p>「{title}」の募集を再通知しますか？</p>
        <Margin marginBottom={10} />
      </ModalBody>
      <ModalSubmitButtons
        submitText="再通知"
        submitColor="primary"
        onSubmit={onSubmit}
        onCancel={onClose}
      />
    </ModalPortal>
  );
};

const ModalBody = styled.div`
  min-height: 50px;
  height: fit-content;
`;
