import React, { useCallback, useEffect } from "react";
import {
  usePolyfitHistory,
  usePolyfitLocationQuery,
} from "../../../hooks/router";
import styled from "styled-components";
import * as Responsive from "../../../components/Responsive";
import * as Typo from "../../../components/Typo";
import { Margin } from "../../../components/Margin";
import { RegistrationFooter } from "../../../components/RegistrationFooter";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { prefectureOptions } from "../../../utils/types/prefecture";
import { StepResident } from "../../../components/icons/StepResident";
import { getAddressFromPostalCode } from "../../../apiClients/postcode";
import { Tooltip } from "react-tooltip";
import { InformationIcon } from "../../../components/icons/InformationIcon";
import { Label } from "../../../components/form/Label";
import {
  useResidentBaseInfo,
  useResidentRegisterInfo,
} from "../../../hooks/api/baseInfo";

const FormValueSchema = z.object({
  postalCode: z
    .string()
    .min(1, "入力必須項目です")
    .refine(
      async (value) => {
        try {
          if (value.length !== 7) return false;
          await getAddressFromPostalCode(value);
          return true;
        } catch (err) {
          return false;
        }
      },
      {
        message: "ハイフンは入力せず、正しい郵便番号を入力してください",
      }
    ),
  prefecture: z.string().min(1, "入力必須項目です"),
  city: z.string().min(1, "入力必須項目です"),
  address1: z.string().min(1, "入力必須項目です"),
  address2: z.string().nullable(),
  phoneNumber: z
    .string()
    .regex(/^[0-9]+$/, {
      message: "ハイフンは入力せず、半角数字のみで入力してください",
    })
    .regex(new RegExp("^0{1}[0-9]{9,10}$"), "電話番号の形式で入力してください"),
});

type FormValue = z.infer<typeof FormValueSchema>;

export default function ResidentContactInfoPage() {
  const history = usePolyfitHistory();
  const { currentBaseInfo, isLoading } = useResidentBaseInfo();
  const { registerResidentInfo } = useResidentRegisterInfo();
  const { token } = usePolyfitLocationQuery("LOADING", {
    token: undefined,
  });

  const {
    reset,
    register,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormValue>({
    resolver: zodResolver(FormValueSchema),
  });

  useEffect(() => {
    reset({
      postalCode: currentBaseInfo.postalCode ?? "",
      prefecture: currentBaseInfo.prefecture ?? "",
      city: currentBaseInfo.city ?? "",
      address1: currentBaseInfo.address1 ?? "",
      address2: currentBaseInfo.address2 ?? "",
      phoneNumber: currentBaseInfo.phoneNumber ?? "",
    });
  }, [currentBaseInfo]);

  const onSubmit = async (data: FormValue) => {
    try {
      await registerResidentInfo({ ...data, address2: data.address2 ?? "" });
      history.push({
        to: "RESIDENT_REGISTER_PREVIEW",
        query: {
          ...(token && { token }),
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getAddress = useCallback(
    async (value: string) => {
      try {
        if (value.length < 7) return true;
        const res = await getAddressFromPostalCode(value);
        setValue("prefecture", res.prefecture);
        setValue("city", res.city);
        setValue("address1", res.suburb);
        setValue("address2", "");
        clearErrors("postalCode");
        clearErrors("prefecture");
        clearErrors("city");
      } catch (err) {
        setError("postalCode", {
          message: "ハイフンは入力せず、正しい郵便番号を入力してください",
        });
      }
    },
    [errors]
  );

  if (isLoading) return <></>;

  return (
    <div>
      <Margin marginBottom={24} />
      <StepResident stepNum={3} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledContainer>
          <Margin marginBottom={16} />
          <SubTitleWrapper>
            <Typo.Heading3>連絡先の入力</Typo.Heading3>
            <InformationIcon data-tooltip-id="title-tooltip" />
          </SubTitleWrapper>

          <Margin marginBottom={16} />
          <Responsive.Row>
            <Responsive.Col>
              <Label required>住所</Label>
              <SelectWrapper>
                <TextField
                  {...register("postalCode")}
                  placeholder="例）1030013"
                  onChange={(e) => getAddress(e.target.value)}
                />
                {errors.postalCode ? (
                  <span className="error-message">
                    {errors.postalCode.message}
                  </span>
                ) : (
                  <Margin marginTop={8} />
                )}
              </SelectWrapper>

              <HorizontalInputWrapper>
                <SelectWrapper>
                  {/* <TextField name="lastNameKana" placeholder="せい" />
                   */}
                  <SelectPrefecture {...register(`prefecture`)} defaultValue="">
                    <option value="" disabled>
                      県名を選択
                    </option>
                    {prefectureOptions.map((opt) => {
                      return (
                        <option key={opt.value} value={opt.value}>
                          {opt.text}
                        </option>
                      );
                    })}
                  </SelectPrefecture>
                  {errors?.prefecture != null ? (
                    <span className="error-message">
                      {errors?.prefecture?.message}
                    </span>
                  ) : (
                    <Margin marginTop={8} />
                  )}
                </SelectWrapper>
                <SelectWrapper>
                  <TextField
                    {...register("city")}
                    placeholder="例）川崎市川崎区"
                  />
                  {errors.city ? (
                    <span className="error-message">{errors.city.message}</span>
                  ) : (
                    <Margin marginTop={8} />
                  )}
                </SelectWrapper>
              </HorizontalInputWrapper>

              <SelectWrapper>
                <TextField
                  {...register("address1")}
                  placeholder="例）旭町3-1"
                />
                {errors.address1 ? (
                  <span className="error-message">
                    {errors.address1.message}
                  </span>
                ) : (
                  <Margin marginTop={8} />
                )}
              </SelectWrapper>

              <SelectWrapper>
                <TextField
                  {...register("address2")}
                  placeholder="例）〇〇マンション101号室"
                />
                {errors.address2 ? (
                  <span className="error-message">
                    {errors.address2.message}
                  </span>
                ) : (
                  <Margin marginTop={8} />
                )}
              </SelectWrapper>

              <Label required>電話番号（日中に連絡が取れる番号）</Label>
              <TextField
                placeholder="例)  09012345678"
                {...register("phoneNumber")}
              />
              {errors.phoneNumber ? (
                <span className="error-message">
                  {errors.phoneNumber.message}
                </span>
              ) : (
                <Margin marginTop={8} />
              )}
            </Responsive.Col>
          </Responsive.Row>
        </StyledContainer>
        <RegistrationFooter />
      </form>

      <Tooltip
        id="title-tooltip"
        place="bottom"
        content="活動を見つけたり、紹介するために利用する情報です。"
        style={{ maxWidth: "256px" }}
      />
    </div>
  );
}

const StyledContainer = styled.div`
  max-width: 496px;
  margin: 0 auto;
  padding-bottom: 88px;
  .error-message {
    font-size: 12px;
    color: #bd271e;
  }
  @media (max-width: 1279px) {
    max-width: 343px;
  }
`;

const HorizontalInputWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 16px;
`;

const SelectWrapper = styled.div`
  min-width: 240px;
  @media (max-width: 1279px) {
    min-width: 163px;
  }
`;

const SelectPrefecture = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 40px 10px 12px;
  background: "#fbfcfd";
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  cursor: "pointer";
`;

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;

const SubTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
