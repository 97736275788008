import React, { useEffect } from "react";
import { Spinner } from "src/components/icons/Spinner";
import styled from "styled-components";
import {
  useCurrentCommunityId,
  usePolyfitHistory,
  usePolyfitLocationQuery,
} from "src/hooks/router";
import Cookies from "js-cookie";
import { useRecoilState } from "recoil";
import { profileTabState } from "src/hooks/recoil/profileTab";
import { useCurrentUser } from "src/hooks/recoil/user";
import { useToast } from "src/components/Toast";
import { createOrUpdateLineAccount } from "src/apiClients/lineAccount";

export const LineLoginCallbackHandler = () => {
  const history = usePolyfitHistory();
  const toast = useToast();
  const currentUser = useCurrentUser();
  const [, setOpenProfileTab] = useRecoilState(profileTabState);
  const { communityId } = useCurrentCommunityId();
  const { code, state } = usePolyfitLocationQuery(
    "LINE_LOGIN_CALLBACK_HANDLER",
    {
      code: "",
      state: "",
    }
  );

  useEffect(() => {
    const processLineLogin = async () => {
      if (!code || !state) {
        console.error("codeまたはstateが存在しません");
        return;
      }

      const storedState = Cookies.get("lineLoginState") ?? "";
      const codeVerifier = Cookies.get("lineCodeVerifier") ?? "";

      if (state !== storedState) {
        console.error("stateが一致しません");
        toast.error("LINEの連携に失敗しました");
        setOpenProfileTab("notificationSetting");
        history.push({ to: "PTA_PROFILE", query: { userId: currentUser.id } });
        return;
      }

      const lineChannelId = import.meta.env.VITE_LINE_LOGIN_CHANNEL_ID;
      const redirectUri = `${window.location.origin}/line/callback?communityId=${communityId}`;

      const params = new URLSearchParams();
      params.append("grant_type", "authorization_code");
      params.append("code", code);
      params.append("redirect_uri", redirectUri);
      params.append("client_id", lineChannelId);
      params.append("code_verifier", codeVerifier);

      try {
        const response = await fetch("https://api.line.me/oauth2/v2.1/token", {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: params.toString(),
        });

        const data = await response.json();
        const accessToken = data.access_token;

        // LINEのプロフィール情報を取得
        const lineProfileResponse = await fetch(
          "https://api.line.me/v2/profile",
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        const lineProfile = await lineProfileResponse.json();

        if (!lineProfile.userId) {
          console.error("LINEのプロフィール情報が取得できませんでした");
          toast.error("LINEの連携に失敗しました");
          setOpenProfileTab("notificationSetting");
          history.push({
            to: "PTA_PROFILE",
            query: { userId: currentUser.id },
          });
          return;
        }

        // LINEのプロフィール情報を保存する
        await createOrUpdateLineAccount({
          lineUserId: lineProfile.userId,
          displayName: lineProfile.displayName,
          pictureUrl: lineProfile.pictureUrl,
        });

        const friendshipResponse = await fetch(
          "https://api.line.me/friendship/v1/status",
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );

        const friendshipStatus = await friendshipResponse.json();

        if (friendshipStatus.friendFlag) {
          toast.error("LINEの連携に成功しました");
        }
        setOpenProfileTab("notificationSetting");
        history.push({
          to: "PTA_PROFILE",
          query: {
            userId: currentUser.id,
            ...(friendshipStatus.friendFlag
              ? {}
              : { requestLineFriendModal: "show" }),
          },
        });
      } catch (error) {
        console.error("LINEログイン処理中にエラーが発生しました", error);
        toast.error("LINEの連携に失敗しました");
        setOpenProfileTab("notificationSetting");
        history.push({ to: "PTA_PROFILE", query: { userId: currentUser.id } });
      }
    };

    processLineLogin();
  }, []);

  return (
    <Card>
      <Spinner />
    </Card>
  );
};

const Card = styled.div`
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
