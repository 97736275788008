export type SchoolType = "B" | "C" | "D" | "BC" | "CD" | "BCD";
export type ChildGrade = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export function getGradesBySchoolType(schoolType: SchoolType): ChildGrade[] {
  switch (schoolType) {
    case "B":
      return [1, 2, 3, 4, 5, 6]; // 小学校 (1-6年生)
    case "C":
      return [7, 8, 9]; // 中学校 (7-9年生)
    case "D":
      return [10, 11, 12]; // 高等学校 (10-12年生)
    case "BC":
      return [1, 2, 3, 4, 5, 6, 7, 8, 9]; // 小中一貫校 (1-9年生)
    case "CD":
      return [7, 8, 9, 10, 11, 12]; // 中高一貫校 (7-12年生)
    case "BCD":
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]; // 小中高一貫校 (1-12年生)
  }
}
