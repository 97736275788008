/**
 * 文字列またはブール値を真偽値に変換する関数
 * @param value - 変換対象の値
 * @returns 変換後の真偽値
 *   - "true" または true の場合、true を返す
 *   - それ以外の場合、false を返す
 */
export const transformToBoolean = (value: string | boolean): boolean => {
  return value === "true" || value === true;
};
