import styled from "styled-components";
import React, { useCallback, useState } from "react";
import * as Typo from "../../../components/Typo";
import { AppliedRecruitmentCard } from "../recruitment/AppliedRecruitmentCard";
import { AppliedRecruitment } from "@shared/types/appliedRecruitment";
import { BREAKPOINTS } from "../../../components/Responsive";
import { useToast } from "../../../components/Toast";
import { deleteRecruitmentApplicationComment } from "../../../apiClients/recruitmentApplicationComment";
import CreateOpenRecruitmentAppliedCommentModal from "../recruitment/CreateRecruitmentAppliedCommentModal";
import DeleteApplicationCommentModal from "./DeleteApplicationCommentModal";

type Props = {
  isLoading: boolean;
  recruitmentList: AppliedRecruitment[] | null;
  refetch: () => void;
  currentCommunityId: string;
};

const AppliedRecruitmentContent = (props: Props) => {
  const [isRecruitmentApplicationId, setIsRecruitmentApplicationId] =
    useState<string>("");
  const [
    isCreateRecruitmentAppliedComment,
    setIsCreateRecruitmentAppliedComment,
  ] = useState(false);
  const [isDeleteModalOpenId, setIsDeleteModalOpenId] = useState<string>();
  const [comment, setComment] = useState<string>("");
  const toast = useToast();
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmitComment = useCallback(async () => {
    setDoubleClickBlocked(true);
    if (!isDeleteModalOpenId) {
      toast.warn("削除に失敗しました");
      setDoubleClickBlocked(false);
      return;
    }

    try {
      await deleteRecruitmentApplicationComment(isDeleteModalOpenId ?? "");
      setIsDeleteModalOpenId(undefined);
      toast.success("削除に成功しました");
      props.refetch();
    } catch (e) {
      toast.warn("削除に失敗しました");
    }
    setDoubleClickBlocked(false);
  }, [isDeleteModalOpenId]);

  const onCloseCommentModal = useCallback(() => {
    setIsDeleteModalOpenId(undefined);
    setIsCreateRecruitmentAppliedComment(true);
  }, []);

  const onOpenConfirmDeleteCommentModal = useCallback(
    (recruitmentApplicationCommentId: string, comment: string) => {
      setIsCreateRecruitmentAppliedComment(false);
      setIsDeleteModalOpenId(recruitmentApplicationCommentId);
      setComment(comment);
    },
    []
  );

  return (
    <>
      <CareerTitle>応募一覧</CareerTitle>
      <CardWrapper>
        {props.isLoading ? (
          <></>
        ) : (
          <CardContainer>
            {props.recruitmentList?.map((r, i) => {
              return (
                <AppliedRecruitmentCard
                  key={i}
                  recruitment={r}
                  onOpen={() => {
                    setIsCreateRecruitmentAppliedComment(true);
                    setIsRecruitmentApplicationId(r.id);
                  }}
                  currentCommunityId={props.currentCommunityId}
                />
              );
            })}
          </CardContainer>
        )}
      </CardWrapper>
      {!!isCreateRecruitmentAppliedComment && (
        <CreateOpenRecruitmentAppliedCommentModal
          isRecruitmentApplicationId={isRecruitmentApplicationId}
          isDeletedRecruitment={
            props.recruitmentList?.find(
              (recruitment) => recruitment.id === isRecruitmentApplicationId
            )?.recruitment.status === "DELETED"
          }
          comments={
            props.recruitmentList?.find(
              (recruitment) => recruitment.id === isRecruitmentApplicationId
            )?.comment ?? []
          }
          onOpenConfirmDeleteCommentModal={onOpenConfirmDeleteCommentModal}
          onClose={() => {
            setIsCreateRecruitmentAppliedComment(false);
          }}
          refetch={props.refetch}
          isCurrentCommunity={
            props.currentCommunityId ===
            props.recruitmentList?.find(
              (recruitment) => recruitment.id === isRecruitmentApplicationId
            )?.community.id
          }
        />
      )}
      {!!isDeleteModalOpenId && (
        <DeleteApplicationCommentModal
          comment={comment}
          onSubmit={onSubmitComment}
          onClose={onCloseCommentModal}
          disabled={doubleClickBlocked}
        />
      )}
    </>
  );
};

const CardWrapper = styled.div`
  margin: 8px auto;
`;

const CardContainer = styled.div`
  position: relative;
  display: grid;
  grid-gap: 24px;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.TABLET}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${BREAKPOINTS.PC}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const CareerTitle = styled(Typo.Heading2)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #343741;
  margin-bottom: 20px;
`;
export default AppliedRecruitmentContent;
