import React from "react";
import styled from "styled-components";

type ChatMessagesProps = {
  children: React.ReactNode;
  className?: string;
  messageListRef: React.RefObject<HTMLDivElement>;
};

export const ChatMessages: React.FC<ChatMessagesProps> = ({
  children,
  className,
  messageListRef,
}) => {
  return (
    <StyledChatMessages ref={messageListRef} className={className}>
      {children}
    </StyledChatMessages>
  );
};

const StyledChatMessages = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
  padding-bottom: 20px;
  opacity: 0;
  transition: opacity 0.3s ease-out;

  &:not(:empty) {
    opacity: 1;
  }
`;
