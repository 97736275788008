import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Title } from "src/components/Title";
import { usePolyfitHistory, usePolyfitLocationQuery } from "src/hooks/router";
import { ChatRoomList } from "../../../features/BoardEducation/ChatMessage/ChatRoomList";
import { useChatRoomFilter } from "src/hooks/recoil/boardEducation/chatRoomFilter";
import { ChatRoomIndividualContent } from "../../../features/BoardEducation/ChatMessage/ChatRoomIndividualContent/ChatRoomIndividualContent";
import { ChatRoomRecruitmentContent } from "../../../features/BoardEducation/ChatMessage/ChatRoomRecruitment/ChatRoomRecruitmentContent";
import { ChatRoomFilter } from "../../../features/BoardEducation/ChatMessage/ChatRoomFilter";
import { formatRecruitmentDates } from "src/features/common/ChatMessage/utils/formatRecruitmentSchedule";
import { useSelectedChatRoom } from "../../../features/BoardEducation/ChatMessage/useSelectedChatRoom";
import { Spinner } from "src/components/icons/Spinner";
import { Button } from "src/components/Button";
import { ChatRoomAppendModal } from "../../../features/BoardEducation/ChatMessage/ChatRoomAppendContent/ChatRoomAppendModal";
import { ChatRoomOrigin, ChatRoomType } from "@shared/types/chatRoom";
import { ChatRoomGroupContent } from "../../../features/BoardEducation/ChatMessage/ChatRoomGroupContent/ChatRoomGroupContent";
import { useChatRooms } from "../../../features/BoardEducation/ChatMessage/useChatRooms";
import { useGetAccount } from "src/hooks/query/boardEducation/account/account";
import { ChatRoomIndividualBEContent } from "../../../features/BoardEducation/ChatMessage/ChatRoomIndividualBEContent/ChatRoomIndividualBEContent";
import { ChatRoomGroupBEContent } from "../../../features/BoardEducation/ChatMessage/ChatRoomGroupBEContent/ChatRoomGroupContent";
import { BREAKPOINTS } from "src/components/Responsive";
import { useGetManagedOrganization } from "../../../hooks/query/boardEducation/organizations/organization";
import { ChevronIcon } from "src/components/icons/SvgIcons";
import { ChatRoomRecruitmentBoardEducationContent } from "src/features/BoardEducation/ChatMessage/ChatRoomRecruitmentBE/ChatRoomRecruitmentBoardEducationContent";

type Tab = "all" | "unanswered";
const isTab = (value: string | undefined): value is Tab =>
  value === "all" || value === "unanswered";

export const ChatRoomPage = () => {
  const history = usePolyfitHistory();
  const { account } = useGetAccount();
  const participantId = account?.boardEducationId ?? "";
  const { chatRooms, isLoading } = useChatRooms();
  const { chatRoom: selectedChatRoom, refetch: refetchSelectedChatRoom } =
    useSelectedChatRoom();
  const { chatRoomId, tab: initTab } = usePolyfitLocationQuery(
    "BE_CHATROOM_LIST",
    {}
  );
  const { isLoading: isLoadingOrganization, organization } =
    useGetManagedOrganization();
  const [showFilter, setShowFilter] = useState(false);
  const { chatRoomFilter, setChatRoomFilter, isFilterActive, resetFilter } =
    useChatRoomFilter();
  const [tab, setTab] = useState<Tab>(isTab(initTab) ? initTab : "all");
  const isOpenChatRoom = chatRoomId !== undefined;

  const onTabClick = (tabName: Tab) => {
    setTab(tabName);
    const query = chatRoomId ? { chatRoomId, tab: tabName } : { tab: tabName };
    history.push({
      to: "BE_CHATROOM_LIST",
      query: query,
    });
  };

  useEffect(() => {
    if (chatRooms && chatRooms.length > 0) {
      history.replace({
        to: "BE_CHATROOM_LIST",
        query: { chatRoomId: chatRooms[0].chatRoom.id, tab: tab },
      });
    }
  }, []);

  const unansweredChatRooms = chatRooms?.filter((chatRoom) => {
    return (
      chatRoom.chatRoom.origin === ChatRoomOrigin.BOARD_EDUCATION &&
      chatRoom.chatRoom.lastMessageCreatedBy &&
      chatRoom.chatRoom.lastMessageCreatedBy !== participantId
    );
  });

  const displayedChatRooms =
    tab === "unanswered" ? unansweredChatRooms : chatRooms;

  // chatRoom の追加に必要な要素
  const [isOpenChatRoomAppender, setIsOpenChatRoomAppender] = useState(false);

  return (
    <PageWrapper>
      <PageHeader>
        <Title>メッセージ</Title>
        <AppendChatRoomButton>
          <Button
            onClick={() => {
              setIsOpenChatRoomAppender(true);
            }}
            fill
            size="large"
            disabled={false}
          >
            メッセージルームを追加
          </Button>
        </AppendChatRoomButton>
      </PageHeader>
      <Padding>
        <ChatContainer>
          <Container>
            <ChatRoomListContainer isOpen={!isOpenChatRoom}>
              <TabWrapper>
                <Tab
                  $isActive={tab === "all"}
                  onClick={() => onTabClick("all")}
                >
                  すべて
                </Tab>
                <Tab
                  $isActive={tab === "unanswered"}
                  onClick={() => onTabClick("unanswered")}
                >
                  未返信
                  <CountBadge>{unansweredChatRooms?.length ?? 0}</CountBadge>
                </Tab>
              </TabWrapper>
              <FilterWrapper>
                <Filter
                  onClick={() => {
                    setShowFilter(true);
                  }}
                >
                  フィルター設定
                  <ChevronIcon />
                </Filter>
                {isFilterActive && (
                  <FilterActiveLabel>フィルター設定中</FilterActiveLabel>
                )}
              </FilterWrapper>
              {showFilter && (
                <ChatRoomFilter
                  onClose={() => {
                    setShowFilter(false);
                  }}
                  chatRoomFilter={chatRoomFilter}
                  resetFilter={resetFilter}
                  setChatRoomFilter={setChatRoomFilter}
                  loading={isLoadingOrganization}
                  communities={organization?.communities ?? []}
                />
              )}
              {isLoading && (
                <SpinnerWrapper>
                  <Spinner />
                </SpinnerWrapper>
              )}
              {!isLoading &&
                displayedChatRooms &&
                displayedChatRooms.length > 0 && (
                  <ChatRoomList
                    chatRooms={displayedChatRooms}
                    participantId={participantId}
                    communityName={"TODO"}
                    isCsAdmin={true}
                  />
                )}
            </ChatRoomListContainer>
            {selectedChatRoom?.chatRoom.type ===
              ChatRoomType.RECRUITMENT_APPLICATION &&
              selectedChatRoom.recruitmentApplication && (
                <ChatRoomRecruitmentContent
                  isOpen={isOpenChatRoom}
                  chatRoomId={selectedChatRoom.chatRoom.id}
                  chatRoomParticipantId={participantId}
                  lastReadAt={selectedChatRoom.lastReadAt}
                  recruitmentId={
                    selectedChatRoom.recruitmentApplication.recruitment.id
                  }
                  recruitmentDuration={formatRecruitmentDates(
                    selectedChatRoom.recruitmentApplication.recruitment.schedule
                  )}
                  recruitmentTitle={
                    selectedChatRoom.recruitmentApplication.recruitment
                      .status === "DELETED"
                      ? `[削除済み] ${selectedChatRoom.recruitmentApplication.recruitment.title}`
                      : `${selectedChatRoom.recruitmentApplication.recruitment.title}`
                  }
                  isDeletedRecruitment={
                    selectedChatRoom.recruitmentApplication.recruitment
                      .status === "DELETED"
                  }
                  communityName={
                    selectedChatRoom.chatRoom.community?.name ?? ""
                  }
                />
              )}
            {selectedChatRoom?.chatRoom.type ===
              ChatRoomType.RECRUITMENT_APPLICATION_BOARD_EDUCATION &&
              selectedChatRoom.recruitmentApplication && (
                <ChatRoomRecruitmentBoardEducationContent
                  isOpen={isOpenChatRoom}
                  chatRoom={selectedChatRoom.chatRoom}
                  chatRoomParticipantId={participantId}
                  lastReadAt={selectedChatRoom.lastReadAt}
                  recruitmentId={
                    selectedChatRoom.recruitmentApplication.recruitment.id
                  }
                  recruitmentDuration={formatRecruitmentDates(
                    selectedChatRoom.recruitmentApplication.recruitment.schedule
                  )}
                  recruitmentTitle={
                    selectedChatRoom.recruitmentApplication.recruitment
                      .status === "DELETED"
                      ? `[削除済み] ${selectedChatRoom.recruitmentApplication.recruitment.title}`
                      : `${selectedChatRoom.recruitmentApplication.recruitment.title}`
                  }
                  isDeletedRecruitment={
                    selectedChatRoom.recruitmentApplication.recruitment
                      .status === "DELETED"
                  }
                  communityName={
                    selectedChatRoom.chatRoom.community?.name ?? ""
                  }
                />
              )}
            {selectedChatRoom?.chatRoom.type ===
              ChatRoomType.INDIVIDUAL_CHAT_WITH_ORGANIZATION &&
              selectedChatRoom.individualChatWithOrganization && (
                <ChatRoomIndividualContent
                  isOpen={isOpenChatRoom}
                  chatRoomParticipantId={participantId}
                  chatRoom={selectedChatRoom.chatRoom}
                  individualChatWithOrganization={
                    selectedChatRoom.individualChatWithOrganization
                  }
                  lastReadAt={selectedChatRoom.lastReadAt}
                  communityName={
                    selectedChatRoom.chatRoom.community?.name ?? ""
                  }
                />
              )}
            {selectedChatRoom?.chatRoom.type ===
              ChatRoomType.INDIVIDUAL_CHAT_WITH_BOARD_EDUCATION &&
              selectedChatRoom.individualChatWithBoardEducation && (
                <ChatRoomIndividualBEContent
                  isOpen={isOpenChatRoom}
                  chatRoomParticipantId={participantId}
                  chatRoom={selectedChatRoom.chatRoom}
                  individualChatWithBoardEducation={
                    selectedChatRoom.individualChatWithBoardEducation
                  }
                  lastReadAt={selectedChatRoom.lastReadAt}
                />
              )}
            {selectedChatRoom?.chatRoom.type ===
              ChatRoomType.GROUP_CHAT_WITH_ORGANIZATION &&
              selectedChatRoom.groupChatWithOrganization && (
                <ChatRoomGroupContent
                  isOpen={isOpenChatRoom}
                  chatRoomParticipantId={participantId}
                  chatRoom={selectedChatRoom.chatRoom}
                  groupChatWithOrganization={
                    selectedChatRoom.groupChatWithOrganization
                  }
                  lastReadAt={selectedChatRoom.lastReadAt}
                  lastReadAts={
                    selectedChatRoom.lastReadAts
                      ? selectedChatRoom.lastReadAts
                      : [selectedChatRoom.lastReadAt]
                  }
                  refetch={refetchSelectedChatRoom}
                />
              )}
            {selectedChatRoom?.chatRoom.type ===
              ChatRoomType.GROUP_CHAT_WITH_BOARD_EDUCATION &&
              selectedChatRoom.groupChatWithBoardEducation && (
                <ChatRoomGroupBEContent
                  isOpen={isOpenChatRoom}
                  chatRoomParticipantId={participantId}
                  chatRoom={selectedChatRoom.chatRoom}
                  groupChat={selectedChatRoom.groupChatWithBoardEducation}
                  lastReadAt={selectedChatRoom.lastReadAt}
                  lastReadAts={
                    selectedChatRoom.lastReadAts
                      ? selectedChatRoom.lastReadAts
                      : [selectedChatRoom.lastReadAt]
                  }
                  refetch={refetchSelectedChatRoom}
                />
              )}
          </Container>
        </ChatContainer>
        <ChatRoomAppendModal
          isOpen={isOpenChatRoomAppender}
          close={() => {
            setIsOpenChatRoomAppender(false);
          }}
          chatRooms={chatRooms}
        />
      </Padding>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow-y: hidden;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Padding = styled.div`
  padding-top: 24px;
  height: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-bottom: 36px;
`;

const ChatRoomListContainer = styled.div<{ isOpen: boolean }>`
  height: 100%;
  max-height: 100%;
  width: 400px;
  @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
    width: 100%;
    display: ${(props) => (props.isOpen ? "block" : "none")};
  }
`;

const Filter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 25px;
  padding: 16px 24px;
  width: fit-content;
  cursor: pointer;
`;

const ChatContainer = styled.div`
  height: 100%;
  background: white;
  border-radius: 8px;
`;

const CountBadge = styled.span`
  display: grid; /* Grid レイアウトを有効化 */
  place-items: center;
  font-size: 12px;
  margin-left: 8px;
  height: 20px;
  line-height: 20px;
  min-width: 30px;
  border-radius: 64px;
  color: #fff;
  background: #0077cc;
`;

const TabWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const Tab = styled.div<{ $isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  width: 50%;
  ${(props) =>
    props.$isActive && `color: #0077CC; border-bottom: 2px solid #0077CC`};
  cursor: pointer;
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FilterActiveLabel = styled.p`
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  height: 20px;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  background: #0077cc;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 40px;
`;

const AppendChatRoomButton = styled.div``;
