import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { ModalPortal, ModalHeader } from "../../../components/Modal";
import { createRecruitmentApplicationComment } from "../../../apiClients/recruitmentApplicationComment";
import { Button } from "../../../components/Button";
import { useForm, useWatch } from "react-hook-form";
import { useCurrentUser } from "../../../hooks/recoil/user";
import { CurrentUser } from "../../../apiClients/auth";
import { CheckBox } from "../../../components/form/CheckBox";
import { useToast } from "../../../components/Toast";
import { toRecruitmentDateFormat } from "../../../utils/time";
import { FixedPositionMenu } from "../../../components/Common/Menu/fixedPositionMenu";
import { Avatar } from "../../../components/Common/Avatar";
import EditRecruitmentAppliedCommentModalForm from "./EditRecruitmentAppliedCommentModal";
import reactTextareaAutosize from "react-textarea-autosize";
interface CreateModalProps {
  onClose: () => void;
  isRecruitmentApplicationId: string;
  isDeletedRecruitment: boolean;
  comments: {
    user: {
      id: string;
      name: string;
      picture: string;
    };
    id: string;
    comment: string;
    isAlert: boolean;
    createdAt: Date;
  }[];
  onOpenConfirmDeleteCommentModal: (
    recruitmentApplicationCommentId: string,
    comment: string
  ) => void;
  refetch: () => void;
  isCurrentCommunity: boolean;
}

export type FormValues = {
  userId: string;
  recruitmentApplicationId: string;
  comment: string;
  isAlert: boolean;
};

export default function CreateOpenRecruitmentAppliedCommentModal({
  onClose,
  isRecruitmentApplicationId,
  isDeletedRecruitment,
  comments,
  onOpenConfirmDeleteCommentModal,
  refetch,
  isCurrentCommunity,
}: CreateModalProps) {
  const { control, register, handleSubmit } = useForm<FormValues>();
  const currentUser: CurrentUser = useCurrentUser();
  const toast = useToast();
  const [isEditingComment, setIsEditingComment] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState<string>("");

  const watchedComment = useWatch({ control, name: "comment" });
  const isDisableSubmit = useMemo(() => !watchedComment, [watchedComment]);

  const sortedComments = useMemo(() => {
    return comments.sort((a, b) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });
  }, [comments]);

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);

  const onCommentClick = async (data: FormValues) => {
    setDoubleClickBlocked(true);
    try {
      const arg: FormValues = {
        userId: currentUser.id,
        recruitmentApplicationId: isRecruitmentApplicationId,
        comment: data.comment,
        isAlert: data.isAlert,
      };
      await createRecruitmentApplicationComment(arg);
      toast.success("コメントを投稿しました");
      refetch();
    } catch (e) {
      toast.warn("コメントの投稿に失敗しました");
    } finally {
      setDoubleClickBlocked(false);
      onClose();
    }
  };

  return (
    <ModalPortal onClose={onClose}>
      <ModalHeader>
        <p>コメント</p>
      </ModalHeader>
      <ModalBody>
        <CommentInfoContainer>
          {sortedComments.map((comment, i) => (
            <div key={i}>
              <UserInline>
                <Avatar
                  size={40}
                  src={comment.user.picture}
                  alt="ユーザー画像"
                />
                <FullNameContainer>{comment.user.name}</FullNameContainer>
                {comment.isAlert && <AlertTag>アラート</AlertTag>}
                {currentUser.id === comment.user.id && isCurrentCommunity && (
                  <CommentEditMenu
                    commentId={comment.id}
                    comment={comment.comment}
                    onOpenConfirmDeleteCommentModal={
                      onOpenConfirmDeleteCommentModal
                    }
                    setIsEditingComment={setIsEditingComment}
                    setCommentId={setEditingCommentId}
                  />
                )}
              </UserInline>
              <CommentContainer>
                {isEditingComment && editingCommentId === comment.id ? (
                  <EditRecruitmentAppliedCommentModalForm
                    comment={comment.comment}
                    isAlert={comment.isAlert}
                    refetch={refetch}
                    recruitmentApplicationCommentId={comment.id}
                    setIsEditingComment={setIsEditingComment}
                  />
                ) : (
                  <Comment>{comment.comment}</Comment>
                )}
              </CommentContainer>
              <CreatedAtContainer>
                {toRecruitmentDateFormat(new Date(comment.createdAt))}
              </CreatedAtContainer>
            </div>
          ))}
        </CommentInfoContainer>
        {!isDeletedRecruitment && isCurrentCommunity && (
          <>
            <FormArea onClick={handleSubmit(onCommentClick)}>
              <TextField
                placeholder="コメントを入力...."
                {...register("comment", {
                  required: "入力必須項目です",
                })}
              />
              <Button
                fill
                color="primary"
                size="large"
                type="submit"
                style={{ width: "84px" }}
                disabled={doubleClickBlocked || isDisableSubmit}
              >
                投稿
              </Button>
            </FormArea>
            <CheckBox
              label="アラートタグをつける"
              register={register("isAlert")}
            />
          </>
        )}
      </ModalBody>
    </ModalPortal>
  );
}

const CommentEditMenu = ({
  commentId,
  comment,
  onOpenConfirmDeleteCommentModal,
  setIsEditingComment,
  setCommentId,
}: {
  commentId: string;
  comment: string;
  onOpenConfirmDeleteCommentModal: (
    recruitmentApplicationCommentId: string,
    comment: string
  ) => void;
  setIsEditingComment: React.Dispatch<React.SetStateAction<boolean>>;
  setCommentId: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <FixedPositionMenu
      menuList={[
        {
          label: "コメントを編集",
          onClick: () => {
            setIsEditingComment(true);
            setCommentId(commentId);
          },
        },
        {
          label: "コメントを削除",
          onClick: () => onOpenConfirmDeleteCommentModal(commentId, comment),
          color: "#BD271E",
        },
      ]}
    />
  );
};

const ModalBody = styled.div`
  min-height: 50px;
  height: fit-content;
`;

const TextField = styled(reactTextareaAutosize)`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 30px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;

const FormArea = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 16px;
  margin-bottom: 8px;
`;

const CommentInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: scroll;
  max-height: 40vh;
`;

const UserInline = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const FullNameContainer = styled.div`
  font-size: 16px;
  font-weight: 700;
  margin-right: auto;
`;

const CommentContainer = styled.div`
  font-size: 12px;
  margin: 8px 0;
`;

const Comment = styled.p`
  white-space: pre-wrap;
`;

const CreatedAtContainer = styled.div`
  font-size: 12px;
  width: fit-content;
  margin-left: auto;
`;

const AlertTag = styled.p`
  background-color: #bd271e;
  padding: 4px 12px;
  color: white;
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  border-radius: 40px;
`;
