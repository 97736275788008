import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Controller,
  ControllerRenderProps,
  useController,
  useFormContext,
} from "react-hook-form";
import { Community } from "@shared/types/community";
import { Margin } from "src/components/Margin";
import { Spinner } from "src/components/icons/Spinner";
import { useOrganizationCommunity } from "src/hooks/recoil/organization";
import styled from "styled-components";
import { type CSEditBaseInfoModalFormValues } from "../CS";
import { BREAKPOINTS } from "src/components/Responsive";
import { useCurrentCommunityId } from "src/hooks/router";
import { User } from "src/apiClients/users";
import { useCurrentUser } from "src/hooks/recoil/user";
import { CommunityListItem } from "src/features/Member/Community/CommunityListItem";
import { SignatureTemplateButton } from "src/features/Member/Community/SignatureTemplateButton";
import {
  useFilteredSchoolCommunitiesExceptMain,
  useFindAdditionalCommunities,
  useFindMainCommunity,
  useSelectedCommunity,
} from "src/features/Member/Community/utils/hooks";
import {
  hasValidSignatureTemplate,
  isAdminRoleCommunity,
} from "src/features/Member/Community/utils/utils";
import { CommunityWithSignatureTemplate } from "@shared/types/organization";
import { CommunityUserTypeSelect } from "src/features/Member/Community/CommunityUserTypeSelect";
import { EditModalFormLabel } from "..";

type TabType = "elementarySchool" | "juniorHighSchool";

type Props = {
  user: User;
};

export const EditBaseInfoCommunity = ({ user }: Props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CSEditBaseInfoModalFormValues>();
  const currentUser = useCurrentUser();
  const { communityId: currentCommunityId } = useCurrentCommunityId();
  const [tab, setTab] = useState<TabType>("elementarySchool");
  const {
    elementarySchoolCommunities,
    juniorHighSchoolCommunities,
    isLoading: isOrgCommunityLoading,
  } = useOrganizationCommunity();
  const communityRoles = user.account?.communityRoles ?? [];

  const mainElementarySchool = useFindMainCommunity(
    elementarySchoolCommunities,
    communityRoles
  );
  const mainJuniorHighSchool = useFindMainCommunity(
    juniorHighSchoolCommunities,
    communityRoles
  );
  const elementarySchools = useFindAdditionalCommunities(
    elementarySchoolCommunities,
    communityRoles
  );
  const juniorHighSchools = useFindAdditionalCommunities(
    juniorHighSchoolCommunities,
    communityRoles
  );

  const [selectedCommunities, setSelectedCommunities] = useState<Community[]>(
    []
  );

  // 最寄りの小学校区
  const { field: mainElementarySchoolField } = useController({
    control,
    name: "community.mainElementarySchool",
  });
  // 最寄りの小学校区での役割
  const { field: mainElementarySchoolTypeField } = useController({
    control,
    name: "community.mainElementarySchoolType",
  });
  // 最寄りの中学校区
  const { field: mainJuniorHighSchoolField } = useController({
    control,
    name: "community.mainJuniorHighSchool",
  });
  // 最寄りの中学校区での役割
  const { field: mainJuniorHighSchoolTypeField } = useController({
    control,
    name: "community.mainJuniorHighSchoolType",
  });
  // 追加の小学校区
  const { field: elementarySchoolsField } = useController({
    name: "community.elementarySchools",
    control,
  });
  // 追加の小学校区
  const { field: juniorHighSchoolsField } = useController({
    name: "community.juniorHighSchools",
    control,
  });
  // 最寄りの小学校区以外の小学校区
  const filteredElementarySchoolCommunities =
    useFilteredSchoolCommunitiesExceptMain(
      elementarySchoolCommunities,
      mainElementarySchoolField.value
    );
  // 最寄りの中学校区以外の中学校区
  const filteredJuniorHighSchoolCommunities =
    useFilteredSchoolCommunitiesExceptMain(
      juniorHighSchoolCommunities,
      mainJuniorHighSchoolField.value
    );

  useEffect(() => {
    mainElementarySchoolField.onChange(
      mainElementarySchool?.community.id ?? ""
    );
    mainJuniorHighSchoolField.onChange(
      mainJuniorHighSchool?.community.id ?? ""
    );

    mainElementarySchoolTypeField.onChange(
      mainElementarySchool?.communityRole.type
    );
    mainJuniorHighSchoolTypeField.onChange(
      mainJuniorHighSchool?.communityRole.type
    );

    elementarySchoolsField.onChange(elementarySchools.map((s) => s.id));
    juniorHighSchoolsField.onChange(juniorHighSchools.map((s) => s.id));
    setSelectedCommunities([...elementarySchools, ...juniorHighSchools]);
  }, [
    elementarySchoolCommunities,
    juniorHighSchoolCommunities,
    communityRoles,
  ]);

  // 最寄りの小学校区が更新された場合は、追加で選択された小学校区からは削除する
  useEffect(() => {
    const values = elementarySchoolsField.value.filter(
      (s) => s !== mainElementarySchoolField.value
    );
    elementarySchoolsField.onChange(values);
    setSelectedCommunities((prev) => {
      return prev.filter((p) => p.id !== mainElementarySchoolField.value);
    });
  }, [mainElementarySchoolField.value]);
  // 最寄りの中学校区が更新された場合は、追加で選択された中学校区からは削除する
  useEffect(() => {
    const values = juniorHighSchoolsField.value.filter(
      (s) => s !== mainJuniorHighSchoolField.value
    );
    juniorHighSchoolsField.onChange(values);
    setSelectedCommunities((prev) => {
      return prev.filter((p) => p.id !== mainJuniorHighSchoolField.value);
    });
  }, [mainJuniorHighSchoolField.value]);

  const handleMainElementarySchoolSelect = (
    field: ControllerRenderProps<
      CSEditBaseInfoModalFormValues,
      "community.mainElementarySchool"
    >,
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    const prevCommunityId = field.value;
    const communityId = event.target.value;
    // 管理者権限を持つ校区を変更する時は確認用のアラートを出す
    if (isAdminRoleCommunity(prevCommunityId, communityRoles)) {
      // 同校区であれば変更可能
      // 自分の校区であれば変更可能
      if (
        prevCommunityId === currentCommunityId ||
        currentUser.id === user.id
      ) {
        if (!confirm("管理者権限を持つ校区を変更してよろしいですか？")) {
          return;
        }
        // 別の校区の管理者権限の校区を変更はできない
      } else {
        alert("管理者権限の校区は変更できません");
        return;
      }
    }
    field.onChange(communityId);
    mainElementarySchoolTypeField.onChange("");
  };

  const handleMainJuniorHighSchoolSelect = (
    field: ControllerRenderProps<
      CSEditBaseInfoModalFormValues,
      "community.mainJuniorHighSchool"
    >,
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    const prevCommunityId = field.value;
    const communityId = event.target.value;
    // 管理者権限を持つ校区を変更する時は確認用のアラートを出す
    if (isAdminRoleCommunity(prevCommunityId, communityRoles)) {
      // 同校区であれば変更可能
      // 自分の校区であれば変更可能
      if (
        prevCommunityId === currentCommunityId ||
        currentUser.id === user.id
      ) {
        if (!confirm("管理者権限を持つ校区を変更してよろしいですか？")) {
          return;
        }
        // 別の校区の管理者権限の校区を変更はできない
      } else {
        alert("管理者権限の校区は変更できません");
        return;
      }
    }
    field.onChange(communityId);
    mainJuniorHighSchoolTypeField.onChange("");
  };

  const handleElementarySchoolsCheckBox = (
    field: ControllerRenderProps<
      CSEditBaseInfoModalFormValues,
      "community.elementarySchools"
    >,
    community: Community
  ) => {
    const values = field.value.includes(community.id)
      ? field.value.filter((s) => s !== community.id)
      : [...field.value, community.id];
    // 管理者権限を持つ校区を変更する時は確認用のアラートを出す
    if (
      isAdminRoleCommunity(community.id, communityRoles) &&
      field.value.length > values.length
    ) {
      // 同校区であれば変更可能
      // 自分の校区であれば変更可能
      if (community.id === currentCommunityId || currentUser.id === user.id) {
        if (!confirm("管理者権限を持つ校区を変更してよろしいですか？")) {
          return;
        }
        // 別の校区の管理者権限の校区を変更はできない
      } else {
        alert("管理者権限の校区は変更できません");
        return;
      }
    }
    field.onChange(values);
    setSelectedCommunities((prev) => {
      if (prev.findIndex((p) => p.id === community.id) === -1) {
        return [...prev, community];
      }
      return prev.filter((p) => p.id !== community.id);
    });
  };

  const handleJuniorHighSchoolsCheckBox = (
    field: ControllerRenderProps<
      CSEditBaseInfoModalFormValues,
      "community.juniorHighSchools"
    >,
    community: Community
  ) => {
    const values = field.value.includes(community.id)
      ? field.value.filter((s) => s !== community.id)
      : [...field.value, community.id];
    // 管理者権限を持つ校区を変更する時は確認用のアラートを出す
    if (
      isAdminRoleCommunity(community.id, communityRoles) &&
      field.value.length > values.length
    ) {
      // 同校区であれば変更可能
      // 自分の校区であれば変更可能
      if (community.id === currentCommunityId || currentUser.id === user.id) {
        if (!confirm("管理者権限を持つ校区を変更してよろしいですか？")) {
          return;
        }
        // 別の校区の管理者権限の校区を変更はできない
      } else {
        alert("管理者権限の校区は変更できません");
        return;
      }
    }
    field.onChange(values);
    setSelectedCommunities((prev) => {
      if (prev.findIndex((p) => p.id === community.id) === -1) {
        return [...prev, community];
      }
      return prev.filter((p) => p.id !== community.id);
    });
  };

  const selectedElementarySchool = useSelectedCommunity(
    elementarySchoolCommunities,
    mainElementarySchoolField.value
  );

  const selectedJuniorHighSchool = useSelectedCommunity(
    juniorHighSchoolCommunities,
    mainJuniorHighSchoolField.value
  );

  const [elemCommunityDisabledMessage, setElemCommunityDisabledMessage] =
    useState("");
  const [juniorCommunityDisabledMessage, setJuniorCommunityDisabledMessage] =
    useState("");

  return (
    <>
      <EditCommunityWrapper>
        <div style={{ flex: 1 }}>
          <EditModalFormLabel>最寄りの小学校区</EditModalFormLabel>
          <SelectWrapper>
            <Controller
              name="community.mainElementarySchool"
              control={control}
              render={({ field, formState }) => {
                return (
                  <>
                    <SelectBox
                      onChange={(event) =>
                        handleMainElementarySchoolSelect(field, event)
                      }
                      value={field.value}
                    >
                      <option value="" disabled style={{ color: "#aab4c4" }}>
                        〇〇小学校
                      </option>
                      {elementarySchoolCommunities
                        .filter(hasValidSignatureTemplate)
                        .map((community) => {
                          return (
                            <option key={community.id} value={community.id}>
                              {community.name}
                            </option>
                          );
                        })}
                    </SelectBox>
                    {formState.errors.community?.mainElementarySchool ? (
                      <span className="error-message">
                        {
                          formState.errors.community.mainElementarySchool
                            .message
                        }
                      </span>
                    ) : (
                      <Margin marginTop={8} />
                    )}
                  </>
                );
              }}
            />
            {selectedElementarySchool && (
              <>
                <SignatureTemplateButton community={selectedElementarySchool} />
                <CommunityUserTypeSelect
                  name="community.mainElementarySchoolType"
                  control={control}
                  fieldError={errors.community?.mainElementarySchoolType}
                />
              </>
            )}
          </SelectWrapper>
        </div>
        <div style={{ flex: 1 }}>
          <EditModalFormLabel>最寄りの中学校区</EditModalFormLabel>
          <SelectWrapper>
            <Controller
              name="community.mainJuniorHighSchool"
              control={control}
              render={({ field, formState }) => {
                return (
                  <>
                    <SelectBox
                      onChange={(event) =>
                        handleMainJuniorHighSchoolSelect(field, event)
                      }
                      value={field.value}
                    >
                      <option value="" disabled style={{ color: "#aab4c4" }}>
                        〇〇中学校
                      </option>
                      {juniorHighSchoolCommunities
                        .filter(hasValidSignatureTemplate)
                        .map((community) => {
                          return (
                            <option key={community.id} value={community.id}>
                              {community.name}
                            </option>
                          );
                        })}
                    </SelectBox>
                    {formState.errors.community?.mainJuniorHighSchool ? (
                      <span className="error-message">
                        {
                          formState.errors.community.mainJuniorHighSchool
                            .message
                        }
                      </span>
                    ) : (
                      <Margin marginTop={8} />
                    )}
                  </>
                );
              }}
            ></Controller>
            {selectedJuniorHighSchool && (
              <>
                <SignatureTemplateButton community={selectedJuniorHighSchool} />
                <CommunityUserTypeSelect
                  name="community.mainJuniorHighSchoolType"
                  control={control}
                  fieldError={errors.community?.mainJuniorHighSchoolType}
                />
              </>
            )}
          </SelectWrapper>
        </div>
      </EditCommunityWrapper>
      <div>
        <EditModalFormLabel>校区を追加</EditModalFormLabel>
        <TabWrapper>
          <Tab
            $isActive={tab === "elementarySchool"}
            onClick={() => setTab("elementarySchool")}
          >
            小学校
          </Tab>
          <Tab
            $isActive={tab === "juniorHighSchool"}
            onClick={() => setTab("juniorHighSchool")}
          >
            中学校
          </Tab>
        </TabWrapper>
        <CommunityListWrapper>
          <CommunityList>
            {isOrgCommunityLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                }}
              >
                <Spinner />
              </div>
            ) : tab === "elementarySchool" ? (
              filteredElementarySchoolCommunities.map((community, index) => {
                return (
                  <Controller
                    key={community.name + index}
                    name="community.elementarySchools"
                    control={control}
                    render={({ field }) => {
                      return (
                        <CommunityListItem
                          key={index}
                          user={user}
                          community={community}
                          setCommunityDisabledMessage={
                            setElemCommunityDisabledMessage
                          }
                          onCheckboxChange={(
                            community: CommunityWithSignatureTemplate
                          ) =>
                            handleElementarySchoolsCheckBox(field, community)
                          }
                          isChecked={field.value.includes(community.id || "")}
                        />
                      );
                    }}
                  ></Controller>
                );
              })
            ) : (
              tab === "juniorHighSchool" &&
              filteredJuniorHighSchoolCommunities.map((community, index) => {
                return (
                  <Controller
                    key={community.name + index}
                    name="community.juniorHighSchools"
                    control={control}
                    render={({ field }) => {
                      return (
                        <CommunityListItem
                          key={index}
                          user={user}
                          community={community}
                          setCommunityDisabledMessage={
                            setJuniorCommunityDisabledMessage
                          }
                          onCheckboxChange={(
                            community: CommunityWithSignatureTemplate
                          ) =>
                            handleJuniorHighSchoolsCheckBox(field, community)
                          }
                          isChecked={field.value.includes(community.id || "")}
                        />
                      );
                    }}
                  ></Controller>
                );
              })
            )}
          </CommunityList>
          {tab === "elementarySchool" ? (
            <DisabledMessage>{elemCommunityDisabledMessage}</DisabledMessage>
          ) : (
            <DisabledMessage>{juniorCommunityDisabledMessage}</DisabledMessage>
          )}
        </CommunityListWrapper>
        {selectedCommunities.length > 0 && (
          <SelectedValuesList>
            <Divider />
            <SubTitle>選択された追加の校区</SubTitle>
            <Margin marginBottom={10} />
            <BadgeWrapper>
              {selectedCommunities.map((community, index) => {
                return <Badge key={index}>{community.name}</Badge>;
              })}
            </BadgeWrapper>
          </SelectedValuesList>
        )}
      </div>
    </>
  );
};

const EditCommunityWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  @media (max-width: ${BREAKPOINTS.SP}) {
    display: block;
  }
`;

const SelectWrapper = styled.div`
  min-width: 200px;
  @media (max-width: 1279px) {
    min-width: 163px;
  }
`;

const SelectBox = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  cursor: pointer;
`;

const TabWrapper = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #1322951a;
`;

const Tab = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: start;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-right: 20px;
  ${(props) =>
    props.$isActive && `color: #0077CC; border-bottom: 2px solid #0077CC`};
  cursor: pointer;
`;

const CommunityListWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-top: 8px;
`;

const CommunityList = styled.ul`
  overflow: auto;
  width: 100%;
  max-height: 192px;
  background: white;
  list-style: none;
  border-radius: 6px;
  border: 1px solid #1322951a;
`;

const SelectedValuesList = styled.ul`
  list-style: none;
`;

const SubTitle = styled.p`
  font-size: 12px;
`;

const Divider = styled.div`
  width: 100%;
  border: 0.75px solid #f0f2f5;
  margin: 10px 0px;
`;

const BadgeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const DisabledMessage = styled.p`
  margin-top: 4px;
  margin-left: 4px;
  font-size: 12px;
  color: #343741;
`;

const Badge = styled.div`
  width: fit-content;
  border: 1px solid #0071c2;
  border-radius: 36px;
  background: #e6f1fa;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 12px;
  gap: 4px;
  font-size: 12px;
  margin-right: 8px;
`;
