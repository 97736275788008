import { z } from "zod";
import { residentInfoSchema } from "@shared/validator/models/residentInfo.schema";

/**
 * preferredPaidフィールドを変換する関数
 * @param val - "EITHER" | "PAID" | "FREE" | null | undefined
 * @returns ("PAID" | "FREE")[] - 変換後の配列
 */
export function convertPreferredPaid(
  val: "EITHER" | "PAID" | "FREE" | null | undefined
): ("PAID" | "FREE")[] {
  if (val === "EITHER") {
    return ["PAID", "FREE"];
  }
  return val ? [val] : [];
}

/**
 * preferredPaidの配列をフォーム用の値に変換する関数
 * @param arr - ("PAID" | "FREE")[]
 * @returns "EITHER" | "PAID" | "FREE" | null - 変換後の値
 */
export function convertArrayToPreferredPaid(
  arr: ("PAID" | "FREE")[]
): "EITHER" | "PAID" | "FREE" | null {
  if (!arr || arr.length === 0) {
    return null;
  }

  if (arr.includes("PAID") && arr.includes("FREE")) {
    return "EITHER";
  }

  if (arr.includes("PAID")) {
    return "PAID";
  }

  if (arr.includes("FREE")) {
    return "FREE";
  }

  return null;
}

export const residentInfoFormSchema = residentInfoSchema.extend({
  preferredPaid: z
    .union([z.literal("EITHER"), z.literal("PAID"), z.literal("FREE")])
    .nullable(),
});
