import React, { useEffect, useMemo, useState } from "react";
import { Title } from "../../../components/Title";
import styled, { css } from "styled-components";
import { usePolyfitHistory } from "../../../hooks/router";
import { volunteerOption } from "../../../@shared/types/volunteerType";
import { NotFoundRecruitment } from "../../../features/Recruitment/recruitment/NotFoundRecruitment";
import { RecruitmentCard } from "../../../features/Recruitment/recruitment/RecruitmentCard";
import { CheckBox } from "../../../components/form/CheckBox";
import { useRecruitmentFilter } from "../../../hooks/recoil/recruitmentFilter";
import { Label } from "../../../components/form/Label";
import { ArrowDropDownIcon } from "../../../components/icons/ArrowDropDown";
import { useRecruitmentList } from "src/hooks/query/recruitments/recruitmentList";
import { LoadingCard } from "src/components/LoadingCard";
import { BREAKPOINTS } from "src/components/Responsive";
import { recruitmentIsOpened } from "src/apiClients/recruitment";
import { Button } from "src/components/Button";

export default function ResidentRecruitmentListPage() {
  const history = usePolyfitHistory();
  const [recruitmentFilter, setRecruitmentFilter] = useRecruitmentFilter();
  const [page, setPage] = useState(1);
  const take = 20;

  const [volunteerType, setVolunteerType] = useState<string>(
    recruitmentFilter.volunteerType ?? ""
  );
  const [isPaidVolunteer, setIsPaidVolunteer] = useState<"PAID" | "FREE" | "">(
    recruitmentFilter.isPaidVolunteer ?? ""
  );
  const [date, setDate] = useState<string>(recruitmentFilter.date ?? "");
  const [isOpen, setIsOpen] = useState(recruitmentFilter.isOpen ?? false);
  const filterCondition = useMemo(() => {
    const filter = {
      volunteerType,
      isPaidVolunteer: isPaidVolunteer ? isPaidVolunteer : undefined,
      date,
      isOpen,
      page,
      take,
    };
    setRecruitmentFilter(filter);
    return filter;
  }, [volunteerType, isPaidVolunteer, date, isOpen, page]);

  // フィルターが変更されたときにpageをリセット
  useEffect(() => {
    setPage(1);
  }, [volunteerType, isPaidVolunteer, date, isOpen]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOpen(event.target.checked);
  };
  const { recruitments, isPending, hasNextPage } = useRecruitmentList(
    filterCondition,
    {
      refetchOnMount: "always",
    }
  );

  const handleNextPage = () => {
    if (hasNextPage) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <>
      <Title>募集一覧</Title>
      <Spacer height={16} />
      <Filter>
        <FilterSummary>
          フィルター設定
          <ArrowDropDownIcon size={24} />
        </FilterSummary>
        <FilterContainer>
          <InputMajorContainer>
            <InputContainer>
              <Label size="s">ボランティア項目</Label>
              <InputSelect
                defaultValue={volunteerType}
                onChange={(e) => setVolunteerType(e.target.value)}
                value={volunteerType}
              >
                <option value="">種類を選択</option>
                {volunteerOption.map((opt) => {
                  return (
                    <option key={opt.value} value={opt.value}>
                      {opt.text}
                    </option>
                  );
                })}
              </InputSelect>
            </InputContainer>
            <InputContainer>
              <Label size="s">ボランティア形態</Label>
              <InputSelect
                defaultValue={isPaidVolunteer}
                onChange={(e) =>
                  setIsPaidVolunteer(e.target.value as "PAID" | "FREE" | "")
                }
                value={isPaidVolunteer}
              >
                <option value="">形態を選択</option>
                <option value="PAID">有償</option>
                <option value="FREE">無償</option>
              </InputSelect>
            </InputContainer>
            <InputContainer>
              <Label size="s">日付を選択</Label>
              <InputDateTimePicker
                min="1900-01-01"
                max="2100-12-31"
                type="date"
                onChange={(e) => setDate(e.target.value)}
                defaultValue={date}
                value={date}
                isDate={date !== undefined && date !== ""}
              />
            </InputContainer>
          </InputMajorContainer>
          <CheckBox
            label="募集中のみ表示"
            onChange={handleChange}
            defaultChecked={isOpen}
          />
        </FilterContainer>
      </Filter>
      <CardWrapper>
        {isPending ? (
          <LoadingCard />
        ) : recruitments?.length === 0 ? (
          <NotFoundRecruitment
            title="掲載中の募集はありません"
            description=" 募集が掲載されるとこちらの画面に表示されます"
          />
        ) : (
          <CardContainer>
            {recruitments?.map((recruitment, i) => {
              const onClick = () =>
                history.push({
                  to: "RESIDENT_RECRUITMENT_DETAIL",
                  query: { id: recruitment.id },
                });
              const isOpenedRecruitment = recruitmentIsOpened(recruitment);

              return (
                <RecruitmentCard
                  key={recruitment.id}
                  recruitment={recruitment}
                  primaryButtonLabel={
                    isOpenedRecruitment
                      ? "詳細を確認する"
                      : "この募集は終了しました"
                  }
                  onClickPrimaryButton={onClick}
                  primaryButtonIsDisabled={!isOpenedRecruitment}
                />
              );
            })}
          </CardContainer>
        )}
      </CardWrapper>
      {!isPending && (
        <PaginationContainer>
          <Button
            fill
            size="large"
            onClick={handlePrevPage}
            disabled={page === 1}
            type="button"
          >
            {`前の${take}件を表示`}
          </Button>
          <Button
            fill
            size="large"
            onClick={handleNextPage}
            disabled={!hasNextPage}
            type="button"
          >
            {`次の${take}件を表示`}
          </Button>
        </PaginationContainer>
      )}
    </>
  );
}

const FilterContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 16px;
`;

const CardWrapper = styled.div`
  margin: 8px auto;
`;

const CardContainer = styled.div`
  position: relative;
  display: grid;
  grid-gap: 24px;
  width: 100%;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const InputContainer = styled.div`
  flex: 1;
  min-width: 200px;
`;

const InputDateTimePicker = styled.input<{ isDate: boolean }>`
  color: ${(props) => (props.isDate ? "#343741" : "#AAB4C4")};
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  font-weight: normal;
  width: 100%;
  box-sizing: border-box;
`;

const InputSelect = styled.select`
  ${(props) =>
    props.value === "" &&
    css`
      color: #aab4c4;
    `}
  ${(props) =>
    props.value !== "" &&
    css`
      color: #343741;
    `}
  appearance: none;
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  box-sizing: border-box;
`;

const InputMajorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  margin-bottom: 16px;
  @media (max-width: ${BREAKPOINTS.SP}) {
    flex-direction: column;
  }
`;

const Filter = styled.details`
  & > summary > svg {
    transition: transform 0.2s;
  }
  &[open] > summary > svg {
    transform: rotate(180deg);
  }
`;

const FilterSummary = styled.summary`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 6px;
  list-style: none;
  ::-webkit-details-marker {
    display: none;
  }
`;

const Spacer = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  margin-top: 24px;
  padding-bottom: 40px;
  gap: 36px;
`;
