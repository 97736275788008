import React, { useEffect } from "react";
import { usePolyfitHistory } from "../../../hooks/router";
import { BaseInfo, useCurrentUser } from "../../../hooks/recoil/user";
import styled from "styled-components";

//components
import * as Responsive from "../../../components/Responsive";
import * as Typo from "../../../components/Typo";
import { StepAdmin } from "../../../components/icons/StepAdmin";
import { StepMember } from "../../../components/icons/StepMember";
import { Margin } from "../../../components/Margin";
import { useCustomReactHookForm } from "../../../components/form/ReactHookForm";
import { RegistrationFooter } from "../../../components/RegistrationFooter";
import { unreachable } from "../../../utils/unreachable";
import { useBaseInfo, useRegisterInfo } from "../../../hooks/api/baseInfo";
import { Role, isPtaAdminRole } from "../../../utils/types/role";

export default function RegisterInfoPage() {
  const history = usePolyfitHistory();
  const user = useCurrentUser();
  const { registerInfo } = useRegisterInfo();
  const { currentBaseInfo } = useBaseInfo();

  const { TextField, SelectField, errors, Form, setValue } =
    useCustomReactHookForm<BaseInfo>();

  useEffect(() => {
    if (currentBaseInfo?.userId !== "") {
      setValue("lastName", currentBaseInfo?.lastName);
      setValue("firstName", currentBaseInfo?.firstName);
      setValue("lastNameKana", currentBaseInfo?.lastNameKana);
      setValue("firstNameKana", currentBaseInfo?.firstNameKana);
      setValue("phoneNumber", currentBaseInfo?.phoneNumber);
    }
  }, [currentBaseInfo]);

  const onSubmit = async (data: BaseInfo) => {
    try {
      await registerInfo({
        ...data,
        isPreschooler: user.type === "PARENT" ? data.isPreschooler : false,
      });
      switch (user.role) {
        case Role.PTAAdmin:
          history.push({
            to: "REGISTER_CHILD_INFO",
          });
          break;
        case Role.PTAMember:
          if (user.type === "TEACHER") {
            history.push({
              to: "REGISTER_TEACHER_INFO",
            });
          } else {
            history.push({
              to: "REGISTER_CHILD_INFO",
            });
          }
          break;
        case Role.CSAdmin:
        case Role.CSMember:
        case Role.CSGuest:
        case Role.CSOperator:
        case Role.BEAdmin:
          break;
        default:
          unreachable(user.role);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Margin marginBottom={24} />
      {isPtaAdminRole(user.role) ? (
        <StepAdmin stepNum={1} />
      ) : (
        <StepMember stepNum={1} />
      )}
      <Form onSubmit={onSubmit}>
        <StyledContainer>
          <Margin marginBottom={16} />
          <Typo.Heading3>
            {user.type === "TEACHER" ? "教員" : "PTA活動を主にされる保護者"}
            さまの情報
          </Typo.Heading3>
          <Margin marginBottom={8} />
          <Responsive.Row>
            <Responsive.Col>
              <Typo.Heading4>お名前</Typo.Heading4>
              <Margin marginBottom={4} />
              <HorizontalInputWrapper>
                <SelectWrapper>
                  <TextField
                    // style={{ width: "240px" }}
                    name="lastName"
                    options={{ required: "入力必須項目です" }}
                    placeholder="姓"
                    isDanger={Boolean(errors.lastName)}
                  />
                  {errors.lastName ? (
                    <span className="error-message">
                      {errors.lastName.message}
                    </span>
                  ) : (
                    <Margin marginTop={16} />
                  )}
                </SelectWrapper>
                <SelectWrapper>
                  <TextField
                    // style={{ width: "240px" }}
                    name="firstName"
                    options={{ required: "入力必須項目です" }}
                    placeholder="名"
                    isDanger={Boolean(errors.firstName)}
                  />
                  {errors.firstName ? (
                    <span className="error-message">
                      {errors.firstName.message}
                    </span>
                  ) : (
                    <Margin marginTop={16} />
                  )}
                </SelectWrapper>
              </HorizontalInputWrapper>
              <Typo.Heading4>ふりがな</Typo.Heading4>
              <HorizontalInputWrapper>
                <SelectWrapper>
                  <TextField
                    // style={{ width: "240px" }}
                    name="lastNameKana"
                    options={{
                      required: "入力必須項目です",
                      pattern: {
                        value: /^[ぁ-んーー]+$/u,
                        message: "ひらがなのみで入力してください",
                      },
                    }}
                    placeholder="せい"
                    isDanger={Boolean(errors.lastNameKana)}
                  />
                  {errors.lastNameKana ? (
                    <span className="error-message">
                      {errors.lastNameKana.message}
                    </span>
                  ) : (
                    <Margin marginTop={18} />
                  )}
                </SelectWrapper>
                <SelectWrapper>
                  <TextField
                    // style={{ width: "240px" }}
                    name="firstNameKana"
                    options={{
                      required: "入力必須項目です",
                      pattern: {
                        value: /^[ぁ-んーー]+$/u,
                        message: "ひらがなのみで入力してください",
                      },
                    }}
                    placeholder="めい"
                    isDanger={Boolean(errors.firstNameKana)}
                  />
                  {errors.firstNameKana ? (
                    <span className="error-message">
                      {errors.firstNameKana.message}
                    </span>
                  ) : (
                    <Margin marginTop={18} />
                  )}
                </SelectWrapper>
              </HorizontalInputWrapper>
              <Typo.Heading4>電話番号（日中に連絡が取れる番号）</Typo.Heading4>
              <Margin marginBottom={4} />
              <TextField
                name="phoneNumber"
                options={{
                  required: "入力必須項目です",
                  pattern: {
                    value: /(0{1}\d{9,10})$/,
                    message:
                      "ハイフンは入力せず、半角文字のみで入力してください",
                  },
                  validate: {
                    message: (v) => {
                      if (!isNaN(v)) {
                        return;
                      } else {
                        return "ハイフンは入力せず、半角文字のみで入力してください";
                      }
                    },
                  },
                }}
                placeholder="例)  09012345678"
                isDanger={Boolean(errors.phoneNumber)}
              />
              {errors.phoneNumber ? (
                <span className="error-message">
                  {errors.phoneNumber.message}
                </span>
              ) : (
                <Margin marginTop={16} />
              )}

              {user.type === "PARENT" && (
                <>
                  <Typo.Heading4>
                    来年度に入学予定の児童（同学校）
                  </Typo.Heading4>
                  <Margin marginBottom={4} />
                  <SelectField
                    name="isPreschooler"
                    options={{
                      required: "入力必須項目です",
                      valueAsNumber: true,
                    }}
                    // @ts-expect-error todo
                    placeholder="選択してください"
                    isDanger={Boolean(errors.isPreschooler)}
                    selectList={[
                      {
                        value: 0,
                        text: "いない",
                      },
                      {
                        value: 1,
                        text: "いる",
                      },
                    ]}
                  />
                  {errors.isPreschooler ? (
                    <span className="error-message">
                      {errors.isPreschooler.message}
                    </span>
                  ) : (
                    <Margin marginTop={16} />
                  )}
                </>
              )}
            </Responsive.Col>
          </Responsive.Row>
        </StyledContainer>
        <RegistrationFooter />
      </Form>
    </div>
  );
}

const StyledContainer = styled.div`
  max-width: 496px;
  margin: 0 auto;
  padding-bottom: 88px;
  .error-message {
    font-size: 12px;
    color: #bd271e;
  }
  @media (max-width: 1279px) {
    max-width: 343px;
  }
`;

const HorizontalInputWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 16px;
`;

const SelectWrapper = styled.div`
  min-width: 240px;
  @media (max-width: 1279px) {
    min-width: 163px;
  }
`;
