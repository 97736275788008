import { z } from "zod";
import { residentInfoSchema } from "../models/residentInfo.schema";
import { accountCommunityRolesRequestSchema } from "../models/accountCommunityRole.schema";

export const residentInfoBodySchema = z.object({
  data: residentInfoSchema,
});

export type ResidentInfoBodySchemaType = z.infer<typeof residentInfoBodySchema>;

export const residentInfoBodyForCSSchema = residentInfoBodySchema.extend({
  userId: z.string().min(1),
});

export type ResidentInfoBodyForCSSchemaType = z.infer<
  typeof residentInfoBodyForCSSchema
>;

export const residentInfoBodyForBESchema = residentInfoBodySchema.extend({
  accountId: z.string().min(1),
});

export type ResidentInfoBodyForBESchemaType = z.infer<
  typeof residentInfoBodyForBESchema
>;

export const updateAccountCommunityRolesRequestSchema = z.object({
  userId: z.string(),
  data: z.array(accountCommunityRolesRequestSchema),
  skipValidate: z.boolean().optional(),
});

export type UpdateAccountCommunityRolesRequestSchemaType = z.infer<
  typeof updateAccountCommunityRolesRequestSchema
>;
