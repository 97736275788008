import * as client from "./client";

type CommunityIcon =
  | {
      id: string;
      communityId: string;
      organizationId: string;
      iconUrl: string;
    }
  | undefined;

export async function createOrUpdateCommunityIcon({
  icon,
}: {
  icon: File;
}): Promise<void> {
  await client.postWithFormData<{ icon: File }>(
    "/communityIcon/createOrUpdate",
    { icon }
  );
}

export async function getCommunityIcon(
  communityId: string,
  organizationId: string
): Promise<CommunityIcon> {
  const res = await client.get<
    { communityId: string; organizationId: string },
    { communityIcon: CommunityIcon }
  >("/communityIcon/get", { communityId, organizationId });
  return res.communityIcon;
}
