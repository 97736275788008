/**
 * 過去日付かどうかを判定する関数
 * @param value - 判定対象の日付文字列
 * @returns 過去日付または未定義/空の場合は true、それ以外は false
 */
export const isPastDate = (value?: string): boolean => {
  if (!value) return true;
  const date = new Date(value);
  const now = new Date();
  return date <= now;
};
