import React from "react";
import styled from "styled-components";

interface Props {
  elements: {
    isActive: boolean;
    text: string;
    onclick: () => void;
  }[];
}

export const TabNavigation = ({ elements }: Props) => {
  return (
    <TabWrapper>
      {elements.map((element, index) => (
        <Tab key={index} $isActive={element.isActive} onClick={element.onclick}>
          {element.text}
        </Tab>
      ))}
    </TabWrapper>
  );
};

const TabWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  border-bottom: 1px solid #d3dae6;
`;

const Tab = styled.div<{ $isActive: boolean }>`
  ${(props) =>
    props.$isActive && `color: #0077CC; border-bottom: 1px solid #0077CC`};
  padding: 10px;
  cursor: pointer;
`;
