import React from "react";
import styled from "styled-components";
import { ChatInputForm } from "src/components/form/ChatInputForm";
import { ChatRoom } from "@shared/types/chatRoom";
import { useCurrentUser } from "src/hooks/recoil/user";
import { updateAdminOnly } from "src/apiClients/boardEducation/chatRoom";

type GroupContentChatMessageInputAreaProps = {
  setInput: React.Dispatch<React.SetStateAction<string>>;
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  chatRoom: ChatRoom;
  chatRoomParticipantId: string;
  canSend: boolean;
  onSend: () => void;
};

export const GroupContentChatMessageInputArea = (
  props: GroupContentChatMessageInputAreaProps
) => {
  const user = useCurrentUser();
  const isAdmin = props.chatRoomParticipantId !== user.id;

  const onCheckedAdminOnly = async (checked: boolean) => {
    await updateAdminOnly(props.chatRoom.id, checked);
  };
  return (
    <>
      <ChatMessageInputContainer>
        <ChatMessageInputArea>
          <ChatInputForm
            isAdmin={isAdmin}
            adminOnly={props.chatRoom.adminOnly}
            onCheckedAdminOnly={onCheckedAdminOnly}
            placeholder="メッセージを入力"
            setValue={props.setInput}
            setFiles={props.setFiles}
            canSend={props.canSend}
            onSend={props.onSend}
          />
        </ChatMessageInputArea>
      </ChatMessageInputContainer>
    </>
  );
};

const ChatMessageInputContainer = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChatMessageInputArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
