import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { approvePost } from "../../apiClients/post";
import { User } from "../../apiClients/users";
import { PostType } from "../../hooks/api/post";
import { useCurrentUser } from "../../hooks/recoil/user";
import {
  useAdminUsersByOrganizationId,
  usePTAUsers,
  useUserById,
} from "../../hooks/api/users";
import { Button } from "../../components/Button";
import { SubmittingModal } from "../../components/Modal";
import { useToast } from "../../components/Toast";
import {
  LastApprovalPostConfirmModal,
  LastApprovalPostScheduleModal,
} from "../../pages/pta/post/LastApprovalPostScheduleModal";
import { logger } from "src/utils/logger";

export const ApproveButton = ({
  post,
  onPostApproved,
}: {
  post: PostType;
  onPostApproved: (post: PostType) => void;
}) => {
  const currentUser = useCurrentUser();
  const [{ users }] = usePTAUsers({});
  const approvers = users.filter((user) => user.isApprover);
  const { adminUsers } = useAdminUsersByOrganizationId({
    organizationId: currentUser.account?.organizationId ?? "",
  });
  const { user: postedUser } = useUserById(post.userId);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sentEmailCount, setSentEmailCount] = useState(0);

  const toast = useToast();

  const [isOpenLastConfirmModal, setIsOpenLastConfirmModal] = useState(false);
  const [isOpenScheduledModal, setIsOpenScheduledModal] = useState(false);

  // TODO: isOpenの値が変わる際に順番か変わってしまうためソートし直す。
  post.postApprovals = [...post.postApprovals].sort((a, b) =>
    a.createdAt > b.createdAt ? 1 : -1
  );

  const isApproved = useCallback(
    (post: PostType, currentUser: User): boolean => {
      // 作成日降順に並び替えたpostApprovals
      const approvals = [...post.postApprovals].sort((a, b) =>
        a.createdAt < b.createdAt ? 1 : -1
      );

      // 最終差し戻し
      const lastRemandApproval = approvals.find(
        (approval) => !approval.approve
      );
      // 最終差し戻し時間
      const lastRemandedAt = lastRemandApproval?.createdAt;

      // 最終差し戻し以降の自分の承認
      const myApprovalAfterLastRemand = approvals.find((approval) => {
        // 自分の承認に絞る
        if (approval.userId !== currentUser.id) {
          return false;
        }
        // 差し戻しがある場合、最終差し戻し以降の承認に絞る
        if (lastRemandedAt) {
          if (approval.createdAt < lastRemandedAt) {
            return false;
          }
        }
        // 承認済みに絞る
        if (!approval.approve) {
          return false;
        }
        // 最終差し戻し以降の自分の承認であればtrue
        return true;
      });

      return Boolean(myApprovalAfterLastRemand);
    },
    [post]
  );

  const allEmailCount = useMemo(() => {
    // メール送信対象のemail一覧（重複を削除する）
    const emails = Array.from(
      new Set([
        ...post.targetUsers.map(({ user }) => user.email),
        ...post.targetUsers.map(({ user }) => user?.partner?.email),
        ...adminUsers.map((user) => user.email),
        postedUser.email,
      ])
    ).filter((email): email is string => typeof email == "string"); // 型を(string | undefined)[] から string[] に変換

    return emails.length;
  }, [post, adminUsers, postedUser]);

  const approve = useCallback(async () => {
    // users のロードがまだ && approvers === 0 になってしまう時はボタンを押下できないようにする
    if (users.length === 0 || approvers.length === 0) {
      return;
    }
    try {
      // setIsLoading(true);
      setSentEmailCount(0);
      // 現在の承認数
      const approvedCount = approveCount(post);
      // 承認者数
      const approversCount = approvers.length;
      // メール最大送信数を設定
      if (approvedCount === approversCount - 1) {
        // 自分が最終承認者の場合
        setIsOpenLastConfirmModal(true);
      } else {
        // 通常の承認の場合
        await approvePost(post.id, false);
        await onPostApproved(post);
        toast.success("承認しました");
      }
    } catch (error) {
      setIsSubmitting(false);
      logger.error(error);
      toast.error("承認に失敗しました");
    }
  }, [post, adminUsers, postedUser, users]);
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onNowSubmit = useCallback(async () => {
    setDoubleClickBlocked(true);
    try {
      setSentEmailCount(0);
      await approvePost(post.id, true);
      setSentEmailCount(allEmailCount);
      onPostApproved(post);
      setIsOpenLastConfirmModal(false);
      setIsSubmitting(true);
    } catch (error) {
      console.log(error);
      toast.error(
        "承認処理は既に実行されています、URLの再リロードをしてください。"
      );
      setIsOpenLastConfirmModal(true);
    } finally {
      setDoubleClickBlocked(false);
    }
  }, [post, adminUsers, postedUser, users]);

  const isButtonDisabled = useMemo(() => {
    return (
      (users.length === 0 && approvers.length === 0) ||
      isApproved(post, currentUser) ||
      isSubmitting
    );
  }, [users, approvers, post, currentUser, isSubmitting]);

  if (typeof postedUser === "undefined" || typeof approvers === "undefined") {
    return null;
  } else {
    return (
      <>
        <StyledButton
          size="large"
          color="primary"
          fill={true}
          disabled={isButtonDisabled}
          onClick={approve}
        >
          承認
        </StyledButton>
        {isSubmitting && (
          <SubmittingModal
            sentEmailCount={sentEmailCount}
            allEmailCount={allEmailCount}
          />
        )}
        {isOpenLastConfirmModal && (
          <LastApprovalPostConfirmModal
            disabled={isButtonDisabled || doubleClickBlocked}
            onNowSubmit={onNowSubmit}
            onScheduledSubmit={() => {
              setIsOpenLastConfirmModal(false);
              setIsOpenScheduledModal(true);
            }}
            onCancel={() => {
              setIsOpenLastConfirmModal(false);
            }}
          />
        )}
        {isOpenScheduledModal && (
          <LastApprovalPostScheduleModal
            post={post}
            onClose={() => {
              setIsOpenScheduledModal(false);
            }}
            onPostApproved={onPostApproved}
          />
        )}
      </>
    );
  }
};

const StyledButton = styled(Button)`
  width: 112px;
`;

/**
 * ログインユーザーが承認済みか判定する
 * @param {PostType} post
 * @return {boolean} 承認済みかどうか
 */
export const isApprovedByMe = (post: PostType): boolean => {
  const currentUser = useCurrentUser();
  // 作成日降順に並び替えたpostApprovals
  const approvals = [...post.postApprovals].sort((a, b) =>
    a.createdAt < b.createdAt ? 1 : -1
  );

  // 最終差し戻し
  const lastRemandApproval = approvals.find((approval) => !approval.approve);
  // 最終差し戻し時間
  const lastRemandedAt = lastRemandApproval?.createdAt;

  // 最終差し戻し以降の自分の承認
  const myApprovalAfterLastRemand = approvals.find((approval) => {
    // 自分の承認に絞る
    if (approval.userId !== currentUser.id) {
      return false;
    }
    // 差し戻しがある場合、最終差し戻し以降の承認に絞る
    if (lastRemandedAt) {
      if (approval.createdAt < lastRemandedAt) {
        return false;
      }
    }
    // 承認済みに絞る
    if (!approval.approve) {
      return false;
    }
    // 最終差し戻し以降の自分の承認であればtrue
    return true;
  });

  return Boolean(myApprovalAfterLastRemand);
};

/**
 * 現在の承認数を取得する
 * @param {PostType} post
 * @return {number} 現在の承認数
 */
export const approveCount = (post: PostType): number => {
  // 作成日降順に並び替えたpostApprovals
  const approvals = [...post.postApprovals].sort((a, b) =>
    a.createdAt < b.createdAt ? 1 : -1
  );

  // 最終差し戻し
  const lastRemandApproval = approvals.find((approval) => !approval.approve);

  // 最終差し戻し時間
  const lastRemandedAt = lastRemandApproval?.createdAt;

  // 最終差し戻し以降の承認
  const approvalsAfterLastRemand = approvals.filter((approval) => {
    // 差し戻しがある場合、最終差し戻し以降の承認に絞る
    if (lastRemandedAt) {
      if (approval.createdAt < lastRemandedAt) {
        return false;
      }
    }
    // 承認済みに絞る
    if (!approval.approve) {
      return false;
    }
    // 最終差し戻し以降の承認であればtrue
    return true;
  });

  return approvalsAfterLastRemand.length;
};
