import React, { useState } from "react";
import styled from "styled-components";
import { CurrentUser } from "../../apiClients/auth";
import {
  SettingButton,
  SettingEditInfo,
} from "../../pages/pta/list/PTAMemberPage";
import { useCurrentUser } from "../../hooks/recoil/user";
import { CreatePostModal } from "./CreatePostModal";
import { Title } from "../../components/Title";
import { ModalBackGround } from "../../components/ListPageFilterSetting";
import { Button } from "../../components/Button";
import { PostApproverModal } from "./PostApproverModal";
import { zIndexes } from "../../zIndex";
import { PostYearsModal } from "./PostYearsModal";
import { isPtaAdminRole } from "../../utils/types/role";
import { UpdateOrganization } from "../../hooks/recoil/organization";
import { Organization } from "../../apiClients/organization";

/**
 * 連絡ページの「連絡、新規作成、設定」の部分
 */

type Props = {
  organization: Organization;
  updateOrganization: (organization: UpdateOrganization) => Promise<void>;
  refetch: () => Promise<void>;
};
export const PostHeader = (props: Props) => {
  const [isNewModal, setIsNewModal] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isApproveUserModal, setIsApproveUserModal] = useState<boolean>(false);
  const [isPostYearsModal, setIsPostYearsModal] = useState<boolean>(false);
  const currentUser: CurrentUser = useCurrentUser();

  return (
    <HeadingBar>
      {/* TODO ここらへんも共通化したい。別コンポーネントに切り出すか */}
      <Title>連絡</Title>
      <PostEditable>
        <Buttons>
          {/* <Button fill>CSV出力</Button> */}
          <Button
            fill={true}
            onClick={() => {
              setIsNewModal(!isNewModal);
            }}
            style={{ marginRight: "5px" }}
            height="40px"
          >
            新規作成
          </Button>
          {isNewModal && (
            <CreatePostModal
              onClose={() => {
                setIsNewModal(!isNewModal);
              }}
              refetch={props.refetch}
            />
          )}
          {/* TODO  */}
          {isPtaAdminRole(currentUser.role) && (
            <SettingButton
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              ...
            </SettingButton>
          )}
        </Buttons>
        {isOpen && (
          <>
            <ModalBackGround
              onClick={() => {
                setIsOpen(false);
              }}
            />
            <PostSettingEditTab>
              <SettingEditInfo
                onClick={() => {
                  setIsApproveUserModal(!isApproveUserModal);
                }}
              >
                承認者の設定
                {isApproveUserModal && (
                  <PostApproverModal
                    onClose={() => {
                      setIsApproveUserModal(!isOpen);
                    }}
                    refetch={props.refetch}
                  />
                )}
              </SettingEditInfo>

              <SettingEditInfo
                onClick={() => {
                  setIsPostYearsModal(true);
                }}
              >
                表示年度の設定
                {isPostYearsModal && props.organization && (
                  <PostYearsModal
                    organization={props.organization}
                    updateOrganization={props.updateOrganization}
                    refetch={props.refetch}
                    onClose={() => {
                      setIsPostYearsModal(false);
                    }}
                  />
                )}
              </SettingEditInfo>
            </PostSettingEditTab>
          </>
        )}
      </PostEditable>
    </HeadingBar>
  );
};

export const HeadingBar = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: center;
`;

export const Buttons = styled.div`
  position: relative;
  margin-left: auto;
  display: flex;
  gap: 10px;
`;

export const PostEditable = styled.div`
  position: relative;
  margin-left: auto;
`;

export const PostSettingEditTab = styled.div`
  position: absolute;
  right: 0;
  background-color: #ffffff;
  border-radius: 5px;
  width: 160px;
  border: 1px solid #e4e6f3;
  padding: 5px 0;
  cursor: pointer;
  z-index: ${zIndexes.notModalMenu};
`;
