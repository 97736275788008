import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import * as theme from "../theme";
import { useCurrentUser, useFetchCurrentUser } from "../hooks/recoil/user";
import { SpMenuButtonIcon } from "./icons/SpMenuButtonIcon";
import { SidebarContent } from "./Common/Sidebar/SideBar";
import { BREAKPOINTS, MobileViewOnly } from "./Responsive";
import { getStaticImageUrl } from "../utils/getStaticImageUrl";
import { Avatar } from "./Common/Avatar";
import { useCurrentCommunityId, usePolyfitHistory } from "../hooks/router";
import { zIndexes } from "../zIndex";
import { logout } from "../utils/auth";
import { LogicalUpDownIcon } from "./icons/logicalUpDown";
import { setCurrentAccount } from "../apiClients/auth";
import { ServiceType } from "../utils/types/serviceType";
import { AccountOrganization, useAccounts } from "../hooks/api/account";
import { ChevronDownIcon } from "./icons/ChevronDownIcon";
import { ChevronUpIcon } from "./icons/ChevronUpIcon";
import { useSidebarMenuModal } from "../hooks/recoil/sidebar";
import {
  hasHigherRole,
  isCsGuestRole,
  isCsMemberRole,
} from "src/utils/types/role";
import { Community } from "@shared/types/community";
import { sortCommunities } from "../features/Member/Community/utils/utils";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { Button } from "./Button";

// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

const NaxBox = styled.div`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: ${zIndexes.fixedElement};
  display: flex;
  align-items: center;
  height: 48px;
  justify-content: space-between;
  padding: 4px 16px;
  background: ${theme.colorsPallet.primary};
`;
const NavHeader = styled.div`
  font-size: ${theme.typo.paragraph};
  color: ${theme.colorsPallet.emptyShade};
  display: flex;
  align-items: center;
  gap: 12px;
`;

const NavTitle = styled.div`
  font-weight: 700;
  @media screen and (max-width: ${BREAKPOINTS.SP}) {
    display: none;
  }
`;

const NavSchoolLabel = styled.div`
  font-weight: normal;
  white-space: nowrap;
  font-size: 14px;
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
    display: initial !important;
  }
  &:hover {
    cursor: pointer;
  }
`;

const ModalBackGround = styled.div`
  z-index: ${zIndexes.modalBackground};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

const HeaderButtonWrapper = styled.div`
  display: flex;
  svg {
    display: none;
    @media (max-width: 1024px) {
      display: initial;
    }
  }
  justify-content: space-between;
`;

function Navigation() {
  const user = useCurrentUser();
  const fetchUser = useFetchCurrentUser();
  const { organization, refetch: fetchOrganization } =
    useGetCurrentOrganization();
  const history = usePolyfitHistory();
  const { communityId, setCommunityId } = useCurrentCommunityId();
  const serviceType = user.role.startsWith("PTA")
    ? ServiceType.PTA
    : ServiceType.CS;
  const title = `polyfit for ${serviceType}`;

  const [
    isShowSPSidebarMenuModal,
    { close: closeSPSidebarMenuModal, toggle: toggleSPSidebarMenuModal },
  ] = useSidebarMenuModal();
  const [isMenuShow, setIsMenuShow] = useState<boolean>(false);
  const [isSelectServiceShow, setIsSelectServiceShow] =
    useState<boolean>(false);
  const [isSelectedPTA, setIsSelectedPTA] = useState<boolean>(false);
  const [isSelectedCS, setIsSelectedCS] = useState<boolean>(false);
  const [isSelectedCSAdmin, setIsSelectedCSAdmin] = useState<boolean>(false);
  const [isSelectedCSMember, setIsSelectedCSMember] = useState<boolean>(false);

  const navigationTitle = useMemo(() => {
    if (!organization) return "";
    return organization.serviceType === "PTA" || isCsMemberRole(user.role)
      ? organization.name
      : organization.communities?.find((c) => c.id === communityId)?.name;
  }, [organization, communityId]);

  const { accounts, ptaOrganizations, csOrganizations } = useAccounts();
  // CSの管理者・オペレーター権限を持つCommunity
  const csAdminCommunities = useMemo(() => {
    const csOrgCommunities = csOrganizations
      .map((org) => org.communities)
      .flat();
    const communities = accounts
      .map((account) => account.communityRoles)
      .flat()
      .map((communityRole) => {
        const community = csOrgCommunities.find((community) => {
          return (
            community.id === communityRole.communityId &&
            hasHigherRole(communityRole.role.name, "CSOperator")
          );
        });
        return community as unknown as Community;
      })
      .filter((community): community is Community => community !== undefined)
      .sort(sortCommunities);
    return communities || [];
  }, [csOrganizations]);

  // CSのメンバー権限を持つOrganization
  const csMemberOrganizations = useMemo(() => {
    const csOrgCommunities = csOrganizations
      .map((org) =>
        accounts
          .map((account) => {
            const communities = account.communityRoles
              .map((communityRole) => {
                const community = org.communities.find(
                  (community) =>
                    community.id === communityRole.communityId &&
                    isCsMemberRole(communityRole.role.name)
                );
                return community as unknown as Community;
              })
              .filter(
                (community): community is Community => community !== undefined
              );
            return communities.length > 0
              ? { ...org, csMemberCommunityId: communities[0].id }
              : undefined;
          })
          .filter(
            (
              item
            ): item is AccountOrganization & { csMemberCommunityId: string } =>
              item !== undefined
          )
      )
      .flat();
    return csOrgCommunities || [];
  }, [csOrganizations]);

  // 選択したコミュニティが同じ権限かどうか
  const isSameRoleByCommunityId = (_communityId: string) => {
    if (csAdminCommunities.some((community) => community.id === _communityId)) {
      return hasHigherRole(user.role, "CSOperator");
    } else {
      return isCsMemberRole(user.role);
    }
  };

  // CSの場合はコミュニティを選択する
  const onSelectCommunity = async (_communityId: string) => {
    const organizationId = csOrganizations.find((org) =>
      org.communities.some((community) => community.id === _communityId)
    )?.id;
    if (!organizationId) {
      setIsSelectServiceShow(false);
      return;
    }
    // 同じorgでcommunityのみを切り替える場合
    if (organizationId === user.account?.organizationId) {
      setIsSelectServiceShow(false);
      if (communityId === _communityId) return;
      // 同じ権限の場合はリロードのみ
      // 権限が変わる場合はLOADINGページに遷移させる
      if (isSameRoleByCommunityId(_communityId)) {
        setCommunityId(_communityId);
        location.reload();
      } else {
        history.push({ to: "LOADING", query: { communityId: _communityId } });
      }
      return;
    }

    await fetchUser();
    await setCurrentAccount(user.id, organizationId);
    fetchOrganization();
    history.push({ to: "LOADING", query: { communityId: _communityId } });
  };
  const onSelectOrganization = async (organizationId: string) => {
    if (organizationId === user.account?.organizationId) {
      setIsSelectServiceShow(false);
      return;
    }
    const communityId =
      ptaOrganizations.find((org) => org.id === organizationId)?.communities[0]
        .id ?? "";
    await fetchUser();
    await setCurrentAccount(user.id, organizationId);
    fetchOrganization();
    history.push({ to: "LOADING", query: { communityId: communityId } });
  };
  const orgRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  // モーダル外をクリックした時にモーダルを閉じる
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        modalRef.current &&
        orgRef.current &&
        !orgRef.current.contains(event.target) &&
        !modalRef.current.contains(event.target)
      ) {
        setIsSelectServiceShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [orgRef, modalRef]);
  const isCSGuest = isCsGuestRole(user.role);

  return (
    <>
      <NaxBox>
        <HeaderButtonWrapper>
          <NavHeader>
            {!isCSGuest && ( //CSGuestの場合はメッセージ以外にアクセスできないので、メニューを表示する必要はない
              <SpMenuButtonIcon onClick={toggleSPSidebarMenuModal} />
            )}
            <NavTitle>{title}</NavTitle>
            <NavSchoolLabel
              ref={orgRef}
              onClick={(e) => {
                e.preventDefault();
                setIsSelectServiceShow(!isSelectServiceShow);
              }}
            >
              {navigationTitle}
              {<LogicalUpDownIcon />}
            </NavSchoolLabel>
          </NavHeader>
          {isShowSPSidebarMenuModal && (
            <SPMenuModal onClose={closeSPSidebarMenuModal} />
          )}
        </HeaderButtonWrapper>
        {isCSGuest ? ( //CSGuestの場合は「プロフィール」にアクセスできないので、ログアウトのみ表示
          <ButtonWrap>
            <Button color="secondary" empty size="small" onClick={logout}>
              ログアウト
            </Button>
          </ButtonWrap>
        ) : (
          <UserImgButton onClick={() => setIsMenuShow(!isMenuShow)}>
            <Avatar
              src={user?.picture || defaultUserImg}
              alt={user?.name}
              size={30}
            />
          </UserImgButton>
        )}
      </NaxBox>
      {isMenuShow && (
        <SettingEditTab>
          <SettingEditInfo
            onClick={() => {
              setIsMenuShow(!isMenuShow);
              history.push({ to: "PTA_PROFILE", query: { userId: user.id } });
            }}
          >
            プロフィール
          </SettingEditInfo>
          <SettingEditInfo
            onClick={() => {
              setIsMenuShow(!isMenuShow);
              logout();
            }}
          >
            ログアウト
          </SettingEditInfo>
        </SettingEditTab>
      )}
      {isMenuShow && <ModalBackGround onClick={() => setIsMenuShow(false)} />}
      {isSelectServiceShow && (
        <ServiceSelectTab ref={modalRef}>
          {ptaOrganizations.length > 0 && (
            <ServiceSelectInfo
              isSelected={isSelectedPTA}
              onClick={() => {
                setIsSelectedPTA(!isSelectedPTA);
                setIsSelectedCS(false);
                setIsSelectedCSAdmin(false);
                setIsSelectedCSMember(false);
              }}
            >
              polyfit for PTA
              {isSelectedPTA ? <ChevronDownIcon /> : <ChevronUpIcon />}
            </ServiceSelectInfo>
          )}
          {isSelectedPTA &&
            ptaOrganizations.map((organization) => {
              return (
                <OrganizationName
                  key={organization.id}
                  onClick={async () => onSelectOrganization(organization.id)}
                >
                  {organization.name}
                </OrganizationName>
              );
            })}
          {csOrganizations.length > 0 && (
            <ServiceSelectInfo
              isSelected={isSelectedCS}
              onClick={() => {
                setIsSelectedCS(!isSelectedCS);
                setIsSelectedPTA(false);
              }}
            >
              polyfit for CS
              {isSelectedCS ? <ChevronDownIcon /> : <ChevronUpIcon />}
            </ServiceSelectInfo>
          )}
          {isSelectedCS && (
            <>
              {csAdminCommunities.length > 0 && (
                <ServiceSelectInfo
                  isSelected={isSelectedCSAdmin}
                  onClick={() => {
                    setIsSelectedCSAdmin(!isSelectedCSAdmin);
                  }}
                >
                  管理者・オペレーター
                  {isSelectedCSAdmin ? <ChevronDownIcon /> : <ChevronUpIcon />}
                </ServiceSelectInfo>
              )}
              {isSelectedCSAdmin &&
                csAdminCommunities.map((community) => {
                  return (
                    <OrganizationName
                      key={community.id}
                      onClick={async () => onSelectCommunity(community.id)}
                    >
                      {community.name}
                    </OrganizationName>
                  );
                })}
              {csMemberOrganizations.length > 0 && (
                <ServiceSelectInfo
                  isSelected={isSelectedCSMember}
                  onClick={() => {
                    setIsSelectedCSMember(!isSelectedCSMember);
                  }}
                >
                  メンバー
                  {isSelectedCSMember ? <ChevronDownIcon /> : <ChevronUpIcon />}
                </ServiceSelectInfo>
              )}
              {isSelectedCSMember &&
                csMemberOrganizations.map((organization) => {
                  return (
                    <OrganizationName
                      key={organization.id}
                      onClick={async () =>
                        onSelectCommunity(organization.csMemberCommunityId)
                      }
                    >
                      {organization.name}
                    </OrganizationName>
                  );
                })}
            </>
          )}
        </ServiceSelectTab>
      )}
    </>
  );
}

export { Navigation };

const ModalWrapper = styled.div`
  position: absolute;
  width: 160px;
  top: 40px;
  top: 0;
  left: 0;
  height: 100vh;
  padding-top: 24px;
  background: white;
  display: none;
  @media (max-width: ${BREAKPOINTS.PC}) {
    display: block;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: ${zIndexes.modal};
  opacity: 1;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const UserImgButton = styled.button`
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
`;

const SettingEditInfo = styled.div`
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #e4e6f3;
  }
`;

const SettingEditTab = styled.div`
  position: fixed;
  right: 15px;
  top: 35px;
  z-index: ${zIndexes.modal};
  background-color: #ffffff;
  border-radius: 5px;
  width: 200px;
  border: 1px solid #e4e6f3;
  padding: 5px 0;
`;

const ServiceSelectTab = styled.div`
  @media (max-width: ${BREAKPOINTS.SP}) {
    left: 64px;
  }
  position: fixed;
  min-width: 206px;
  left: 120px;
  top: 35px;
  z-index: ${zIndexes.modal};
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #e4e6f3;
  padding: 5px 0;
  max-height: 90vh;
  overflow-y: auto;
`;

const ServiceSelectInfo = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 10px 12px;
  text-align: left;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  background-color: ${(props) => (props.isSelected ? "#e4e6f3" : "white")};
  &:hover {
    background-color: #e4e6f3;
  }
`;

const OrganizationName = styled.div`
  padding: 10px 24px;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: #f5f7fa;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  padding: 14px 0 14px 20px;
  cursor: pointer;
`;

const ButtonWrap = styled.div`
  margin-left: auto;
  background: ${theme.colorsPallet.emptyShade};
  border-radius: 6px;
`;

export type SPMenuModalProps = {
  onClose: () => void;
};
const SPMenuModal = ({ onClose }: SPMenuModalProps) => {
  return (
    <MobileViewOnly>
      <ModalOverlay onClick={onClose}>
        <ModalWrapper onClick={(e) => e.stopPropagation()}>
          <SidebarContent />
        </ModalWrapper>
      </ModalOverlay>
    </MobileViewOnly>
  );
};
