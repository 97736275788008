import { ApplicationCountsForFilterType } from "@shared/validator/features/recruitment-application.schema";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { getApplicationCountsForFilter } from "src/apiClients/recruitmentApplication";
import { QueryOptions } from "src/hooks/query/QueryOptions";

export const useRecruitmentApplicationCountsForFilter = (
  { recruitmentId, name, status }: ApplicationCountsForFilterType,
  queryOptions: QueryOptions = {}
) => {
  const query = useQuery({
    queryKey: [
      "api",
      "recruitment",
      "application",
      recruitmentId,
      name,
      status,
    ],
    queryFn: async () =>
      await getApplicationCountsForFilter({ recruitmentId, name, status }),
    ...queryOptions,
  });

  return {
    applicationUserTypeCounts: useMemo(
      () => query.data?.userTypeCounts || [],
      [query.data]
    ),
    applicationStatusCount: useMemo(
      () => query.data?.statusCounts || [],
      [query.data]
    ),
    ...query,
  };
};
