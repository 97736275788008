import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { io, Socket } from "socket.io-client";
import { getToken, refreshToken } from "../../apiClients/client";
import { useCurrentCommunityId } from "../router";

const WebSocketContext = createContext<{
  instance: Socket | null;
  isInitialized: boolean;
}>({ instance: null, isInitialized: false });

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};

export const WebSocketProvider = ({
  children,
  isSkipCurrentCommunityId,
}: {
  children: React.ReactNode;
  isSkipCurrentCommunityId: boolean;
}) => {
  const [instance, setSocket] = useState<Socket | null>(null);
  const [isInitialized, setIsSocketInitialized] = useState(false);
  const { communityId } = useCurrentCommunityId();

  const [tokenRefreshed, setTokenRefreshed] = useState(false);
  const tokenRefreshedRef = useRef(false);
  tokenRefreshedRef.current = tokenRefreshed;

  useEffect(() => {
    const establishConnection = async () => {
      const token = await getToken();
      if (!token) return;
      if (!isSkipCurrentCommunityId && communityId === "") return;

      // websocketの接続portは3001
      const newSocket = io(location.origin.replace("3000", "3001"), {
        withCredentials: true,
        query: {
          token: `Bearer ${token}`,
          currentCommunityId: communityId,
          isSkipCurrentCommunityId,
        },
        reconnectionDelay: 5000,
        reconnectionAttempts: 5,
        transports: ["websocket"],
      });
      return newSocket;
    };

    const registerEventListeners = (socket: Socket) => {
      socket.on("connect", () => {
        console.log("websocket connected");
        setIsSocketInitialized(true);
      });

      // 接続に失敗した場合は一度だけトークンを再取得して再接続する
      socket.on("connect_error", (err) => {
        socket.disconnect();
        if (tokenRefreshedRef.current) {
          return;
        }
        setTokenRefreshed(true);
        refreshToken().then(() => {
          initialize();
        });
        return;
      });
    };

    const initialize = async () => {
      const socket = await establishConnection();
      if (socket === undefined) return;
      registerEventListeners(socket);
      setSocket(socket);
    };

    initialize();

    // コンポーネントがアンマウントされた際に WebSocket 接続を破棄
    return () => {
      if (instance) {
        instance.disconnect();
        setIsSocketInitialized(false);
      }
    };
  }, [communityId]);

  return (
    <WebSocketContext.Provider value={{ instance, isInitialized }}>
      {children}
    </WebSocketContext.Provider>
  );
};
