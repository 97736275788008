import React, { useEffect } from "react";
import {
  usePolyfitHistory,
  usePolyfitLocationQuery,
} from "../../../hooks/router";
import styled from "styled-components";
import * as Responsive from "../../../components/Responsive";
import * as Typo from "../../../components/Typo";
import { StepResident } from "../../../components/icons/StepResident";
import { Margin } from "../../../components/Margin";
import { RegistrationFooter } from "../../../components/RegistrationFooter";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  ButtonGroupExtend,
  ButtonSelect,
} from "../../../components/ButtonSelect";
import { genderOption } from "../../../utils/types/gender";
import { Tooltip } from "react-tooltip";
import { InformationIcon } from "../../../components/icons/InformationIcon";
import { Label } from "../../../components/form/Label";
import {
  useResidentBaseInfo,
  useResidentRegisterInfo,
} from "../../../hooks/api/baseInfo";
import { ageGroup, zTransformAgeGroup } from "../../../utils/types/ageGroup";
import { useCurrentUser } from "src/hooks/recoil/user";
import { isCsAdminRole } from "src/utils/types/role";

const FormValueSchema = z.object({
  lastName: z.string().min(1, "入力必須項目です"),
  firstName: z.string().min(1, "入力必須項目です"),
  lastNameKana: z
    .string()
    .min(1, "入力必須項目です")
    .refine((value) => /^[ぁ-んーー]+$/u.test(value), {
      message: "ひらがなで入力してください",
    }),
  firstNameKana: z
    .string()
    .min(1, "入力必須項目です")
    .refine((value) => /^[ぁ-んーー]+$/u.test(value), {
      message: "ひらがなで入力してください",
    }),
  gender: z.enum(["MALE", "FEMALE", "OTHER"], {
    errorMap: () => ({ message: "入力必須項目です" }),
  }),
  ageGroup: zTransformAgeGroup,
});

type FormValue = z.infer<typeof FormValueSchema>;

export default function ResidentRegisterInfoPage() {
  const user = useCurrentUser();
  const history = usePolyfitHistory();
  const { currentBaseInfo, isLoading } = useResidentBaseInfo();
  const { registerResidentInfo } = useResidentRegisterInfo();
  const { token } = usePolyfitLocationQuery("RESIDENT_REGISTER_INFO", {
    token: undefined,
  });

  const {
    reset,
    setValue,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValue>({
    resolver: zodResolver(FormValueSchema),
  });

  useEffect(() => {
    reset({
      lastName: currentBaseInfo?.lastName,
      firstName: currentBaseInfo?.firstName,
      lastNameKana: currentBaseInfo?.lastNameKana,
      firstNameKana: currentBaseInfo?.firstNameKana,
      gender: currentBaseInfo?.gender,
      ageGroup: currentBaseInfo?.ageGroup ?? -1,
    });
  }, [currentBaseInfo]);

  const onSubmit = async (data: FormValue) => {
    try {
      await registerResidentInfo({
        ...data,
      });
      // 管理者はOrg作成ページで校区を紐づけているので校区選択ページはスキップする
      if (isCsAdminRole(user.role)) {
        history.push({
          to: "RESIDENT_CONTACT_INFO",
          query: {
            ...(token && { token }),
          },
        });
      } else {
        history.push({
          to: "RESIDENT_REGISTER_COMMUNITY",
          query: {
            ...(token && { token }),
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleGenderChange = (value: "MALE" | "FEMALE" | "OTHER") => {
    if (value === watch("gender")) {
      reset({ gender: undefined });
    } else {
      setValue("gender", value);
    }
  };

  if (isLoading) return <></>;

  return (
    <div>
      <Margin marginBottom={24} />
      <StepResident stepNum={1} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledContainer>
          <Margin marginBottom={16} />
          <SubTitleWrapper>
            <Typo.Heading3>基本情報の入力</Typo.Heading3>
            <InformationIcon data-tooltip-id="title-tooltip" />
          </SubTitleWrapper>
          <Margin marginBottom={16} />
          <Responsive.Row>
            <Responsive.Col>
              <Label required>お名前</Label>
              <HorizontalInputWrapper>
                <SelectWrapper>
                  <TextField placeholder="例）山田" {...register("lastName")} />
                  {errors.lastName ? (
                    <span className="error-message">
                      {errors.lastName.message}
                    </span>
                  ) : (
                    <Margin marginTop={8} />
                  )}
                </SelectWrapper>
                <SelectWrapper>
                  <TextField
                    placeholder="例）太郎"
                    {...register("firstName")}
                  />
                  {errors.firstName ? (
                    <span className="error-message">
                      {errors.firstName.message}
                    </span>
                  ) : (
                    <Margin marginTop={8} />
                  )}
                </SelectWrapper>
              </HorizontalInputWrapper>
              <HorizontalInputWrapper>
                <SelectWrapper>
                  <TextField
                    placeholder="例）やまだ"
                    {...register("lastNameKana")}
                  />
                  {errors.lastNameKana ? (
                    <span className="error-message">
                      {errors.lastNameKana.message}
                    </span>
                  ) : (
                    <Margin marginTop={8} />
                  )}
                </SelectWrapper>
                <SelectWrapper>
                  <TextField
                    placeholder="例）たろう"
                    {...register("firstNameKana")}
                  />
                  {errors.firstNameKana ? (
                    <span className="error-message">
                      {errors.firstNameKana.message}
                    </span>
                  ) : (
                    <Margin marginTop={8} />
                  )}
                </SelectWrapper>
              </HorizontalInputWrapper>
              <Label required>性別</Label>
              <ButtonGroupExtend>
                {genderOption.map((option, i) => (
                  <ButtonSelect
                    key={i}
                    selected={watch("gender") === option.value}
                    onClick={() => handleGenderChange(option.value)}
                  >
                    <span>{option.text}</span>
                  </ButtonSelect>
                ))}
              </ButtonGroupExtend>

              {errors?.gender != null ? (
                <span className="error-message">{errors?.gender?.message}</span>
              ) : (
                <Margin marginTop={16} />
              )}
              <Label required>年代</Label>
              <SelectWrapper>
                <SelectBox {...register("ageGroup")}>
                  <option value="-1" disabled>
                    年代を選択
                  </option>
                  {ageGroup.map((opt) => {
                    return (
                      <option key={opt.value} value={opt.value}>
                        {opt.text}
                      </option>
                    );
                  })}
                </SelectBox>
                {errors.ageGroup ? (
                  <span className="error-message">
                    {errors?.ageGroup?.message}
                  </span>
                ) : (
                  <Margin marginTop={8} />
                )}
              </SelectWrapper>
              <Margin marginBottom={4} />
            </Responsive.Col>
          </Responsive.Row>
        </StyledContainer>
        <RegistrationFooter />
      </form>

      <Tooltip
        id="title-tooltip"
        place="bottom"
        content="活動内容を適切に配分するために利用する内容です。地域により、保険加入にも利用されます。"
        style={{ maxWidth: "256px" }}
      />
    </div>
  );
}

const StyledContainer = styled.div`
  max-width: 496px;
  margin: 0 auto;
  padding-bottom: 88px;
  .error-message {
    font-size: 12px;
    color: #bd271e;
  }
  @media (max-width: 1279px) {
    max-width: 343px;
  }
`;

const HorizontalInputWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 16px;
`;

const SelectWrapper = styled.div`
  min-width: 240px;
  @media (max-width: 1279px) {
    min-width: 163px;
  }
`;

const SelectBox = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 40px 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  cursor: pointer;
`;

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;

const SubTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
