import React from "react";
import { PageContainer, PageHeader, PageBody } from "../../../components/Page";
import * as Typo from "../../../components/Typo";
import * as Responsive from "../../../components/Responsive";
import { AirPlaneIcon } from "../../../components/icons/SvgIcons";
import styled from "styled-components";
import { Navigation } from "../../../components/Navigation3";
import { Button } from "../../../components/Button";
import { usePolyfitHistory } from "../../../hooks/router";

const SignupResidentCompletePageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 702px;
  margin: 0 auto;
  padding-top: 124px;
  .message {
    text-align: center;
  }
  button {
    margin: 12px auto;
    display: flex;
  }
  @media (max-width: 1279px) {
    padding-top: 120px;
  }
`;

export default function SignupCompleteResidentPage() {
  const history = usePolyfitHistory();

  return (
    <>
      <Navigation />
      <SignupResidentCompletePageContainer>
        <AirPlaneIcon />
        <PageHeader>新規登録が完了しました！</PageHeader>
        <PageBody>
          <Responsive.Row>
            <Responsive.Col>
              <Typo.Paragraph className="message">
                早速polyfitを使ってみましょう。
              </Typo.Paragraph>
            </Responsive.Col>
          </Responsive.Row>
          <Responsive.Row>
            <Responsive.Col>
              <Button
                size="large"
                fill
                onClick={() => {
                  history.push({
                    to: "LOADING",
                    query: {},
                  });
                }}
              >
                polyfitを使う
              </Button>
            </Responsive.Col>
          </Responsive.Row>
        </PageBody>
      </SignupResidentCompletePageContainer>
    </>
  );
}
