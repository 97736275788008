import React, { useCallback, useState } from "react";
import {
  ModalBody,
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "../../../../components/Modal";
import styled from "styled-components";
import { Margin } from "../../../../components/Margin";
import { ModalLabel } from "../../../Post/CreatePostModal";

interface Props {
  name: string;
  onSubmit: (comment: string) => void;
  onClose: () => void;
  disabled: boolean;
}

export default function DeclineApplicationModal({
  name,
  onSubmit,
  onClose,
  disabled,
}: Props) {
  const [text, setText] = useState<string>("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const onChangeText = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (e.target.value.length <= 3000) {
        setText(e.target.value);
        setShowErrorMessage(false);
      } else {
        setShowErrorMessage(true);
      }
    },
    []
  );

  const handleSubmit = useCallback(() => {
    if (showErrorMessage) return;
    onSubmit(text);
  }, [showErrorMessage, text, onSubmit]);

  return (
    <>
      <ModalPortal onClose={onClose}>
        <ModalHeader>{name} を不参加にしますか？</ModalHeader>
        <ModalBody>
          <div>不参加を確定すると、「不参加」のステータスに移動します。</div>
          <Margin marginTop={15} />
          <ModalLabel>コメント</ModalLabel>
          <Margin marginTop={5} />
          <TextArea
            name=""
            id=""
            cols={30}
            rows={10}
            value={text}
            onChange={onChangeText}
            placeholder="入力してください（3000文字以内）"
          />
          <ModalSubmitButtons
            submitColor="danger"
            submitText="不参加にする"
            onSubmit={handleSubmit}
            disabled={showErrorMessage || disabled}
            onCancel={onClose}
          />
        </ModalBody>
      </ModalPortal>
    </>
  );
}

const TextArea = styled.textarea`
  width: 100%;
  background-color: #fbfcfd;
  border: 1px solid rgba(19, 34, 149, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 8px;
`;
