import React from "react";
import styled from "styled-components";
import { applicationStatusOptions } from "../../../apiClients/recruitmentApplication";
import { Avatar } from "../../../components/Common/Avatar";
import { toDisplayDateFormatYearMonth } from "../../../utils/time";
import { getStaticImageUrl } from "../../../utils/getStaticImageUrl";
import { useCheckUserInThisCommunity } from "src/hooks/query/auth/checkUserInThisCommunity";
import { RecruitmentApplication } from "@shared/types/recruitmentApplication";
import { Link } from "src/components/Link";

type ApplicantTableProps = {
  applicantList: RecruitmentApplication[] | null;
  handleChangeStatus: (
    applicantId?: string,
    userName?: string,
    userId?: string
  ) => (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const ApplicantTable = ({
  applicantList,
  handleChangeStatus,
}: ApplicantTableProps) => {
  const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

  const filteredApplicationStatusOptions = (
    applicant: RecruitmentApplication
  ) => {
    if (applicant.status === "APPROVED") {
      return applicationStatusOptions.filter((opt) => opt.value !== "APPLIED");
    }
    return applicationStatusOptions;
  };

  return (
    <Table>
      <thead>
        <TheadTr>
          <TheadTh first>名前</TheadTh>
          <TheadTh>応募タイトル</TheadTh>
          <TheadTh>開催日</TheadTh>
          <TheadTh>コメント</TheadTh>
          <TheadTh>選考</TheadTh>
          <TheadTh last></TheadTh>
        </TheadTr>
      </thead>

      {applicantList?.map((applicant, i) => {
        const { checkUserInThisCommunity } = useCheckUserInThisCommunity(
          applicant.user.id
        );
        return (
          <Tbody key={i}>
            <TbodyTr>
              <TbodyTd>
                <UserPicCell>
                  <Avatar
                    src={applicant.user.picture || defaultUserImg}
                    alt="ユーザーのアイコン"
                    size={40}
                  />
                  <Name>{applicant.user.name}</Name>
                </UserPicCell>
              </TbodyTd>
              <TbodyTd>
                <LinkText
                  to={{
                    to: "RESIDENT_RECRUITMENT_DETAIL",
                    query: { id: applicant.recruitment.id },
                  }}
                  place="start"
                >
                  {applicant.recruitment.title}
                </LinkText>
              </TbodyTd>
              <TbodyTd>
                {[
                  ...new Set(
                    applicant.recruitment.schedule.map((s) =>
                      toDisplayDateFormatYearMonth(new Date(s.start))
                    )
                  ),
                ].map((date, i) => (
                  <div key={i}>{date}</div>
                ))}
              </TbodyTd>
              <TbodyTd>
                <CommentText
                  title={applicant.coverLetterChatMessage?.content}
                  isEmpty={!applicant.coverLetterChatMessage?.content}
                >
                  {applicant.coverLetterChatMessage?.content || "なし"}
                </CommentText>
              </TbodyTd>
              <TbodyTd>
                <InputSelect
                  value={applicant.rejectedAt ? "REJECTED" : applicant.status}
                  onChange={handleChangeStatus(
                    applicant.id,
                    applicant.user.name,
                    applicant.user.id
                  )}
                  disabled={
                    !!applicant.rejectedAt ||
                    applicant.status === "REJECTED" ||
                    applicant.status === "DECLINED"
                  }
                >
                  {filteredApplicationStatusOptions(applicant).map((opt) => {
                    return (
                      <option key={opt.value} value={opt.value}>
                        {opt.text}
                      </option>
                    );
                  })}
                </InputSelect>
              </TbodyTd>
              <TbodyTd>
                {checkUserInThisCommunity ? (
                  <LinkText
                    to={{
                      to: "PTA_LIST_MEMBER",
                      query: { userId: applicant.user.id },
                    }}
                    place="end"
                  >
                    応募者の詳細確認
                  </LinkText>
                ) : (
                  <>この応募者は現在コミュニティに所属していません</>
                )}
              </TbodyTd>
            </TbodyTr>
          </Tbody>
        );
      })}
    </Table>
  );
};

const Table = styled.table`
  background-color: #fff;
  border: none;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  font-weight: normal;
`;

const TheadTr = styled.tr`
  background-color: #fff;
`;

const TheadTh = styled.th<{
  first?: boolean;
  last?: boolean;
}>`
  color: #343741;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  padding: 8px;
  ${(props) => props.first && `padding-left: 24px;`}
  ${(props) => props.last && `padding-right: 16px;`}
`;

const Tbody = styled.tbody`
  padding: 0 16px;
`;

const TbodyTr = styled.tr`
  background-color: #fff;
  border-top: 1px solid #e3e6eb;
`;

const TbodyTd = styled.td`
  color: #343741;
  font-size: 12px;
  font-weight: normal;
  text-align: left;
  padding: 8px;
  max-width: 200px;
`;

const UserPicCell = styled.div`
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  height: 100%;
  gap: 8px;
`;

const Name = styled.div`
  color: #343741;
  font-size: 16px;
  font-weight: normal;
`;

const InputSelect = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 40px 10px 12px;
  background-color: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  cursor: "pointer";
`;

const LinkText = styled(Link)<{ place: "start" | "center" | "end" }>`
  padding-right: 16px;
  cursor: pointer;
  display: block;
  text-align: ${(props) => props.place};
  color: #07c;
`;

const CommentText = styled.div<{ isEmpty: boolean }>`
  color: ${(props) => (props.isEmpty ? "grey" : "inherit")};
  max-height: 100px;
  overflow-y: auto;
  white-space: normal;
  padding-right: 10px;
`;
