import { post, get } from "./client";
import {
  LineAccountData,
  LineAccountDeleteResponse,
  LineAccountResponse,
} from "@shared/types/lineAccount";

export async function createOrUpdateLineAccount(
  data: LineAccountData
): Promise<LineAccountResponse> {
  return await post<LineAccountData, LineAccountResponse>(
    "/lineAccount/upsert",
    data
  );
}

export async function getLineAccount(): Promise<LineAccountResponse | null> {
  return await get<{}, LineAccountResponse | null>("/lineAccount/get", {});
}

export async function deleteLineAccount(): Promise<LineAccountDeleteResponse> {
  return await post<{}, LineAccountDeleteResponse>("/lineAccount/delete");
}
