import React, { memo } from "react";
import { SidebarMenu } from "./SidebarMenu";
import { useLocation } from "react-router-dom";
import { MessageIcon } from "../../icons/MessageIcon";
import { BagEditIcon } from "../../icons/BagEditIcon";
import SideBarLineBadge from "./SideBarLineBadge";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { useGetLineAccount } from "src/hooks/query/lineAccount";

export const CSOperatorSidebar = memo(() => {
  const { pathname } = useLocation();
  const { organization } = useGetCurrentOrganization();
  const { lineAccount } = useGetLineAccount();

  return (
    <>
      <SidebarMenu
        isActive={pathname.startsWith("/resident/recruitment")}
        icon={<BagEditIcon />}
        text="募集"
        menus={[
          {
            text: "募集一覧",
            to: "/resident/recruitment",
          },
          {
            text: "新規募集作成",
            to: "/resident/recruitment/create",
          },
          {
            text: "ひな形から募集作成",
            to: "/resident/recruitment/template",
          },
          {
            text: "下書きから募集作成",
            to: "/resident/recruitment/draft",
          },
          {
            text: "応募一覧",
            to: "/resident/recruitment/application_list",
          },
          {
            text: "応募した募集",
            to: "/resident/recruitment/apply",
          },
          {
            text: "参加する募集",
            to: "/resident/recruitment/participate",
          },
        ]}
      />
      <SidebarMenu
        isActive={pathname.startsWith("/resident/chat/recruitment")}
        to="/resident/chat/recruitment"
        icon={<MessageIcon />}
        text="メッセージ"
      />
      {organization?.lineOption && !lineAccount && <SideBarLineBadge />}
    </>
  );
});
