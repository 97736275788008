import { useQueries, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useApiContext } from "src/apiClients/client";
import { getDownloadUrl } from "src/apiClients/storage";

export function useGetDownloadUrl(
  filePath: string,
  fileName: string,
  skip = false
) {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: ["api", "storage", "download", apiContext, filePath],
    queryFn: async () => {
      return await getDownloadUrl(filePath, fileName);
    },
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: !skip,
  });

  return {
    url: useMemo(() => query.data ?? "", [query.data]),
    ...query,
  };
}

export function useGetDownloadUrls(filePaths: string[]) {
  const { apiContext } = useApiContext();
  const queries = useQueries({
    queries: filePaths.map((filePath) => ({
      queryKey: ["api", "storage", "download", apiContext, filePath],
      queryFn: async () => await getDownloadUrl(filePath, ""),
      staleTime: Infinity,
      gcTime: Infinity,
    })),
  });

  const urls = useMemo(
    () => queries.map((query) => query.data ?? ""),
    [queries]
  );
  const urlsWithPath = useMemo(
    () =>
      queries.map((query, index) => ({
        url: query.data ?? "",
        path: filePaths[index],
      })),
    [queries, filePaths]
  );
  const isLoading = queries.some((query) => query.isLoading);
  const error = queries.find((query) => query.error)?.error;

  return { urls, urlsWithPath, isLoading, error };
}
