import React, { useState } from "react";
import {
  EditModalFormLabel,
  ModalWrapper,
  TextField,
} from "../../../pages/pta/admin/ProfilePage";
import styled from "styled-components";
import { ModalBody, ModalHeader, ModalPortal } from "../../../components/Modal";
import { Button } from "../../../components/Button";
import { Margin } from "../../../components/Margin";
import { useForm } from "react-hook-form";
import { useToast } from "../../../components/Toast";
import { updateResidentInfo } from "../../../apiClients/auth";
import { residentInfoSchema } from "@shared/validator/models/residentInfo.schema";

type FormValues = {
  privateEnterprise: string | null;
  university: string | null;
  externalOrganization: string | null;
  pta: string | null;
};

type Props = {
  onClose: () => void;
  residentInfo: FormValues;
  fetch: () => void;
};

const ResidentInfoBelongOrganizationEditModal = ({
  onClose,
  residentInfo,
  fetch,
}: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: residentInfo,
    mode: "onBlur",
  });

  const toast = useToast();
  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);

  const onSubmit = async (data: FormValues) => {
    setDoubleClickBlocked(true);

    try {
      const parsedData = residentInfoSchema.parse({
        ...residentInfo,
        privateEnterprise: data.privateEnterprise || null,
        university: data.university || null,
        externalOrganization: data.externalOrganization || null,
        pta: data.pta || null,
      });
      await updateResidentInfo(parsedData);
      toast.success("団体情報を更新しました");
      fetch();
      onClose();
    } catch (error) {
      toast.error("団体の更新に失敗しました");
    } finally {
      setDoubleClickBlocked(false);
    }
  };

  return (
    <>
      <ModalPortal onClose={onClose} modalBoxIsHeightFull={true}>
        <ModalWrapper>
          <ModalHeader>所属団体</ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <EditModalFormLabel>民間企業</EditModalFormLabel>
                <div>
                  <TextField
                    {...register("privateEnterprise", {})}
                    placeholder="例)  〇〇株式会社"
                  />
                  {errors.privateEnterprise && (
                    <ErrorMessage>
                      {errors.privateEnterprise.message}
                    </ErrorMessage>
                  )}
                </div>
              </div>
              <div>
                <EditModalFormLabel>大学</EditModalFormLabel>
                <div>
                  <TextField
                    {...register("university", {})}
                    placeholder="例)  〇〇大学"
                  />
                  {errors.privateEnterprise && (
                    <ErrorMessage>{errors.university?.message}</ErrorMessage>
                  )}
                </div>
              </div>
              <div>
                <EditModalFormLabel>外郭団体</EditModalFormLabel>
                <div>
                  <TextField
                    {...register("externalOrganization", {})}
                    placeholder="例)  〇〇団体"
                  />
                  {errors.privateEnterprise && (
                    <ErrorMessage>
                      {errors.externalOrganization?.message}
                    </ErrorMessage>
                  )}
                </div>
              </div>
              <div>
                <EditModalFormLabel>PTA</EditModalFormLabel>
                <div>
                  <TextField
                    {...register("pta", {})}
                    placeholder="例)  〇〇PTA"
                  />
                  {errors.privateEnterprise && (
                    <ErrorMessage>{errors.pta?.message}</ErrorMessage>
                  )}
                </div>
              </div>
              <Margin marginTop={25} />
              <Flex>
                <Button
                  type="button"
                  color="primary"
                  empty
                  size="large"
                  onClick={onClose}
                >
                  キャンセル
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  fill
                  size="large"
                  disabled={doubleClickBlocked}
                >
                  保存
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalWrapper>
      </ModalPortal>
    </>
  );
};

export default ResidentInfoBelongOrganizationEditModal;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
`;

const ErrorMessage = styled.span`
  font-size: 12px;
  color: #bd271e;
`;
