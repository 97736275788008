import React, { useCallback, useState } from "react";
import {
  useBEApplicantList,
  useInvalidateBEApplicantList,
} from "../../../hooks/query/boardEducation/recruitmentApplication/recruitmentApplicationList";
import { TabNavigation } from "../../../components/TabNavigation";
import {
  approveApplication,
  declineApplicationByRecruitmentApplicationId,
  rejectApplication,
} from "../../../apiClients/boardEducation/recruitmentApplication";
import ApproveApplicationModal from "../../../features/BoardEducation/Recruitment/application/ApproveApplicationModal";
import RejectApplicationModal from "../../../features/BoardEducation/Recruitment/application/RejectApplicationModal";
import DeclineApplicationModal from "../../../features/BoardEducation/Recruitment/application/DeclineApplicationModal";
import { usePolyfitHistory } from "../../../hooks/router";
import styled from "styled-components";
import { NoResults } from "../../../features/ListPage/NoResults";
import { ApplicantTable } from "../../../features/BoardEducation/Recruitment/application/ApplicantTable";
import { ApplicantCardList } from "../../../features/BoardEducation/Recruitment/application/ApplicantCardList";
import {
  BREAKPOINTS,
  MobileViewOnly,
  PcViewOnly,
} from "../../../components/Responsive";
import { useRecruitmentApplicationFilter } from "../../../hooks/recoil/recruitmentApplicationFilter";
import CountRecruitmentApplicationBar from "../../../features/BoardEducation/Recruitment/application/CountRecruitmentApplicationBar";
import { SelectField } from "../../pta/admin/ProfilePage";
import { Header } from "../../../components/Header";
import { Spinner } from "../../../components/icons/Spinner";
import { logger } from "src/utils/logger";
import {
  ApplicationStatus,
  RecruitmentApplicationListOrder,
} from "@shared/types/recruitmentApplication";

const orderOptions = [
  { text: "タイトル順", value: "RECRUITMENT_TITLE" },
  { text: "開催日初回順", value: "RECRUITMENT_START_DATE" },
  { text: "応募者氏名順", value: "USER_NAME" },
];

export default function BERecruitmentApplicationListPage() {
  const history = usePolyfitHistory();

  const [tab, setTab] = useState<ApplicationStatus>("APPLIED");
  const [nameFilter, setNameFilter] = useRecruitmentApplicationFilter();

  const { applicantList, isLoading } = useBEApplicantList(
    tab,
    nameFilter.name,
    nameFilter.order
  );
  const { invalidateBEApplicantList } = useInvalidateBEApplicantList();

  const [editingApplicantId, setEditingApplicantId] = useState<
    string | undefined
  >(undefined);
  const [editingApplicantName, setEditingApplicantName] = useState<
    string | undefined
  >(undefined);
  const [confirmChangeStatus, setConfirmChangeStatus] = useState<
    "APPLIED" | "APPROVED" | "REJECTED" | "DECLINED" | undefined
  >(undefined);

  const handleModalOpen = useCallback(
    (
      modalStatus: "APPROVED" | "REJECTED" | "DECLINED",
      editingApplicantId: string,
      editingApplicantName: string
    ) => {
      setConfirmChangeStatus(modalStatus);
      setEditingApplicantId(editingApplicantId);
      setEditingApplicantName(editingApplicantName);
    },
    []
  );

  const handleApproveSubmit = useCallback(async () => {
    setDoubleClickBlocked(true);
    try {
      await approveApplication(editingApplicantId ?? "");
      invalidateBEApplicantList();
      setConfirmChangeStatus(undefined);
    } catch (error) {
      logger.error(new Error(`Error during approval process:` + error));
    } finally {
      setDoubleClickBlocked(false);
    }
  }, [editingApplicantId]);

  const handleRejectSubmit = useCallback(
    async (comment: string) => {
      setDoubleClickBlocked(true);
      try {
        await rejectApplication(editingApplicantId ?? "", comment);
        invalidateBEApplicantList();
        setConfirmChangeStatus(undefined);
      } catch (error) {
        logger.error(new Error(`Error during approval process:` + error));
      } finally {
        setDoubleClickBlocked(false);
      }
    },
    [editingApplicantId]
  );

  const handleDeclineSubmit = useCallback(
    async (comment: string) => {
      setDoubleClickBlocked(true);
      try {
        await declineApplicationByRecruitmentApplicationId(
          editingApplicantId ?? "",
          comment
        );
        invalidateBEApplicantList();
        setConfirmChangeStatus(undefined);
      } catch (error) {
        logger.error(new Error(`Error during approval process:` + error));
      } finally {
        setDoubleClickBlocked(false);
      }
    },
    [editingApplicantId]
  );

  const handleClose = useCallback(() => {
    setConfirmChangeStatus(undefined);
    setEditingApplicantId(undefined);
  }, []);

  const onApplicantDetailClick = useCallback(
    (accountId: string, communityId: string) => {
      history.push({
        to: "BE_ADMIN_MEMBER_DETAIL",
        query: { accountId, communityId },
      });
    },
    [history]
  );

  const handleChangeStatus = useCallback(
    (applicantId?: string, userName?: string) =>
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        switch (e.target.value) {
          case "APPROVED":
            handleModalOpen(e.target.value, applicantId ?? "", userName ?? "");
            break;
          case "REJECTED":
            handleModalOpen(e.target.value, applicantId ?? "", userName ?? "");
            break;
          case "DECLINED":
            handleModalOpen(e.target.value, applicantId ?? "", userName ?? "");
            break;
          default:
            break;
        }
      },
    []
  );

  const applicantListIsEmpty = applicantList && !applicantList?.length;

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false); //同時にモーダルが開くことは無いので一つのdoubleClickBlockedで十分

  return (
    <ApplicantListPage>
      <CountRecruitmentApplicationBar />
      <Header title="応募一覧" />
      <TabNavigation
        elements={[
          {
            isActive: tab === "APPLIED",
            text: "選考中",
            onclick: () => setTab("APPLIED"),
          },
          {
            isActive: tab === "APPROVED",
            text: "参加決定",
            onclick: () => setTab("APPROVED"),
          },
          {
            isActive: tab === "REJECTED",
            text: "見送り",
            onclick: () => setTab("REJECTED"),
          },
          {
            isActive: tab === "DECLINED",
            text: "不参加",
            onclick: () => setTab("DECLINED"),
          },
        ]}
      />

      <FilterWrapper>
        <div>
          <SelectField
            onChange={(event) => {
              setNameFilter({
                name: nameFilter.name,
                order: Object.keys(RecruitmentApplicationListOrder).includes(
                  event.target.value
                )
                  ? (event.target.value as RecruitmentApplicationListOrder)
                  : undefined,
              });
            }}
            value={nameFilter.order}
          >
            <option value="">選択してください</option>
            {orderOptions.map((opt) => {
              return (
                <option key={opt.value} value={opt.value}>
                  {opt.text}
                </option>
              );
            })}
          </SelectField>
        </div>
        <div>
          <TextFieldLabel htmlFor="search-text-field"></TextFieldLabel>
          <SearchTextField
            value={nameFilter.name}
            placeholder="氏名で検索"
            onChange={(event) => setNameFilter({ name: event.target.value })}
          />
        </div>
      </FilterWrapper>

      {isLoading ? (
        <SpinnerCard>
          <Spinner />
        </SpinnerCard>
      ) : applicantListIsEmpty ? (
        <NoResults />
      ) : (
        <>
          <PcViewOnly>
            <ApplicantTable
              applicantList={applicantList}
              handleChangeStatus={handleChangeStatus}
              onApplicantDetailClick={onApplicantDetailClick}
            />
          </PcViewOnly>
          <MobileViewOnly>
            <ApplicantCardList
              applicantList={applicantList}
              handleChangeStatus={handleChangeStatus}
              onApplicantDetailClick={onApplicantDetailClick}
            />
          </MobileViewOnly>
        </>
      )}
      {confirmChangeStatus === "APPROVED" && (
        <ApproveApplicationModal
          name={editingApplicantName ?? ""}
          onSubmit={handleApproveSubmit}
          onClose={handleClose}
          disabled={doubleClickBlocked}
        />
      )}
      {confirmChangeStatus === "REJECTED" && (
        <RejectApplicationModal
          name={editingApplicantName ?? ""}
          onSubmit={handleRejectSubmit}
          onClose={handleClose}
          disabled={doubleClickBlocked}
        />
      )}
      {confirmChangeStatus === "DECLINED" && (
        <DeclineApplicationModal
          name={editingApplicantName ?? ""}
          onSubmit={handleDeclineSubmit}
          onClose={handleClose}
          disabled={doubleClickBlocked}
        />
      )}
    </ApplicantListPage>
  );
}

const ApplicantListPage = styled.div`
  margin: 0 auto;
`;

const FilterWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
  gap: 16px;

  > div {
    width: 100%;
    max-width: 220px;
  }

  @media screen and (max-width: ${BREAKPOINTS.SP}) {
    > div {
      max-width: 100%;
    }
    flex-direction: column;
    gap: 8px;
    margin-bottom: 24px;
  }
`;

const TextFieldLabel = styled.label`
  position: relative;
  width: 100%;

  &:before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 20px;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' fill='none' viewBox='0 0 16 16'%3e%3cpath fill='%23343741' d='m11.271 11.978 3.872 3.873a.502.502 0 0 0 .708 0 .502.502 0 0 0 0-.708l-3.565-3.564c2.38-2.747 2.267-6.923-.342-9.532-2.73-2.73-7.17-2.73-9.898 0-2.728 2.729-2.728 7.17 0 9.9a6.955 6.955 0 0 0 4.949 2.05.5.5 0 0 0 0-1 5.96 5.96 0 0 1-4.242-1.757 6.01 6.01 0 0 1 0-8.486 6.004 6.004 0 0 1 8.484 0 6.01 6.01 0 0 1 0 8.486.5.5 0 0 0 .034.738Z'/%3e%3c/svg%3e")
      center / contain no-repeat;
  }
`;

const SearchTextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  padding-left: 40px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;

const SpinnerCard = styled.div`
  display: flex;
  justify-content: center;
  background: #ffffff;
  padding-bottom: 40px;
  padding-top: 40px;
`;
