import { CSChild, RequestUpdateCSChildArgs } from "@shared/types/csChild";
import * as client from "./client";

export async function getChildrenByCurrentUser() {
  return await client.get<{}, CSChild[]>(
    "/csChild/getChildrenByCurrentUser",
    {}
  );
}

export async function updateChildren(data: RequestUpdateCSChildArgs[]) {
  return await client.post<{}, RequestUpdateCSChildArgs[]>(
    "/csChild/updateChildren",
    data
  );
}

export async function deleteChildren(accountId?: string) {
  return await client.post<{}, {}>("/csChild/deleteChildren", { accountId });
}

export async function getChildrenByOrganizationId() {
  return await client.get<{}, CSChild[]>(
    "/csChild/getChildrenByOrganizationId",
    {}
  );
}
