import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryOptions } from "./QueryOptions";
import { useMemo } from "react";
import { getLineAccount } from "src/apiClients/lineAccount";

export const useGetLineAccount = (queryOptions: QueryOptions = {}) => {
  const query = useQuery({
    queryKey: ["api", "lineAccount", "getLineAccount"],
    queryFn: async () => {
      return await getLineAccount();
    },
    ...queryOptions,
  });

  return {
    lineAccount: useMemo(() => query.data ?? undefined, [query.data]),
    ...query,
  };
};

export function useInvalidateLineAccount() {
  const queryClient = useQueryClient();

  function invalidateGetLineAccount() {
    queryClient.invalidateQueries({
      queryKey: ["api", "lineAccount", "getLineAccount"],
    });
  }

  return {
    invalidateGetLineAccount,
  };
}
