/**
 * ソート関数: `order` プロパティに基づいて配列をソート
 * - `order` が `null` の場合、配列の末尾に移動
 * - `order` が数値の場合、昇順にソート
 *
 * @param values ソート対象の配列
 * @returns ソートされた新しい配列
 */
export const sortByOrder = <T extends { order: number | null }>(
  values: T[]
): T[] => {
  if (!values || !values.length) return values;

  return [...values].sort((a, b) => {
    if (a.order === null && b.order === null) return 0;
    if (a.order === null) return 1;
    if (b.order === null) return -1;
    return a.order - b.order;
  });
};
