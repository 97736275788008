import * as client from "./client";
import {
  DeleteInvitationBEUserBody,
  InvitationBEUserList,
  InvitationBEUserListRequest,
} from "../../../server/src/@shared/types/invitationBEUserList";

/**
 * 招待中の教育委員会ユーザーを取得する（Internal用）
 */
export async function getInvitationBEUsersByOrganizationId({
  organizationId,
}: {
  organizationId: string;
}): Promise<InvitationBEUserList[]> {
  const res = await client.get<
    InvitationBEUserListRequest,
    { invitationBEUsers: InvitationBEUserList[] }
  >("/invitationBeUser/list", {
    organizationId,
  });
  return res.invitationBEUsers;
}

/**
 * 招待中の教育委員会ユーザーを削除する（Internal用）
 */
export async function deleteInvitationBEUser(
  args: DeleteInvitationBEUserBody
): Promise<void> {
  await client.post<{}, DeleteInvitationBEUserBody>(
    "/invitationBeUser/delete",
    {
      ...args,
    }
  );
}
