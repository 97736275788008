import { z } from "zod";

export type TabType = "elementarySchool" | "juniorHighSchool";

export const communityRolesFormSchema = z
  .object({
    mainElementarySchool: z.string(),
    mainJuniorHighSchool: z.string(),
    mainElementarySchoolType: z
      .enum(["PARENT", "TEACHER", "RESIDENT"], {
        errorMap: () => ({
          message: "最寄りの小学校区の役割を選択してください",
        }),
      })
      .optional(),
    mainJuniorHighSchoolType: z
      .enum(["PARENT", "TEACHER", "RESIDENT"], {
        errorMap: () => ({
          message: "最寄りの中学校区の役割を選択してください",
        }),
      })
      .optional(),
    elementarySchools: z.string().array(),
    juniorHighSchools: z.string().array(),
  })
  .refine(
    (data) => {
      if (data.mainElementarySchool) {
        return !!data.mainElementarySchoolType;
      }
      return true;
    },
    {
      message: "最寄りの小学校区の役割を選択してください",
      path: ["mainElementarySchoolType"],
    }
  )
  .refine(
    (data) => {
      if (data.mainJuniorHighSchool) {
        return !!data.mainJuniorHighSchoolType;
      }
      return true;
    },
    {
      message: "最寄りの中学校区の役割を選択してください",
      path: ["mainJuniorHighSchoolType"],
    }
  );

export type CommunityRolesFormSchema = z.infer<typeof communityRolesFormSchema>;
