import React, { useMemo, useState } from "react";
import { Title } from "../../../components/Title";
import styled, { css } from "styled-components";
import { usePolyfitHistory } from "../../../hooks/router";
import { volunteerOption } from "@shared/types/volunteerType";
import { NotFoundRecruitment } from "../../../features/BoardEducation/Recruitment/recruitment/NotFoundRecruitment";
import { CheckBox } from "../../../components/form/CheckBox";
import { useRecruitmentFilter } from "../../../hooks/recoil/recruitmentFilter";
import { Label } from "../../../components/form/Label";
import { ArrowDropDownIcon } from "../../../components/icons/ArrowDropDown";
import { BREAKPOINTS, Row } from "src/components/Responsive";
import { Button } from "src/components/Button";
import { useBERecruitmentList } from "src/hooks/query/boardEducation/recruitment/recruitmentList";
import { RecruitmentListColumn } from "src/features/BoardEducation/Recruitment/recruitment/RecruitmentListColumn";
import { recruitmentIsOpened } from "src/apiClients/recruitment";
import { useBESchoolOptions } from "src/hooks/query/boardEducation/recruitment/schoolOptions";
import { MobileViewOnly, PcViewOnly } from "src/components/Responsive";
import { RecruitmentListCardColumn } from "src/features/BoardEducation/Recruitment/recruitment/RecruitmentListCardColumn";

export default function BERecruitmentListPage() {
  const history = usePolyfitHistory();
  const [recruitmentFilter, setRecruitmentFilter] = useRecruitmentFilter();
  const { schoolOptions } = useBESchoolOptions();

  const [volunteerType, setVolunteerType] = useState<string>(
    recruitmentFilter.volunteerType ?? ""
  );
  const [isPaidVolunteer, setIsPaidVolunteer] = useState<"PAID" | "FREE" | "">(
    recruitmentFilter.isPaidVolunteer ?? ""
  );
  const [date, setDate] = useState<string>(recruitmentFilter.date ?? "");
  const [isOpen, setIsOpen] = useState(recruitmentFilter.isOpen ?? false);
  const [selectedSchool, setSelectedSchool] = useState<string>("");
  const filterCondition = useMemo(() => {
    const filter = {
      volunteerType,
      isPaidVolunteer: isPaidVolunteer ? isPaidVolunteer : undefined,
      date,
      isOpen,
      selectedSchool,
    };
    setRecruitmentFilter(filter);
    return filter;
  }, [volunteerType, isPaidVolunteer, date, isOpen, selectedSchool]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOpen(event.target.checked);
  };
  const { recruitments, isPending, refetch } = useBERecruitmentList(
    filterCondition,
    {
      refetchOnMount: "always",
    }
  );

  const onClickCreate = () => {
    history.push({ to: "BE_ADMIN_RECRUITMENT_CREATE" });
  };

  return (
    <>
      <Row justifyContent={"between"} style={{ margin: 0 }}>
        <Title>募集一覧</Title>
        <Button onClick={onClickCreate} fill size="large">
          新規作成
        </Button>
      </Row>

      <Spacer height={16} />
      <Filter>
        <FilterSummary>
          フィルター設定
          <ArrowDropDownIcon size={24} />
        </FilterSummary>
        <FilterContainer>
          <InputMajorContainer>
            <InputContainer>
              <Label size="s">学校を選択</Label>
              <InputSelect
                defaultValue={""}
                onChange={(e) => setSelectedSchool(e.target.value)}
                value={selectedSchool}
              >
                <option value="">学校を選択</option>
                {schoolOptions?.map((opt, i) => {
                  return (
                    <option key={i} value={opt.value}>
                      {opt.value}
                    </option>
                  );
                })}
              </InputSelect>
            </InputContainer>
            <InputContainer>
              <Label size="s">ボランティア項目</Label>
              <InputSelect
                defaultValue={volunteerType}
                onChange={(e) => setVolunteerType(e.target.value)}
                value={volunteerType}
              >
                <option value="">種類を選択</option>
                {volunteerOption.map((opt) => {
                  return (
                    <option key={opt.value} value={opt.value}>
                      {opt.text}
                    </option>
                  );
                })}
              </InputSelect>
            </InputContainer>
            <InputContainer>
              <Label size="s">ボランティア形態</Label>
              <InputSelect
                defaultValue={isPaidVolunteer}
                onChange={(e) =>
                  setIsPaidVolunteer(e.target.value as "PAID" | "FREE" | "")
                }
                value={isPaidVolunteer}
              >
                <option value="">形態を選択</option>
                <option value="PAID">有償</option>
                <option value="FREE">無償</option>
              </InputSelect>
            </InputContainer>
            <InputContainer>
              <Label size="s">日付を選択</Label>
              <InputDateTimePicker
                min="1900-01-01"
                max="2100-12-31"
                type="date"
                onChange={(e) => setDate(e.target.value)}
                defaultValue={date}
                value={date}
                isDate={date !== undefined && date !== ""}
              />
            </InputContainer>
          </InputMajorContainer>
          <CheckBox
            label="募集中のみ表示"
            onChange={handleChange}
            defaultChecked={isOpen}
          />
        </FilterContainer>
      </Filter>
      <CardWrapper>
        {isPending ? (
          <LoadingCard />
        ) : recruitments?.length === 0 ? (
          <NotFoundRecruitment
            title="掲載中の募集はありません"
            description=" 募集が掲載されるとこちらの画面に表示されます"
          />
        ) : (
          <>
            <PcViewOnly>
              <NameListTable>
                <tbody>
                  <NameListColumnNameRow head="true">
                    <NameListColumn>日付</NameListColumn>
                    <NameListColumn>募集タイトル</NameListColumn>
                    <NameListColumn>学校</NameListColumn>
                    <NameListColumn>募集作成者</NameListColumn>
                    <NameListColumn>募集人数</NameListColumn>
                    <NameListColumn>応募数</NameListColumn>
                    <NameListColumn>確定数</NameListColumn>
                    <NameListColumn>ステータス</NameListColumn>
                  </NameListColumnNameRow>
                  {recruitments?.length
                    ? recruitments?.map((recruitment) => {
                        if (isOpen && !recruitmentIsOpened(recruitment)) {
                          return <></>;
                        }

                        return (
                          <RecruitmentListColumn
                            key={recruitment.id}
                            recruitment={recruitment}
                            refetch={refetch}
                          />
                        );
                      })
                    : null}
                </tbody>
              </NameListTable>
            </PcViewOnly>
            <MobileViewOnly>
              {recruitments?.length
                ? recruitments?.map((recruitment) => {
                    if (isOpen && !recruitmentIsOpened(recruitment)) {
                      return <></>;
                    }

                    return (
                      <RecruitmentListCardColumn
                        key={recruitment.id}
                        recruitment={recruitment}
                        refetch={refetch}
                      />
                    );
                  })
                : null}
            </MobileViewOnly>
          </>
        )}
      </CardWrapper>
    </>
  );
}

const FilterContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 16px;
`;

const CardWrapper = styled.div`
  margin: 8px auto;
`;

const InputContainer = styled.div`
  flex: 1;
  min-width: 200px;
`;

const InputDateTimePicker = styled.input<{ isDate: boolean }>`
  color: ${(props) => (props.isDate ? "#343741" : "#AAB4C4")};
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  font-weight: normal;
  width: 100%;
  box-sizing: border-box;
`;

const InputSelect = styled.select`
  ${(props) =>
    props.value === "" &&
    css`
      color: #aab4c4;
    `}
  ${(props) =>
    props.value !== "" &&
    css`
      color: #343741;
    `}
  appearance: none;
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  box-sizing: border-box;
`;

const InputMajorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  margin-bottom: 16px;
  @media (max-width: ${BREAKPOINTS.SP}) {
    flex-direction: column;
  }
`;

const Filter = styled.details`
  & > summary > svg {
    transition: transform 0.2s;
  }
  &[open] > summary > svg {
    transform: rotate(180deg);
  }
`;

const FilterSummary = styled.summary`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 6px;
  list-style: none;
  ::-webkit-details-marker {
    display: none;
  }
`;

const Spacer = styled.div<{ height: number }>`
  height: ${(props) => props.height}px;
`;

const NameListTable = styled.table`
  background-color: #fff;
  border: none;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  font-weight: normal;
`;

const NameListColumnNameRow = styled.tr<{ head: "true" | "false" }>`
  background-color: #fff;
  ${(props) =>
    props.head === "false" &&
    `border-top: 1px solid #e3e6eb; cursor: pointer; &:hover { filter: brightness(95%);}`};
`;

const NameListColumn = styled.th`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  text-align: left;
  padding: 12px 16px;
  white-space: nowrap;
`;

const LoadingCard = styled.div`
  background-color: #fff;
  padding-bottom: 40px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
