import React, { ComponentProps } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { SidebarDropDownMenu } from "./SidebarDropDownMenu";
import { useSidebarMenuModal } from "../../../hooks/recoil/sidebar";
import { useCurrentCommunityId } from "src/hooks/router";

type SidebarMenuProps = {
  to?: string;
  onClick?: () => void;
  isActive?: boolean;
  isNotCloseByClickMenu?: boolean;
  icon: React.ReactNode;
  text: string;
} & ComponentProps<typeof SidebarDropDownMenu>;

export const SidebarMenu = ({
  to,
  onClick,
  isActive = false,
  icon,
  text,
  menus,
  isNotCloseByClickMenu = false,
}: SidebarMenuProps) => {
  const { pathname, search } = useLocation();
  const [, { close }] = useSidebarMenuModal();

  const searchParams = new URLSearchParams(search);
  const { communityId } = useCurrentCommunityId();
  const getTo = (to: string | undefined) => {
    if (!searchParams.get("communityId") && communityId) {
      searchParams.append("communityId", communityId);
    }
    return to
      ? to + "?" + searchParams.toString()
      : pathname + "?" + searchParams.toString();
  };

  const onClickMenu = () => {
    if (onClick) {
      onClick();
    }
    if (!isNotCloseByClickMenu && !menus) {
      close();
    }
  };

  return (
    <Container>
      {/* 現在のURL */}
      <LinkContainer to={getTo(to)} $isActive={isActive} onClick={onClickMenu}>
        {icon}
        <Text>{text}</Text>
      </LinkContainer>
      {menus && <SidebarDropDownMenu menus={menus} />}
    </Container>
  );
};

const ActiveStyle = `
  color:#fff;
  background-color: #0077cc;
  svg *:not([fill="white"]) {
    fill: white;
  }
  svg *[fill="white"] {
    fill: #37434f;
  }
  svg *[stroke="#37434F"] {
    stroke: #fff;
  }
`;

const Container = styled.div`
  position: relative;
  &:hover > div {
    display: block;
  }
`;

const LinkContainer = styled(Link)<{ $isActive?: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px 8px 12px 16px;
  gap: 8px;
  svg {
    width: 24px;
    height: 24px;
  }
  ${({ $isActive }) => $isActive && ActiveStyle};
  &:hover {
    ${ActiveStyle}
  }
`;

const Text = styled.p`
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
