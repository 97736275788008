import React from "react";

type Props = {
  size?: number;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CameraIcon = ({ size = 24 }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="11.5" fill="white" stroke="#E4E6F3" />
      <g clipPath="url(#clip0_4543_187459)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 7.5C9 7.08579 9.33579 6.75 9.75 6.75H14.25C14.6642 6.75 15 7.08579 15 7.5V9H17.25C17.6642 9 18 9.33579 18 9.75V16.5C18 16.9142 17.6642 17.25 17.25 17.25H6.75C6.33579 17.25 6 16.9142 6 16.5V9.75C6 9.33579 6.33579 9 6.75 9H9V7.5ZM12 15C13.2426 15 14.25 13.9926 14.25 12.75C14.25 11.5074 13.2426 10.5 12 10.5C10.7574 10.5 9.75 11.5074 9.75 12.75C9.75 13.9926 10.7574 15 12 15Z"
          fill="#AAB4C4"
        />
      </g>
      <defs>
        <clipPath id="clip0_4543_187459">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
