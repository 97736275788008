import React from "react";
import { User } from "../../../apiClients/users";
import { CurrentUser } from "../../../apiClients/auth";
import styled from "styled-components";
import { Heading3 } from "../../../components/Typo";
import { isCsRole, isPtaRole } from "../../../utils/types/role";
import { Organization } from "../../../apiClients/organization";
import { PTAEditBaseInfoModal } from "./PTA";
import { CSEditBaseInfoModal } from "./CS";
import { ButtonGroup } from "src/components/Button";

type EditBaseInfoModalProps = {
  user: User;
  organization: Organization;
  onClose: () => void;
  getUser: () => Promise<void>;
  userId: string;
  cu: CurrentUser;
};

export const EditBaseInfoModal = ({
  user,
  organization,
  onClose,
  getUser,
  userId,
  cu,
}: EditBaseInfoModalProps) => {
  //PTAだったらPTA、CSだったらCSを返す
  const isPTA = isPtaRole(user.role);
  const isCS = isCsRole(user.role);
  if (isPTA) {
    return (
      <PTAEditBaseInfoModal
        user={user}
        organization={organization}
        onClose={onClose}
        getUser={getUser}
        userId={userId}
        cu={cu}
      />
    );
  }
  if (isCS) {
    return (
      <CSEditBaseInfoModal
        user={user}
        organization={organization}
        onClose={onClose}
        getUser={getUser}
        userId={userId}
        cu={cu}
      />
    );
  }
};

export const ButtonGroupExtend = styled(ButtonGroup)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  .button {
    width: auto;
    /* @media (max-width: 1279px) {
        width: auto;
      } */
  }
  svg {
    margin-left: 12px;
  }
`;

export const CheckTabs = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  > * {
    width: 100%;
  }
`;

export const ModalWrapper = styled.div`
  overflow-y: scroll;
  max-height: 564px;
  padding: 20px;
`;

export const ModalHeader = styled(Heading3)`
  padding-bottom: 24px;
  display: flex;
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  color: #bd271e;
`;

export const EditModalFormLabel = styled.label`
  color: #1a1c21;
  font-weight: 700;
  font-size: 12px;
  margin-top: 14px;
  margin-bottom: 4px;
  display: inline-block;
`;

export const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;

export const SelectField = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  cursor: pointer;
`;
