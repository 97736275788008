import {
  Child,
  SurveyResults,
  isMultiChoiceSurveyResult,
  isOpenEndedSurveyResult,
} from "../../../apiClients/surveyResults";
import { getClassName } from "../../../utils/getChildClass";
import { SchoolDisplayType } from "@shared/types/organization";
import { getGradeLabel } from "@shared/utils/getGradeLabel";

// 各質問に対する各ユーザーの回答をCSVに落とし込む
// 形式は
// userId, userName, 質問１, 質問２, ...
function convertToCSV(
  surveyResults: SurveyResults,
  schoolDisplayType: SchoolDisplayType
): string {
  // 子供情報は要らないという要望がきそうな気がするのでフラグを用意しておく
  const needsChildrenInfo = true;
  // サーバー側から渡されるのは各質問に対しての形式なので、各ユーザーに対しての情報に変換する
  // ユーザーIDをキーにしたMap
  // ユーザー名とそれぞれの質問への回答と各ユーザーの子供の情報をもつ
  interface UserData {
    name: string;
    answers: Array<string>;
    children: Array<Child>;
  }
  const answerOfUsers = new Map<string, UserData>();
  // 質問情報
  for (const [
    surveyIndex,
    surveyResult,
  ] of surveyResults.resultList.entries()) {
    // 簡単のため最初の質問情報を使って各ユーザーの行を初期化する
    if (surveyIndex === 0) {
      for (const answer of surveyResult.answers) {
        const userId: string = answer.userId;
        const userName: string = answer.userName;
        const children: Array<Child> = answer.children;
        answerOfUsers.set(userId, {
          name: userName,
          answers: new Array<string>(),
          children,
        });
      }
    }
    if (isMultiChoiceSurveyResult(surveyResult)) {
      // 「選択肢ID：選択肢内容」の関係を作成
      const mapChoiceIdAndChoiceText = new Map<string, string>();
      for (const choice of surveyResult.question.choiceList) {
        mapChoiceIdAndChoiceText.set(choice.id, choice.text);
      }
      for (const answerOfUser of surveyResult.answers) {
        const userId: string = answerOfUser.userId;
        const userName: string = answerOfUser.userName;
        const children: Array<Child> = answerOfUser.children;
        // CSVに書き出すデータを生成する
        // 元のデータは選択肢IDなので文章に直して追加する
        const userAnswer: string = escapeCSVData(
          answerOfUser.choiceIds
            .map((choiceId: string) => {
              // undefinedにはなり得ないが文法上必要
              const choiceText: string | undefined =
                mapChoiceIdAndChoiceText.get(choiceId);
              return choiceText ?? "";
            })
            .join(",")
        );
        // 新しい情報を追加する
        const currentUserData: UserData | undefined = answerOfUsers.get(userId);
        if (currentUserData) {
          answerOfUsers.set(userId, {
            name: userName,
            answers: [...currentUserData.answers, userAnswer],
            children,
          });
        } else {
          answerOfUsers.set(userId, {
            name: userName,
            answers: [userAnswer],
            children,
          });
        }
      }
    } else if (isOpenEndedSurveyResult(surveyResult)) {
      for (const answerOfUser of surveyResult.answers) {
        const userId: string = answerOfUser.userId;
        const userName: string = answerOfUser.userName;
        const children: Array<Child> = answerOfUser.children;
        // CSVに書き出すデータを生成する
        const userAnswer: string = escapeCSVData(answerOfUser.text);
        // 新しい情報を追加する
        const currentUserData: UserData | undefined = answerOfUsers.get(userId);
        if (currentUserData) {
          answerOfUsers.set(userId, {
            name: userName,
            answers: [...currentUserData.answers, userAnswer],
            children,
          });
        } else {
          answerOfUsers.set(userId, {
            name: userName,
            answers: [userAnswer],
            children,
          });
        }
      }
    } else {
      const _unreachable: never = surveyResult;
      throw new Error("unknown survey type");
    }
  }
  // 整形したデータから文字列を作成してエンコードする
  let csvContent: string = "data:text/csv;charset=utf-8,\ufeff";
  // ヘッダー作成
  const header: string[] = ["ID", "名前"];
  for (const surveyResult of surveyResults.resultList) {
    // 質問文
    const question = surveyResult.question;
    header.push(question.text);
  }
  // 子供情報をヘッダーに追加
  if (needsChildrenInfo) {
    // 回答者の中で一番子供が多い人に揃える
    const maxNumberOfChildren: number =
      surveyResults.resultList[0].answers.reduce(
        (currentValue: number, answer) => {
          const nextValue: number = answer.children.length;
          return Math.max(currentValue, nextValue);
        },
        0
      );
    for (let n = 0; n < maxNumberOfChildren; n++) {
      // １始まりのカウンタ
      const counter: number = n + 1;
      header.push(`子供${counter}`);
      header.push(`子供${counter}(学年)`);
      header.push(`子供${counter}(クラス)`);
    }
  }
  csvContent += header.join(",");
  csvContent += "\n";
  // メイン部分
  answerOfUsers.forEach(
    (
      answerOfUser: {
        name: string;
        answers: Array<string>;
        children: Array<Child>;
      },
      userId: string
    ) => {
      const row = new Array<string>();
      row.push(userId);
      row.push(answerOfUser.name);
      for (const answer of answerOfUser.answers) {
        row.push(answer);
      }
      if (needsChildrenInfo) {
        for (const child of answerOfUser.children) {
          const lastName: string = child.lastName;
          const firstName: string = child.firstName;
          const childName: string = `${lastName} ${firstName}`;
          const childGrade: string = getGradeLabel(
            child?.grade,
            schoolDisplayType,
            true,
            false,
            "short"
          );
          const childClass: string = !child.class
            ? ""
            : getClassName(child.class);
          row.push(childName);
          row.push(childGrade);
          row.push(childClass);
        }
      }
      csvContent += row.join(",");
      csvContent += "\n";
    }
  );
  return encodeURI(csvContent);
}

const escapeCSVData = (data: string): string => {
  // テキスト中に , が含まれる場合そこで区切られてしまうため、ダブルクォーテーションで全体を囲む
  // またテキスト中にダブルクォーテーションが含まれる場合は、二重のダブルクォーテーションに置換することでエスケープする
  return `"${data.replace(/"/g, '""')}"`;
};

export function saveToCSV(
  surveyResults: SurveyResults,
  schoolDisplayType: SchoolDisplayType
): void {
  const csvContent: string = convertToCSV(surveyResults, schoolDisplayType);
  const link = document.createElement("a");
  link.setAttribute("href", csvContent);
  link.setAttribute("download", "アンケート結果.csv");
  link.click();
}
