import React from "react";
import styled from "styled-components";
import { PCBaseInfoWrapper } from "src/pages/pta/admin/ProfilePage";
import { Heading2 } from "src/components/Typo";
import { LineLinkSettingSection } from "./LineLinkSettingSection";

export const NotificationSetting = () => {
  return (
    <PCBaseInfoWrapper>
      <NotificationSettingContainer>
        <Title>通知設定</Title>
        <LineLinkSettingSection />
      </NotificationSettingContainer>
    </PCBaseInfoWrapper>
  );
};

const NotificationSettingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Title = styled(Heading2)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24.2px;
  color: #343741;
  margin-bottom: 12px;
`;
