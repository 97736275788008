import React from "react";
import { useFormContext } from "react-hook-form";
import { CurrentUser } from "src/apiClients/auth";
import { getStaticImageUrl } from "src/utils/getStaticImageUrl";
import styled from "styled-components";
import { CSEditBaseInfoModalFormValues as CSFormValues } from "../CS";
import { PTAEditBaseInfoModalFormValues as PTAFormValues } from "../PTA";
import { CameraIcon } from "../../../../components/icons/CameraIcon";
import { User } from "src/apiClients/users";
import { CheckTabs, EditModalFormLabel, ErrorMessage, TextField } from "..";

const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");
type EditBaseInfoModalFormValues = CSFormValues | PTAFormValues;

type NameAndImageSectionProps = {
  imgPreviewUrl: string;
  user: User;
  currentUser: CurrentUser;
  onClickFileInput: () => void;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  inputFileRef: React.MutableRefObject<null>;
};

export const NameAndImageSection = ({
  imgPreviewUrl,
  user,
  currentUser,
  onClickFileInput,
  onInputChange,
  inputFileRef,
}: NameAndImageSectionProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<EditBaseInfoModalFormValues>();

  return (
    <>
      <DivCenterWrapper>
        <UploadImagesContainer>
          <Picture
            src={imgPreviewUrl || user?.picture || defaultUserImg}
            alt="image"
          />
          {user.id === currentUser.id && (
            <>
              <UploadImgButton type="button" onClick={onClickFileInput}>
                <CameraIcon />
              </UploadImgButton>
              <input
                ref={inputFileRef}
                hidden
                multiple
                type="file"
                name="image"
                readOnly={true}
                accept="image/jpeg, image/png"
                onChange={onInputChange}
              />
            </>
          )}
        </UploadImagesContainer>
      </DivCenterWrapper>
      <div>
        <EditModalFormLabel>お名前</EditModalFormLabel>
        <CheckTabs>
          <div>
            <TextField placeholder="姓" {...register("baseInfo.lastName")} />
            {errors.baseInfo?.lastName && (
              <ErrorMessage>{errors.baseInfo.lastName.message}</ErrorMessage>
            )}
          </div>
          <div>
            <TextField placeholder="名" {...register("baseInfo.firstName")} />
            {errors.baseInfo?.firstName && (
              <ErrorMessage>{errors.baseInfo?.firstName.message}</ErrorMessage>
            )}
          </div>
        </CheckTabs>
      </div>
      <div>
        <EditModalFormLabel>ふりがな</EditModalFormLabel>
        <CheckTabs>
          <div>
            <TextField
              placeholder="せい"
              {...register("baseInfo.lastNameKana")}
            />
            {errors.baseInfo?.lastNameKana && (
              <ErrorMessage>
                {errors.baseInfo?.lastNameKana.message}
              </ErrorMessage>
            )}
          </div>
          <div>
            <TextField
              placeholder="めい"
              {...register("baseInfo.firstNameKana")}
            />
            {errors.baseInfo?.firstNameKana && (
              <ErrorMessage>
                {errors.baseInfo?.firstNameKana.message}
              </ErrorMessage>
            )}
          </div>
        </CheckTabs>
      </div>
    </>
  );
};

const UploadImagesContainer = styled.div`
  position: relative;
`;

const UploadImgButton = styled.button`
  background-color: gray;
  border-radius: 50%;
  border: none;
  position: absolute;
  bottom: 0;
  left: 53%;
  height: 24px;
  width: 24px;
`;

const DivCenterWrapper = styled.div`
  text-align: center;
`;

const Picture = styled.img`
  width: 72px;
  height: 72px;
  object-fit: cover;
  border-radius: 50%;
`;
