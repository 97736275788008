import { z } from "zod";

export const accountCommunityTypeSchema = z.enum([
  "PARENT",
  "TEACHER",
  "RESIDENT",
]);

export const accountCommunityRolesRequestSchema = z.object({
  roleName: z.string().optional(),
  communityId: z.string(),
  isMain: z.boolean(),
  type: accountCommunityTypeSchema,
});

export type AccountCommunityRolesRequestSchemaType = z.infer<
  typeof accountCommunityRolesRequestSchema
>;
