import React from "react";
import styled from "styled-components";
import { applicationStatusOptions } from "../../../apiClients/recruitmentApplication";
import { Avatar } from "../../../components/Common/Avatar";
import { getStaticImageUrl } from "../../../utils/getStaticImageUrl";
import { toDisplayDateFormatYearMonth } from "../../../utils/time";
import { useCheckUserInThisCommunity } from "src/hooks/query/auth/checkUserInThisCommunity";
import { RecruitmentApplication } from "@shared/types/recruitmentApplication";
import { Link } from "src/components/Link";

type ApplicantCardListProps = {
  applicantList: RecruitmentApplication[] | null;
  handleChangeStatus: (
    applicantId?: string,
    userName?: string,
    userId?: string
  ) => (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const ApplicantCardList = ({
  applicantList,
  handleChangeStatus,
}: ApplicantCardListProps) => {
  const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

  const filteredApplicationStatusOptions = (
    applicant: RecruitmentApplication
  ) => {
    if (applicant.status === "APPROVED") {
      return applicationStatusOptions.filter((opt) => opt.value !== "APPLIED");
    }
    return applicationStatusOptions;
  };
  return (
    <List>
      {applicantList?.map((applicant) => {
        const { checkUserInThisCommunity } = useCheckUserInThisCommunity(
          applicant.user.id
        );
        return (
          <Card key={applicant.id}>
            <CardHeader>
              <Avatar
                src={applicant.user.picture || defaultUserImg}
                alt="ユーザーのアイコン"
                size={40}
              />
              <Name>{applicant.user.name}</Name>
            </CardHeader>
            <CardBody>
              <div>
                <Title>応募タイトル</Title>
                <Text>
                  <LinkText
                    to={{
                      to: "RESIDENT_RECRUITMENT_DETAIL",
                      query: { id: applicant.recruitment.id },
                    }}
                  >
                    {applicant.recruitment.title}
                  </LinkText>
                </Text>
              </div>
              <div>
                <Title>開催日</Title>
                {[
                  ...new Set(
                    applicant.recruitment.schedule.map((s) =>
                      toDisplayDateFormatYearMonth(new Date(s.start))
                    )
                  ),
                ].map((date, i) => (
                  <Text key={i}>{date}</Text>
                ))}
              </div>
              <div>
                <Title>コメント</Title>
                <CommentText
                  title={applicant.coverLetterChatMessage?.content}
                  isEmpty={!applicant.coverLetterChatMessage?.content}
                >
                  {applicant.coverLetterChatMessage?.content || "なし"}
                </CommentText>
              </div>
              <div>
                <Title>選考</Title>
                <InputSelect
                  value={applicant.rejectedAt ? "REJECTED" : applicant.status}
                  onChange={handleChangeStatus(
                    applicant.id,
                    applicant.user.name,
                    applicant.user.id
                  )}
                  disabled={
                    !!applicant.rejectedAt ||
                    applicant.status === "REJECTED" ||
                    applicant.status === "DECLINED"
                  }
                >
                  {filteredApplicationStatusOptions(applicant).map((opt) => {
                    return (
                      <option key={opt.value} value={opt.value}>
                        {opt.text}
                      </option>
                    );
                  })}
                </InputSelect>
              </div>
            </CardBody>
            <CardFooter>
              {checkUserInThisCommunity ? (
                <LinkText
                  to={{
                    to: "PTA_LIST_MEMBER",
                    query: { userId: applicant.user.id },
                  }}
                >
                  応募者の詳細確認
                </LinkText>
              ) : (
                <NotInThisCommunity>
                  この応募者は現在コミュニティに所属していません
                </NotInThisCommunity>
              )}
            </CardFooter>
          </Card>
        );
      })}
    </List>
  );
};

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Card = styled.div`
  border-radius: 6px;
  border: 1px solid #e4e6f3;
  background: #fff;
  padding: 24px;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CardFooter = styled.div`
  margin-top: 24px;
  text-align: center;
`;

const Name = styled.p`
  color: #343741;
  font-size: 16px;
`;

const Title = styled.p`
  color: #69707d;
  font-size: 12px;
  margin-bottom: 4px;
`;

const Text = styled.p`
  color: #343741;
  font-size: 16px;
`;

const LinkText = styled(Link)`
  cursor: pointer;
  color: #07c;
`;

const InputSelect = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 40px 10px 12px;
  background-color: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  cursor: "pointer";
`;

const CommentText = styled(Text)<{ isEmpty: boolean }>`
  color: ${(props) => (props.isEmpty ? "grey" : "inherit")};
  max-height: 200px;
  overflow-y: auto;
  white-space: normal;
  padding-right: 10px;
`;

const NotInThisCommunity = styled.p`
  font-size: 12px;
`;
