import { z } from "zod";

/**
 * 配列フィールドをnullまたはundefinedから空配列に変換
 */
export const transformArray = <T extends z.ZodTypeAny>(schema: T) =>
  z.preprocess((val) => {
    if (val === null || val === undefined) {
      return [];
    }
    return val;
  }, schema);

export const transformStringOrBooleanToBoolean = () =>
  z.union([z.string(), z.boolean()]).transform((val): boolean | undefined => {
    if (val === undefined || val === null) return undefined;

    if (typeof val === "string") {
      const lowerVal = val.toLowerCase();
      if (lowerVal === "true") return true;
      if (lowerVal === "false") return false;
      if (lowerVal.trim() === "") return undefined;
      throw new Error(`Invalid boolean string: "${val}"`);
    }

    return val;
  });

export const transformStringOrNumberToNumber = () =>
  z.union([z.string(), z.number()]).transform((val): number | undefined => {
    if (val === undefined || val === null) return undefined;
    if (typeof val === "string") {
      if (val.trim() === "") return undefined;
      const parsed = parseInt(val, 10);
      if (isNaN(parsed)) {
        throw new Error(`"${val}" is not a valid number`);
      }
      return parsed;
    }
    return val;
  });
