import { ChatRoomType } from "@shared/types/chatRoom";
import React, { useState } from "react";
import { ModalPortal } from "../../../../components/Modal";
import { ChatRoomSelectorToAppend } from "src/features/common/ChatMessage/components/ChatRoomAppend/ChatRoomSelectorToAppend";
import { ChatRoomAppenderForIndividual } from "./ChatRoomAppenderForIndividual";
import { ChatRoomAppenderForGroup } from "./ChatRoomAppenderForGroup";
import { ChatRoomEntity } from "@shared/types/chatRoom";
import { ReFetchChatRoomList } from "src/hooks/query/chatRoomList";

type Props = {
  isOpen: boolean;
  close: () => void;
  chatRooms: ChatRoomEntity[] | undefined;
  refetch: ReFetchChatRoomList;
};

export const ChatRoomAppendModal = ({
  isOpen,
  close,
  chatRooms,
  refetch,
}: Props) => {
  const [chatRoomType, setChatRoomType] = useState<ChatRoomType>();
  const resetClose = () => {
    setChatRoomType(undefined);
    close();
  };
  if (!isOpen) return <></>;
  if (chatRoomType === "INDIVIDUAL_CHAT_WITH_ORGANIZATION") {
    return (
      <ModalPortal onClose={() => resetClose()}>
        <ChatRoomAppenderForIndividual
          close={resetClose}
          chatRooms={chatRooms}
          refetch={refetch}
        />
      </ModalPortal>
    );
  } else if (chatRoomType === "GROUP_CHAT_WITH_ORGANIZATION") {
    return (
      <ModalPortal onClose={() => resetClose()}>
        <ChatRoomAppenderForGroup
          close={resetClose}
          chatRooms={chatRooms}
          refetch={refetch}
        />
      </ModalPortal>
    );
  } else {
    return (
      <ModalPortal
        onClose={() => {
          close();
        }}
      >
        <ChatRoomSelectorToAppend setChatRoomType={setChatRoomType} />
      </ModalPortal>
    );
  }
};
