import { ApplicationUserTypeCounts } from "@shared/types/recruitmentApplication";
import { CommunityUser } from "@shared/types/userType";
import React from "react";
import { PieChart, Pie, Cell, Tooltip, Label } from "recharts";
import { BREAKPOINTS } from "src/components/Responsive";
import { colorsPallet } from "src/theme";
import styled from "styled-components";

const COLORS = [
  colorsPallet.primary,
  colorsPallet.success,
  colorsPallet.accent,
];

export const ResidentRecruitmentApplicationChart = ({
  applicationUserTypeCounts,
  isSearchWithName,
}: {
  applicationUserTypeCounts: ApplicationUserTypeCounts[] | null;
  isSearchWithName: boolean;
}) => {
  if (!applicationUserTypeCounts) return null;

  const data = [
    {
      name: "保護者",
      value:
        applicationUserTypeCounts.find(
          (item) => item.type === CommunityUser.PARENT
        )?.count || 0,
    },
    {
      name: "地域住民",
      value:
        applicationUserTypeCounts.find(
          (item) => item.type === CommunityUser.RESIDENT
        )?.count || 0,
    },
    {
      name: "教員",
      value:
        applicationUserTypeCounts.find(
          (item) => item.type === CommunityUser.TEACHER
        )?.count || 0,
    },
  ];

  const total = data.reduce((sum, item) => sum + item.value, 0);
  if (isSearchWithName) {
    return (
      <NotShowChart>
        氏名を検索している場合はグラフは表示されません
      </NotShowChart>
    );
  }
  return (
    <ChartSection>
      {total === 0 ? (
        <ChartEmpty>条件に合う応募者がいません</ChartEmpty>
      ) : (
        <div style={{ width: "180px", height: "180px", outline: "none" }}>
          <PieChart width={180} height={180}>
            <Pie
              data={data}
              cx={80}
              cy={80}
              innerRadius={50}
              outerRadius={80}
              paddingAngle={1}
              dataKey="value"
              style={{ outline: "none" }}
              animationDuration={0}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
              <Label
                content={({ viewBox }) => {
                  if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                    return (
                      <Center
                        x={viewBox.cx}
                        y={viewBox.cy}
                        textAnchor="middle"
                        dominantBaseline="middle"
                      >
                        <CenterValue x={viewBox.cx} y={viewBox.cy}>
                          {total}
                        </CenterValue>
                        <CenterText x={viewBox.cx} y={(viewBox.cy || 0) + 24}>
                          合計
                        </CenterText>
                      </Center>
                    );
                  }
                }}
              />
            </Pie>
            <Tooltip />
          </PieChart>
        </div>
      )}
      <ChartLegend>
        {data.map((item, index) => (
          <ChartLegendItem key={index}>
            <ChartLegendItemLeft>
              <ChartLegendItemColor
                style={{ backgroundColor: COLORS[index] }}
              />
              <ChartLegendItemText>{item.name}</ChartLegendItemText>
            </ChartLegendItemLeft>
            <ChartLegendItemValue>{item.value}</ChartLegendItemValue>
          </ChartLegendItem>
        ))}
        <ChartLegendTotal>
          <ChartLegendItemText>合計</ChartLegendItemText>
          <ChartLegendItemValue>{total}</ChartLegendItemValue>
        </ChartLegendTotal>
      </ChartLegend>
    </ChartSection>
  );
};

const Center = styled.text`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ChartSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
`;

const CenterValue = styled.tspan`
  font-size: 24px;
  font-weight: bold;
`;

const CenterText = styled.tspan`
  font-size: 14px;
`;

const ChartLegend = styled.div`
  padding: 0 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const ChartLegendItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ChartLegendItemLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ChartLegendItemColor = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 12px;
`;

const ChartLegendItemText = styled.div`
  font-size: 14px;
`;

const ChartLegendItemValue = styled.div`
  width: 16px;
  display: flex;
  justify-content: center;
  font-size: 14px;
`;

const ChartLegendTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(19, 34, 149, 0.2);
  width: 100%;
  margin-top: 4px;
  padding-top: 4px;
`;

const ChartEmpty = styled.div`
  width: 180px;
  height: 180px;
  outline: none;
  display: grid;
  place-items: center;
  font-size: 12px;
`;

const NotShowChart = styled.div`
  font-size: 12px;
  width: 180px;
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    width: 100%;
  }
`;
