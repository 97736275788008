import { z } from "zod";
import { accountCommunityRolesRequestSchema } from "../../models/accountCommunityRole.schema";

export const updateAccountCommunityRolesRequestSchema = z.object({
  accountId: z.string(),
  data: z.array(accountCommunityRolesRequestSchema),
  skipValidate: z.boolean().optional(),
});

export type UpdateAccountCommunityRolesRequestSchemaType = z.infer<
  typeof updateAccountCommunityRolesRequestSchema
>;
