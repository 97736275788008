import { z } from "zod";
import { volunteerOption, VolunteerType } from "../../types/volunteerType";
import {
  medicalLicenseOptions,
  skillOptions,
  teachingLicenseOptions,
  TeachingLicenseType,
  MedicalLicenseType,
  SkillType,
} from "../../types/residentInfo";
import { transformArray } from "../rules/transform";

export const paidOption = [
  { value: "PAID", text: "有償" },
  { value: "FREE", text: "無償" },
] as const;

export type PaidType = (typeof paidOption)[number]["value"];

export const residentInfoSchema = z.object({
  preferredDays: transformArray(
    z.array(
      z.enum([
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
        "SUNDAY",
      ])
    )
  ),
  teacherLicenses: transformArray(
    z.array(
      z.enum(
        teachingLicenseOptions.map((opt) => opt.value) as [
          TeachingLicenseType,
          ...TeachingLicenseType[]
        ]
      )
    )
  ),
  medicalLicenses: transformArray(
    z.array(
      z.enum(
        medicalLicenseOptions.map((opt) => opt.value) as [
          MedicalLicenseType,
          ...MedicalLicenseType[]
        ]
      )
    )
  ),
  skills: transformArray(
    z.array(
      z.enum(
        skillOptions.map((opt) => opt.value) as [SkillType, ...SkillType[]]
      )
    )
  ),
  preferredPaid: transformArray(z.array(z.enum(["PAID", "FREE"]))),
  preferredVolunteerType: transformArray(
    z.array(
      z.enum(
        volunteerOption.map((opt) => opt.value) as [
          VolunteerType,
          ...VolunteerType[]
        ]
      )
    )
  ),
  privateEnterprise: z.string().nullable(),
  university: z.string().nullable(),
  externalOrganization: z.string().nullable(),
  pta: z.string().nullable(),
  walkMinute: z.number().optional(),
});

export type ResidentInfoSchemaType = z.infer<typeof residentInfoSchema>;
