import React from "react";
import { CSEditBaseInfoModalFormValues as CSFormValues } from "../CS";
import { PTAEditBaseInfoModalFormValues as PTAFormValues } from "../PTA";
import { EditModalFormLabel, ErrorMessage, TextField } from "..";
import { useFormContext } from "react-hook-form";
type EditBaseInfoModalFormValues = CSFormValues | PTAFormValues;

export const PhoneNumberSection = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<EditBaseInfoModalFormValues>();
  return (
    <div>
      <EditModalFormLabel>電話番号</EditModalFormLabel>
      <div>
        <TextField
          {...register("baseInfo.phoneNumber")}
          placeholder="例)  09012345678"
        />
        {errors.baseInfo?.phoneNumber && (
          <ErrorMessage>{errors.baseInfo.phoneNumber.message}</ErrorMessage>
        )}
      </div>
    </div>
  );
};
