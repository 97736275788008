import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Title } from "../../../components/Title";
import { ChevronIcon } from "../../../components/icons/SvgIcons";
import {
  useCurrentCommunityId,
  usePolyfitHistory,
  usePolyfitLocationQuery,
} from "../../../hooks/router";
import { ChatRoomList } from "../../../features/Recruitment/chatMessage/ChatRoomList";
import { useChatRooms } from "../../../features/Recruitment/chatMessage/useChatRooms";
import { useCurrentUser } from "../../../hooks/recoil/user";
import { ChatRoomFilter } from "../../../features/Recruitment/chatMessage/ChatRoomFilter";
import { useChatRoomFilter } from "../../../hooks/recoil/chatRoomFilter";
import { ChatRoomIndividualContent } from "../../../features/Recruitment/chatMessage/ChatRoomIndividualContent/ChatRoomIndividualContent";
import { ChatRoomRecruitmentContent } from "../../../features/Recruitment/chatMessage/ChatRoomRecruitment/ChatRoomRecruitmentContent";
import { formatRecruitmentDates } from "src/features/common/ChatMessage/utils/formatRecruitmentSchedule";
import { useSelectedChatRoom } from "../../../features/Recruitment/chatMessage/useSelectedChatRoom";
import {
  isCsAdminRole,
  isCsGuestRole,
  isCsMemberRole as isCsMemberRole,
} from "../../../utils/types/role";
import { Spinner } from "../../../components/icons/Spinner";
import { Button } from "src/components/Button";
import { ChatRoomAppendModal } from "../../../features/Recruitment/chatMessage/ChatRoomAppendContent/ChatRoomAppendModal";
import {
  boardEducationChatRoomTypes,
  ChatRoomOrigin,
  ChatRoomType,
} from "@shared/types/chatRoom";
import { ChatRoomGroupContent } from "../../../features/Recruitment/chatMessage/ChatRoomGroupContent/ChatRoomGroupContent";
import { ChatRoomIndividualBEContent } from "src/features/Recruitment/chatMessage/ChatRoomIndividualBEContent/ChatRoomIndividualBEContent";
import { ChatRoomGroupBEContent } from "src/features/Recruitment/chatMessage/ChatRoomGroupBEContent/ChatRoomGroupBEContent";
import { BREAKPOINTS } from "src/components/Responsive";
import { ChatRoomRecruitmentBoardEducationContent } from "src/features/Recruitment/chatMessage/ChatRoomRecruitmentBE/ChatRoomRecruitmentBoardEducationContent";
import { CloseIcon } from "src/components/icons/CloseIcon";

type Tab = "all" | "unanswered";
const isTab = (value: string | undefined): value is Tab =>
  value === "all" || value === "unanswered";

export const ResidentChatPage = () => {
  const history = usePolyfitHistory();
  const user = useCurrentUser();
  const { communityId } = useCurrentCommunityId();
  const { chatRoom: selectedChatRoom, refetch: refetchSelectedChatRoom } =
    useSelectedChatRoom();
  const { chatRoomId, tab: initTab } = usePolyfitLocationQuery(
    "RESIDENT_CHAT",
    {}
  );
  const participantId = useMemo(() => {
    if (isCsMemberRole(user.role) || isCsGuestRole(user.role)) return user.id;
    if (
      selectedChatRoom &&
      boardEducationChatRoomTypes.includes(selectedChatRoom.chatRoom.type)
    )
      return user.id;
    return communityId ?? "";
  }, [selectedChatRoom]);

  const {
    chatRooms,
    isLoading,
    fetchNextPage,
    hasNextPage,
    refetch,
    isFetchingNextPage,
  } = useChatRooms();
  const [showFilter, setShowFilter] = useState(false);
  const { chatRoomFilter, setChatRoomFilter, isFilterActive, resetFilter } =
    useChatRoomFilter();
  const [tab, setTab] = useState<Tab>(isTab(initTab) ? initTab : "all");

  const isOpenChatRoom = chatRoomId !== undefined;

  const onTabClick = (tabName: Tab) => {
    setTab(tabName);
    const query = chatRoomId ? { chatRoomId, tab: tabName } : { tab: tabName };
    history.push({
      to: "RESIDENT_CHAT",
      query: query,
    });
  };

  useEffect(() => {
    if (chatRooms && chatRooms.length > 0) {
      history.replace({
        to: "RESIDENT_CHAT",
        query: { chatRoomId: chatRooms[0].chatRoom.id, tab: tab },
      });
    }
  }, []);

  const unansweredChatRooms = chatRooms?.filter((chatRoom) => {
    return chatRoom.chatRoom.lastMessageCreatedBy &&
      chatRoom.chatRoom.origin === ChatRoomOrigin.BOARD_EDUCATION
      ? chatRoom.chatRoom.lastMessageCreatedBy
      : chatRoom.chatRoom.lastMessageCreatedBy !== participantId;
  });

  const displayedChatRooms =
    tab === "unanswered" ? unansweredChatRooms : chatRooms;

  // chatRoom の追加に必要な要素
  const [isOpenChatRoomAppender, setIsOpenChatRoomAppender] = useState(false);
  const isCSAdmin = isCsAdminRole(user.role);
  const isCSGuest = isCsGuestRole(user.role);
  const [isRegistrationBannerOpen, setIsRegistrationBannerOpen] =
    useState(true);

  return (
    <PageWrapper>
      {isCSGuest && isRegistrationBannerOpen && (
        <MainRegistrationWrapper>
          <CloseButton
            onClick={() => {
              setIsRegistrationBannerOpen(false);
            }}
          >
            <CloseIcon />
          </CloseButton>
          <div>
            <MainRegistrationMessage>
              アカウントを本登録しませんか？
            </MainRegistrationMessage>
            <MainRegistrationDescription>
              アカウントを本登録すると、募集への応募が可能になります。
            </MainRegistrationDescription>
          </div>
          <MainRedistrationButton>
            <Button
              onClick={() => {
                history.push({
                  to: "RESIDENT_REGISTER_INFO",
                  query: {},
                });
              }}
              fill
              disabled={false}
            >
              本登録へ
            </Button>
          </MainRedistrationButton>
        </MainRegistrationWrapper>
      )}
      <PageHeader>
        <Title>メッセージ</Title>
        {isCSAdmin && (
          <AppendChatRoomButton>
            <Button
              onClick={() => {
                setIsOpenChatRoomAppender(true);
              }}
              fill
              size="large"
              disabled={false}
            >
              メッセージルームを追加
            </Button>
          </AppendChatRoomButton>
        )}
      </PageHeader>
      <Padding>
        <ChatContainer>
          <Container
            $isShowMainRegistrationMessage={
              isRegistrationBannerOpen && isCSGuest
            }
          >
            <ChatRoomListContainer $isOpen={!isOpenChatRoom}>
              <TabWrapper>
                <Tab
                  $isActive={tab === "all"}
                  onClick={() => onTabClick("all")}
                >
                  すべて
                </Tab>
                <Tab
                  $isActive={tab === "unanswered"}
                  onClick={() => onTabClick("unanswered")}
                >
                  未返信
                  <CountBadge>{unansweredChatRooms?.length ?? 0}</CountBadge>
                </Tab>
              </TabWrapper>
              {!isCSGuest && (
                <FilterWrapper>
                  <Filter
                    onClick={() => {
                      setShowFilter(true);
                    }}
                  >
                    フィルター設定
                    <ChevronIcon />
                  </Filter>
                  {isFilterActive && (
                    <FilterActiveLabel>フィルター設定中</FilterActiveLabel>
                  )}
                </FilterWrapper>
              )}
              {showFilter && (
                <ChatRoomFilter
                  onClose={() => {
                    setShowFilter(false);
                  }}
                  chatRoomFilter={chatRoomFilter}
                  resetFilter={resetFilter}
                  setChatRoomFilter={setChatRoomFilter}
                />
              )}
              {isLoading && (
                <SpinnerWrapper>
                  <Spinner />
                </SpinnerWrapper>
              )}
              {!isLoading &&
                displayedChatRooms &&
                displayedChatRooms.length > 0 && (
                  <>
                    <ChatRoomList
                      chatRooms={displayedChatRooms}
                      participantId={participantId}
                      isCsAdmin={isCSAdmin}
                      fetchNextPage={fetchNextPage}
                      hasNextPage={hasNextPage}
                      isFetchingNextPage={isFetchingNextPage}
                    />
                  </>
                )}
            </ChatRoomListContainer>
            {selectedChatRoom?.chatRoom.type ===
              ChatRoomType.RECRUITMENT_APPLICATION &&
              selectedChatRoom.recruitmentApplication && (
                <ChatRoomRecruitmentContent
                  isOpen={isOpenChatRoom}
                  chatRoom={selectedChatRoom.chatRoom}
                  chatRoomParticipantId={participantId}
                  lastReadAt={selectedChatRoom.lastReadAt}
                  recruitmentId={
                    selectedChatRoom.recruitmentApplication.recruitment.id
                  }
                  recruitmentDuration={formatRecruitmentDates(
                    selectedChatRoom.recruitmentApplication.recruitment.schedule
                  )}
                  recruitmentTitle={
                    selectedChatRoom.recruitmentApplication.recruitment
                      .status === "DELETED"
                      ? `[削除済み] ${selectedChatRoom.recruitmentApplication.recruitment.title}`
                      : `${selectedChatRoom.recruitmentApplication.recruitment.title}`
                  }
                  isDeletedRecruitment={
                    selectedChatRoom.recruitmentApplication.recruitment
                      .status === "DELETED"
                  }
                />
              )}
            {selectedChatRoom?.chatRoom.type ===
              ChatRoomType.RECRUITMENT_APPLICATION_BOARD_EDUCATION &&
              selectedChatRoom.recruitmentApplication && (
                <ChatRoomRecruitmentBoardEducationContent
                  isOpen={isOpenChatRoom}
                  chatRoom={selectedChatRoom.chatRoom}
                  chatRoomParticipantId={participantId}
                  lastReadAt={selectedChatRoom.lastReadAt}
                  recruitmentId={
                    selectedChatRoom.recruitmentApplication.recruitment.id
                  }
                  recruitmentDuration={formatRecruitmentDates(
                    selectedChatRoom.recruitmentApplication.recruitment.schedule
                  )}
                  recruitmentTitle={
                    selectedChatRoom.recruitmentApplication.recruitment
                      .status === "DELETED"
                      ? `[削除済み] ${selectedChatRoom.recruitmentApplication.recruitment.title}`
                      : `${selectedChatRoom.recruitmentApplication.recruitment.title}`
                  }
                  isDeletedRecruitment={
                    selectedChatRoom.recruitmentApplication.recruitment
                      .status === "DELETED"
                  }
                />
              )}
            {selectedChatRoom?.chatRoom.type ===
              ChatRoomType.INDIVIDUAL_CHAT_WITH_ORGANIZATION &&
              selectedChatRoom.individualChatWithOrganization && (
                <ChatRoomIndividualContent
                  isOpen={isOpenChatRoom}
                  chatRoomParticipantId={participantId}
                  chatRoom={selectedChatRoom.chatRoom}
                  individualChatWithOrganization={
                    selectedChatRoom.individualChatWithOrganization
                  }
                  lastReadAt={selectedChatRoom.lastReadAt}
                />
              )}
            {selectedChatRoom?.chatRoom.type ===
              ChatRoomType.INDIVIDUAL_CHAT_WITH_BOARD_EDUCATION &&
              selectedChatRoom.individualChatWithBoardEducation && (
                <ChatRoomIndividualBEContent
                  isOpen={isOpenChatRoom}
                  // 教育委員会のチャットルームの場合、校区管理者を含めてparticipantIdは自身のuserIdになる
                  chatRoomParticipantId={participantId}
                  chatRoom={selectedChatRoom.chatRoom}
                  individualChatWithBoardEducation={
                    selectedChatRoom.individualChatWithBoardEducation
                  }
                  lastReadAt={selectedChatRoom.lastReadAt}
                  boardEducationName={
                    selectedChatRoom.individualChatWithBoardEducation
                      ?.boardEducation?.name ?? ""
                  }
                />
              )}
            {selectedChatRoom?.chatRoom.type ===
              ChatRoomType.GROUP_CHAT_WITH_ORGANIZATION &&
              selectedChatRoom.groupChatWithOrganization && (
                <ChatRoomGroupContent
                  isOpen={isOpenChatRoom}
                  chatRoomParticipantId={participantId}
                  chatRoom={selectedChatRoom.chatRoom}
                  groupChatWithOrganization={
                    selectedChatRoom.groupChatWithOrganization
                  }
                  lastReadAt={selectedChatRoom.lastReadAt}
                  lastReadAts={
                    selectedChatRoom.lastReadAts
                      ? selectedChatRoom.lastReadAts
                      : [selectedChatRoom.lastReadAt]
                  }
                  refetch={refetchSelectedChatRoom}
                />
              )}
            {selectedChatRoom?.chatRoom.type ===
              ChatRoomType.GROUP_CHAT_WITH_BOARD_EDUCATION &&
              selectedChatRoom.groupChatWithBoardEducation && (
                <ChatRoomGroupBEContent
                  isOpen={isOpenChatRoom}
                  chatRoomParticipantId={participantId}
                  chatRoom={selectedChatRoom.chatRoom}
                  lastReadAt={selectedChatRoom.lastReadAt}
                  lastReadAts={
                    selectedChatRoom.lastReadAts
                      ? selectedChatRoom.lastReadAts
                      : [selectedChatRoom.lastReadAt]
                  }
                  refetch={refetchSelectedChatRoom}
                />
              )}
          </Container>
        </ChatContainer>
        <ChatRoomAppendModal
          isOpen={isOpenChatRoomAppender}
          close={() => {
            setIsOpenChatRoomAppender(false);
          }}
          chatRooms={chatRooms}
          refetch={refetch}
        />
      </Padding>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow-y: hidden;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Padding = styled.div`
  padding-top: 24px;
  height: 100%;
`;

const Container = styled.div<{ $isShowMainRegistrationMessage: boolean }>`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-bottom: ${(props) =>
    props.$isShowMainRegistrationMessage ? "128px" : "36px"};
`;

const ChatRoomListContainer = styled.div<{ $isOpen: boolean }>`
  height: 100%;
  max-height: 100%;
  width: 400px;
  @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
    width: 100%;
    display: ${(props) => (props.$isOpen ? "block" : "none")};
  }
`;

const Filter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 25px;
  padding: 16px 24px;
  width: fit-content;
  cursor: pointer;
`;

const ChatContainer = styled.div`
  height: 100%;
  background: white;
  border-radius: 8px;
`;

const CountBadge = styled.span`
  display: grid; /* Grid レイアウトを有効化 */
  place-items: center;
  font-size: 12px;
  margin-left: 8px;
  height: 20px;
  line-height: 20px;
  min-width: 30px;
  border-radius: 64px;
  color: #fff;
  background: #0077cc;
`;

const TabWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const Tab = styled.div<{ $isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  width: 50%;
  ${(props) =>
    props.$isActive && `color: #0077CC; border-bottom: 2px solid #0077CC`};
  cursor: pointer;
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FilterActiveLabel = styled.p`
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  height: 20px;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  background: #0077cc;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 40px;
`;

const AppendChatRoomButton = styled.div``;

const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: -4px;
  right: 0px;
  border: none;
  background-color: black;
  border-radius: 16px;
  padding: 4px;
  display: grid;
  place-content: center;
  z-index: 3;
`;

const MainRegistrationWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 12px 16px 12px 12px;
  gap: 8px;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 24px;
  align-items: center;
  justify-content: space-between;
  background-color: #e6f1fa;
`;

const MainRegistrationMessage = styled.p`
  display: flex;
  font-weight: 600;
  font-size: 16px;
  color: #0077cc;
  @media (max-width: ${BREAKPOINTS.SP}) {
    font-size: 12px;
  }
`;

const MainRegistrationDescription = styled.p`
  display: flex;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 14px;
  color: #343741;
  @media (max-width: ${BREAKPOINTS.SP}) {
    font-size: 10px;
  }
`;

const MainRedistrationButton = styled.div`
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  width: auto;
`;
