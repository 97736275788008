import * as client from "./client";
import { Account, BaseInfo } from "./auth";
import { Partner } from "./partner";
import { TeacherFilterType } from "../hooks/api/users";
import {
  MedicalLicenseType,
  SkillType,
  TeachingLicenseType,
} from "../utils/types/license";
import { VolunteerType } from "../@shared/types/volunteerType";
import { ChildClass } from "./child";
import { UserType } from "../utils/types/userType";
import { Role } from "../utils/types/role";
import { ServiceType } from "../utils/types/serviceType";
import { ResidentFilterType } from "@shared/types/user";
import { BEResidentUser } from "@shared/types/boardEducation/residentUsers";

export type User = {
  id: string;
  email: string;
  name: string;
  role: Role;
  isApprover: boolean;
  able: boolean;
  signUpState: boolean;
  internalRole: "INTERNAL_ADMIN" | "Member";
  picture: string;
  type: UserType;
  firebaseUid: string;
  walkMinute?: number;
  baseInfo?: BaseInfo;
  account?: Account;
  partner?: Partner;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
};

export function inPTA(user: User) {
  return user.role.startsWith("PTA");
}

export function inCommunitySchool(user: User) {
  return user.role.startsWith("CS");
}

export function inBoardEducation(user: User) {
  return user.role.startsWith("BE");
}

export function isTeacherUser(
  user: User | TeacherUser | ResidentUser
): user is TeacherUser {
  return user.type === "TEACHER";
}

export function isResidentUser(
  user: User | TeacherUser | ResidentUser
): user is ResidentUser {
  return user.type === "RESIDENT";
}

export type TeacherUser = User & {
  type: "TEACHER";
  teacherInfo: {
    hasHomeroom: boolean;
    grade: number;
    class: ChildClass;
    accountId?: string;
  };
};

export type ResidentUser = User & {
  type: "RESIDENT";
  residentInfo: {
    preferredDays: (
      | "MONDAY"
      | "TUESDAY"
      | "WEDNESDAY"
      | "THURSDAY"
      | "FRIDAY"
      | "SATURDAY"
      | "SUNDAY"
    )[];
    teacherLicenses: TeachingLicenseType[];
    medicalLicenses: MedicalLicenseType[];
    skills: SkillType[];
    preferredPaid: ("PAID" | "FREE")[];
    preferredVolunteerType: VolunteerType[];
    privateEnterprise: string | null;
    university: string | null;
    externalOrganization: string | null;
    pta: string | null;
  };
};

/**
 * PTAの全ユーザーを取得する
 */
export async function getPTAUsers(): Promise<User[]> {
  const res = await client.get<{}, { users: User[] }>("/users/ptaUsers", {});
  return res.users;
}

/**
 * PTA/CSの両ユーザーを取得する（Internal用）
 */
export async function getUsersByCommunityId({
  communityId,
  serviceType,
}: {
  communityId: string;
  serviceType: ServiceType;
}): Promise<User[]> {
  const res = await client.get<
    { communityId: string; serviceType: ServiceType },
    { users: User[] }
  >("/users/usersByCommunityId", {
    communityId,
    serviceType,
  });
  return res.users;
}

/**
 * PTA/CSの両ユーザーを取得する（Internal用）
 */
export async function getUsersByOrganizationId({
  organizationId,
}: {
  organizationId: string;
}): Promise<User[]> {
  const res = await client.get<{ organizationId: string }, { users: User[] }>(
    "/users/usersByOrganizationId",
    {
      organizationId,
    }
  );
  return res.users;
}

/**
 * 未承認メンバー取得
 */
export async function getDisabledUsersByOrganizationId(): Promise<User[]> {
  const res = await client.get<{}, { users: User[] }>(
    "/users/disabledUsersByOrganizationId",
    {}
  );
  return res.users;
}

export async function getDisabledTeacherUser(
  q?: string,
  filter?: TeacherFilterType
): Promise<TeacherUser[]> {
  const filterQuery = JSON.stringify(filter);
  const res = await client.get<{}, { users: TeacherUser[] }>(
    "/users/disabledTeacherUsers",
    { q, filter: filterQuery }
  );
  return res.users;
}

/**
 * 教員メンバー一覧・検索
 */
export async function getTeacherUsers(
  q?: string,
  filter?: TeacherFilterType
): Promise<TeacherUser[]> {
  const filterQuery = JSON.stringify(filter);
  const res = await client.get<{}, { users: TeacherUser[] }>(
    "/users/teacherUsers",
    { q, filter: filterQuery }
  );
  return res.users;
}

/**
 * 地域住民一覧・検索
 */
export async function getResidentUsers(
  filter?: Partial<ResidentFilterType>
): Promise<ResidentUser[]> {
  const filterQuery = JSON.stringify(filter);
  const res = await client.get<{}, { users: ResidentUser[] }>(
    "/users/residentUsers",
    { filter: filterQuery }
  );
  return res.users;
}

export type UpdatedUser = {
  userId: string;
  email?: string;
  name?: string;
  picture?: string;
  internalRole?: "INTERNAL_ADMIN" | "Member";
};

export async function updateUserById(args: UpdatedUser): Promise<User> {
  const res = await client.post<UpdatedUser, { user: User }>(
    "/users/updateUser",
    args
  );
  return res.user;
}

export async function updateApprovers(approverIds: string[]): Promise<User> {
  const res = await client.post<string[]>(
    "/users/updateApprovers",
    approverIds
  );
  return res.user;
}

/**
 * ユーザー情報、基本情報の更新
 * @param args
 * @returns
 */
export async function updateUserAndBaseInfo(args: User): Promise<User> {
  const res = await client.post<User, { user: User }>(
    "/users/updateUserAndBaseInfo",
    args
  );
  return res.user;
}

/**
 * ID指定でユーザー取得
 * @param param
 * @returns
 */
export async function getMemberUser({
  userId,
}: {
  userId: string;
}): Promise<User> {
  const res = await client.get<{ userId: string }, { user: User }>(
    "/users/getMemberUser",
    { userId: userId }
  );
  return res.user;
}

/**
 * organizationId指定でAdminユーザーを取得
 * @param param
 * @returns
 */
export async function getAdminUserByOrganizationId(
  organizationId: string
): Promise<{ users: User[] }> {
  const res = await client.get<{ organizationId: string }, { users: User[] }>(
    "/users/getAdminUsersByOrganizationId",
    { organizationId }
  );

  return res;
}

/**
 * 地域住民一覧・検索
 */
export async function getBEResidentUsers(
  filter?: Partial<ResidentFilterType>
): Promise<BEResidentUser[]> {
  const filterQuery = JSON.stringify(filter);
  const res = await client.get<{}, { users: BEResidentUser[] }>(
    "/boardEducation/users/residentUsers",
    { filter: filterQuery }
  );
  return res.users;
}
