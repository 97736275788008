import React, { useCallback, useState } from "react";
import {
  ModalPortal,
  ModalHeader,
  ModalBody,
  useModal,
} from "../../components/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-regular-svg-icons/faClipboard";
import { useGetOrGenerateInvitation } from "../../hooks/recoil/invitation";
import { useCurrentUser } from "../../hooks/recoil/user";
import { useToast } from "../../components/Toast";
import styled from "styled-components";
import { InviteIcon } from "../../components/icons/InviteIcon";
import { QRCodeCanvas } from "qrcode.react";
import { SideLineHeaderText } from "../../components/SideLineHeaderText";
import * as theme from "../../theme";
import { SidebarMenu } from "../../components/Common/Sidebar/SidebarMenu";
import { SwitchBar } from "../../components/Common/SwitchBar";
import { FileIcon } from "../../components/icons/FileIcon";
import { usePolyfitHistory } from "../../hooks/router";
import { Button } from "../../components/Button";
import { isCsAdminRole, isPtaAdminRole } from "../../utils/types/role";
import { Margin } from "src/components/Margin";

const InputMemberPage = ({ onClose }: { onClose: () => void }) => {
  const history = usePolyfitHistory();
  const onClick = useCallback(() => {
    onClose();
    history.push({ to: "RESIDENT_INVITATION", query: { step: "1" } });
  }, [history]);
  return (
    <InputMemberArea>
      <FileIcon size={170} />
      <Button color="primary" fill onClick={onClick}>
        メンバー情報入力画面へ
      </Button>
    </InputMemberArea>
  );
};

const LinkPage = ({ link }: { link: string }) => {
  const toast = useToast();
  const onClick = useCallback(() => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(link);
      toast.info("コピーしました");
    }
  }, [link, toast]);
  return (
    <ClipboardButtonArea onClick={onClick}>
      {link}
      <ClipboardIcon>
        コピー
        {/* @ts-ignore */}
        <FontAwesomeIcon icon={faClipboard} />
      </ClipboardIcon>
    </ClipboardButtonArea>
  );
};

const QrcodePage = ({ link }: { link: string }) => {
  const toast = useToast();
  return (
    <>
      <QRCodeCanvas
        value={link}
        size={160}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"L"}
        includeMargin={false}
      />
      <QRCodeButtons>
        <QRCodeCopy
          onClick={() => {
            const c = document.getElementsByTagName("canvas");
            c[0].toBlob(function (blob) {
              if (blob == null) {
                return;
              }
              // https://www.memory-lovers.blog/entry/2019/03/04/004013 のような事情でts-ignoreしてます。。
              const item = new ClipboardItem({
                "image/png": blob,
              });
              navigator.clipboard.write([item]);
              toast.info("コピーしました");
            });
          }}
        >
          <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.4 0C10.6349 0 10.8603 0.098875 11.0223 0.273L13.7651 3.2725C13.916 3.43525 14 3.65138 14 3.87538V13.125C14 13.608 13.616 14 13.1429 14H2.85714C2.384 14 2 13.608 2 13.125V0.875C2 0.392 2.384 0 2.85714 0H10.4ZM13 4H10.4C10.1792 4 10 3.8208 10 3.6V1H3V13H13V4Z"
              fill="#5A606B"
            />
            <path
              d="M2 1H1C0.447715 1 0 1.44772 0 2V15C0 15.5523 0.447715 16 1 16H11C11.5523 16 12 15.5523 12 15V14H11V15H1V2H2V1Z"
              fill="#5A606B"
            />
          </svg>
          画像をコピー
        </QRCodeCopy>
        <QRCodeDownload
          onClick={() => {
            const c = document.getElementsByTagName("canvas");
            const link = document.createElement("a");
            link.href = c[0].toDataURL("image/png");
            link.download = "招待リンク.png";
            link.click();
            toast.info("ダウンロードしました");
          }}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 10.1143L10.8497 8.17116C11.0627 7.94295 11.4088 7.94295 11.6208 8.17116C11.8338 8.39937 11.8338 8.77171 11.6208 8.99992L9.67075 11.0494C9.03175 11.7363 8.00075 11.7384 7.35975 11.0494L5.40975 8.99992C5.19675 8.77171 5.19675 8.39937 5.40975 8.17116C5.62175 7.94295 5.96775 7.94295 6.18075 8.17116L8 10.0822V1.55556C8 1.24873 8.22386 1 8.5 1C8.77614 1 9 1.24873 9 1.55556V10.1143ZM4.17972 6C3.70743 6 3.3008 6.33646 3.20818 6.80388L2.01924 12.8039C2.00644 12.8685 2 12.9341 2 13C2 13.5523 2.44359 14 2.99078 14H14.009C14.0743 14 14.1394 13.9935 14.2033 13.9806C14.7399 13.8723 15.0879 13.3454 14.9806 12.8039L13.7916 6.80388C13.699 6.33646 13.2924 6 12.8201 6H4.17972ZM6 5V6H11V5H12.8253C13.771 5 14.5852 5.67292 14.7707 6.60777L15.9611 12.6078C16.176 13.6909 15.4792 14.7445 14.4047 14.9612C14.2766 14.987 14.1463 15 14.0156 15H2.98397C1.88826 15 1 14.1046 1 13C1 12.8683 1.01291 12.7369 1.03853 12.6078L2.22891 6.60777C2.41438 5.67292 3.22863 5 4.17436 5H6Z"
              fill="#5A606B"
            />
          </svg>
          ダウンロード
        </QRCodeDownload>
      </QRCodeButtons>
    </>
  );
};

const SelectedPage = ({
  selectedPageIndex,
  link,
  isPTAOpened,
  isCSOpened,
  onClose,
}: {
  selectedPageIndex: number;
  link: string;
  isPTAOpened: boolean;
  isCSOpened: boolean;
  onClose: () => void;
}) => {
  if (isPTAOpened) {
    return selectedPageIndex === 0 ? (
      <LinkPage link={link} />
    ) : selectedPageIndex === 1 ? (
      <QrcodePage link={link} />
    ) : (
      <></>
    );
  } else if (isCSOpened) {
    return selectedPageIndex === 0 ? (
      <InputMemberPage onClose={onClose} />
    ) : selectedPageIndex === 1 ? (
      <LinkPage link={link} />
    ) : selectedPageIndex === 2 ? (
      <QrcodePage link={link} />
    ) : (
      <></>
    );
  }
  return <></>;
};

export const InvitationPanel = () => {
  const [isOpened, modalApi] = useModal();
  const [isPTAOpened, modalPTAApi] = useModal();
  const [isCSOpened, modalCSApi] = useModal();
  const [selectedPTAPageIndex, setSelectedPTAPageIndex] = useState(0);
  const [selectedCSPageIndex, setSelectedCSPageIndex] = useState(0);

  const user = useCurrentUser();

  const isPTAAdmin = isPtaAdminRole(user.role);
  const isCSAdmin = isCsAdminRole(user.role);

  const ptaLabels = ["リンク", "QRコード"];
  const csLabels = ["メンバー情報", "リンク", "QRコード"];
  const { loading, invitationLink, invitationResidentLink } =
    useGetOrGenerateInvitation({
      skip: !user,
      pta: isPTAAdmin,
      resident: isCSAdmin,
    });

  const handleOpenModal = () => {
    if (isPTAAdmin && !isCSAdmin) {
      modalPTAApi.show();
    }
    if (!isPTAAdmin && isCSAdmin) {
      modalCSApi.show();
    }
    if (isPTAAdmin && isCSAdmin) {
      modalApi.show();
    }
  };

  return (
    <>
      <SidebarMenu
        onClick={handleOpenModal}
        icon={<InviteIcon />}
        text="招待"
        isNotCloseByClickMenu={true}
      />
      {isOpened && (
        <SelectInvitationPanel
          onClose={() => {
            modalApi.close();
          }}
          openPTAInvitationPanel={() => {
            modalApi.close();
            modalPTAApi.show();
          }}
          openCSInvitationPanel={() => {
            modalApi.close();
            modalCSApi.show();
          }}
        />
      )}
      {isPTAOpened && !loading && (
        <ModalPortal onClose={modalPTAApi.close} modalBoxIsHeightFull={true}>
          <ModalHeader>招待</ModalHeader>
          <ModalBody>
            リンクのコピーまたはQRコードのコピー・ダウンロードでメンバーを招待できます。
            <Margin marginTop={12} />
            <SwitchBar
              labels={ptaLabels}
              onSelect={setSelectedPTAPageIndex}
              selectedIndex={selectedPTAPageIndex}
            ></SwitchBar>
            <Margin marginTop={12} />
            <LinkArea>
              <SelectedPage
                selectedPageIndex={selectedPTAPageIndex}
                link={invitationLink}
                isPTAOpened
                isCSOpened={false}
                onClose={modalPTAApi.close}
              />
            </LinkArea>
          </ModalBody>
        </ModalPortal>
      )}
      {isCSOpened && !loading && (
        <ModalPortal onClose={modalCSApi.close} modalBoxIsHeightFull={true}>
          <ModalHeader>招待</ModalHeader>
          <ModalBody>
            リンクのコピーまたはQRコードのコピー・ダウンロードでメンバーを招待できます。
            <Margin marginTop={12} />
            <SwitchBar
              labels={csLabels}
              onSelect={setSelectedCSPageIndex}
              selectedIndex={selectedCSPageIndex}
            ></SwitchBar>
            <Margin marginTop={12} />
            <LinkArea>
              <SelectedPage
                selectedPageIndex={selectedCSPageIndex}
                link={invitationResidentLink}
                isPTAOpened={false}
                isCSOpened
                onClose={modalCSApi.close}
              />
            </LinkArea>
          </ModalBody>
        </ModalPortal>
      )}
    </>
  );
};

export const SelectInvitationPanel = ({
  onClose,
  openPTAInvitationPanel,
  openCSInvitationPanel,
}: {
  onClose: () => void;
  openPTAInvitationPanel: () => void;
  openCSInvitationPanel: () => void;
}) => {
  return (
    <ModalPortal onClose={onClose} modalBoxIsHeightFull={true}>
      <ModalHeader>作成したいアカウントを選択</ModalHeader>

      <SideLineHeaderText text="PTAの方はこちら" border />
      <SelectUserTypeButton onClick={() => openPTAInvitationPanel()}>
        PTA用のアカウントを招待
      </SelectUserTypeButton>

      <Spacer />

      <SideLineHeaderText text="地域住民の方はこちら" border />
      <SelectUserTypeButton onClick={() => openCSInvitationPanel()}>
        地域住民向けのアカウントを招待
      </SelectUserTypeButton>
    </ModalPortal>
  );
};

const InputMemberArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f7fa;
  padding-bottom: 24px;
`;

const ClipboardButtonArea = styled.div`
  cursor: pointer;
  width: 100%;
  border: ${theme.form.borderColor};
  border-radius: ${theme.form.borderRadius};
  background: ${theme.form.background.normal};
  font-size: ${theme.typo.small};
  font-weight: normal;
  line-height: 21px;
  box-sizing: border-box;
  appearance: none;
  padding: 9.5px 12px;
  display: flex;
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;

  &:hover {
    box-shadow: ${theme.shadow.bottomSmall};
  }
`;

const ClipboardIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 84px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  background: ${theme.colorsPallet.darkShade};
  color: ${theme.colorsPallet.emptyShade};
`;

const LinkArea = styled.div`
  text-align: center;
  height: 240px;
`;

const QRCodeCopy = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  /* Font Style */
  color: #5a606b;
  font-size: 14px;
  padding: 9.5px 26px;
`;

const QRCodeDownload = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  /* Font Style */
  color: #5a606b;
  font-size: 14px;
  padding: 9.5px 26px;
`;

const QRCodeButtons = styled.div`
  width: 160px;
  margin: auto;
`;

const Spacer = styled.div`
  height: 20px;
`;

const SelectUserTypeButton = styled.button`
  height: 36px;
  display: block;
  width: 100%;
  align-items: center;
  color: ${theme.colorsPallet.primary};
  background-color: #ffffff;
  border: 1px solid ${theme.colorsPallet.primary};
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    opacity: 0.7;
  }
`;
