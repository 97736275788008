import React from "react";
import { PageContainer, PageHeader, PageBody } from "../../../components/Page";
import * as Typo from "../../../components/Typo";
import * as Responsive from "../../../components/Responsive";
import styled from "styled-components";
import { Button } from "../../../components/Button";
import {
  usePolyfitHistory,
  usePolyfitLocationQuery,
} from "../../../hooks/router";
import { AvatarInScreenIcon } from "../../../components/icons/AvatarInScreenIcon";
import * as theme from "../../../theme";
import { Link } from "react-router-dom";

export const ResidentChatRegistrationDescription = () => {
  const history = usePolyfitHistory();
  const { token } = usePolyfitLocationQuery(
    "RESIDENT_CHAT_REGISTRATION_DESCRIPTION",
    {
      token: "",
    }
  );

  return (
    <>
      <Container>
        <IconContainer>
          <AvatarInScreenIcon />
        </IconContainer>
        <PageHeader>アカウント作成が必要です</PageHeader>
        <PageBody>
          <Responsive.Row>
            <Responsive.Col>
              <Typo.Paragraph className="message">
                クラウド地域協働ソフトである「polyfit」を使って、チャット形式で行われます。アカウントを作成すれば、チャット形式で問い合わせができるようになりますので、
                アカウントの作成をお願いいたします。
              </Typo.Paragraph>
            </Responsive.Col>
          </Responsive.Row>
          <Responsive.Row>
            <Responsive.Col>
              <ButtonWrapper>
                <Button
                  size="large"
                  fill
                  className="message"
                  onClick={() => {
                    history.push({
                      to: "SIGNUP",
                      query: { token },
                    });
                  }}
                >
                  アカウントを作成
                </Button>
              </ButtonWrapper>
            </Responsive.Col>
            <Link
              to={{
                pathname: "/signup",
                search: `token=${token}&guest=1`,
              }}
              style={{
                color: theme.colorsPallet.primary,
                fontSize: "14px",
                margin: "4px auto 0 auto",
              }}
            >
              ゲスト参加
            </Link>
          </Responsive.Row>
        </PageBody>
      </Container>
    </>
  );
};

const Container = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 702px;
  margin: 0 auto;
  padding-top: 124px;
  padding-bottom: 16px;
  button {
    margin: 24px auto;
  }
  .message {
    text-align: center;
  }
  @media (max-width: 1279px) {
    padding-top: 120px;
  }
`;

const IconContainer = styled.div`
  margin-bottom: 12px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
