import React, { useState, useEffect } from "react";
import { useCurrentUser } from "../../../hooks/recoil/user";
import { inCommunitySchool, inPTA } from "../../../apiClients/users";
import { SignatureTemplatesPTATab } from "src/features/SignatureTemplates/SignatureTemplatesPTATab";
import { SignatureTemplatesCSTab } from "src/features/SignatureTemplates/SignatureTemplatesCSTab";
import { isPtaAdminRole } from "src/utils/types/role";

export default function SignatureTemplatesPage() {
  const [tab, setTab] = useState<"pta" | "cs">();
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (tab === undefined) {
      if (inPTA(currentUser)) {
        setTab("pta");
      } else if (inCommunitySchool(currentUser)) {
        setTab("cs");
      }
    }
  }, [currentUser]);

  switch (tab) {
    case "pta":
      return (
        <SignatureTemplatesPTATab isAdmin={isPtaAdminRole(currentUser.role)} />
      );
    case "cs":
      return (
        <SignatureTemplatesCSTab
          showTab={inPTA(currentUser) && inCommunitySchool(currentUser)}
          setTab={setTab}
        />
      );
    default:
      return null;
  }
}
