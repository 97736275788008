import React, { memo } from "react";
import { SidebarMenu } from "./SidebarMenu";
import { useLocation } from "react-router-dom";
import { HomeIcon } from "../../icons/Home";
import { MailIcon } from "../../icons/MailIcon";
import { MemberListIcon } from "../../icons/MemberListIcon";
import { LinkIcon } from "../../icons/LinkIcon";
import { SignatureIcon } from "src/components/icons/SignatureIcon";

export const PTAMemberSidebar = memo(() => {
  const { pathname } = useLocation();
  return (
    <>
      <SidebarMenu
        to="/pta"
        isActive={pathname == "/pta" || pathname == "/pta/disabled_members"}
        icon={<HomeIcon />}
        text="ホーム"
      />
      <SidebarMenu
        to="/pta/post"
        isActive={pathname.startsWith("/pta/post")}
        icon={<MailIcon />}
        text="連絡"
      />
      <SidebarMenu
        to="/pta/list"
        isActive={pathname.startsWith("/pta/list")}
        icon={<MemberListIcon />}
        text="名簿"
      />
      <SidebarMenu
        to="/pta/admin/signature_templates"
        isActive={pathname.startsWith("/pta/admin/signature_templates")}
        icon={<SignatureIcon />}
        text="設定"
      />
      <SidebarMenu
        to="/pta/link/list"
        isActive={pathname === "/pta/link/list"}
        icon={<LinkIcon />}
        text="リンク"
      />
    </>
  );
});
