import { useEffect } from "react";
import { useChatRoomFilter } from "../../../hooks/recoil/chatRoomFilter";
import { useWebSocket } from "../../../hooks/recoil/socket";
import { EVENT_NAMES } from "@shared/types/webSocket";
import { useChatRoomList } from "src/hooks/query/chatRoomList";

export const useChatRooms = () => {
  const socket = useWebSocket();
  const { chatRoomFilter } = useChatRoomFilter();

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    refetch,
    isFetchingNextPage,
  } = useChatRoomList(chatRoomFilter);

  const chatRooms = data ? data.pages.flatMap((page) => page.chatRooms) : [];

  useEffect(() => {
    if (!socket.instance) return;

    const handleChangeChatRoom = async (data: { chatRoomId: string }) => {
      await refetch();
    };

    socket.instance.on(EVENT_NAMES.UPDATED_CHAT_ROOM, handleChangeChatRoom);
    socket.instance.on(EVENT_NAMES.ADDED_NEW_CHAT_ROOM, handleChangeChatRoom);
    socket.instance.on(EVENT_NAMES.DELETED_CHAT_ROOM, handleChangeChatRoom);

    return () => {
      socket.instance?.off(EVENT_NAMES.UPDATED_CHAT_ROOM, handleChangeChatRoom);
      socket.instance?.off(
        EVENT_NAMES.ADDED_NEW_CHAT_ROOM,
        handleChangeChatRoom
      );
      socket.instance?.off(EVENT_NAMES.DELETED_CHAT_ROOM, handleChangeChatRoom);
    };
  }, [socket.isInitialized, refetch]);

  return {
    chatRooms,
    isLoading,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};
