import React from "react";
import styled from "styled-components";
import { getStaticImageUrl } from "../../../../utils/getStaticImageUrl";
import { Avatar } from "../../../../components/Common/Avatar";
import { useGetCommunityById } from "src/hooks/query/communityById";

type Props = {
  onClick: () => void;
  userName: string;
  chatRoomName: string;
  createdAt: string;
  userIcon: string | null;
  isActive: boolean;
  isUnAnswered: boolean;
  isCsAdmin: boolean;
  communityId: string | undefined;
};

const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

export const ChatRoomIndividualCard = (props: Props) => {
  const { community } = useGetCommunityById(props.communityId ?? "");

  return (
    <ChatRoom $isActive={props.isActive} onClick={props.onClick}>
      <Avatar
        src={props.userIcon ?? defaultUserImg}
        size={80}
        alt={defaultUserImg}
      />
      <Info>
        <Badges>
          <StatusBadge color="#F9AB01">個別メッセージ</StatusBadge>
          {props.isUnAnswered && <UnreadBadge />}
        </Badges>
        <RTitle>
          {!props.isCsAdmin ? `${community?.name} 管理者` : props.userName}
        </RTitle>
        <UserNameAndDate>
          <ChatRoomDate>{props.createdAt}</ChatRoomDate>
        </UserNameAndDate>
      </Info>
    </ChatRoom>
  );
};

const ChatRoom = styled.div<{ $isActive: boolean }>`
  cursor: pointer;
  display: flex;
  padding: 16px;
  border-radius: 16px;
  background: ${(props) => (props.$isActive ? "#F5F7FA" : "#fff")};
  &:hover {
    background: #f5f7fa;
  }
`;

const UserNameAndDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 70%;
  margin-left: 16px;
`;

const ChatRoomDate = styled.p`
  color: #69707d;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
`;

const Badges = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StatusBadge = styled.span<{ color: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.color};
  color: white;
  font-size: 0.7em;
  height: 20px;
  padding: 2px 8px;
  border-radius: 4px;
  gap: 4px;
`;

const UnreadBadge = styled.span`
  margin-left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: #0077cc;
`;

const RTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  margin-top: 12px;
`;
