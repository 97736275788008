import React from "react";
import styled from "styled-components";
import { usePolyfitHistory, usePolyfitLocationQuery } from "src/hooks/router";
import { formatDateTime } from "src/features/common/ChatMessage/utils/formatRecruitmentSchedule";
import { ChatRoomRecruitmentCard } from "./ChatRoomCard/ChatRoomRecruitmentCard";
import { ChatRoomIndividualCard } from "./ChatRoomCard/ChatRoomIndividualCard";
import { ChatRoomIndividualFromQRCard } from "./ChatRoomCard/ChatRoomIndividualFromQRCard";
import {
  ApprovalStatus,
  ChatRoomOrigin,
  ChatRoomType,
} from "@shared/types/chatRoom";
import { ChatRoomEntity } from "@shared/types/boardEducation/chatRoom";
import { ChatRoomGroupCard } from "./ChatRoomCard/ChatRoomGroupCard";
import { ChatRoomIndividualBoardEducationCard } from "./ChatRoomCard/ChatRoomIndividualBoardEducationCard";
import { ChatRoomGroupBoardEducationCard } from "./ChatRoomCard/ChatRoomGroupBoardEducationCard";
import { ChatRoomRecruitmentBoardEducationCard } from "./ChatRoomCard/ChatRoomRecruitmentBoardEducationCard";

type ChatRoomListProps = {
  chatRooms: ChatRoomEntity[];
  participantId: string;
  communityName: string;
  isCsAdmin: boolean;
};

export const ChatRoomList = (props: ChatRoomListProps) => {
  const history = usePolyfitHistory();
  const { tab, chatRoomId } = usePolyfitLocationQuery("RESIDENT_CHAT", {});
  const onChatRoomClick = (roomId: string) => {
    history.replace({
      to: "BE_CHATROOM_LIST",
      query: { chatRoomId: roomId, tab },
    });
  };

  return (
    <List>
      {props.chatRooms.map((room) => {
        if (
          room.chatRoom.type ===
            ChatRoomType.INDIVIDUAL_CHAT_WITH_ORGANIZATION &&
          room.individualChatWithOrganization
        ) {
          /*
            chatRoom が csAdmin から作られた場合 or QRコードから連絡されて Approve された場合
          */
          if (
            room.chatRoom.origin === ChatRoomOrigin.CSADMIN ||
            (room.chatRoom.origin === ChatRoomOrigin.QRCODE &&
              room.chatRoom.approvalStatus === ApprovalStatus.APPROVED)
          ) {
            return (
              <ChatRoomIndividualCard
                key={room.chatRoom.id}
                onClick={() => onChatRoomClick(room.chatRoom.id)}
                isActive={room.chatRoom.id === chatRoomId}
                isUnAnswered={
                  room.chatRoom.lastMessageCreatedBy != undefined &&
                  room.chatRoom.lastMessageCreatedBy !== props.participantId
                }
                chatRoomName={room.chatRoom.name}
                userName={room.individualChatWithOrganization.account.name}
                communityName={room.chatRoom.community?.name ?? ""}
                userIcon={room.individualChatWithOrganization.account.picture}
                isCsAdmin={props.isCsAdmin}
                createdAt={formatDateTime(room.chatRoom.createdAt)}
              />
            );
            /*
            QRコードから連絡されたが、まだ Approve されていない場合
          */
          } else {
            return (
              <ChatRoomIndividualFromQRCard
                key={room.chatRoom.id}
                onClick={() => onChatRoomClick(room.chatRoom.id)}
                isActive={room.chatRoom.id === chatRoomId}
                isUnAnswered={
                  room.chatRoom.lastMessageCreatedBy != undefined &&
                  room.chatRoom.lastMessageCreatedBy !== props.participantId
                }
                chatRoomName={room.chatRoom.name}
                userName={room.individualChatWithOrganization.account.name}
                communityName={room.chatRoom.community?.name ?? ""}
                userIcon={room.individualChatWithOrganization.account.picture}
                createdAt={formatDateTime(room.chatRoom.createdAt)}
              />
            );
          }
        } else if (
          room.chatRoom.type ===
            ChatRoomType.INDIVIDUAL_CHAT_WITH_BOARD_EDUCATION &&
          room.individualChatWithBoardEducation
        ) {
          return (
            <ChatRoomIndividualBoardEducationCard
              key={room.chatRoom.id}
              onClick={() => onChatRoomClick(room.chatRoom.id)}
              isActive={room.chatRoom.id === chatRoomId}
              isUnAnswered={
                room.chatRoom.lastMessageCreatedBy != undefined &&
                room.chatRoom.lastMessageCreatedBy !== props.participantId
              }
              chatRoomName={room.chatRoom.name}
              userName={room.individualChatWithBoardEducation.account.name}
              userIcon={room.individualChatWithBoardEducation.account.picture}
              createdAt={formatDateTime(room.chatRoom.createdAt)}
            />
          );
        } else if (
          room.chatRoom.type === ChatRoomType.GROUP_CHAT_WITH_ORGANIZATION &&
          room.groupChatWithOrganization
        ) {
          return (
            <ChatRoomGroupCard
              key={room.chatRoom.id}
              onClick={() => onChatRoomClick(room.chatRoom.id)}
              isActive={room.chatRoom.id === chatRoomId}
              isUnAnswered={
                room.chatRoom.lastMessageCreatedBy != undefined &&
                room.chatRoom.lastMessageCreatedBy !== props.participantId
              }
              isPending={
                room.chatRoom.ParticipantChatRooms.find(
                  (participantChatRoom) =>
                    participantChatRoom.participantId === props.participantId
                )?.approvalStatus === ApprovalStatus.PENDING
              }
              chatRoomName={room.chatRoom.name}
              communityName={room.chatRoom.community?.name ?? ""}
              iconFilePath={room.chatRoom.iconFilePath}
              createdAt={formatDateTime(room.chatRoom.createdAt)}
            />
          );
        } else if (
          room.chatRoom.type === ChatRoomType.GROUP_CHAT_WITH_BOARD_EDUCATION &&
          room.groupChatWithBoardEducation
        ) {
          return (
            <ChatRoomGroupBoardEducationCard
              key={room.chatRoom.id}
              onClick={() => onChatRoomClick(room.chatRoom.id)}
              isActive={room.chatRoom.id === chatRoomId}
              isUnAnswered={
                room.chatRoom.lastMessageCreatedBy != undefined &&
                room.chatRoom.lastMessageCreatedBy !== props.participantId
              }
              isPending={
                room.chatRoom.ParticipantChatRooms.find(
                  (participantChatRoom) =>
                    participantChatRoom.participantId === props.participantId
                )?.approvalStatus === ApprovalStatus.PENDING
              }
              chatRoomName={room.chatRoom.name}
              iconFilePath={room.chatRoom.iconFilePath}
              createdAt={formatDateTime(room.chatRoom.createdAt)}
            />
          );
        } else if (
          room.chatRoom.type === ChatRoomType.RECRUITMENT_APPLICATION &&
          room.recruitmentApplication
        ) {
          return (
            <ChatRoomRecruitmentCard
              key={room.chatRoom.id}
              onClick={() => onChatRoomClick(room.chatRoom.id)}
              isActive={room.chatRoom.id === chatRoomId}
              isUnAnswered={
                room.chatRoom.lastMessageCreatedBy != undefined &&
                room.chatRoom.lastMessageCreatedBy !== props.participantId
              }
              recruitmentTitle={
                room.recruitmentApplication.recruitment.status === "DELETED"
                  ? `[削除済み] ${room.recruitmentApplication.recruitment.title}`
                  : `${room.recruitmentApplication.recruitment.title}`
              }
              recruitmentApplicationStatus={room.recruitmentApplication.status}
              userName={room.recruitmentApplication.user.name}
              communityName={room.chatRoom.community?.name ?? ""}
              userIcon={room.recruitmentApplication.user.picture}
              createdAt={formatDateTime(room.chatRoom.createdAt)}
            />
          );
        } else if (
          room.chatRoom.type ===
            ChatRoomType.RECRUITMENT_APPLICATION_BOARD_EDUCATION &&
          room.recruitmentApplication
        ) {
          return (
            <ChatRoomRecruitmentBoardEducationCard
              key={room.chatRoom.id}
              onClick={() => onChatRoomClick(room.chatRoom.id)}
              isActive={room.chatRoom.id === chatRoomId}
              isUnAnswered={
                room.chatRoom.lastMessageCreatedBy != undefined &&
                room.chatRoom.lastMessageCreatedBy !== props.participantId
              }
              recruitmentTitle={
                room.recruitmentApplication.recruitment.status === "DELETED"
                  ? `[削除済み] ${room.recruitmentApplication.recruitment.title}`
                  : `${room.recruitmentApplication.recruitment.title}`
              }
              recruitmentApplicationStatus={room.recruitmentApplication.status}
              userName={room.recruitmentApplication.user.name}
              userIcon={room.recruitmentApplication.user.picture}
              createdAt={formatDateTime(room.chatRoom.createdAt)}
            />
          );
        }
        return <></>;
      })}
    </List>
  );
};

const List = styled.div`
  height: calc(100% - 121px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  padding: 0 24px;
`;
