import {
  SignatureTemplateUpsertRequest,
  SignatureTemplateRequest,
  SignatureTemplate,
} from "@shared/types/signatureTemplate";
import * as client from "./client";

export async function uploadPdf({
  pdfFile,
}: {
  pdfFile: File;
}): Promise<string> {
  const res = await client.postWithFormData<
    { pdfFile: File },
    { pdfFilePath: string }
  >("/signature/uploadPdf", { pdfFile });
  return res.pdfFilePath;
}

export async function getSignatureTemplate({
  type,
}: SignatureTemplateRequest): Promise<SignatureTemplate> {
  const res = await client.get<
    { type: "PTA" | "RESIDENT" },
    { signatureTemplate: SignatureTemplate }
  >("/signature/getSignatureTemplate", {
    type,
  });
  return res.signatureTemplate;
}

export async function getSignatureTemplateList({
  type,
}: SignatureTemplateRequest): Promise<SignatureTemplate[]> {
  const res = await client.get<
    { type: "PTA" | "RESIDENT" },
    { signatureTemplates: SignatureTemplate[] }
  >("/signature/getSignatureTemplateList", {
    type,
  });
  return res.signatureTemplates;
}

export async function createSignatureTemplate({
  type,
  pdfFilePath,
  comment,
}: SignatureTemplateUpsertRequest): Promise<SignatureTemplate> {
  const res = await client.post<
    SignatureTemplateUpsertRequest,
    { signatureTemplate: SignatureTemplate }
  >("/signature/createSignatureTemplate", {
    type,
    pdfFilePath,
    comment,
  });
  return res.signatureTemplate;
}

export async function createOrUpdateSignatureTemplate({
  type,
  pdfFilePath,
}: SignatureTemplateUpsertRequest): Promise<SignatureTemplate> {
  const res = await client.post<
    SignatureTemplateUpsertRequest,
    { signatureTemplate: SignatureTemplate }
  >("/signature/createOrUpdateSignatureTemplate", {
    type,
    pdfFilePath,
  });
  return res.signatureTemplate;
}
