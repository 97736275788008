import { z } from "zod";
import {
  zRequiredString,
  zHiraganaString,
  zPhoneNumberString,
} from "@shared/validator/rules/string";
import { transformToBoolean } from "@shared/validator/utils/transform";

/**
 * ホーム/プロフィール
 */
export const PTAFormValueSchema = z.object({
  baseInfo: z.object({
    lastName: zRequiredString,
    lastNameKana: zHiraganaString,
    firstName: zRequiredString,
    firstNameKana: zHiraganaString,
    belongId: z.string().optional().nullable(),
    partId: z.string().optional().nullable(),
    phoneNumber: zPhoneNumberString,
    schoolGroup: z.string().optional().nullable(),
    isPreschooler: z.number().optional().nullable(), // memberPTAFormValueSchemaにはない
    selfFreeMemo: z.string().optional().nullable(),
    adminFreeMemo: z.string().optional().nullable(),
  }),
});

/**
 * 名簿/プロフィール
 * 管理人&&保護者用
 */
export const parentPTAAdminFormValueSchema = z.object({
  baseInfo: z.object({
    userId: z.string(),
    lastName: zRequiredString,
    lastNameKana: zHiraganaString,
    firstName: zRequiredString,
    firstNameKana: zHiraganaString,
    belongId: z.string().optional().nullable(),
    partId: z.string().optional().nullable(),
    phoneNumber: zPhoneNumberString,
    schoolGroup: z.string().optional().nullable(),
    selfFreeMemo: z.string().optional().nullable(),
    adminFreeMemo: z.string().optional().nullable(),
  }),
});

/**
 * 名簿/プロフィール
 * !管理人&&保護者用
 */
export const parentPTAFormValueSchema = z.object({
  baseInfo: z.object({
    userId: z.string(),
    lastName: zRequiredString,
    lastNameKana: zHiraganaString,
    firstName: zRequiredString,
    firstNameKana: zHiraganaString,
    belongId: z.string().optional().nullable(),
    partId: z.string().optional().nullable(),
    schoolGroup: z.string().optional().nullable(),
    selfFreeMemo: z.string().optional().nullable(),
    adminFreeMemo: z.string().optional().nullable(),
  }),
});

/**
 * 名簿/プロフィール
 * 管理人&&教員用
 */
export const teacherPTAAdminFormValueSchema = z.object({
  baseInfo: z.object({
    userId: z.string(),
    lastName: zRequiredString,
    lastNameKana: zHiraganaString,
    firstName: zRequiredString,
    firstNameKana: zHiraganaString,
    belongId: z.string().optional().nullable(),
    partId: z.string().optional().nullable(),
    phoneNumber: zPhoneNumberString,
    schoolGroup: z.string().optional().nullable(),
    selfFreeMemo: z.string().optional().nullable(),
    adminFreeMemo: z.string().optional().nullable(),
  }),
  teacherInfo: z
    .object({
      hasHomeroom: z
        .union([z.string(), z.boolean()])
        .transform(transformToBoolean),
      grade: z.number().optional(),
      class: z.number().optional(),
    })
    .refine(
      (data) => {
        if (data.hasHomeroom) {
          return !!data.grade;
        }
        return true;
      },
      {
        message: "学年・クラスを選択してください",
        path: ["teacherInfo", "grade"],
      }
    )
    .refine(
      (data) => {
        if (data.hasHomeroom) {
          return !!data.class;
        }
        return true;
      },
      {
        message: "学年・クラスを選択してください",
        path: ["teacherInfo", "class"],
      }
    ),
});

/**
 * 名簿/プロフィール
 * !管理人&&教員用
 */
export const teacherPTAFormValueSchema = z.object({
  baseInfo: z.object({
    userId: z.string(),
    lastName: zRequiredString,
    lastNameKana: zHiraganaString,
    firstName: zRequiredString,
    firstNameKana: zHiraganaString,
    belongId: z.string().optional().nullable(),
    partId: z.string().optional().nullable(),
    schoolGroup: z.string().optional().nullable(),
    selfFreeMemo: z.string().optional().nullable(),
    adminFreeMemo: z.string().optional().nullable(),
  }),
  teacherInfo: z
    .object({
      hasHomeroom: z
        .union([z.string(), z.boolean()])
        .transform(transformToBoolean),
      grade: z.number().optional(),
      class: z.number().optional(),
    })
    .refine(
      (data) => {
        if (data.hasHomeroom) {
          return !!data.grade;
        }
        return true;
      },
      {
        message: "学年・クラスを選択してください",
        path: ["teacherInfo", "grade"],
      }
    )
    .refine(
      (data) => {
        if (data.hasHomeroom) {
          return !!data.class;
        }
        return true;
      },
      {
        message: "学年・クラスを選択してください",
        path: ["teacherInfo", "class"],
      }
    ),
});
