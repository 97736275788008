import React, { useState } from "react";
import { useToast } from "src/components/Toast";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "src/components/Modal";
import { ModalWrapper, ModalHeader } from "src/pages/pta/admin/ProfilePage";
import { Margin } from "src/components/Margin";
import { deleteLineAccount } from "src/apiClients/lineAccount";
import { useInvalidateLineAccount } from "src/hooks/query/lineAccount";

interface Props {
  isOpen: boolean;
  close: () => void;
}

export const UnlinkLineModal: React.FC<Props> = ({ isOpen, close }) => {
  const toast = useToast();
  const { invalidateGetLineAccount } = useInvalidateLineAccount();

  const [isProcessing, setIsProcessing] = useState(false);

  const handleRemove = async () => {
    if (isProcessing) return;

    try {
      setIsProcessing(true);

      await deleteLineAccount();
      invalidateGetLineAccount();
      toast.success("LINEアカウントの連携を解除しました");
      close();
    } catch (err) {
      toast.error("LINEアカウントの連携解除に失敗しました");
      console.error(err);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      {isOpen && (
        <ModalPortal onClose={() => close()}>
          <ModalWrapper>
            <ModalHeader>LINEアカウントの連携解除</ModalHeader>
            <ModalBody>
              <Margin marginTop={20} />
              <div>
                <p>LINEアカウントの連携を解除しますか？</p>
              </div>
              <Margin marginTop={40} />
              <ModalSubmitButtons
                disabled={isProcessing}
                submitText="連携解除"
                onSubmit={handleRemove}
                onCancel={close}
              />
            </ModalBody>
          </ModalWrapper>
        </ModalPortal>
      )}
    </>
  );
};
