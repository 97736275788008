import {
  GetLineInsightArgs,
  LineCustomAggregationUnit,
  LineInsightWithAmountOfLineMessages,
} from "@shared/types/lineCustomAggregation";
import { get, post } from "./client";

export async function getOrCreateCustomAggregationUnit(
  organizationId: string
): Promise<LineCustomAggregationUnit | undefined> {
  return await get<{}, LineCustomAggregationUnit | undefined>(
    "/lineCustomAggregation/getOrCreateCustomAggregationUnit",
    { organizationId }
  );
}

export async function getLineInsightWithAmountOfLineMessages(
  customAggregationUnit: LineCustomAggregationUnit,
  from: string,
  to: string
): Promise<LineInsightWithAmountOfLineMessages | undefined> {
  return await post<
    GetLineInsightArgs,
    LineInsightWithAmountOfLineMessages | undefined
  >("/lineCustomAggregation/getLineInsightWithAmountOfLineMessages", {
    customAggregationUnit,
    from,
    to,
  });
}
