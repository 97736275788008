import React, { useCallback, useState } from "react";
import styled from "styled-components";
import {
  PCBaseInfoWrapper,
  StyledTr,
  StyledTh,
  StyledTd,
  EditLinkContainer,
  EditBaseInfoIcon,
  StyledFlexContainer,
  ChildFormLabel,
  ChildFormContainer,
} from "../../../pages/pta/admin/ProfilePage";
import { PencilIcon } from "../../../components/icons/PencilIcon";
import { useGetChildren } from "../../../hooks/api/child";
import ChildInfoEditModal from "./ChildInfoEditModal";
import { getClassName } from "../../../utils/getChildClass";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import { User } from "src/apiClients/users";
import { BREAKPOINTS } from "src/components/Responsive";

type Props = {
  user: User;
  isAdminOrCurrentUser: boolean;
};

const ChildInfoContent = ({ user, isAdminOrCurrentUser }: Props) => {
  const { organization } = useGetCurrentOrganization();
  const { children, getChildren, isLoading } = useGetChildren(user.id);
  const [isOpen, setIsOpen] = useState(false);

  const onClose = useCallback(() => {
    setIsOpen(false);
    getChildren();
  }, [setIsOpen]);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  return (
    <>
      <PCBaseInfoWrapper>
        <StyledFlexContainer>
          {isLoading ? (
            <div>読み込み中...</div>
          ) : (
            <>
              <ChildFormContainer>
                <StyledContainerPc>
                  {children.map((child, index) => (
                    <ChildrenWrapper key={index} isFirstChild={index === 0}>
                      <ChildFormLabel>
                        お子さま ({index + 1}人目)
                      </ChildFormLabel>

                      <ChildInfoTable>
                        <StyledTr>
                          <StyledTh>お名前</StyledTh>
                          <StyledTd>
                            {child?.childLastName} {child?.childFirstName}
                          </StyledTd>
                        </StyledTr>
                        <StyledTr>
                          <StyledTh>学年</StyledTh>
                          <StyledTd>
                            {getGradeLabel(
                              child?.grade,
                              organization?.schoolDisplayType,
                              true,
                              false,
                              "short"
                            )}
                          </StyledTd>
                        </StyledTr>
                        <StyledTr>
                          <StyledTh>クラス</StyledTh>
                          <StyledTd>{getClassName(child.class)}</StyledTd>
                        </StyledTr>
                      </ChildInfoTable>
                    </ChildrenWrapper>
                  ))}
                </StyledContainerPc>
              </ChildFormContainer>

              <ChildFormContainer>
                {children.map((child, index) => (
                  <StyledContainerSp key={index}>
                    <ChildFormLabel>お子さま ({index + 1}人目)</ChildFormLabel>

                    <StyledTableSp>
                      <div>
                        <StyledTHSp>お名前</StyledTHSp>
                        <StyledTdSp>
                          {child?.childLastName} {child?.childFirstName}
                        </StyledTdSp>
                      </div>
                      <div>
                        <StyledTHSp>学年</StyledTHSp>
                        <StyledTdSp>
                          {getGradeLabel(
                            child?.grade,
                            organization?.schoolDisplayType,
                            true,
                            false,
                            "short"
                          )}
                        </StyledTdSp>
                      </div>
                      <div>
                        <StyledTHSp>クラス</StyledTHSp>
                        <StyledTdSp>{getClassName(child.class)}</StyledTdSp>
                      </div>
                    </StyledTableSp>
                  </StyledContainerSp>
                ))}
              </ChildFormContainer>
              {isAdminOrCurrentUser && (
                <EditLinkContainer onClick={onOpen}>
                  <PencilIcon />
                  <EditBaseInfoIcon>編集</EditBaseInfoIcon>
                </EditLinkContainer>
              )}
            </>
          )}
        </StyledFlexContainer>
      </PCBaseInfoWrapper>

      <ChildInfoEditModal
        isOpen={isOpen && isAdminOrCurrentUser}
        userChildren={children}
        close={onClose}
        user={user}
      />
    </>
  );
};

const ChildrenWrapper = styled.div<{ isFirstChild: boolean }>`
  margin-top: ${(props) => (props.isFirstChild ? 0 : "10px")};
`;

const ChildInfoTable = styled.table`
  margin-top: 0;
`;

const StyledContainerPc = styled.div`
  @media (max-width: ${BREAKPOINTS.SP}) {
    display: none;
  }
`;

const StyledContainerSp = styled.div`
  @media (min-width: ${BREAKPOINTS.SP}) {
    display: none;
  }

  &:not(:last-child) {
    margin-bottom: 14px;
  }
`;

const StyledTHSp = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

const StyledTdSp = styled.p`
  font-size: 12px;
`;

const StyledTableSp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
`;

export default ChildInfoContent;
