import React from "react";
import { ChevronIcon } from "../../../../components/icons/SvgIcons";
import styled from "styled-components";
import { getStaticImageUrl } from "../../../../utils/getStaticImageUrl";
import { Avatar } from "../../../../components/Common/Avatar";
import { ChatRoom as ChatRoomType } from "@shared/types/chatRoom";
import { formatDateTime } from "src/features/common/ChatMessage/utils/formatRecruitmentSchedule";
import { useGetCommunityById } from "src/hooks/query/communityById";

type Props = {
  onClick: () => void;
  recruitmentTitle: string;
  recruitmentApplicationStatus: string;
  userName: string;
  chatRoom: ChatRoomType;
  userIcon: string | null;
  isActive: boolean;
  isUnAnswered: boolean;
  isAdmin: boolean;
};

const statusColorMap: { [key: string]: string } = {
  APPLIED: "#FF7E62",
  APPROVED: "#79AAD9",
  REJECTED: "#EE789D",
  DECLINED: "#6DCCB1",
};

const statusTextMap: { [key: string]: string } = {
  APPLIED: "応募",
  APPROVED: "参加決定",
  REJECTED: "見送り",
  DECLINED: "不参加",
};

const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

export const ChatRoomRecruitmentCard = (props: Props) => {
  const createdAt = formatDateTime(props.chatRoom.createdAt);
  const { community } = useGetCommunityById(props.chatRoom.communityId ?? "");
  const communityName = community?.name;
  return (
    <ChatRoom isActive={props.isActive} onClick={props.onClick}>
      <Avatar
        src={props.userIcon ?? defaultUserImg}
        size={80}
        alt={defaultUserImg}
      />
      <RecruitmentInfo>
        <Badges>
          <StatusBadge
            color={statusColorMap[props.recruitmentApplicationStatus]}
          >
            {statusTextMap[props.recruitmentApplicationStatus]}{" "}
            <ChevronIcon size="16" color="#fff" />
          </StatusBadge>
          {props.isUnAnswered && <UnreadBadge />}
        </Badges>
        <RTitle>{props.recruitmentTitle}</RTitle>
        {props.isAdmin ? (
          <ChatRoomSubLabel>
            <UserName>{props.userName}</UserName>
          </ChatRoomSubLabel>
        ) : (
          <ChatRoomSubLabel>
            <UserName>{communityName}</UserName>
          </ChatRoomSubLabel>
        )}
        <ChatRoomSubLabel>
          <ChatRoomDate>{createdAt}</ChatRoomDate>
        </ChatRoomSubLabel>
      </RecruitmentInfo>
    </ChatRoom>
  );
};

const ChatRoom = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  display: flex;
  padding: 16px;
  border-radius: 16px;
  background: ${(props) => (props.isActive ? "#F5F7FA" : "#fff")};
  &:hover {
    background: #f5f7fa;
  }
`;

const ChatRoomSubLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

const RecruitmentInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 70%;
  margin-left: 16px;
`;

const UserName = styled.p`
  color: #868686;
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.05em;
  flex-shrink: 0;
`;

const ChatRoomDate = styled.p`
  color: #69707d;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
`;

const Badges = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StatusBadge = styled.span<{ color: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${(props) => props.color};
  color: white;
  padding: 4px 8px;
  border-radius: 20px;
  font-size: 0.7em;
  height: 20px;
  padding: 2px, 8px;
  border-radius: 4px;
  gap: 4px;
`;

const UnreadBadge = styled.span`
  margin-left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: #0077cc;
`;

const RTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  margin-top: 12px;
`;
