import { LineCustomAggregationUnit } from "@shared/types/lineCustomAggregation";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import {
  getLineInsightWithAmountOfLineMessages,
  getOrCreateCustomAggregationUnit,
} from "src/apiClients/lineCustomAggregation";
import { QueryOptions } from "./QueryOptions";

export const useGetOrCreateCustomAggregationUnit = (
  organizationId: string,
  queryOptions: QueryOptions = {}
) => {
  const query = useQuery({
    queryKey: [
      "api",
      "lineCustomAggregation",
      "getOrCreateCustomAggregationUnit",
      organizationId,
    ],
    queryFn: async () => {
      return await getOrCreateCustomAggregationUnit(organizationId);
    },
    ...queryOptions,
  });

  return {
    customAggregationUnit: useMemo(() => query.data ?? undefined, [query.data]),
    ...query,
  };
};

export const useGetLineInsightWithAmountOfLineMessages = (
  customAggregationUnit: LineCustomAggregationUnit,
  from: string,
  to: string,
  queryOptions: QueryOptions = {}
) => {
  const query = useQuery({
    queryKey: [
      "api",
      "lineCustomAggregation",
      "getLineInsightWithAmountOfLineMessages",
    ],
    queryFn: async () => {
      return await getLineInsightWithAmountOfLineMessages(
        customAggregationUnit,
        from,
        to
      );
    },
    ...queryOptions,
  });
  return {
    lineInsightWithAmountOfLineMessages: useMemo(
      () => query.data ?? undefined,
      [query.data]
    ),
    ...query,
  };
};
