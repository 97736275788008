import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { usePolyfitHistory } from "src/hooks/router";
import { Avatar } from "src/components/Common/Avatar";
import { getStaticImageUrl } from "src/utils/getStaticImageUrl";
import { toDisplaySlashDateFormat } from "src/utils/time";
import { recruitmentIsOpened } from "src/apiClients/recruitment";
import { Role } from "@shared/types/role";
import { beRoleOptions } from "src/utils/types/role";
import { BERecruitment } from "@shared/types/boardEducation/recruitment";
import { AppliedCountModal } from "./AppliedCountModal";
import { ApprovedCountModal } from "./ApprovedCountModal";
import CloseRecruitmentModal from "./CloseRecruitmentModal";
import DeleteOpenRecruitmentModal from "./DeleteOpenRecruitmentModal";
import { useToast } from "src/components/Toast";
import {
  closeBERecruitment,
  deleteBEOpenRecruitment,
} from "src/apiClients/boardEducation/recruitment";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { Button } from "src/components/Button";

// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

interface RecruitmentListCardColumnProps {
  recruitment: BERecruitment;
  refetch: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<BERecruitment[], Error>>;
}

export const RecruitmentListCardColumn = ({
  recruitment,
  refetch,
}: RecruitmentListCardColumnProps) => {
  if (!recruitment.account) return null;
  const history = usePolyfitHistory();
  const toast = useToast();
  const [isCloseRecruitment, setIsCloseRecruitment] = useState<boolean>(false);
  const [isDeleteRecruitment, setIsDeleteRecruitment] =
    useState<boolean>(false);
  const [isAppliedCountOpenModal, setIsAppliedCountOpenModal] =
    useState<boolean>(false);
  const [isApprovedCountOpenModal, setIsApprovedCountOpenModal] =
    useState<boolean>(false);
  const [doubleClickBlocked, setDoubleClickBlocked] = useState<boolean>(false);

  const onDetailRecruitment = () => {
    history.push({
      to: "BE_ADMIN_RECRUITMENT_DETAIL",
      query: { id: recruitment.id },
    });
  };

  const onSubmitCloseRecruitment = useCallback(async () => {
    if (doubleClickBlocked) return;
    setDoubleClickBlocked(true);
    try {
      await closeBERecruitment(recruitment.id);
      await refetch();
      toast.success("募集の掲載を終了しました");
    } catch (e) {
      toast.error("募集の掲載の終了に失敗しました");
    } finally {
      setIsCloseRecruitment(false);
      setDoubleClickBlocked(false);
    }
  }, [recruitment.id, refetch, doubleClickBlocked]);

  const onSubmitDeleteRecruitment = async () => {
    if (doubleClickBlocked) return;
    setDoubleClickBlocked(true);
    try {
      await deleteBEOpenRecruitment(recruitment.id);
      await refetch();
      toast.success("募集を削除しました");
      history.push({
        to: "BE_ADMIN_RECRUITMENT_LIST",
      });
    } catch (e) {
      toast.error("募集の削除に失敗しました");
    } finally {
      setIsDeleteRecruitment(false);
      setDoubleClickBlocked(false);
    }
  };

  return (
    <RecruitmentMobileCard onClick={onDetailRecruitment}>
      <RecruitmentMobileCardHeader>
        <RecruitmentTitle>{recruitment.title}</RecruitmentTitle>
        <IsRecruitmentOpen isOpen={recruitmentIsOpened(recruitment)}>
          {recruitmentIsOpened(recruitment) ? "募集中" : "募集終了"}
        </IsRecruitmentOpen>
      </RecruitmentMobileCardHeader>
      <RecruitmentMobileCardSection>
        <RecruitmentMobileCardLabel>日付</RecruitmentMobileCardLabel>
        {toDisplaySlashDateFormat(recruitment.schedule[0].start)}
      </RecruitmentMobileCardSection>
      <RecruitmentMobileCardSection>
        <RecruitmentMobileCardLabel>学校</RecruitmentMobileCardLabel>
        {recruitment.community?.name || recruitment.managedCSOrganizationName}
      </RecruitmentMobileCardSection>
      <RecruitmentMobileCardSection>
        <RecruitmentMobileCardLabel>募集作成者</RecruitmentMobileCardLabel>
        <UserInfo>
          <Avatar
            src={recruitment.account.user.picture || defaultUserImg}
            alt={recruitment.account.user.name}
            size={40}
          />
          <UserNameRole>
            <UserName>{recruitment.account.user.name}</UserName>
            <div>
              <UserRole role={recruitment.account.role.name as Role}>
                {
                  beRoleOptions.find(
                    (role) => role.value === recruitment.account?.role.name
                  )?.text
                }
              </UserRole>
            </div>
          </UserNameRole>
        </UserInfo>
      </RecruitmentMobileCardSection>
      <RecruitmentMobileCardSection>
        <RecruitmentMobileCardLabel>募集人数</RecruitmentMobileCardLabel>
        {recruitment.volunteerCount === -1
          ? "無制限"
          : recruitment.volunteerCount}
      </RecruitmentMobileCardSection>
      <RecruitmentMobileCardSection>
        <RecruitmentMobileCardLabel>応募数</RecruitmentMobileCardLabel>
        <ApplicantInfoWrapper>
          <ApplicantCount>{recruitment.recruitmentAppliedCount}</ApplicantCount>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setIsAppliedCountOpenModal(true);
            }}
            disabled={recruitment.recruitmentAppliedCount === 0}
            color="primary"
            fill
            size="small"
          >
            応募者を確認
          </Button>
        </ApplicantInfoWrapper>
      </RecruitmentMobileCardSection>
      <RecruitmentMobileCardSection>
        <RecruitmentMobileCardLabel>確定数</RecruitmentMobileCardLabel>
        <ApplicantInfoWrapper>
          <ApplicantCount>
            {recruitment.recruitmentApprovedCount}
          </ApplicantCount>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setIsApprovedCountOpenModal(true);
            }}
            disabled={recruitment.recruitmentApprovedCount === 0}
            color="primary"
            fill
          >
            参加確定者を確認
          </Button>
        </ApplicantInfoWrapper>
      </RecruitmentMobileCardSection>
      {isAppliedCountOpenModal && (
        <AppliedCountModal
          onClose={() => setIsAppliedCountOpenModal(false)}
          recruitmentId={recruitment.id}
        />
      )}
      {isApprovedCountOpenModal && (
        <ApprovedCountModal
          onClose={() => setIsApprovedCountOpenModal(false)}
          recruitmentId={recruitment.id}
        />
      )}
      {isCloseRecruitment && (
        <CloseRecruitmentModal
          title={recruitment.title}
          onSubmit={onSubmitCloseRecruitment}
          onClose={() => setIsCloseRecruitment(false)}
        />
      )}
      {isDeleteRecruitment && (
        <DeleteOpenRecruitmentModal
          title={recruitment.title}
          onSubmit={onSubmitDeleteRecruitment}
          onClose={() => setIsDeleteRecruitment(false)}
          disabled={doubleClickBlocked}
        />
      )}
    </RecruitmentMobileCard>
  );
};

const RecruitmentMobileCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: #fff;
  margin-bottom: 24px;
  border-radius: 8px;
  border: 1px solid rgba(228, 230, 243, 1);
  cursor: pointer;
  &:hover {
    background-color: #f3f4f8;
  }
`;

const RecruitmentMobileCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
`;

const RecruitmentTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

const IsRecruitmentOpen = styled.div<{ isOpen: boolean }>`
  color: #fff;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 12px;
  background-color: ${(props) => (props.isOpen ? "#6DCCB1" : "#C4C4C4")};
  flex-shrink: 0;
`;

const RecruitmentMobileCardSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const RecruitmentMobileCardLabel = styled.div`
  color: #69707d;
  font-size: 12px;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const UserNameRole = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserName = styled.div`
  font-size: 14px;
`;

const UserRole = styled.div<{ role: Role }>`
  flex-shrink: 1;
  padding: 0 6px;
  font-size: 12px;
  color: #fff;
  background-color: ${(props) => {
    switch (props.role) {
      case Role.CSAdmin:
        return "#79aad9";
      case Role.CSOperator:
        return "#EE789D";
      case Role.CSMember:
        return "#6DCCB1";
      case Role.BEAdmin:
        return "#8858FF";
      default:
        return "#C4C4C4";
    }
  }};
  padding: 2px 6px;
  border-radius: 10px;
  display: inline-block;
`;

// 新しく追加するスタイルコンポーネント
const ApplicantInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ApplicantCount = styled.span`
  font-size: 16px;
  font-weight: bold;
`;
