import { Belong } from "./belong";
import { Part } from "./part";
import { SignatureTemplate } from "./signatureTemplate";
import { CommunityWithSchoolCode } from "./community";

export const ServiceType = {
  PTA: "PTA",
  CS: "CS",
} as const;

export const schoolType = {
  B: "小学校 (1-6年生)",
  C: "中学校 (7-9年生)",
  D: "高等学校 (10-12年生)",
  BC: "小中一貫校 (1-9年生)",
  CD: "中高一貫校 (7-12年生)",
  BCD: "小中高一貫校 (1-12年生)",
} as const;

/**
 * 学校種別
 * - B: 小学校
 * - C: 中学校
 * - D: 高校
 * - BC: 小中学
 * - CD: 中学高校
 * - BCD: 小中高
 */
export type SchoolType = keyof typeof schoolType;

export const schoolDisplayType = {
  CONTINUOUS: "連番表記",
  SEGMENTED: "学校種別表記",
} as const;

/**
 * 表示モード
 * - CONTINUOUS: 連番表記 [学年を1から連続した数字で表示 (例: 1, 2, 3, ..., 9)]
 * - SEGMENTED: 学校種別表記 [学校段階ごとに区切って表示 (例: 小1, 小2, ..., 中1, 中2, 中3)]
 */
export type SchoolDisplayType = keyof typeof schoolDisplayType;

export type ServiceType = (typeof ServiceType)[keyof typeof ServiceType];

export type Organization = {
  id: string;
  name: string;
  serviceType: ServiceType;
  nameListPublished: boolean;
  requireAdminToUpdateCareer: boolean;
  postSchoolYearsFilter: string[];
  lineOption: boolean;
  schoolType: SchoolType;
  schoolDisplayType: SchoolDisplayType;
  belongs: Belong[];
  parts: Part[];
  communities: CommunityWithSchoolCode[];
  createdAt: string;
  updatedAt: string;
};

type BoardEducation = {
  id: string;
  organizationId: string;
  managedCSOrganizationId?: string | null;
  createdAt?: string;
  updatedAt?: string;
};

export type InternalOrganization = Organization & {
  managingBoardEducation?: BoardEducation;
};

export type CommunityWithSignatureTemplate = CommunityWithSchoolCode & {
  signatureTemplates: SignatureTemplate[];
};

export type OrganizationWithSignatureTemplates = Omit<
  Organization,
  "communities"
> & {
  communities: CommunityWithSignatureTemplate[];
};

export type CreateBEOrganizationArgs = {
  name: string;
  csOrganizationId: string;
};

export type CreateBeOrganizationResponse = {
  organization: Organization;
  boardEducation: BoardEducation;
};

export type UpdateOrganizationArg = {
  id: string;
  communityIds?: string[];
  name?: string;
  nameListPublished?: boolean;
  requireAdminToUpdateCareer?: boolean;
  postSchoolYearsFilter?: string[];
  schoolType?: SchoolType;
  schoolDisplayType?: SchoolDisplayType;
  lineOption?: boolean;
};
