import React, { useMemo, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import {
  ModalWrapper,
  ModalHeader,
  CheckTabs,
  TextField,
  SelectField,
  ErrorMessage,
} from "../../../pages/pta/admin/ProfilePage";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "../../../components/Modal";
import {
  TrashButton,
  TrashIcon,
  AddChildForm,
} from "../../../pages/registration/pta/RegisterChildInfoPage";
import { Margin } from "../../../components/Margin";
import { useToast } from "../../../components/Toast";
import { classOption } from "../../../utils/getChildClass";
import { useBaseInfo } from "../../../hooks/api/baseInfo";
import { CSChild, RequestUpdateCSChildArgs } from "@shared/types/csChild";
import { deleteChildren, updateChildren } from "src/apiClients/csChild";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { Label } from "src/components/form/Label";
import styled from "styled-components";
import { useOrganizationCommunity } from "src/hooks/recoil/organization";

type FormValues = {
  children: RequestUpdateCSChildArgs[];
};

export const CsChildInfoEditModal = ({
  isOpen,
  userChildren,
  refetch,
  close,
}: {
  isOpen: boolean;
  userChildren: CSChild[];
  refetch: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<CSChild[], Error>>;
  close: () => void;
}) => {
  const { currentBaseInfo } = useBaseInfo();
  const toast = useToast();
  const { communities } = useOrganizationCommunity();

  const convertedArgs: RequestUpdateCSChildArgs[] = useMemo(() => {
    return userChildren.map((child) => ({
      id: child.id,
      accountId: child.accountId,
      childLastName: child.childLastName,
      childFirstName: child.childFirstName,
      childLastNameKana: child.childLastNameKana,
      childFirstNameKana: child.childFirstNameKana,
      communityId: child.communityId,
      grade: child.grade,
      class: child.class,
    }));
  }, [userChildren]);

  const defaultValues = useMemo(() => {
    return {
      children: userChildren.length
        ? convertedArgs
        : [
            {
              childLastName: currentBaseInfo.lastName,
              childFirstName: "",
              childLastNameKana: currentBaseInfo.lastNameKana,
              childFirstNameKana: "",
              communityId: null,
              grade: null,
              class: null,
            },
          ],
    };
  }, [userChildren, currentBaseInfo]);

  const {
    register,
    control,
    reset,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues,
    mode: "onBlur",
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const { fields, append, remove } = useFieldArray({
    name: "children",
    control,
  });

  const newChildInfo = useMemo(() => {
    return {
      childLastName: currentBaseInfo.lastName,
      childFirstName: "",
      childLastNameKana: currentBaseInfo.lastNameKana,
      childFirstNameKana: "",
      communityId: null,
      grade: null,
      class: null,
    };
  }, [currentBaseInfo]);

  // 元の子供情報がない状態で子供削除の処理はできない
  const onSubmitChildInfo = async (): Promise<void> => {
    try {
      if (fields.length === 0) {
        await deleteChildren();
      } else {
        const children = getValues("children");
        const args: RequestUpdateCSChildArgs[] = children.map((child) => {
          const arg: RequestUpdateCSChildArgs = {
            childFirstName: child.childFirstName,
            childFirstNameKana: child.childFirstNameKana,
            childLastName: child.childLastName,
            childLastNameKana: child.childLastNameKana,
            communityId: child.communityId,
            grade: child.grade,
            class: child.class,
          };
          if (child.id) {
            arg.id = child.id;
          }
          if (child.accountId) {
            arg.accountId = child.accountId;
          }
          return arg;
        });
        await updateChildren(args);
      }
      await refetch();
      close();
      toast.success("保存しました");
    } catch (err) {
      console.error(err);
    }
  };

  const gradeOption = [
    { value: "1", text: "1" },
    { value: "2", text: "2" },
    { value: "3", text: "3" },
    { value: "4", text: "4" },
    { value: "5", text: "5" },
    { value: "6", text: "6" },
  ];

  return (
    <>
      {isOpen && (
        <ModalPortal onClose={close} modalBoxIsHeightFull={true}>
          <ModalWrapper>
            <ModalHeader>お子さま情報</ModalHeader>
            {fields.length === 0 && <div>お子さま情報を全て削除します</div>}
            <ModalBody>
              <FieldWrapper>
                {fields.map((_field, index) => (
                  <div key={index}>
                    <StyledFlexSpaceBetween>
                      <LabelWrapper>
                        <EditModalFormLabel>
                          お子さまのお名前({index + 1}人目)
                        </EditModalFormLabel>
                        <Label required />
                      </LabelWrapper>

                      {!(fields.length === 1 && userChildren.length === 0) && (
                        <TrashButton
                          style={{ marginTop: "12px", marginRight: "5px" }}
                          type="button"
                          onClick={() => remove(index)}
                        >
                          <TrashIcon />
                        </TrashButton>
                      )}
                    </StyledFlexSpaceBetween>

                    <CheckTabs>
                      <div>
                        <TextField
                          placeholder="例) 山田"
                          {...register(
                            `children.${index}.childLastName` as const,
                            {
                              required: "入力必須項目です",
                            }
                          )}
                        />
                        {errors?.children &&
                          errors.children[index]?.childLastName && (
                            <ErrorMessage>
                              {
                                errors?.children?.[index]?.childLastName
                                  ?.message
                              }
                            </ErrorMessage>
                          )}
                      </div>
                      <div>
                        <TextField
                          placeholder="例) 太郎"
                          {...register(
                            `children.${index}.childFirstName` as const,
                            {
                              required: "入力必須項目です",
                            }
                          )}
                        />
                        {errors?.children &&
                          errors.children[index]?.childFirstName && (
                            <ErrorMessage>
                              {
                                errors?.children?.[index]?.childFirstName
                                  ?.message
                              }
                            </ErrorMessage>
                          )}
                      </div>
                    </CheckTabs>
                    <LabelWrapper>
                      <EditModalFormLabel>ふりがな</EditModalFormLabel>
                      <Label required />
                    </LabelWrapper>
                    <CheckTabs>
                      <div>
                        <TextField
                          placeholder="例) やまだ"
                          {...register(
                            `children.${index}.childLastNameKana` as const,
                            {
                              required: "入力必須項目です",
                              pattern: {
                                value: /^[ぁ-んーー]+$/u,
                                message: "ひらがなのみで入力してください",
                              },
                            }
                          )}
                        />
                        {errors?.children &&
                          errors.children[index]?.childLastNameKana && (
                            <ErrorMessage>
                              {
                                errors?.children?.[index]?.childLastNameKana
                                  ?.message
                              }
                            </ErrorMessage>
                          )}
                      </div>
                      <div>
                        <TextField
                          placeholder="例) たろう"
                          {...register(
                            `children.${index}.childFirstNameKana` as const,
                            {
                              required: "入力必須項目です",
                              pattern: {
                                value: /^[ぁ-んーー]+$/u,
                                message: "ひらがなのみで入力してください",
                              },
                            }
                          )}
                        />
                        {errors?.children &&
                          errors.children[index]?.childFirstNameKana && (
                            <ErrorMessage>
                              {
                                errors?.children?.[index]?.childFirstNameKana
                                  ?.message
                              }
                            </ErrorMessage>
                          )}
                      </div>
                    </CheckTabs>
                    <CheckTabs>
                      <div>
                        <LabelWrapper>
                          <EditModalFormLabel>学校</EditModalFormLabel>
                          <Label optional />
                        </LabelWrapper>
                        <SelectWrapper>
                          <SelectField
                            {...register(
                              `children.${index}.communityId` as const
                            )}
                            defaultValue=""
                          >
                            <option value="" disabled>
                              選択してください
                            </option>
                            {communities.map((community) => {
                              return (
                                <option key={community.id} value={community.id}>
                                  {community.name}
                                </option>
                              );
                            })}
                          </SelectField>
                          {errors?.children &&
                            errors.children[index]?.communityId && (
                              <ErrorMessage>
                                {
                                  errors?.children?.[index]?.communityId
                                    ?.message
                                }
                              </ErrorMessage>
                            )}
                        </SelectWrapper>
                      </div>
                    </CheckTabs>
                    <CheckTabs>
                      <div>
                        <LabelWrapper>
                          <EditModalFormLabel>学年</EditModalFormLabel>
                          <Label optional />
                        </LabelWrapper>
                        <SelectField
                          {...register(`children.${index}.grade` as const, {
                            setValueAs: (v) =>
                              v === "" ? null : parseInt(v, 10),
                            validate: (value) => {
                              if (value === null) return true;
                              const communityId = getValues(
                                `children.${index}.communityId`
                              );
                              const selectedCommunity = communities.find(
                                (c) => c.id === communityId
                              );
                              if (
                                selectedCommunity?.name.includes("中学校") &&
                                (value === undefined || value < 1 || value > 3)
                              ) {
                                return "中学校の場合、学年は1から3の間で選択してください。";
                              }
                              return true;
                            },
                          })}
                          defaultValue=""
                        >
                          <option value="" disabled>
                            選択してください
                          </option>
                          {gradeOption.map((opt) => {
                            return (
                              <option key={opt.value} value={opt.value}>
                                {opt.text}
                              </option>
                            );
                          })}
                        </SelectField>
                        {errors?.children && errors.children[index]?.grade && (
                          <ErrorMessage>
                            {errors?.children?.[index]?.grade?.message}
                          </ErrorMessage>
                        )}
                      </div>
                    </CheckTabs>
                    <CheckTabs>
                      <div>
                        <LabelWrapper>
                          <EditModalFormLabel>クラス</EditModalFormLabel>
                          <Label optional />
                        </LabelWrapper>
                        <SelectField
                          {...register(`children.${index}.class` as const, {
                            setValueAs: (v) =>
                              v === "" ? undefined : parseInt(v, 10),
                          })}
                          defaultValue=""
                        >
                          <option value="" disabled>
                            選択してください
                          </option>
                          {classOption.map((opt) => {
                            return (
                              <option key={opt.value} value={opt.value}>
                                {opt.text}
                              </option>
                            );
                          })}
                        </SelectField>
                        {errors?.children && errors.children[index]?.class && (
                          <ErrorMessage>
                            {errors?.children?.[index]?.class?.message}
                          </ErrorMessage>
                        )}
                      </div>
                    </CheckTabs>
                  </div>
                ))}
              </FieldWrapper>

              <Margin marginTop={20} />
              <AddChildForm
                type="button"
                onClick={() => {
                  append(newChildInfo);
                }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: "5px" }}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8 7H11.5C11.7761 7 12 7.22386 12 7.5C12 7.77614 11.7761 8 11.5 8H8V11.5C8 11.7761 7.77614 12 7.5 12C7.22386 12 7 11.7761 7 11.5V8H3.5C3.22386 8 3 7.77614 3 7.5C3 7.22386 3.22386 7 3.5 7H7V3.5C7 3.22386 7.22386 3 7.5 3C7.77614 3 8 3.22386 8 3.5V7ZM7.5 0C11.6356 0 15 3.36441 15 7.5C15 11.6356 11.6356 15 7.5 15C3.36441 15 0 11.6356 0 7.5C0 3.36441 3.36441 0 7.5 0ZM7.5 0.882353C3.84529 0.882353 0.882353 3.84529 0.882353 7.5C0.882353 11.1547 3.84529 14.1176 7.5 14.1176C11.1547 14.1176 14.1176 11.1547 14.1176 7.5C14.1176 3.84529 11.1547 0.882353 7.5 0.882353Z"
                    fill="#5A606B"
                  />
                </svg>
                お子さまを追加
              </AddChildForm>
              <Margin marginTop={25} />
              <ModalSubmitButtons
                submitText="保存"
                onCancel={close}
                onSubmit={() => handleSubmit(onSubmitChildInfo)()}
                disabled={fields.length === 0 && userChildren.length === 0}
              />
            </ModalBody>
          </ModalWrapper>
        </ModalPortal>
      )}
    </>
  );
};

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledFlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 30px;

  @media (max-width: 1025px) {
    gap: 10px;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  margin-top: 14px;
  margin-bottom: 4px;
`;

const EditModalFormLabel = styled.label`
  color: #343741;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
`;

const SelectWrapper = styled.div`
  min-width: 200px;
  @media (max-width: 1279px) {
    min-width: 163px;
  }
`;
