import * as communityApi from "../../apiClients/community";
import { useQuery } from "@tanstack/react-query";
import { QueryOptions } from "./QueryOptions";
import { useMemo } from "react";
import { useApiContext } from "src/apiClients/client";

export function useGetCommunityMemberRoleCounts(
  queryOptions: QueryOptions = {}
) {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: ["api", "community", "getCommunityMemberRoleCounts", apiContext],
    queryFn: async () => {
      return await communityApi.getCommunityMemberRoleCounts();
    },
    ...queryOptions,
  });

  return {
    communitiesMemberRoleCounts: useMemo(() => query.data || [], [query.data]),
    ...query,
  };
}
