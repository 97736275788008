import React, { useCallback, useMemo, useRef, useState } from "react";
import { User, isResidentUser } from "../../../apiClients/users";
import { useCurrentUser } from "../../../hooks/recoil/user";
import {
  BaseInfo,
  CurrentUser,
  updateCommunityRoles,
  updateResidentInfoByCSAdmin,
} from "../../../apiClients/auth";
import {
  useCurrentCommunityId,
  usePolyfitHistory,
} from "../../../hooks/router";
import {
  PaidType,
  VolunteerType,
  paidOption,
  volunteerOption,
} from "../../../@shared/types/volunteerType";
import { residentInfoSchema } from "@shared/validator/models/residentInfo.schema";
import {
  DayOfWeekType,
  dayOfWeekOptions,
} from "../../../utils/types/dayOfWeek";
import {
  MedicalLicenseType,
  SkillType,
  TeachingLicenseType,
  medicalLicenseOptions,
  skillOptions,
  teachingLicenseOptions,
} from "../../../utils/types/license";
import { FormProvider, useForm } from "react-hook-form";
import { toInputDateFormatYearMonth } from "../../../utils/time";
import {
  ModalPortal,
  ModalBody,
  ModalSubmitButtons,
} from "../../../components/Modal";
import { getAddressFromPostalCode } from "../../../apiClients/postcode";
import { Margin } from "../../../components/Margin";
import { ButtonSelect } from "../../../components/ButtonSelect";
import { prefectureOptions } from "../../../utils/types/prefecture";
import { genderOption } from "../../../utils/types/gender";
import { useRegisterInfo } from "../../../hooks/api/baseInfo";
import { ageGroup, zTransformAgeGroup } from "../../../utils/types/ageGroup";
import { Organization } from "../../../apiClients/organization";
import { EditBaseInfoCommunity } from "./components/EditBaseInfoCommunity";
import { useToast } from "src/components/Toast";
import { APIError } from "src/utils/types/ApiError";
import { CommunityRolesFormSchema as CommunityFormValue } from "src/validator/form/member/community/community.schema";
import { UserType } from "../../../../../server/src/@shared/types/userType";
import { zodResolver } from "@hookform/resolvers/zod";
import { CSAdminFormValueSchema } from "src/validator/form/member/CSBaseInfoSchema";
import { useInvalidateGetResidentUsers } from "src/hooks/query/residentUsers";
import { logger } from "src/utils/logger";
import { NameAndImageSection } from "./components/NameAndImageSection";
import {
  ButtonGroupExtend,
  CheckTabs,
  EditModalFormLabel,
  ErrorMessage,
  ModalHeader,
  ModalWrapper,
  SelectField,
  TextField,
} from ".";
import { PhoneNumberSection } from "./components/PhoneNumberSection";
import {
  convertArrayToPreferredPaid,
  convertPreferredPaid,
} from "src/validator/form/residentInfo/residentInfoFormSchema";
import { updateAccountCommunityRolesRequestSchema } from "@shared/validator/features/auth.schema";

type CSEditBaseInfoModalProps = {
  user: User;
  organization: Organization;
  onClose: () => void;
  getUser: () => Promise<void>;
  userId: string;
  cu: CurrentUser;
};

// CSEditBaseInfoModalFormValues の型
export type CSEditBaseInfoModalFormValues = {
  baseInfo: BaseInfo;
  residentInfo: {
    preferredDays: DayOfWeekType[];
    teacherLicenses: TeachingLicenseType[];
    medicalLicenses: MedicalLicenseType[];
    skills: SkillType[];
    preferredPaid?: "EITHER" | PaidType | null;
    preferredVolunteerType: VolunteerType[];
    privateEnterprise: string | null;
    university: string | null;
    externalOrganization: string | null;
    pta: string | null;
  };
  community: CommunityFormValue;
};

export const CSEditBaseInfoModal = ({
  user,
  onClose,
  getUser,
  userId,
}: CSEditBaseInfoModalProps) => {
  const [faceImg, setFaceImg] = useState<File>();
  const currentUser: CurrentUser = useCurrentUser();
  const { communityId: currentCommunityId } = useCurrentCommunityId();
  const { registerInfo, registerFaceImgPath } = useRegisterInfo();
  const history = usePolyfitHistory();
  const toast = useToast();
  const { invalidateGetResidentUsers } = useInvalidateGetResidentUsers();

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const onSubmit = async (data: CSEditBaseInfoModalFormValues) => {
    setDoubleClickBlocked(true);
    try {
      const { baseInfo, residentInfo, community } = data;
      await onSubmitFile();
      await registerInfo(baseInfo);
      if (user.type === "RESIDENT") {
        const convertedPreferredPaid = convertPreferredPaid(
          residentInfo.preferredPaid
        );
        const parsedData = residentInfoSchema.parse({
          ...residentInfo,
          preferredPaid: convertedPreferredPaid,
          privateEnterprise: residentInfo.privateEnterprise || null,
          university: residentInfo.university || null,
          externalOrganization: residentInfo.externalOrganization || null,
          pta: residentInfo.pta || null,
        });

        await updateResidentInfoByCSAdmin(userId, parsedData);
        invalidateGetResidentUsers();
        const data = [];
        data.push(
          ...(community.mainElementarySchool
            ? [
                {
                  communityId: community.mainElementarySchool,
                  isMain: true,
                  type: community.mainElementarySchoolType,
                },
              ]
            : []),
          ...(community.mainJuniorHighSchool
            ? [
                {
                  communityId: community.mainJuniorHighSchool,
                  isMain: true,
                  type: community.mainJuniorHighSchoolType,
                },
              ]
            : []),
          ...community.elementarySchools.map((school) => {
            return {
              communityId: school,
              isMain: false,
              type: UserType.RESIDENT,
            };
          }),
          ...community.juniorHighSchools.map((school) => {
            return {
              communityId: school,
              isMain: false,
              type: UserType.RESIDENT,
            };
          })
        );
        const update = updateAccountCommunityRolesRequestSchema.parse({
          userId,
          data,
        });
        // 更新後のコミュニティに現在選択しているコミュニティが含まれていない場合はLOADING画面に遷移
        if (update.data.every((u) => u.communityId !== currentCommunityId)) {
          history.push({ to: "LOADING", query: {} });
        }
        await updateCommunityRoles(update);
      }
      await getUser();
      history.push({
        to: "PTA_LIST_MEMBER",
        query: {
          userId: user.id,
        },
      });
      setDoubleClickBlocked(false);
      onClose();
    } catch (err) {
      setDoubleClickBlocked(false);
      logger.error(err);
      if (err instanceof APIError) {
        toast.error(err.message);
      }
    }
  };

  const methods = useForm<CSEditBaseInfoModalFormValues>({
    defaultValues: {
      baseInfo: {
        ...user.baseInfo,
        ageGroup: user.baseInfo?.ageGroup ?? -1,
        birthday: user.baseInfo?.birthday
          ? (toInputDateFormatYearMonth(user.baseInfo.birthday) as any)
          : undefined,
      },
      residentInfo:
        isResidentUser(user) && !!user.residentInfo
          ? {
              preferredDays: user.residentInfo.preferredDays,
              teacherLicenses: user.residentInfo.teacherLicenses,
              medicalLicenses: user.residentInfo.medicalLicenses,
              skills: user.residentInfo.skills,
              preferredPaid: convertArrayToPreferredPaid(
                user.residentInfo.preferredPaid
              ),
              preferredVolunteerType: user.residentInfo.preferredVolunteerType,
              privateEnterprise: user.residentInfo.privateEnterprise,
              university: user.residentInfo.university,
              externalOrganization: user.residentInfo.externalOrganization,
              pta: user.residentInfo.pta,
            }
          : {
              preferredDays: [],
              teacherLicenses: [],
              medicalLicenses: [],
              skills: [],
              preferredPaid: null,
              preferredVolunteerType: [],
              privateEnterprise: "",
              university: "",
              externalOrganization: "",
              pta: "",
            },
      community: {
        mainElementarySchool: "",
        mainJuniorHighSchool: "",
        mainElementarySchoolType: undefined,
        mainJuniorHighSchoolType: undefined,
        elementarySchools: [],
        juniorHighSchools: [],
      },
    },
    resolver: zodResolver(CSAdminFormValueSchema),
    mode: "onChange",
  });
  const {
    setValue,
    watch,
    formState,
    clearErrors,
    setError,
    register,
    handleSubmit,
  } = methods;

  const inputFileRef = useRef(null);
  const onClickFileInput = () => {
    const inputFileElement = inputFileRef.current! as HTMLInputElement;
    inputFileElement?.click();
  };

  const onInputChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const input = event.target;
      const files = Array.from(input.files || []);
      const file = files[0];
      if (file) {
        setFaceImg(file);
      }
    },
    []
  );
  const onSubmitFile = async () => {
    if (faceImg) await registerFaceImgPath(faceImg);
  };

  const imgPreviewUrl = useMemo(() => {
    if (faceImg) return URL.createObjectURL(faceImg);
    else return "";
  }, [faceImg]);

  const handleGenderChange = (value: "MALE" | "FEMALE" | "OTHER") => {
    setValue("baseInfo.gender", value);
  };

  const handlePreferredDaysChange = (value: DayOfWeekType) => {
    const list = watch("residentInfo.preferredDays");
    if (list.includes(value)) {
      const newLists = list.filter((v) => v !== value);
      setValue("residentInfo.preferredDays", newLists);
    } else {
      const newLists = [...list, value];
      setValue("residentInfo.preferredDays", newLists);
    }
  };

  const handleTeacherChange = (value: TeachingLicenseType) => {
    const list = watch("residentInfo.teacherLicenses");
    if (list.includes(value)) {
      const newLists = list.filter((v) => v !== value);
      setValue("residentInfo.teacherLicenses", newLists);
    } else {
      const newLists = [...list, value];
      setValue("residentInfo.teacherLicenses", newLists);
    }
  };

  const handleMedicalChange = (value: MedicalLicenseType) => {
    const list = watch("residentInfo.medicalLicenses");
    if (list.includes(value)) {
      const newLists = list.filter((v) => v !== value);
      setValue("residentInfo.medicalLicenses", newLists);
    } else {
      const newLists = [...list, value];
      setValue("residentInfo.medicalLicenses", newLists);
    }
  };

  const handleSkillsChange = (value: SkillType) => {
    const list = watch("residentInfo.skills");
    if (list.includes(value)) {
      const newLists = list.filter((v) => v !== value);
      setValue("residentInfo.skills", newLists);
    } else {
      const newLists = [...list, value];
      setValue("residentInfo.skills", newLists);
    }
  };

  const handlePreferredPaidChange = (value: PaidType) => {
    const prev = watch("residentInfo.preferredPaid");
    if (prev === value) {
      setValue("residentInfo.preferredPaid", null);
    } else {
      setValue("residentInfo.preferredPaid", value);
    }
  };

  const handleVolunteerChange = (value: VolunteerType) => {
    const list = watch("residentInfo.preferredVolunteerType");
    if (list.includes(value)) {
      const newLists = list.filter((v) => v !== value);
      setValue("residentInfo.preferredVolunteerType", newLists);
    } else {
      const newLists = [...list, value];
      setValue("residentInfo.preferredVolunteerType", newLists);
    }
  };

  const getAddress = useCallback(async (value: string) => {
    try {
      const res = await getAddressFromPostalCode(value);
      setValue("baseInfo.prefecture", res.prefecture);
      setValue("baseInfo.city", res.city);
      setValue("baseInfo.address1", res.suburb);
      setValue("baseInfo.address2", "");
      clearErrors("baseInfo.postalCode");
    } catch (err) {
      setError("baseInfo.postalCode", {
        message: "ハイフンは入力せず、正しい郵便番号を入力してください",
      });
    }
  }, []);

  return (
    <ModalPortal onClose={onClose}>
      <ModalWrapper>
        <ModalHeader>情報を編集</ModalHeader>
        <ModalBody>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <NameAndImageSection
                imgPreviewUrl={imgPreviewUrl}
                user={user}
                currentUser={currentUser}
                onClickFileInput={onClickFileInput}
                onInputChange={onInputChange}
                inputFileRef={inputFileRef}
              />
              <PhoneNumberSection />
              {user.type === "RESIDENT" && (
                <>
                  <div>
                    <EditModalFormLabel>性別</EditModalFormLabel>

                    <ButtonGroupExtend>
                      {genderOption.map((option, i) => (
                        <ButtonSelect
                          key={i}
                          selected={watch("baseInfo.gender") === option.value}
                          onClick={() => handleGenderChange(option.value)}
                        >
                          <span>{option.text}</span>
                        </ButtonSelect>
                      ))}
                    </ButtonGroupExtend>
                    {formState.errors?.baseInfo &&
                      formState.errors.baseInfo.gender && (
                        <ErrorMessage>
                          {formState.errors.baseInfo.gender.message}
                        </ErrorMessage>
                      )}
                  </div>
                  <div>
                    <EditModalFormLabel>年代</EditModalFormLabel>
                    <SelectField
                      {...register(`baseInfo.ageGroup` as const, {
                        required: "入力必須項目です",
                        setValueAs: (v) => {
                          try {
                            return zTransformAgeGroup.parse(v);
                          } catch (error) {
                            return undefined;
                          }
                        },
                      })}
                      defaultValue={-1}
                    >
                      <option value="-1" disabled>
                        年代を選択
                      </option>
                      {ageGroup.map((opt) => {
                        return (
                          <option key={opt.value} value={opt.value}>
                            {opt.text}
                          </option>
                        );
                      })}
                    </SelectField>
                    {formState.errors.baseInfo?.ageGroup && (
                      <ErrorMessage>
                        {formState.errors.baseInfo?.ageGroup.message}
                      </ErrorMessage>
                    )}
                  </div>
                  <div>
                    <EditModalFormLabel>生年月日</EditModalFormLabel>
                    <TextField
                      min="1900-01-01"
                      max="2100-12-31"
                      type="date"
                      {...register(`baseInfo.birthday` as const, {
                        validate: (value) => {
                          if (!value) return true;

                          return value && new Date(value) <= new Date()
                            ? true
                            : "未来の日付は設定できません";
                        },
                      })}
                      // style={{ appearance: "none" }}
                    ></TextField>
                    {formState.errors?.baseInfo &&
                      formState.errors.baseInfo.birthday && (
                        <ErrorMessage>
                          {formState.errors.baseInfo.birthday.message}
                        </ErrorMessage>
                      )}
                  </div>
                  <div>
                    <EditModalFormLabel>郵便番号</EditModalFormLabel>
                    <TextField
                      {...register(`baseInfo.postalCode` as const, {
                        required: "入力必須項目です",
                        // validate: (value) =>
                        //   value ? validatePostalCode(value) : false,
                      })}
                      placeholder="例）1030013"
                      onChange={(e) => getAddress(e.target.value)}
                    ></TextField>
                    {formState.errors?.baseInfo &&
                      formState.errors.baseInfo.postalCode && (
                        <ErrorMessage>
                          ハイフンは入力せず、正しい郵便番号を入力してください
                        </ErrorMessage>
                      )}
                  </div>
                  <div>
                    <EditModalFormLabel>住所</EditModalFormLabel>
                    <CheckTabs>
                      <div>
                        <SelectField
                          {...register(`baseInfo.prefecture`)}
                          defaultValue=""
                        >
                          <option value="" disabled>
                            県名を選択
                          </option>
                          {prefectureOptions.map((opt) => {
                            return (
                              <option key={opt.value} value={opt.value}>
                                {opt.text}
                              </option>
                            );
                          })}
                        </SelectField>
                        {formState.errors.baseInfo?.prefecture && (
                          <ErrorMessage>
                            {formState.errors.baseInfo?.prefecture.message}
                          </ErrorMessage>
                        )}
                      </div>
                      <div>
                        <TextField
                          {...register("baseInfo.city")}
                          placeholder="例）川崎市川崎区"
                        />
                        {formState.errors.baseInfo?.city && (
                          <ErrorMessage>
                            {formState.errors.baseInfo?.city.message}
                          </ErrorMessage>
                        )}
                      </div>
                    </CheckTabs>
                    <Margin marginTop={8} />
                    <TextField
                      {...register(`baseInfo.address1`)}
                      placeholder="例）川崎市川崎区"
                    ></TextField>
                    {formState.errors?.baseInfo &&
                      formState.errors.baseInfo.address1 && (
                        <ErrorMessage>
                          {formState.errors.baseInfo.address1.message}
                        </ErrorMessage>
                      )}
                    <Margin marginTop={8} />
                    <TextField
                      {...register(`baseInfo.address2`)}
                      placeholder="例）〇〇マンション101号室"
                    ></TextField>
                    {formState.errors?.baseInfo &&
                      formState.errors.baseInfo.address2 && (
                        <ErrorMessage>
                          {formState.errors.baseInfo.address2.message}
                        </ErrorMessage>
                      )}
                  </div>
                  <div>
                    <EditModalFormLabel>希望活動曜日</EditModalFormLabel>
                    <ButtonGroupExtend>
                      {dayOfWeekOptions.map((option, i) => (
                        <ButtonSelect
                          key={i}
                          selected={methods
                            .watch("residentInfo.preferredDays")
                            .includes(option.value)}
                          onClick={() =>
                            handlePreferredDaysChange(option.value)
                          }
                        >
                          <span>{option.text}</span>
                        </ButtonSelect>
                      ))}
                    </ButtonGroupExtend>
                    {formState.errors?.residentInfo?.preferredDays != null ? (
                      <span className="error-message">
                        {/* @ts-ignore */}
                        {formState.errors?.residentInfo.preferredDays?.map(
                          // @ts-ignore
                          (e) => e.message
                        )}
                      </span>
                    ) : (
                      <Margin marginTop={16} />
                    )}
                  </div>
                  <div>
                    <EditModalFormLabel>教員免許状</EditModalFormLabel>
                    <ButtonGroupExtend>
                      {teachingLicenseOptions.map((option, i) => (
                        <ButtonSelect
                          key={i}
                          selected={methods
                            .watch("residentInfo.teacherLicenses")
                            .includes(option.value)}
                          onClick={() => handleTeacherChange(option.value)}
                        >
                          <span>{option.text}</span>
                        </ButtonSelect>
                      ))}
                    </ButtonGroupExtend>
                    {formState.errors?.residentInfo?.teacherLicenses != null ? (
                      <span className="error-message">
                        {/* @ts-ignore */}
                        {formState.errors?.residentInfo.teacherLicenses?.map(
                          // @ts-ignore
                          (e) => e.message
                        )}
                      </span>
                    ) : (
                      <Margin marginTop={16} />
                    )}
                  </div>
                  <div>
                    <EditModalFormLabel>医療・看護</EditModalFormLabel>
                    <ButtonGroupExtend>
                      {medicalLicenseOptions.map((option, i) => (
                        <ButtonSelect
                          key={i}
                          selected={methods
                            .watch("residentInfo.medicalLicenses")
                            .includes(option.value)}
                          onClick={() => handleMedicalChange(option.value)}
                        >
                          <span>{option.text}</span>
                        </ButtonSelect>
                      ))}
                    </ButtonGroupExtend>
                    {formState.errors?.residentInfo?.medicalLicenses != null ? (
                      <span className="error-message">
                        {/* @ts-ignore */}
                        {formState.errors?.residentInfo.medicalLicenses?.map(
                          // @ts-ignore
                          (e) => e.message
                        )}
                      </span>
                    ) : (
                      <Margin marginTop={16} />
                    )}
                  </div>
                  <div>
                    <EditModalFormLabel>スキル</EditModalFormLabel>
                    <ButtonGroupExtend>
                      {skillOptions.map((option, i) => (
                        <ButtonSelect
                          key={i}
                          selected={methods
                            .watch("residentInfo.skills")
                            .includes(option.value)}
                          onClick={() => handleSkillsChange(option.value)}
                        >
                          <span>{option.text}</span>
                        </ButtonSelect>
                      ))}
                    </ButtonGroupExtend>
                    {formState.errors?.residentInfo?.skills != null ? (
                      <span className="error-message">
                        {/* @ts-ignore */}
                        {formState.errors?.residentInfo.skills?.map(
                          (e) => e!.message
                        )}
                      </span>
                    ) : (
                      <Margin marginTop={16} />
                    )}
                  </div>
                  <div>
                    <EditModalFormLabel>有償無償選択</EditModalFormLabel>
                    <ButtonGroupExtend>
                      {paidOption.map((option, i) => (
                        <ButtonSelect
                          key={i}
                          selected={
                            watch("residentInfo.preferredPaid") === option.value
                          }
                          onClick={() =>
                            handlePreferredPaidChange(option.value)
                          }
                        >
                          <span>{option.text}</span>
                        </ButtonSelect>
                      ))}
                    </ButtonGroupExtend>
                    {formState.errors?.residentInfo?.preferredPaid != null ? (
                      <span className="error-message">
                        {formState.errors?.residentInfo.preferredPaid?.message}
                      </span>
                    ) : (
                      <Margin marginTop={16} />
                    )}
                  </div>
                  <div>
                    <EditModalFormLabel>
                      ボランティアの項目選択
                    </EditModalFormLabel>
                    <ButtonGroupExtend>
                      {volunteerOption.map((option, i) => (
                        <ButtonSelect
                          key={i}
                          selected={methods
                            .watch("residentInfo.preferredVolunteerType")
                            .includes(option.value)}
                          onClick={() => handleVolunteerChange(option.value)}
                        >
                          <span>{option.text}</span>
                        </ButtonSelect>
                      ))}
                    </ButtonGroupExtend>
                    {/* [imo] ↓medicalLicensesでいいのか？ */}
                    {formState.errors?.residentInfo?.medicalLicenses != null ? (
                      <span className="error-message">
                        {/* @ts-ignore */}
                        {formState.errors?.residentInfo.medicalLicenses?.map(
                          // @ts-ignore
                          (e) => e.message
                        )}
                      </span>
                    ) : (
                      <Margin marginTop={16} />
                    )}
                  </div>
                  <div>
                    <EditModalFormLabel>民間企業</EditModalFormLabel>
                    <TextField
                      {...register(`residentInfo.privateEnterprise`)}
                      placeholder="〇〇株式会社"
                    ></TextField>
                    {formState.errors?.residentInfo &&
                      formState.errors.residentInfo?.privateEnterprise && (
                        <ErrorMessage>
                          ハイフンは入力せず、正しい郵便番号を入力してください
                        </ErrorMessage>
                      )}
                  </div>
                  <div>
                    <EditModalFormLabel>大学</EditModalFormLabel>
                    <TextField
                      {...register(`residentInfo.university`)}
                      placeholder="〇〇大学"
                    ></TextField>
                    {formState.errors?.residentInfo &&
                      formState.errors.residentInfo.university && (
                        <ErrorMessage>
                          ハイフンは入力せず、正しい郵便番号を入力してください
                        </ErrorMessage>
                      )}
                  </div>
                  <div>
                    <EditModalFormLabel>外郭団体</EditModalFormLabel>
                    <TextField
                      {...register(`residentInfo.externalOrganization`)}
                      placeholder="〇〇団体"
                    ></TextField>
                    {formState.errors?.residentInfo &&
                      formState.errors.residentInfo?.externalOrganization && (
                        <ErrorMessage>
                          ハイフンは入力せず、正しい郵便番号を入力してください
                        </ErrorMessage>
                      )}
                  </div>
                  <div>
                    <EditModalFormLabel>PTA</EditModalFormLabel>
                    <TextField
                      {...register(`residentInfo.pta`)}
                      placeholder="〇〇PTA"
                    ></TextField>
                    {formState.errors?.residentInfo &&
                      formState.errors.residentInfo.pta && (
                        <ErrorMessage>
                          ハイフンは入力せず、正しい郵便番号を入力してください
                        </ErrorMessage>
                      )}
                  </div>
                  <EditBaseInfoCommunity user={user} />
                </>
              )}
              <Margin marginTop={25} />
              <ModalSubmitButtons
                disabled={
                  Object.keys(formState.errors).length > 0 || doubleClickBlocked
                }
                submitText="保存"
                onCancel={onClose}
              />
            </form>
          </FormProvider>
        </ModalBody>
      </ModalWrapper>
    </ModalPortal>
  );
};
