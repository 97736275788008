import { useQuery } from "@tanstack/react-query";
import { QueryOptions } from "../QueryOptions";
import { useMemo } from "react";
import { useApiContext } from "src/apiClients/client";
import { checkUserInThisCommunity } from "src/apiClients/auth";

export function useCheckUserInThisCommunity(
  userId: string,
  queryOptions: QueryOptions = {}
) {
  const { apiContext } = useApiContext();

  const query = useQuery({
    queryKey: ["api", "auth", "checkUserInThisCommunity", apiContext, userId],
    queryFn: async () => {
      return await checkUserInThisCommunity(userId);
    },
    ...queryOptions,
  });

  return {
    checkUserInThisCommunity: useMemo(() => query.data, [query.data]),
    ...query,
  };
}
