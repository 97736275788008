import React, { useState } from "react";
import { UseFormSetValue } from "react-hook-form";
import { SearchIcon } from "src/components/icons/SearchIcon";
import styled from "styled-components";
import { FormValues } from "./DestinationForm";
import { CheckBox } from "src/components/form/CheckBox";
import {
  TargetClassesType,
  TargetGradeAndClass,
  TargetGradesType,
} from "@shared/types/post/api";
import { ArrowRightIcon } from "src/components/icons/ArrowRight";
import { BREAKPOINTS } from "src/components/Responsive";
import { getGradeLabel } from "@shared/utils/getGradeLabel";
import { useGetCurrentOrganization } from "src/hooks/query/organization";

type props = {
  targetGrades: { id: TargetGradesType; name: string }[];
  targetClasses: { id: TargetClassesType; name: string }[];
  setValue: UseFormSetValue<FormValues>;
  selectedIsAll: boolean;
  selectedGradeAndClasses: TargetGradeAndClass[];
  selectTargetAll: (isAll: boolean) => void;
  getClassName: (
    classItem: TargetClassesType | null | undefined
  ) => string | undefined;
};
function DestinationGradeTab({
  targetGrades,
  targetClasses,
  setValue,
  selectedIsAll,
  selectedGradeAndClasses,
  selectTargetAll,
  getClassName,
}: props) {
  const { organization } = useGetCurrentOrganization();
  const [predictions, setPredictions] = useState<TargetGradeAndClass[]>([]);
  const [openGradeDetails, setOpenGradeDetails] = useState<TargetGradesType[]>(
    selectedIsAll
      ? []
      : [selectedGradeAndClasses.map((gac) => gac.grade)].flat()
  );
  const filterTargets = (searchText: string): TargetGradeAndClass[] => {
    const targetsPool = targetGrades.map((target) => {
      return {
        grade: target.id,
        classes: [
          ...targetClasses.map((cls) => {
            return cls.id;
          }),
        ],
      };
    });
    return targetsPool.map((target) => {
      return {
        grade: target.grade,
        classes: target.classes.filter(
          (cls) =>
            searchText.includes(`${target.grade}年` + getClassName(cls)) ||
            (`${target.grade}年` + getClassName(cls)).includes(searchText)
        ),
      };
    });
  };

  const onFocusSelectedTargetInput = () => {
    if (selectedIsAll) return setPredictions([]);
    setPredictions([
      ...targetGrades.map((target) => {
        return {
          grade: target.id,
          classes: [
            ...targetClasses.map((cls) => {
              return cls.id;
            }),
          ],
        };
      }),
    ]);
  };

  const onChangeSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPredictions(filterTargets(event.target.value));
  };

  const handleChange = (target: TargetGradesType) => {
    let newSelectedGradeAndClasses: TargetGradeAndClass[];
    if (
      selectedGradeAndClasses.some(
        (gradeAndClass) =>
          gradeAndClass.grade === target &&
          gradeAndClass.classes.length === targetClasses.length
      )
    ) {
      newSelectedGradeAndClasses = selectedGradeAndClasses.filter(
        (gradeAndClass) => gradeAndClass.grade !== target
      );
    } else if (
      selectedGradeAndClasses.some(
        (gradeAndClass) => gradeAndClass.grade === target
      )
    ) {
      newSelectedGradeAndClasses = selectedGradeAndClasses.map(
        (gradeAndClass) =>
          gradeAndClass.grade === target
            ? {
                grade: gradeAndClass.grade,
                classes: targetClasses.map((cls) => cls.id),
              }
            : gradeAndClass
      );
    } else {
      newSelectedGradeAndClasses = [
        ...selectedGradeAndClasses,
        {
          grade: target,
          classes: targetClasses.map((cls) => cls.id),
        },
      ];
    }

    //クラス選択部分を開ける
    if (
      !selectedGradeAndClasses.some(
        (gradeAndClass) =>
          gradeAndClass.grade === target &&
          gradeAndClass.classes.length === targetClasses.length
      )
    ) {
      const newOpenGradeDetails = openGradeDetails.some(
        (detail) => detail == target
      )
        ? openGradeDetails
        : [...openGradeDetails, target];
      setOpenGradeDetails(newOpenGradeDetails);
    }
    setValue("targetGradeAndClasses", newSelectedGradeAndClasses);
    if (
      newSelectedGradeAndClasses.length === targetGrades.length &&
      newSelectedGradeAndClasses.every(
        (gac) => gac.classes.length === targetClasses.length
      )
    ) {
      selectTargetAll(true);
    } else {
      selectTargetAll(false);
    }
  };

  const handleGradeAndClass = (
    grade: TargetGradesType,
    cls: TargetClassesType
  ) => {
    //指定されたgradeが存在していれば、そのgradeのclassesにclsを追加・もしくは削除
    //指定されたgradeが存在していなければ、新しくgradeを追加し、そのclassesにclsを追加
    if (
      selectedGradeAndClasses.some(
        (gradeAndClass) => gradeAndClass.grade === grade
      )
    ) {
      const newSelectedGradeAndClasses = selectedGradeAndClasses.map((gac) =>
        gac.grade === grade
          ? {
              grade: gac.grade,
              classes: gac.classes.includes(cls)
                ? gac.classes.filter((c) => c !== cls)
                : [...gac.classes, cls],
            }
          : gac
      );
      setValue("targetGradeAndClasses", newSelectedGradeAndClasses);
      if (
        newSelectedGradeAndClasses.length === targetGrades.length &&
        newSelectedGradeAndClasses.every(
          (gac) => gac.classes.length === targetClasses.length
        )
      ) {
        selectTargetAll(true);
      } else {
        selectTargetAll(false);
      }
    } else {
      const newSelectedGradeAndClasses = [
        ...selectedGradeAndClasses,
        { grade, classes: [cls] },
      ];
      setValue("targetGradeAndClasses", newSelectedGradeAndClasses);
      if (
        newSelectedGradeAndClasses.length === targetGrades.length &&
        newSelectedGradeAndClasses.every(
          (gac) => gac.classes.length === targetClasses.length
        )
      ) {
        selectTargetAll(true);
      } else {
        selectTargetAll(false);
      }
    }
  };
  const toggleGrade = (gradeId: TargetGradesType) => {
    setOpenGradeDetails((prev) =>
      prev.includes(gradeId)
        ? prev.filter((id) => id !== gradeId)
        : [...prev, gradeId]
    );
  };
  const checkTargetAll = (isAll: boolean) => {
    if (isAll) {
      setValue("targetGradeAndClasses", []);
      selectTargetAll(false);
    } else {
      const newSelectedGradeAndClasses = targetGrades.map((target) => {
        return {
          grade: target.id,
          classes: [
            ...targetClasses.map((cls) => {
              return cls.id;
            }),
          ],
        };
      });
      setValue("targetGradeAndClasses", newSelectedGradeAndClasses);
      selectTargetAll(true);
    }
  };
  return (
    <Tab>
      <SearchSection>
        <SearchBox>
          <SearchIcon size={16} />
          <SearchInput
            placeholder="検索"
            onFocus={onFocusSelectedTargetInput}
            onChange={onChangeSearchText}
          />
        </SearchBox>
        {predictions.length > 0 && (
          <>
            <PredictionList>
              {predictions.map((value, index) => {
                return value.classes.map((classItem, classIndex) => (
                  <PredictionListItem key={`${index}-${classIndex}`}>
                    <CheckBox
                      checked={selectedGradeAndClasses.some(
                        (gradeAndClass) =>
                          gradeAndClass.grade === value.grade &&
                          gradeAndClass.classes.includes(classItem)
                      )}
                      onChange={() =>
                        handleGradeAndClass(value.grade, classItem)
                      }
                      label={`${value.grade}年` + getClassName(classItem)}
                    />
                  </PredictionListItem>
                ));
              })}
            </PredictionList>
            <Background onClick={() => setPredictions([])} />
          </>
        )}
      </SearchSection>
      <SelectBoxes>
        <SelectBox>
          <CheckBox
            checked={selectedIsAll}
            onChange={() => {
              checkTargetAll(selectedIsAll);
            }}
            label="全学年"
          ></CheckBox>
        </SelectBox>
        {targetGrades.map((target) => (
          <>
            <SelectBox key={target.id}>
              <CheckBox
                checked={selectedGradeAndClasses.some(
                  (gac) =>
                    gac.grade === target.id &&
                    gac.classes.length === targetClasses.length
                )}
                onChange={() => handleChange(target.id)}
                label={getGradeLabel(
                  target.id,
                  organization?.schoolDisplayType,
                  true,
                  true,
                  "full",
                  "年"
                )}
              />
              <ToggleButton
                onClick={() => {
                  toggleGrade(target.id);
                }}
              >
                {!openGradeDetails.includes(target.id) ? (
                  <ArrowRightIcon size={20} />
                ) : (
                  <ClosedIcon>ー</ClosedIcon>
                )}
              </ToggleButton>
            </SelectBox>
            {openGradeDetails.includes(target.id) && (
              <ClassSelectBox>
                {targetClasses.map((cls) => (
                  <div key={cls.id}>
                    <CheckBox
                      checked={selectedGradeAndClasses.some(
                        (gradeAndClass) =>
                          gradeAndClass.grade === target.id &&
                          gradeAndClass.classes.includes(cls.id)
                      )}
                      onChange={() => {
                        handleGradeAndClass(target.id, cls.id);
                      }}
                      label={cls.name}
                    />
                  </div>
                ))}
              </ClassSelectBox>
            )}
          </>
        ))}
      </SelectBoxes>
    </Tab>
  );
}

export default DestinationGradeTab;

const Tab = styled.div`
  position: relative;
`;

const SearchSection = styled.section`
  padding: 8px 12px;
  border-bottom: 1px solid #d3dae6;
`;

const SearchBox = styled.div`
  width: 100%;
  background-color: #fbfcfd;
  border: 1px solid rgba(19, 34, 149, 0.1);
  border-radius: 6px;
  padding: 10px 8px;
  font-size: 14px;
  position: relative;
  z-index: 1;
  display: flex;
  gap: 8px;
`;

const SearchInput = styled.input`
  height: 100%;
  width: 100%;
  border: none;
  background-color: #fbfcfd;
`;

const PredictionList = styled.ul`
  position: absolute;
  z-index: 4;
  width: calc(100% - 24px);
  background: white;
  list-style: none;
  top: 44px;
  box-shadow: 0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
`;

const PredictionListItem = styled.li`
  border-bottom: 1px solid #d3dae6;
  padding: 8px 12px;
  display: flex;
  gap: 4px;
  font-weight: bold;
  font-size: 12px;
`;

const SelectBoxes = styled.section``;

const SelectBox = styled.div`
  border-bottom: 1px solid #d3dae6;
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  font-weight: bold;
  font-size: 12px;
`;
const ClosedIcon = styled.div`
  margin-right: 4px;
  height: 23px;
`;
const ToggleButton = styled.div`
  padding-left: 20px;
  cursor: pointer;
`;
const ClassSelectBox = styled.div`
  border-bottom: 1px solid #d3dae6;
  padding: 8px 12px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 4px;
  font-weight: 400;
  font-size: 12px;
  flex-wrap: wrap;
  & > div {
    &:last-child {
      grid-column: span 2 / span 2;
    }
  }
  @media (max-width: ${BREAKPOINTS.SP}) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100vw;
  height: 100vh;
`;
