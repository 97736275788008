import { atom, useRecoilState } from "recoil";
import { recoilPersist } from "recoil-persist";
import { RecruitmentListQueryType } from "@shared/validator/features/recruitment.schema";

const { persistAtom } = recoilPersist();

const recruitmentFilterState = atom<RecruitmentListQueryType>({
  key: "RecruitmentFilter",
  default: {
    volunteerType: "",
    isPaidVolunteer: undefined,
    date: "",
    isOpen: false,
  },
  effects_UNSTABLE: [persistAtom],
});

export const useRecruitmentFilter = () => {
  const [recruitmentFilter, setRescruitmentFilter] = useRecoilState(
    recruitmentFilterState
  );

  return [recruitmentFilter, setRescruitmentFilter] as const;
};
