import * as client from "../client";
import { AppliedRecruitment } from "@shared/types/appliedRecruitment";
import { ApplicationStatusWithAll } from "@shared/types/recruitmentApplication";
import { RecruitmentApplicationListOrder } from "@shared/types/recruitmentApplication";
import {
  BERecruitmentApplication,
  RecruitmentApplicationApproveRequest,
  RecruitmentApplicationDeclineRequest,
  RecruitmentApplicationListByAccountIdRequest,
  RecruitmentApplicationListByStatusRequest,
  RecruitmentApplicationRejectRequest,
} from "@shared/types/boardEducation/recruitmentApplication";

export async function getBEApplicationListByAccountId(
  accountId: string
): Promise<AppliedRecruitment[]> {
  return await client.get<
    RecruitmentApplicationListByAccountIdRequest,
    AppliedRecruitment[]
  >("/boardEducation/recruitment-application/getListByAccountId", {
    accountId,
  });
}

export async function getBEApplicationListByStatus(
  status: ApplicationStatusWithAll,
  name: string,
  order?: RecruitmentApplicationListOrder
): Promise<BERecruitmentApplication[]> {
  return await client.get<
    RecruitmentApplicationListByStatusRequest,
    BERecruitmentApplication[]
  >("/boardEducation/recruitment-application/getListByStatus", {
    status,
    name,
    order,
  });
}

/**
 * 応募系APIクライアント 更新系
 */
export async function approveApplication(
  recruitApplicationId: string
): Promise<void> {
  await client.post<RecruitmentApplicationApproveRequest, { result: string }>(
    "/boardEducation/recruitment-application/approve",
    { recruitApplicationId }
  );
}

export async function rejectApplication(
  recruitApplicationId: string,
  comment: string
): Promise<void> {
  await client.post<RecruitmentApplicationRejectRequest, { result: string }>(
    "/boardEducation/recruitment-application/reject",
    {
      recruitApplicationId,
      comment,
    }
  );
}

export async function declineApplicationByRecruitmentApplicationId(
  recruitApplicationId: string,
  comment: string
): Promise<void> {
  await client.post<RecruitmentApplicationDeclineRequest, { result: string }>(
    "/boardEducation/recruitment-application/declineByRecruitmentApplicationId",
    {
      recruitApplicationId,
      comment,
    }
  );
}
