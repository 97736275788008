import { SignatureTemplate } from "@shared/types/signatureTemplate";
import React from "react";
import { entityUrl } from "src/apiClients/storage";
import { ModalBody, ModalHeader, ModalPortal } from "src/components/Modal";
import { PdfViewer } from "src/components/PdfViewer";
import { toDateFormat } from "src/utils/time";

type Props = {
  close: () => void;
  signatureTemplate: SignatureTemplate;
};

export const SignatureTemplateModal = ({ close, signatureTemplate }: Props) => {
  const pdfFilePathForPreview = entityUrl(signatureTemplate.pdfFilePath);

  return (
    <>
      <ModalPortal onClose={() => close()}>
        <ModalHeader>
          {toDateFormat(new Date(signatureTemplate.createdAt))}
        </ModalHeader>
        <ModalBody>
          <PdfViewer width="100%" file={pdfFilePathForPreview} />
        </ModalBody>
      </ModalPortal>
    </>
  );
};
