import { createRoute } from "..";
import AppDefaultLayout from "../../layouts/AppDefaultLayout";
import ResidentHomePage from "../../pages/resident/ResidentHomePage";
import ResidentRecruitmentApplicationListPage from "../../pages/resident/application/ResidentRecruitmentApplicationListPage";
import { ResidentChatPage } from "../../pages/resident/chatMessage/ResidentChatPage";
import ResidentRecruitmentApplyListPage from "../../pages/resident/recruitment/ResidentRecruitmentApplyListPage";
import ResidentRecruitmentApproveListPage from "../../pages/resident/recruitment/ResidentRecruitmentApproveListPage";
import ResidentRecruitmentCreateFromTemplatePage from "../../pages/resident/recruitment/ResidentRecruitmentCreateFromTemplatePage";
import ResidentRecruitmentCreatePage from "../../pages/resident/recruitment/ResidentRecruitmentCreatePage";
import ResidentRecruitmentDetailPage from "../../pages/resident/recruitment/ResidentRecruitmentDetailPage";
import ResidentRecruitmentDraftListPage from "../../pages/resident/recruitment/ResidentRecruitmentDraftListPage";
import ResidentRecruitmentEditDraftPage from "../../pages/resident/recruitment/ResidentRecruitmentEditDraftPage";
import ResidentRecruitmentEditOpenedPage from "../../pages/resident/recruitment/ResidentRecruitmentEditOpenedPage";
import ResidentRecruitmentListPage from "../../pages/resident/recruitment/ResidentRecruitmentListPage";
import ResidentRecruitmentTemplateListPage from "../../pages/resident/recruitment/ResidentRecruitmentTemplateListPage";
import ResidentInviteMemberPage from "../../pages/resident/inviteMember/ResidentInviteMemberPage";
import ResidentCommunityIconPage from "src/pages/resident/admin/ResidentCommunityIconPage";

/**
 * 認証済みユーザーだけが通れるルート(地域住民)
 */
export const RESIDENT_ROUTE_DEFS = {
  RESIDENT_INDEX: createRoute({
    path: "/resident",
    title: "ホーム",
    component: ResidentHomePage,
    layout: AppDefaultLayout,
    route: "CS_MEMBER",
  }),
  RESIDENT_RECRUITMENT_LIST: createRoute({
    path: "/resident/recruitment",
    title: "募集一覧",
    component: ResidentRecruitmentListPage,
    layout: AppDefaultLayout,
    route: "CS_MEMBER",
  }),
  RESIDENT_RECRUITMENT_DETAIL: createRoute<{ id: string }>({
    path: "/resident/recruitment/detail",
    title: "募集詳細",
    component: ResidentRecruitmentDetailPage,
    layout: AppDefaultLayout,
    route: "CS_MEMBER",
  }),
  RESIDENT_RECRUITMENT_CREATE: createRoute({
    path: "/resident/recruitment/create",
    title: "募集新規作成",
    component: ResidentRecruitmentCreatePage,
    layout: AppDefaultLayout,
    route: "CS_MEMBER",
  }),
  RESIDENT_RECRUITMENT_CREATE_FROM_TEMPLATE: createRoute<{
    templateId: string;
  }>({
    path: "/resident/recruitment/template/create",
    title: "ひな形から募集作成",
    component: ResidentRecruitmentCreateFromTemplatePage,
    layout: AppDefaultLayout,
    route: "CS_MEMBER",
  }),
  RESIDENT_RECRUITMENT_EDIT_DRAFT: createRoute<{ id: string }>({
    path: "/resident/recruitment/draft/edit",
    title: "下書き募集編集",
    component: ResidentRecruitmentEditDraftPage,
    layout: AppDefaultLayout,
    route: "CS_MEMBER",
  }),
  RESIDENT_RECRUITMENT_EDIT_OPENED: createRoute<{ id: string }>({
    path: "/resident/recruitment/opened/edit",
    title: "公開募集編集",
    component: ResidentRecruitmentEditOpenedPage,
    layout: AppDefaultLayout,
    route: "CS_MEMBER",
  }),
  RESIDENT_RECRUITMENT_TEMPLATE_LIST: createRoute({
    path: "/resident/recruitment/template",
    title: "募集テンプレート一覧",
    component: ResidentRecruitmentTemplateListPage,
    layout: AppDefaultLayout,
    route: "CS_MEMBER",
  }),
  RESIDENT_RECRUITMENT_APPLY_LIST: createRoute({
    path: "/resident/recruitment/apply",
    title: "応募した募集一覧",
    component: ResidentRecruitmentApplyListPage,
    layout: AppDefaultLayout,
    route: "CS_MEMBER",
  }),
  RESIDENT_RECRUITMENT_PARTICIPATE_LIST: createRoute({
    path: "/resident/recruitment/participate",
    title: "参加する募集一覧",
    component: ResidentRecruitmentApproveListPage,
    layout: AppDefaultLayout,
    route: "CS_MEMBER",
  }),
  // TODO: CSAdminのみが通れるルートとして制御する
  RESIDENT_RECRUITMENT_APPLICATION_LIST: createRoute({
    path: "/resident/recruitment/application_list",
    title: "応募一覧",
    component: ResidentRecruitmentApplicationListPage,
    layout: AppDefaultLayout,
    route: "CS_MEMBER",
  }),
  RESIDENT_RECRUITMENT_DRAFT_LIST: createRoute({
    path: "/resident/recruitment/draft",
    title: "下書き一覧",
    component: ResidentRecruitmentDraftListPage,
    layout: AppDefaultLayout,
    route: "CS_MEMBER",
  }),
  RESIDENT_CHAT: createRoute<{
    tab?: string;
    chatRoomId?: string;
  }>({
    path: "/resident/chat/recruitment",
    title: "メッセージ",
    component: ResidentChatPage,
    layout: AppDefaultLayout,
    route: "CS_MEMBER_OR_CS_GUEST",
  }),
  RESIDENT_COMMUNITY_ICON: createRoute({
    path: "/resident/admin/community_icon",
    title: "アイコン設定",
    component: ResidentCommunityIconPage,
    layout: AppDefaultLayout,
    route: "CS_ADMIN",
  }),
  RESIDENT_INVITATION: createRoute<{ step?: string }>({
    path: "/resident/invitation",
    title: "メンバー招待",
    component: ResidentInviteMemberPage,
    layout: AppDefaultLayout,
    route: "CS_ADMIN",
  }),
};

export const residentRouters = Object.values(RESIDENT_ROUTE_DEFS);
