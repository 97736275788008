import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { Routes } from "src/routers";
import { createPolyfitUrl, BlocksLocation } from "src/hooks/router";

interface TypedLinkProps<T extends Routes> extends Omit<RouterLinkProps, "to"> {
  to: BlocksLocation<T>;
}

export const Link = <T extends Routes>({ to, ...rest }: TypedLinkProps<T>) => {
  const url = createPolyfitUrl(to);
  return <RouterLink to={url} {...rest} />;
};
