import React, { useCallback, useEffect, useState } from "react";
import {
  approveApplication,
  declineApplicationByRecruitmentApplicationId,
  downloadApplicationListPdfByFilter,
  rejectApplication,
} from "../../../apiClients/recruitmentApplication";
import ApproveApplicationModal from "../../../features/Recruitment/application/ApproveApplicationModal";
import RejectApplicationModal from "../../../features/Recruitment/application/RejectApplicationModal";
import DeclineApplicationModal from "../../../features/Recruitment/application/DeclineApplicationModal";
import styled from "styled-components";
import { NoResults } from "../../../features/ListPage/NoResults";
import { ApplicantTable } from "../../../features/Recruitment/application/ApplicantTable";
import { ApplicantCardList } from "../../../features/Recruitment/application/ApplicantCardList";
import { MobileViewOnly, PcViewOnly } from "../../../components/Responsive";
import CountRecruitmentApplicationBar from "../../../features/Recruitment/application/CountRecruitmentApplicationBar";
import { Spinner } from "../../../components/icons/Spinner";
import { logger } from "src/utils/logger";
import {
  ApplicationStatus,
  RecruitmentApplication,
} from "@shared/types/recruitmentApplication";
import { ResidentRecruitmentApplicationFilter } from "./ResidentRecruitmentApplicationFilter";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { getApplicationListByFilter } from "../../../apiClients/recruitmentApplication";
import { Button } from "src/components/Button";
import { Title } from "src/components/Title";
import { Tooltip } from "react-tooltip";
import { useFileDownload } from "src/hooks/useFileDownload";
import { useRecruitmentApplicationCountsForFilter } from "src/hooks/query/recruitments/recruitmentApplication";

export default function ResidentRecruitmentApplicationListPage() {
  // filter
  const methods = useForm<ResidentRecruitmentApplicationFilter>({
    defaultValues: {
      recruitmentId: undefined,
      name: undefined,
      applicationStatus: undefined,
    },
  });

  const [applicantList, setApplicantList] = useState<
    RecruitmentApplication[] | null
  >(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [name, setName] = useState<string>("");

  const getCurrentFormStatusWithDefault = (): {
    status: ApplicationStatus | "ALL";
    name: string;
    recruitmentId: string | undefined;
  } => {
    const status = methods.getValues("applicationStatus") || "ALL";
    const name = methods.getValues("name") || "";
    const recruitmentId = methods.getValues("recruitmentId") || undefined;
    return {
      status,
      name,
      recruitmentId,
    };
  };

  const currentFormStatus = getCurrentFormStatusWithDefault();
  const { applicationUserTypeCounts, applicationStatusCount } =
    useRecruitmentApplicationCountsForFilter(
      {
        recruitmentId: currentFormStatus.recruitmentId,
        name: name,
        status: currentFormStatus.status,
      },
      {
        keepPreviousData: true,
      }
    );

  const fetchRecruitment = useCallback(async () => {
    setLoading(true);
    const currentFormStatus = getCurrentFormStatusWithDefault();
    const recruitments = await getApplicationListByFilter(
      currentFormStatus.status,
      currentFormStatus.name,
      currentFormStatus.recruitmentId
    );
    setName(currentFormStatus.name); //nameは検索ボタン設定時に適用する
    methods.reset(methods.getValues());
    setApplicantList(recruitments);
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchRecruitment();
  }, []);

  const [editingApplicantId, setEditingApplicantId] = useState<
    string | undefined
  >(undefined);
  const [editingApplicantName, setEditingApplicantName] = useState<
    string | undefined
  >(undefined);
  const [confirmChangeStatus, setConfirmChangeStatus] = useState<
    "APPLIED" | "APPROVED" | "REJECTED" | "DECLINED" | undefined
  >(undefined);

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false); //同時にモーダルが開くことは無いので一つのdoubleClickBlockedで十分

  const handleModalOpen = useCallback(
    (
      modalStatus: "APPROVED" | "REJECTED" | "DECLINED",
      editingApplicantId: string,
      editingApplicantName: string
    ) => {
      setConfirmChangeStatus(modalStatus);
      setEditingApplicantId(editingApplicantId);
      setEditingApplicantName(editingApplicantName);
    },
    []
  );

  const handleApproveSubmit = useCallback(async () => {
    setDoubleClickBlocked(true);
    try {
      await approveApplication(editingApplicantId ?? "");
      await fetchRecruitment();
      setConfirmChangeStatus(undefined);
    } catch (error) {
      logger.error(new Error(`Error during approval process:` + error));
    } finally {
      setDoubleClickBlocked(false);
    }
  }, [editingApplicantId]);

  const handleRejectSubmit = useCallback(
    async (comment: string) => {
      setDoubleClickBlocked(true);
      try {
        await rejectApplication(editingApplicantId ?? "", comment);
        await fetchRecruitment();
        setConfirmChangeStatus(undefined);
      } catch (error) {
        logger.error(new Error(`Error during approval process:` + error));
      } finally {
        setDoubleClickBlocked(false);
      }
    },
    [editingApplicantId]
  );

  const handleDeclineSubmit = useCallback(
    async (comment: string) => {
      setDoubleClickBlocked(true);
      try {
        await declineApplicationByRecruitmentApplicationId(
          editingApplicantId ?? "",
          comment
        );
        await fetchRecruitment();
        setConfirmChangeStatus(undefined);
      } catch (error) {
        logger.error(new Error(`Error during approval process:` + error));
      } finally {
        setDoubleClickBlocked(false);
      }
    },
    [editingApplicantId]
  );

  const handleClose = useCallback(() => {
    setConfirmChangeStatus(undefined);
    setEditingApplicantId(undefined);
  }, []);

  const handleChangeStatus = useCallback(
    (applicantId?: string, userName?: string, userId?: string) =>
      (e: React.ChangeEvent<HTMLSelectElement>) => {
        switch (e.target.value) {
          case "APPROVED":
            handleModalOpen(e.target.value, applicantId ?? "", userName ?? "");
            break;
          case "REJECTED":
            handleModalOpen(e.target.value, applicantId ?? "", userName ?? "");
            break;
          case "DECLINED":
            handleModalOpen(e.target.value, applicantId ?? "", userName ?? "");
            break;
          default:
            break;
        }
      },
    []
  );

  const applicantListIsEmpty = applicantList && !applicantList?.length;

  const onSubmit: SubmitHandler<ResidentRecruitmentApplicationFilter> = () => {
    fetchRecruitment();
  };

  const { download } = useFileDownload();

  const currentApplicationStatus = methods.watch("applicationStatus");
  const currentRecruitmentId = methods.watch("recruitmentId");
  const isEnabledPdfExport = (
    currentApplicationStatus: ApplicationStatus | undefined,
    currentRecruitmentId: string | undefined
  ) => {
    if (methods.formState.isDirty) {
      return false;
    } else {
      return Boolean(
        currentApplicationStatus === ApplicationStatus.APPROVED &&
          currentRecruitmentId
      );
    }
  };

  return (
    <ApplicantListPage>
      <CountRecruitmentApplicationBar />
      <PageHeader>
        <Title>応募一覧</Title>
        <Button
          onClick={async () => {
            const currentFormStatus = getCurrentFormStatusWithDefault();
            const blob = await downloadApplicationListPdfByFilter(
              currentFormStatus.status,
              currentFormStatus.name,
              currentFormStatus.recruitmentId
            );
            download(blob, "participant_list.pdf");
          }}
          fill
          size="large"
          disabled={
            !isEnabledPdfExport(currentApplicationStatus, currentRecruitmentId)
          }
          data-tooltip-id={"pdf-export-tooltip"}
        >
          PDF出力
        </Button>
        {!isEnabledPdfExport(currentApplicationStatus, currentRecruitmentId) ? (
          <Tooltip
            id="pdf-export-tooltip"
            place="bottom"
            content="フィルター設定に、任意の募集タイトルが設定されていて、選考ステータスが「参加決定」の場合のみPDF出力できます。"
            style={{ maxWidth: "312px" }}
          />
        ) : null}
      </PageHeader>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ResidentRecruitmentApplicationFilter
            applicationUserTypeCounts={applicationUserTypeCounts}
            applicationStatusCounts={applicationStatusCount}
            isSearchWithName={name !== ""}
          />
        </form>
      </FormProvider>

      {isLoading ? (
        <SpinnerCard>
          <Spinner />
        </SpinnerCard>
      ) : applicantListIsEmpty ? (
        <NoResults />
      ) : (
        <>
          <PcViewOnly>
            <ApplicantTable
              applicantList={applicantList}
              handleChangeStatus={handleChangeStatus}
            />
          </PcViewOnly>
          <MobileViewOnly>
            <ApplicantCardList
              applicantList={applicantList}
              handleChangeStatus={handleChangeStatus}
            />
          </MobileViewOnly>
        </>
      )}
      {confirmChangeStatus === "APPROVED" && (
        <ApproveApplicationModal
          name={editingApplicantName ?? ""}
          onSubmit={handleApproveSubmit}
          onClose={handleClose}
          disabled={doubleClickBlocked}
        />
      )}
      {confirmChangeStatus === "REJECTED" && (
        <RejectApplicationModal
          name={editingApplicantName ?? ""}
          onSubmit={handleRejectSubmit}
          onClose={handleClose}
          disabled={doubleClickBlocked}
        />
      )}
      {confirmChangeStatus === "DECLINED" && (
        <DeclineApplicationModal
          name={editingApplicantName ?? ""}
          onSubmit={handleDeclineSubmit}
          onClose={handleClose}
          disabled={doubleClickBlocked}
        />
      )}
    </ApplicantListPage>
  );
}

const ApplicantListPage = styled.div`
  margin: 0 auto;
`;

const SpinnerCard = styled.div`
  display: flex;
  justify-content: center;
  background: #ffffff;
  padding-bottom: 40px;
  padding-top: 40px;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
`;
