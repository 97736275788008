import React, { useCallback, useState } from "react";
import { PencilIcon } from "src/components/icons/PencilIcon";
import { useGetCSChildren } from "src/hooks/query/csChild";
import {
  ChildFormContainer,
  ChildFormLabel,
  EditBaseInfoIcon,
  EditLinkContainer,
  PCBaseInfoWrapper,
  StyledFlexContainer,
  StyledTd,
  StyledTh,
  StyledTr,
} from "src/pages/pta/admin/ProfilePage";
import styled from "styled-components";
import { CsChildInfoEditModal } from "./CsChildInfoEditModal";
import { getClassName } from "src/utils/getChildClass";
import { useGetCurrentOrganization } from "src/hooks/query/organization";
import { getGradeLabel } from "@shared/utils/getGradeLabel";

export const CsChildInfoContent = () => {
  const { csChildren, refetch, isLoading } = useGetCSChildren();
  const { organization } = useGetCurrentOrganization();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClose = useCallback(() => {
    setIsOpen(false);
    refetch();
  }, [setIsOpen]);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  return (
    <>
      <PCBaseInfoWrapper>
        <StyledFlexContainer>
          {isLoading ? (
            <div>読み込み中...</div>
          ) : (
            <>
              <ChildFormContainer>
                <StyledContainerPc>
                  {csChildren?.length ? (
                    csChildren.map((child, index) => (
                      <ChildrenWrapper key={index} $isFirstChild={index === 0}>
                        <ChildFormLabel>
                          お子さま ({index + 1}人目)
                        </ChildFormLabel>
                        <ChildInfoTable>
                          <StyledTr>
                            <StyledTh>お名前</StyledTh>
                            <StyledTd>
                              {child.childLastName} {child.childFirstName}
                            </StyledTd>
                          </StyledTr>
                          <StyledTr>
                            <StyledTh>学校</StyledTh>
                            <StyledTd>
                              {child.community?.name || "未設定"}
                            </StyledTd>
                          </StyledTr>
                          <StyledTr>
                            <StyledTh>学年</StyledTh>
                            <StyledTd>
                              {child.grade
                                ? getGradeLabel(
                                    child.grade,
                                    organization?.schoolDisplayType,
                                    true,
                                    false,
                                    "short"
                                  )
                                : "未設定"}
                            </StyledTd>
                          </StyledTr>
                          <StyledTr>
                            <StyledTh>クラス</StyledTh>
                            <StyledTd>
                              {child.class
                                ? getClassName(child.class)
                                : "未設定"}
                            </StyledTd>
                          </StyledTr>
                        </ChildInfoTable>
                      </ChildrenWrapper>
                    ))
                  ) : (
                    <ChildrenWrapper $isFirstChild={true}>
                      <ChildFormLabel>お子さま</ChildFormLabel>
                      <ChildInfoTable>
                        <StyledTr>
                          <StyledTh>お名前</StyledTh>
                          <StyledTd>未設定</StyledTd>
                        </StyledTr>
                        <StyledTr>
                          <StyledTh>学校</StyledTh>
                          <StyledTd>未設定</StyledTd>
                        </StyledTr>
                        <StyledTr>
                          <StyledTh>学年</StyledTh>
                          <StyledTd>未設定</StyledTd>
                        </StyledTr>
                        <StyledTr>
                          <StyledTh>クラス</StyledTh>
                          <StyledTd>未設定</StyledTd>
                        </StyledTr>
                      </ChildInfoTable>
                    </ChildrenWrapper>
                  )}
                </StyledContainerPc>
              </ChildFormContainer>

              <ChildFormContainer>
                {csChildren?.length ? (
                  csChildren?.map((child, index) => (
                    <StyledContainerSp key={index}>
                      <ChildFormLabel>
                        お子さま ({index + 1}人目)
                      </ChildFormLabel>
                      <StyledTableSp>
                        <div>
                          <StyledTHSp>お名前</StyledTHSp>
                          <StyledTdSp>
                            {child.childLastName} {child.childFirstName}
                          </StyledTdSp>
                        </div>
                        <div>
                          <StyledTHSp>学校</StyledTHSp>
                          <StyledTdSp>
                            {child.community?.name || "未設定"}
                          </StyledTdSp>
                        </div>
                        <div>
                          <StyledTHSp>学年</StyledTHSp>
                          <StyledTdSp>
                            {child.grade
                              ? getGradeLabel(
                                  child.grade,
                                  organization?.schoolDisplayType,
                                  true,
                                  false,
                                  "short"
                                )
                              : "未設定"}
                          </StyledTdSp>
                        </div>
                        <div>
                          <StyledTHSp>クラス</StyledTHSp>
                          <StyledTdSp>
                            {child.class ? getClassName(child.class) : "未設定"}
                          </StyledTdSp>
                        </div>
                      </StyledTableSp>
                    </StyledContainerSp>
                  ))
                ) : (
                  <StyledContainerSp>
                    <ChildFormLabel>お子さま</ChildFormLabel>

                    <StyledTableSp>
                      <div>
                        <StyledTHSp>お名前</StyledTHSp>
                        <StyledTdSp>未設定</StyledTdSp>
                      </div>
                      <div>
                        <StyledTHSp>学校</StyledTHSp>
                        <StyledTdSp>未設定</StyledTdSp>
                      </div>
                      <div>
                        <StyledTHSp>学年</StyledTHSp>
                        <StyledTdSp>未設定</StyledTdSp>
                      </div>
                      <div>
                        <StyledTHSp>クラス</StyledTHSp>
                        <StyledTdSp>未設定</StyledTdSp>
                      </div>
                    </StyledTableSp>
                  </StyledContainerSp>
                )}
              </ChildFormContainer>

              <EditLinkContainer onClick={onOpen}>
                <PencilIcon />
                <EditBaseInfoIcon>編集</EditBaseInfoIcon>
              </EditLinkContainer>
            </>
          )}
        </StyledFlexContainer>
      </PCBaseInfoWrapper>
      {csChildren && (
        <CsChildInfoEditModal
          isOpen={isOpen}
          userChildren={csChildren}
          refetch={refetch}
          close={onClose}
        />
      )}
    </>
  );
};

const ChildrenWrapper = styled.div<{ $isFirstChild: boolean }>`
  margin-top: ${(props) => (props.$isFirstChild ? 0 : "10px")};
`;

const ChildInfoTable = styled.table`
  margin-top: 10px;
`;

const StyledContainerPc = styled.div`
  @media (max-width: 500px) {
    display: none;
  }
`;

const StyledContainerSp = styled.div`
  @media (min-width: 500px) {
    display: none;
  }

  &:not(:last-child) {
    margin-bottom: 14px;
  }
`;

const StyledTHSp = styled.p`
  font-size: 14px;
  font-weight: bold;
`;

const StyledTdSp = styled.p`
  font-size: 12px;
`;

const StyledTableSp = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 5px;
`;
