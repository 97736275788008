import { useState, useCallback, useEffect } from "react";
import * as authApi from "../../apiClients/auth";
import { ResidentInfoSchemaType } from "@shared/validator/models/residentInfo.schema";

export const useResidentInfo = () => {
  const [currentResidentInfo, setCurrentResidentInfo] = useState<
    ResidentInfoSchemaType | undefined | null
  >(undefined);

  const [isLoading, setIsLoading] = useState(false);

  const getResidentInfo = useCallback(async () => {
    setIsLoading(true);
    const info = (await authApi.getResidentInfo()).data;
    setCurrentResidentInfo(info);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getResidentInfo();
  }, []);

  return {
    isLoading,
    currentResidentInfo,
    refetchResidentInfo: getResidentInfo,
  };
};
