import {
  InfiniteData,
  QueryObserverResult,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { useMemo } from "react";
import { useApiContext } from "src/apiClients/client";
import { fetchChatRooms } from "src/apiClients/chatRoom";
import { QueryOptions } from "src/hooks/query/QueryOptions";
import {
  ChatRoomFilterType,
  ChatRoomListResponse,
} from "@shared/types/chatRoom";

export type ReFetchChatRoomList = () => Promise<
  QueryObserverResult<InfiniteData<ChatRoomListResponse, unknown>, Error>
>;

export const useChatRoomList = (
  filter: ChatRoomFilterType,
  queryOptions: QueryOptions = {}
) => {
  const { apiContext } = useApiContext();
  const take = 20;

  const query = useInfiniteQuery({
    queryKey: ["api", "chatRooms", apiContext, filter],
    queryFn: async ({ pageParam = 1 }) =>
      await fetchChatRooms(filter, pageParam, take),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.hasNextPage) {
        return allPages.length + 1;
      }
      return undefined;
    },
    ...queryOptions,
  });

  const flattenedChatRooms = useMemo(
    () => query.data?.pages.flatMap((page) => page.chatRooms) || [],
    [query.data]
  );

  return {
    chatRooms: flattenedChatRooms,
    ...query,
  };
};
