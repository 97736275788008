import React, { useState, useRef, useCallback } from "react";
import styled from "styled-components";
import { zIndexes } from "../../../zIndex";

type MenuProps = {
  menuList: {
    label: string;
    onClick: () => void;
    color?: string;
  }[];
};

export const FixedPositionMenu = ({ menuList }: MenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleButtonClick = useCallback((e: React.MouseEvent) => {
    const buttonRect = buttonRef.current?.getBoundingClientRect();

    if (buttonRect) {
      setMenuPosition({
        top: buttonRect.top,
        left: buttonRect.left,
      });
    }
    setIsOpen(true);
  }, []);

  return (
    <MenuContainer>
      <MenuButton ref={buttonRef} onClick={handleButtonClick}>
        ...
      </MenuButton>
      {isOpen}
      {isOpen && (
        <>
          <MenuList style={{ top: menuPosition.top, left: menuPosition.left }}>
            {menuList.map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => {
                  item.onClick();
                  setIsOpen(false);
                }}
                $color={item.color}
              >
                {item.label}
              </MenuItem>
            ))}
          </MenuList>
          <Backdrop onClick={() => setIsOpen(false)} />
        </>
      )}
    </MenuContainer>
  );
};

const MenuContainer = styled.div`
  display: inline-block;
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 4px 8px;
  color: #666;

  &:hover {
    color: #333;
  }
`;

const MenuList = styled.div`
  position: fixed;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: ${zIndexes.modal + 1};
  width: 174px;
  transform: translate(-140px, 40px);
  position: fixed;
`;

const MenuItem = styled.li<{ $color?: string }>`
  padding: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8.16px;
  cursor: pointer;
  color: ${({ $color }) => $color || "#000"};

  &:hover {
    background: #deefff;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndexes.modal};
`;
