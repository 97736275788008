import React, { useState } from "react";
import { LinkLineModal } from "src/features/Profile/NotificationSetting/LinkLineModal";
import { colorsPallet } from "src/theme";
import styled from "styled-components";

function SideBarLineBadge() {
  const [isLineModalOpen, setIsLineModalOpen] = useState(false);
  return (
    <>
      <LineBadgeWrapper>
        <LineBadge onClick={() => setIsLineModalOpen(true)}>LINE連携</LineBadge>
      </LineBadgeWrapper>
      <LinkLineModal
        isOpen={isLineModalOpen}
        close={() => {
          setIsLineModalOpen(false);
        }}
        mode="register"
      />
    </>
  );
}

export default SideBarLineBadge;

const LineBadgeWrapper = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  padding: 8px 0;
`;
const LineBadge = styled.button`
  cursor: pointer;
  border: none;
  background-color: ${colorsPallet.primary};
  color: #fff;
  font-size: 14px;
  border-radius: 24px;
  padding: 6px 22px;
`;
